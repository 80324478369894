import React from 'react';
import { Icon } from '@/components';
import classNames from 'classnames';
import './index.scss';

type AlterProps = Omit<{
  type: 'success' | 'danger' | 'tertiary',
  className?: string,
  children: any
}, ''>

export default function Alter({
  type,
  className,
  children
}: AlterProps): React.ReactElement {
  return (
    <div className={classNames('alter', type, className)}>
      <Icon icon={`alter/${type}`} className="icon" />
      {children}
    </div>
  );
}
