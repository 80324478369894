import { ColumnProps } from '@douyinfe/semi-ui/table/interface';
import { Col, Row, TagGroup, Tooltip } from '@douyinfe/semi-ui';
import { Icon, Pagination, Table } from '@/components';
import _ from 'lodash';
import { DateUtils } from '@/utils';
import { TagProps } from '@douyinfe/semi-ui/tag/interface';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { Integration } from '@/model/Integration';
import usePagination from '@/utils/usePagination';
import { useIntegrationList, useJobList } from '@/views/sync/utils/JobRequestHooks';
import { useNavigate } from 'react-router-dom';
import { JobData } from '@/model/Sync';

const statusList = [
  { status: 'RUNNING', color: '#3089D2' },
  { status: 'SUCCEED', color: '#5DBD5B' },
  { status: 'warning', color: '#EE9F2A' },
  { status: 'FAILED', color: '#d25252' }
];

interface StatusTagProps {
  value: number | string,
  status: string,
}

function StatusTag({ status, value }: StatusTagProps): React.ReactElement {
  return (
    <Row
      type="flex"
      align="middle"
    >
      <Icon icon={`sync/integrations/status/${status}`} className="icon w-[16px] h-[16px] mr-[4px]" hideOnError />
      {value}
    </Row>
  );
}

type IntegrationExtends = {
  datasets: string[],
  jobStatus: Record<string, number>,
  lastSync: string
} & Integration

type IntegrationListProps = {
  renderColumn?: (columns: ColumnProps<IntegrationExtends>[]) => ColumnProps<IntegrationExtends>[],
  onRowSelected?: (item: IntegrationExtends) => void
}

export default function IntegrationList({ renderColumn, onRowSelected }: IntegrationListProps) {
  const { t } = useTranslation(['integrations']);
  const { data: jobList } = useJobList();
  const { integrationList, isFetchingIntegrationList } = useIntegrationList();

  const { pageData, onChange, total } = usePagination(integrationList ?? [], 20, 1);
  const nav = useNavigate();

  const buildJobs = (page: Integration[], _jobs: JobData[]): IntegrationExtends[] => _.map(page, (item): IntegrationExtends => {
    const integrationJobs = _.filter(_jobs, (job) => job.integration_id === item.integration_id);
    const jobStatusReduce = (_status: Record<string, number>, jobInstanceStatus: string): Record<string, number> => {
      // job.running_state
      if (jobInstanceStatus in _status) {
        _status[jobInstanceStatus] += 1;
      } else {
        _status[jobInstanceStatus] = 1;
      }
      return _status;
    };

    const init: Record<string, number> = {};
    const status = _.reduce(item.job_statuses, jobStatusReduce, init);

    return {
      datasets: _.map(integrationJobs, (jobItem) => jobItem?.job_name),
      jobStatus: status,
      lastSync: '',
      ...item
    };
  });

  const data = buildJobs(pageData || [], jobList || []);

  const renderStatus = (status: any): any => {
    if (!_.keys(status)?.length) {
      return [{
        color: 'blue',
        className: 'status-tag text-[10px] px-[6px]',
        children: <StatusTag value={t<string>('table.emptyJob', { ns: 'integrations' })} status="empty" />,
        style: {
          backgroundColor: '#A1AAB0',
          color: '#FFFFFF'
        }
      }];
    }
    return _.filter(
      _.map(statusList, (item): TagProps => {
        if (item.status in status) {
          const value = status[item.status];
          const paddingInline = value.toString().length >= 3 ? '4px' : '8px';
          return {
            color: 'blue',
            className: `status-tag ${item.status}`,
            children: <StatusTag value={value} status={item.status} />,
            style: {
              backgroundColor: item.color,
              color: '#FFFFFF',
              paddingInline
            }
          };
        }
        return {};
      }),
      (item) => !!item?.color
    );
  };

  const columns: ColumnProps<IntegrationExtends>[] = [
    {
      title: t('table.fields.name', { ns: 'integrations' }),
      width: '172px',
      render: (record: Record<string, any>) => {
        const type = record.integrate_type;
        const name = record.integration_name;
        return (
          <Row type="flex" align="middle" className="flex-nowrap">
            <Icon
              className="icon integration-type"
              icon={`sync/integrations/engine/${type.toLowerCase()}`}
            />
            <p className="text-ellipsis">
              {name}
            </p>
          </Row>
        );
      }
    },
    {
      title: t('table.fields.dataset', { ns: 'integrations' }),
      dataIndex: 'datasets',
      width: '300px',
      render: (databases) => {
        const databaseList = _.join(databases, ', ');
        return (
          <Tooltip trigger={databases?.length === 0 ? 'custom' : 'hover'} content={databaseList}>
            <div className="datasets">
              <span className="count">{databases?.length}</span>
              <span className="">
                {databaseList}
              </span>
            </div>
          </Tooltip>
        );
      }
    },
    {
      title: t('table.fields.lastSync', { ns: 'integrations' }),
      width: '148px',
      className: 'text-nowrap',
      dataIndex: 'updated_at',
      render: (updatedAt) => DateUtils.fromNow(updatedAt)
    },
    {
      title: t('table.fields.status', { ns: 'integrations' }),
      dataIndex: 'jobStatus',
      width: '158px',
      render: (jobStatus: Record<string, number>, record) => (
        <div onClick={(e) => {
          e.stopPropagation();
          nav(`/sync/integrations/${record.integration_id}/#jobs`);
        }}
        >
          <TagGroup
            maxTagCount={3}
            showPopover
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
            tagList={renderStatus(jobStatus)}
            size="large"
          />
        </div>
      )
    }
  ];

  return (
    <>
      <Table
        className="integrations-table"
        loading={_.isEmpty(integrationList) && isFetchingIntegrationList}
        resizable
        columns={renderColumn ? renderColumn(columns) : columns}
        dataSource={data}
        empty={null}
        onRow={(item: IntegrationExtends) => ({
          onClick: () => {
            if (onRowSelected) {
              onRowSelected(item);
            }
          }
        })}
      />
      <Row className="pagination-wrapper sync-pagination-wrapper" type="flex" align="middle" justify="space-between">
        <Pagination
          total={total}
          showSizeChanger
          onChange={onChange}
        />
        <Col className="total">
          <p className="total-p">
            <span>{'Total Jobs: '}</span>
            <span className="font-medium">{total}</span>
          </p>
        </Col>
      </Row>
    </>
  );
}
