import { AxiosResponse } from 'axios';
import Request from '@/api/Request';
import { ResponseData } from '@/model/Response';
import { RequestLogItem } from '@/model/Log';

export declare type LogSearchParams = {
  app_id: string;
  begin_at: number;
  date: string;
  end_at: number;
  http_code: number | string;
  err_code: number | string;
  sort: string | undefined;
  page?: number;
  limit?: number;
};

export default class LogExplorerApi {
  static getList(data?: LogSearchParams): Promise<
    AxiosResponse<
      ResponseData<{
        logs: RequestLogItem[];
        count: number;
      }>
    >
  > {
    return Request.get('/v2/log/list', {
      params: data
    });
  }

  static getChartData(data: LogSearchParams): Promise<AxiosResponse> {
    return Request.get('/v1/log/count', {
      params: data
    });
  }

  static detail(requestId: string): Promise<AxiosResponse> {
    return Request.get(`/v2/log/${requestId}`);
  }
}
