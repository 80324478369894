import { Dispatch, SetStateAction, useState } from 'react';

export type IsLoading = boolean;
export type LoadingCallback<T extends (...args: any[]) => Promise<any>> = (
  ...args: Parameters<T>
) => ReturnType<T>;
export type ResetFunc = () => void;
export type AnyError = any;

interface CallbackResult<T extends (...args: any[]) => Promise<void>> {
  callback: LoadingCallback<T>,
  loading: IsLoading
  error: AnyError | undefined,
  reset: ResetFunc
}

export const useLoadingCallback = <T extends (...args: any[]) => Promise<any>>(
  callback: T
): CallbackResult<T> => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any | undefined>();

  const handleCallback = async (...args: Parameters<T>): Promise<void> => {
    setError(undefined);
    setIsLoading(true);

    try {
      if (isLoading) {
        return undefined;
      }
      const value = await callback(...args);
      setIsLoading(false);
      return value;
    } catch (e) {
      setError(e);
      setIsLoading(false);
      throw e;
    }
  };

  const reset = (): void => {
    setIsLoading(false);
    setError(undefined);
  };

  return {
    callback: handleCallback as LoadingCallback<T>,
    loading: isLoading,
    error,
    reset
  };
};

export const useLoading = (initialState = false): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [loading, setLoading] = useState(initialState);

  return [
    loading, setLoading
  ];
};
