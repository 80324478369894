import { useEffect, useState } from 'react';

const light = (
  <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.5" d="M0.349609 3.60857L4.971 0.967773L9.59238 3.60857L4.971 6.24936L0.349609 3.60857Z" fill="#6AB6F3" />
    <path opacity="0.5" d="M5.32215 12.1583L5.34585 6.83565L9.94354 4.15381L9.91984 9.47644L5.32215 12.1583Z" fill="#6AB6F3" />
    <path opacity="0.5" d="M4.68078 12.1583L4.65708 6.83565L0.0593931 4.15381L0.0830929 9.47644L4.68078 12.1583Z" fill="#6AB6F3" />
  </svg>
);
const solid = (
  <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.348633 3.60857L4.97002 0.967773L9.59141 3.60857L4.97002 6.24936L0.348633 3.60857Z" fill="#6AB6F3" />
    <path d="M5.32117 12.1583L5.34487 6.83565L9.94256 4.15381L9.91886 9.47644L5.32117 12.1583Z" fill="#409FED" />
    <path d="M4.67883 12.1583L4.65513 6.83565L0.05744 4.15381L0.0811397 9.47644L4.67883 12.1583Z" fill="#3089D2" />
  </svg>
);

export default function QueueAnimation() {
  const [queueIconList, setQueueIconList] = useState([solid, light, light]);

  useEffect(() => {
    const intervalId = window.setInterval(() => {
      setQueueIconList((prev) => {
        const newQueueIconList = [...prev];
        newQueueIconList.unshift(newQueueIconList.pop() as JSX.Element);
        return newQueueIconList;
      });
    }, 1000);

    return () => {
      window.clearInterval(intervalId);
    };
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {queueIconList}
    </>
  );
}
