import React from 'react';
import './index.scss';
import classNames from 'classnames';

export default function Container(
  props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement>
): React.ReactElement {
  const {
    children,
    className,
    ...rest
  } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={classNames('container', className)} {...rest}>
      {children}
    </div>
  );
}
