/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Switch } from '@douyinfe/semi-ui';
import { SwitchProps } from '@douyinfe/semi-ui/lib/es/switch';
import './index.scss';

export default function ChainbaseSwitch(props: SwitchProps): React.ReactElement {
  const { className, disabled, ...otherProps } = props;

  return (
    <Switch
      className={`chainbase-switch ${disabled ? 'chainbase-switch-disabled' : ''} ${className}`}
      disabled={disabled}
      {...otherProps}
    />
  );
}
