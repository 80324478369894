import { ReactNode, createContext, useMemo, useState } from 'react';

export interface TextSelector {
  text: string,
  select(text: string): void;
}

export const TextSelectorContext = createContext<TextSelector>({
  text: '',
  select(): void {
    throw new Error('Function not implemented.');
  }
});

export default function TextSelectorProvider({ children }: {children: ReactNode}) {
  const [selected, setSelectedText] = useState('');

  const value = useMemo(() => ({
    text: selected,
    select(text: string) {
      setSelectedText(text);
    }
  }), [selected]);

  return (
    <TextSelectorContext.Provider value={value}>
      {children}
    </TextSelectorContext.Provider>
  );
}
