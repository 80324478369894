import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { Card, AuthToast } from '@/components';
import AccountApi from '@/api/AccountApi';
import './index.scss';
import AccountLogin from './AccountLogin';

type LocationProps = {
  email: string
}
export default function EmailActivation(): React.ReactElement {
  const location = useLocation();
  const { email = '' } = location.state as LocationProps;
  const { t } = useTranslation();

  const descriptions = t(
    'register.activation.descriptions',
    { returnObjects: true }
  );

  const handleResendEmail: MouseEventHandler = (): boolean => {
    AccountApi.resendEmail(location.state)
      .then(AuthToast.showResponseMessage);
    return false;
  };

  return (
    <div className="register email-activation">
      <Card className="register-card">
        <h5 className="title">{t('register.activation.title')}</h5>
        {
          _.map(descriptions, (description: string, index: number) => (
            <div key={index}>
              <br />
              <p>
                {description.trim() === '[email]' ? email : description}

                <button
                  type="button"
                  className="btn-resend"
                  onClick={handleResendEmail}
                >
                  {
                    index === descriptions.length - 1
                      ? t('register.activation.resendButton')
                      : null
                  }
                </button>
              </p>
            </div>
          ))
        }
      </Card>

      <AccountLogin />
    </div>
  );
}
