import { TableRow } from '@/model/Table';
import { QueryResult } from '@/views/dataCloudStudio/components/SQLStudio/components/QueryResult';
import { ExecutionResults } from '../hooks/dataCloud/useSqlQuery';

function calculateStringSize(str: string) {
  let sizeInBytes = str.length;
  for (let i = 0; i < str.length; i += 1) {
    const code = str.charCodeAt(i);
    if (code > 0x7ff) {
      sizeInBytes += 1;
      if (code > 0xffff) {
        sizeInBytes += 1;
      }
    }
  }
  return sizeInBytes;
}

function calcSize(tableRows: TableRow[]) {
  let size = 0;
  for (let i = 0; i < tableRows.length; i += 1) {
    const row = tableRows[i];
    const rowSize = Object.values(row).map((val) => calculateStringSize(JSON.stringify(val))).reduce((prev, cur) => prev + cur);
    size += rowSize;
  }
  return size;
}

export const castExecutionResultsToQueryResult = (from: ExecutionResults): QueryResult => {
  const columnNames = from.columns.map((col) => col.name);

  const kvResult = from.data.map((row) => {
    const obj: { [key: string]: any } = {};
    columnNames.forEach((name, index) => {
      const key = `${name}_${index}`;
      obj[key] = row[index];
    });
    return obj;
  });

  const to: QueryResult = {
    task_id: from.execution_id,
    rows: from.total_row_count,
    elapsed: from.execution_time_millis,
    meta: from.columns.map((value, index) => ({
      schema_type: 'column',
      name: columnNames[index],
      key: `${value.name}_${index}`,
      type: value.type,
      index
    })),
    kv_result: kvResult,
    bytes_read: calcSize(kvResult)
  };

  return to;
};
