import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Project } from '@/model/Project';
import ProjectApi from '@/api/ProjectApi';
import { Page } from '@/model/Request';
import { RequestTable } from '@/model/Table';
import _ from 'lodash';

type ProjectItem = {
  data?: Project | null;
  loading: boolean;
};

type StateProps = {
  projects: RequestTable<Project>;
  projectMap: Record<string, ProjectItem>;
};

const initialState: StateProps = {
  projects: {
    loading: false,
    list: [],
    pagination: {
      total: 0
    }
  },
  projectMap: {}
};

export const fetchProjectList = createAsyncThunk(
  'project/getList',
  async (page?: Page | undefined) => {
    const response = await ProjectApi.getList(page);
    const { result, total } = response.data;

    if (_.size(result) > 0) {
      try {
        const ids = _.map(result, (item) => item.uuid);

        const monitorResponse = await ProjectApi.getMonitor({ ids });
        _.forEach(result, (item) => {
          item.monitor = _.find(
            monitorResponse.data.result,
            (monitor) => monitor.app_uuid === item.uuid
          );
        });
      } catch (e) {
        console.log(e);
      }
    }

    return {
      loading: false,
      list: result,
      pagination: {
        total
      }
    };
  }
);

export const fetchProject = createAsyncThunk(
  'project/getById',
  async (projectId: string) => {
    const response = await ProjectApi.getById(projectId);
    if (response.data.result && response.data.result.length > 0) {
      return {
        projectId,
        project: response.data.result[0]
      };
    }
    return {
      projectId,
      project: null
    };
  }
);

export const projectReducer = createSlice({
  name: 'ProjectSlice',
  initialState,
  reducers: {
    updateProject: (state, action: PayloadAction<Project>) => {
      state.projectMap[action.payload.uuid] = {
        loading: false,
        data: action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectList.pending, (state) => {
        state.projects.loading = true;
      })
      .addCase(fetchProjectList.fulfilled, (state, action) => {
        state.projects = action.payload;
        state.projects.loading = false;
        action.payload?.list?.forEach((p) => {
          state.projectMap[p.uuid] = {
            loading: false,
            data: p
          };
        });
      })
      .addCase(fetchProjectList.rejected, (state) => {
        state.projects.loading = false;
      })
      .addCase(fetchProject.pending, (state, action) => {
        state.projectMap[action.meta.arg] = {
          loading: true
        };
      })
      .addCase(fetchProject.fulfilled, (state, action) => {
        state.projectMap[action.meta.arg] = {
          loading: false,
          data: action.payload.project
        };
      })
      .addCase(fetchProject.rejected, (state, action) => {
        state.projectMap[action.meta.arg] = {
          loading: false
        };
      });
  }
});

export default projectReducer.reducer;

export const { updateProject } = projectReducer.actions;
