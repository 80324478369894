import { Container } from '@/components';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { BlockLoading } from '@/components/Loading/BlockLoading';
import { FeatureName, useWhiteList } from '@/hooks/UseWhiteList';
import OverviewContent from './components/OverviewContent';
import SyncOverviewHeader from '../commons/SyncOverviewHeader';
import { useIntegrationList } from '../utils/JobRequestHooks';
import IntegrationSelector, { IntegrationType } from '../commons/IntegrationSelector';

export default function SyncIntegrations(): JSX.Element {
  const { t } = useTranslation(['integrations', 'translation']);
  const nav = useNavigate();
  const { isFetchingIntegrationList, isEmpty } = useIntegrationList();
  const { checkWhitelist, openRequestModal } = useWhiteList(FeatureName.sync_service, { rollback: false });

  const handleItemClick = (item: IntegrationType) => {
    if (checkWhitelist()) {
      nav(`/sync/integrations/create?integration=${item.name}`);
    } else {
      openRequestModal();
    }
  };

  return (
    <Container className="integrations-container max-w-full">
      <SyncOverviewHeader
        title={t<string>('name', { ns: 'integrations' })}
        description={t<string>('description', { ns: 'integrations' })}
        buttonContent={t('create', { ns: 'integrations' })}
        onButtonClick={() => {
          if (checkWhitelist()) {
            nav('/sync/integrations/create');
          } else {
            openRequestModal();
          }
        }}
      />
      {isFetchingIntegrationList && <BlockLoading />}
      {!isFetchingIntegrationList && !isEmpty && <OverviewContent />}
      {!isFetchingIntegrationList && isEmpty && (
      <div className="integration-selector-wrapper">
        <IntegrationSelector onItemClick={handleItemClick} />
      </div>
      )}
    </Container>
  );
}
