import {
  ActiveTabContext
} from '@/views/dataCloudStudio/components/Providers/components/ActiveTabProvider';
import { Parameter } from '@/views/dataCloudStudio/components/Providers/components/ParametersProvider';
import {
  SQLFilesContext
} from '@/views/dataCloudStudio/components/Providers/components/SQLFilesProvider';
import _ from 'lodash';
import { useContext, useRef } from 'react';

const ParameterTextRegex = /{{[^{}]*}}/g;

export default function useDynamicParametersBuilder() {
  const sqlFiles = useContext(SQLFilesContext);
  const activeTab = useContext(ActiveTabContext);
  const curFile = sqlFiles?.find((val) => val.uuid === activeTab.current);
  const paramsStr = curFile?.parameters;
  const exsitedParamList = paramsStr
    ? (JSON.parse(paramsStr) as Parameter[])
    : [];
  const exsitedParamNameList = exsitedParamList.map((param) => param.name);
  const deletedSet = useRef(new Set<string>());

  const build = (text: string): Parameter[] => {
    const iterator = text.matchAll(ParameterTextRegex);
    let result = iterator.next();
    const matchedList = [];
    while (!result.done) {
      const value = result.value[0];
      const sliced = value.slice(2, value.length - 2);
      matchedList.push(sliced.trim());
      result = iterator.next();
    }

    const intersaction = _.intersection(exsitedParamNameList, matchedList);
    const toAdd = _.xor(intersaction, matchedList).map((name) => ({
      name,
      dataType: 'Text',
      defaultValue: ''
    }));
    const toRemove = _.xor(intersaction, exsitedParamNameList).filter(
      (name) => !deletedSet.current.has(`${activeTab.current}-${name}`)
    );
    return exsitedParamList
      .concat(toAdd)
      .filter((param) => !toRemove.includes(param.name))
      .filter(
        (param) => !deletedSet.current.has(`${activeTab.current}-${param.name}`)
      );
  };

  const remove = (name: string) => {
    deletedSet.current.add(`${activeTab.current}-${name}`);
  };

  return { build, remove };
}
