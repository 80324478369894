/* eslint-disable camelcase */
import IntegrationList from '@/views/sync/commons/IntegrationList';
import { useNavigate } from 'react-router-dom';

export default function OverviewContent() {
  const nav = useNavigate();

  return (
    <div className="integrations-table-wrapper px-[32px] py-[24px]">
      <IntegrationList
        onRowSelected={(item) => {
          nav(`/sync/integrations/${item.integration_id}`);
        }}
      />
    </div>
  );
}
