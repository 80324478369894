import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useReducer } from 'react';
import TableTree from '@/components/TableTree';
import InfiniteScroll from 'react-infinite-scroller';
import { ColumnSchema, GroupSchema, TableSchema, TreeItemSchema } from '@/model/Table';
import TableDescriptionPopover from '@/components/TableTree/TableDescriptionPopover';
import {
  ActiveTabContext
} from '@/views/dataCloudStudio/components/Providers/components/ActiveTabProvider';
import { isTable } from '@/store/reducers/dataCloudReducer';
import {
  QueryTriggerContext
} from '@/views/dataCloudStudio/components/Providers/components/QueryTriggerProvider';
import {
  EditorRefContext
} from '@/views/dataCloudStudio/components/Providers/components/EditorRefProvider';
import TableTreeSearch from '@/views/dataCloud/index/TableNavigation/TableTreeSearch';
import DataCloudStudioApi from '@/api/DataCloudStudioApi';
import { Loading } from '@/components';
import { Metadata, MetadataGroup, MetadataItem, TablePrompt } from '@/model/DataCloudStudio';
import { dataCloudStudioReducer } from '@/store/reducers/dataCloudStudioReducer';
import { useAppDispatch } from '@/store/hooks';

export declare type TableCategory = 'raw' | 'decoded' | 'abstracted';

function isMetadataGroup(object: Metadata | MetadataGroup): object is MetadataGroup {
  return 'type' in object && object.type === 'group';
}

interface TableGroup {
  queryParams?: any;
  list: MetadataItem[];
  next?: string;
}

interface TableTreeState {
  isLoading: boolean;
  groups: TableGroup[]
}

type Action =
    | {type: 'loading'}
    | { type: 'update', state: Partial<TableTreeState> }
    | { type: 'join', group: TableGroup };

function createTableTreeReducer(state: TableTreeState, action: Action) {
  const { type } = action;
  switch (type) {
    case 'loading':
      return { ...state, isLoading: true };
    case 'join':
      for (let index = 0; index < state.groups.length; index += 1) {
        if (action.group === state.groups[index]) {
          return {
            ...state,
            isLoading: false
          };
        }
      }
      return state;
    case 'update':
      return { ...state, ...action.state };
    default:
      throw new Error(`Action ${action} not supported`);
  }
}

type TableTreeScrollListProps = {
  type: TableCategory
}

export default function TableTreeScrollList({ type }: TableTreeScrollListProps) {
  const activeTab = useContext(ActiveTabContext);
  const sqlQuery = useContext(QueryTriggerContext);
  const editorRef = useContext(EditorRefContext);
  const dispatch = useAppDispatch();

  const [tableState, tableStateDispatch] = useReducer(
    createTableTreeReducer,
    {
      isLoading: false,
      groups: [
        { list: [], next: undefined }
      ]
    } as TableTreeState
  );

  const fetchMetadata = useCallback(async (params?: any, cursor?: string) => {
    const currentGroup = tableState.groups[tableState.groups.length - 1];
    tableStateDispatch({ type: 'loading' });
    const response = await DataCloudStudioApi.getMetaDataList(type, {
      next: cursor,
      ...params
    });

    // fix Asynchronous loading may result in data corruption.
    if (cursor === currentGroup.next) {
      const { next, data: tables } = response.data;

      currentGroup.list = [...(currentGroup?.list || []), ...tables];
      currentGroup.next = next;
      const tablePrompts = _.map(_.filter(tables, (item) => item.type === 'table'), (item) => ({
        column: item.column,
        table: item.table,
        name: item.name,
        type: item.type
      } as TablePrompt));
      dispatch(dataCloudStudioReducer.actions.appendTables(tablePrompts));

      tableStateDispatch({ type: 'join', group: currentGroup });
    }
  }, [dispatch, tableState, tableStateDispatch, type]);

  useEffect(() => {
    fetchMetadata();
  }, []);

  const renderPopoverContent = (item: TableSchema): React.ReactElement => (
    <TableDescriptionPopover
      data={item}
      onPreview={(tableName) => {
        if (!activeTab.current) {
          return;
        }
        sqlQuery.preview(activeTab.current, tableName);
      }}
    />
  );

  const handleTableClick = (schema: TableSchema | ColumnSchema) => {
    if (activeTab.current) {
      if (isTable(schema)) {
        editorRef.insert(schema.table_name);
      } else {
        editorRef.insert(schema.name);
      }
    }
  };

  const handleSearch = useCallback(async (values: Record<string, any>) => {
    const currentGroup = tableState.groups[tableState.groups.length - 1];
    if (!values.keyword) {
      values.keyword = '';
    }
    const params = { ...currentGroup.queryParams, ...values };
    currentGroup.queryParams = params;
    currentGroup.list = [];
    currentGroup.next = undefined;
    tableStateDispatch({ type: 'update', state: tableState });
    await fetchMetadata(params, undefined);
  }, [tableState, fetchMetadata]);

  const toTree = (node: MetadataItem): TreeItemSchema => {
    // if we don't have the parent yet

    if (isMetadataGroup(node)) {
      return {
        schema_type: 'group',
        name: node.name,
        schema: node.chain,
        description: '',
        link: '',
        // logo: 'dataCloud/contract',
        children: []
      } as GroupSchema;
    }

    return {
      schema_type: 'table',
      name: node.name,
      description: node.description,
      table_name: node.table,
      schema: node.chain,
      chainPriority: 0,
      priority: node.priority,
      type: node.category,
      decodeType: node.functionType,
      columns: _.map(node.column, (col, index) => ({
        index,
        schema_type: 'column',
        name: col.name,
        type: col.dataType
      }))
    };
  };

  const handleExpandGroup = async (paths: string[]) => {
    if (tableState.groups.length - 1 < paths.length) {
      const params = { blockchain: 'all', groupNames: paths };
      tableState.groups.push({ queryParams: params, list: [] } as TableGroup);
      tableStateDispatch({ type: 'update', state: tableState });
      await fetchMetadata(params);
    } else {
      tableState.groups = _.slice(tableState.groups, 0, paths.length + 1);
      tableStateDispatch({ type: 'update', state: tableState });
    }
  };

  const buildTableTree = (metadataList: MetadataItem[]): (GroupSchema | TableSchema)[] => _.map(metadataList, toTree);

  const currentGroup = tableState.groups[tableState.groups.length - 1];

  const handleLoadMore = useCallback(async () => {
    const current = tableState.groups[tableState.groups.length - 1];

    if (!tableState.isLoading && current.next) {
      await fetchMetadata(current.queryParams, current.next);
    }
  }, [tableState, fetchMetadata]);

  return (
    <>
      <TableTreeSearch key={JSON.stringify(currentGroup.queryParams)} defaultValue={currentGroup.queryParams} onSearch={handleSearch} />

      <InfiniteScroll
        loadMore={handleLoadMore}
        className="scroll-list"
        hasMore={!!currentGroup?.next}
        loader={tableState?.isLoading && currentGroup?.list?.length !== 0 ? (
          <div className="mb-[8px]">
            <Loading className="w-[18px] h-[18px]" centered type="grey" />
          </div>
        ) : undefined}
        useWindow={false}
        // threshold={400}
      >
        <TableTree
          key={`TableTree:${type}`}
          previewable
          loading={tableState.isLoading && currentGroup?.list?.length === 0}
          list={buildTableTree(currentGroup?.list || [])}
          onTableClick={handleTableClick}
          renderPopoverContent={renderPopoverContent}
          category={type}
          onExpandGroup={handleExpandGroup}
        />
      </InfiniteScroll>
    </>
  );
}
