import FortmaticWallet from 'fortmatic';
import { ethers } from 'ethers';
import { Wallet } from './Wallet';

export default class FortMatic extends Wallet {
  provider: ethers.providers.Web3Provider;

  constructor() {
    super();
    const fortMaticKey = process.env.REACT_APP_FORTMATIC_API_KEY as string;
    const fm = new FortmaticWallet(fortMaticKey);
    const provider: any = fm.getProvider();
    this.provider = new ethers.providers.Web3Provider(provider);
    this.requestAccount();
  }
}
