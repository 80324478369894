import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { Button } from '@douyinfe/semi-ui';
import Lottie from 'lottie-react';
import { Icon } from '@/components';
import { useNavigate } from 'react-router-dom';
import { FeatureName, useWhiteList } from '@/hooks/UseWhiteList';
import animationData from './sync-job-empty.json';

export default function EmptyJob(): React.ReactElement {
  const { t } = useTranslation();
  const tEmpty = (text: string): string => t(`sync.jobs.empty.${text}`);
  const tIntegration = (text: string): string => tEmpty(`integrations.${text}`);
  const nav = useNavigate();
  const { checkWhitelist, openRequestModal } = useWhiteList(FeatureName.sync_service, { rollback: false });

  const onCreateClick = (): void => {
    if (!checkWhitelist()) {
      openRequestModal();
    } else {
      nav('/sync/jobs/create');
    }
  };

  const renderTitle = (): React.ReactElement => (
    <h1 className="empty-job-title">
      <span className="first-word">
        {tEmpty('title.firstWord')}
      </span>
      <span>
        {tEmpty('title.remainedWords')}
      </span>
    </h1>
  );

  const rendeListItem = (props: {
    icon: string,
    title: string,
    iconPosition: 'left' | 'right',
    iconWarpperClass?: string
  }): React.ReactElement => (
    <li className="list-item">
      <div className={`icon-warpper ${props.iconWarpperClass}`}>
        <Icon className={`list-item-icon on-${props.iconPosition} ${props.iconWarpperClass}`} icon={props.icon} />
      </div>
      <p className="list-item-text">{props.title}</p>
    </li>
  );

  const renderDatasets = (): React.ReactElement => (
    <div className="empty-job-main-list datasets">
      <h2>{tEmpty('datasets.title')}</h2>
      <ul className="dataset-list">
        {rendeListItem({
          icon: 'sync/jobs/empty/raw-data',
          title: tEmpty('datasets.rawData'),
          iconPosition: 'left'
        })}
        {rendeListItem({
          icon: 'sync/jobs/empty/decoded-data',
          title: tEmpty('datasets.decodedData'),
          iconPosition: 'left'
        })}
        {rendeListItem({
          icon: 'sync/jobs/empty/abstracted-data',
          title: tEmpty('datasets.abstractedData'),
          iconPosition: 'left',
          iconWarpperClass: 'abstracted-data'
        })}
        {rendeListItem({
          icon: 'sync/jobs/empty/more-data',
          title: tEmpty('datasets.moreData'),
          iconPosition: 'left'
        })}
      </ul>
    </div>
  );

  const renderIntegrations = (): React.ReactElement => (
    <div className="empty-job-main-list integrations">
      <h2>{tEmpty('integrations.title')}</h2>
      <ul>
        {rendeListItem({
          icon: 'sync/jobs/empty/s3',
          title: tIntegration('s3'),
          iconPosition: 'right'
        })}
        {rendeListItem({
          icon: 'sync/jobs/empty/webhook',
          title: tIntegration('webhook'),
          iconPosition: 'right'
        })}
        {rendeListItem({
          icon: 'sync/jobs/empty/kafka',
          title: tIntegration('kafka'),
          iconPosition: 'right'
        })}
        {rendeListItem({
          icon: 'sync/jobs/empty/snowflake',
          title: tIntegration('snowflake'),
          iconPosition: 'right'
        })}
        {rendeListItem({
          icon: 'sync/jobs/empty/mysql',
          title: tIntegration('mysql'),
          iconPosition: 'right'
        })}
        {rendeListItem({
          icon: 'sync/jobs/empty/postgresql',
          title: tIntegration('postgresql'),
          iconPosition: 'right'
        })}
        {rendeListItem({
          icon: 'sync/jobs/empty/elasticsearch',
          title: tIntegration('elasticsearch'),
          iconPosition: 'right'
        })}
        {rendeListItem({
          icon: 'sync/jobs/empty/more-data',
          title: tIntegration('more'),
          iconPosition: 'right'
        })}
      </ul>
    </div>
  );

  const renderMain = (): React.ReactElement => (
    <div className="empty-job-main-warpper">
      <Lottie
        className="empty-job-animation"
        animationData={animationData}
      />
      <div className="empty-job-main">
        {renderDatasets()}
        {renderIntegrations()}
        <Icon icon="sync/jobs/empty/arrow-group" className="arrow-group" />

        <div className="logo-warpper">
          <Icon icon="sync/jobs/empty/chainbase-logo" className="chainbase-logo logo" />
        </div>
      </div>
    </div>
  );

  const renderFooter = (): React.ReactElement => (
    <footer className="empty-job-footer">
      <Button
        className="empty-job-create-button"
        theme="solid"
        onClick={onCreateClick}
      >
        {tEmpty('buttonContent')}
      </Button>
      <p className="empty-job-more-info">
        <span className="prev-words">
          {tEmpty('moreInfo.prevWords')}
        </span>
        <a className="last-word" href="https://docs.chainbase.com/docs/sync-overview">
          {tEmpty('moreInfo.lastWord')}
        </a>
      </p>
    </footer>
  );

  return (
    <div className="empty-job-warpper">
      {renderTitle()}
      {renderMain()}
      {renderFooter()}
    </div>
  );
}
