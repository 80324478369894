import { Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';
import _ from 'lodash';
import './login.scss';
import { Icon } from '@/components';
import AccountApi from '@/api/AccountApi';
import { useAuth } from '@/config/auth/AuthProvider';

export default function LoginLayout(): React.ReactElement {
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    auth.setAuthLoading(true);
    AccountApi.currentUserInfo({
      headers: {
        IgnoreAuth: true
      }
    })
      .then((response) => {
        const { code, account } = response.data;

        const con = _.find(['/activationStatus', '/reset'], (item) => window.location.pathname.startsWith(item));

        if (code === 200) {
          auth.setCurrentUser(account);
          if (!con) {
            navigate('/');
          }
        }
      })
      .finally(() => {
        auth.setAuthLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="login-container">
      <button
        type="button"
        className="btn-logo"
        onClick={() => {
          window.location.reload();
        }}
      >
        <Icon className="logo" icon="header/Logo-black" />
      </button>
      <div className="content">
        <Outlet />
      </div>
      <div className="footer">
        <p className="medium">
          {t('login.copyright')}
          <a className="secondary" href="https://chainbase.com/terms" target="_blank" rel="noreferrer">
            {t('login.teamOfService')}
          </a>
        </p>
      </div>
    </div>
  );
}
