import React from 'react';
import {
  Container,
  Empty,
  HeaderTitle,
  Icon,
  Layout, Modal,
  Pagination,
  Table, Toast
} from '@/components';
import {
  Button, Col, Row, Typography
} from '@douyinfe/semi-ui';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ArchivedQueryItem } from '@/props';
import { ColumnProps } from '@douyinfe/semi-ui/lib/es/table';
import ArchivedQueriesApi from '@/api/ArchivedQueriesApi';
import { DateUtils } from '@/utils';
import {
  dataCloudTableTree,
  fetchDataCloudTable
} from '@/store/reducers/dataCloudReducer';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import px2rem from '@/utils/px2rem';
import classNames from 'classnames';
import {
  fetchArchivedQueriesList
} from '@/store/reducers/archivedQueriesReducer';
import { Guide } from '@/config/constant';
import OperationButton from '@/components/Button/OperationButton';
import { fetchProjectList } from '@/store/reducers/projectReducer';
import ArchivedQueryFormModal from './Form';
import QueryBlockChains from './QueryBlockChains';
import './index.scss';

export default function ArchivedQueries(): React.ReactElement {
  const { Header } = Layout;
  const { t } = useTranslation(['archivedQueries', 'translation']);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const tableList = useAppSelector(dataCloudTableTree);
  const archivedQueriesTable = useAppSelector((state) => state.archivedQueries.table);
  const search = useAppSelector((state) => state.archivedQueries.search);

  React.useEffect(() => {
    // first load table list
    if (_.size(tableList) === 0) {
      dispatch(fetchDataCloudTable());
    }
    dispatch(fetchArchivedQueriesList({
      page: 1,
      limit: 20
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewQuery = (): void => {
    navigate('/dataCloud');
  };

  const columns: ColumnProps<ArchivedQueryItem>[] = [
    {
      title: t('archivedQueries.tableTitle.name'),
      minWidth: '200px',
      dataIndex: 'title',
      render(title: string, record): React.ReactElement {
        return (
          <Typography.Text link={
            {
              onClick() {
                navigate('/dataCloud', {
                  state: record
                });
              }
            }
          }
          >
            {title}
          </Typography.Text>
        );
      }
    },
    {
      title: t('archivedQueries.tableTitle.description'),
      dataIndex: 'description',
      render(description) {
        return (
          <div
            style={
              {
                maxWidth: '200px'
              }
            }
            className="description text-ellipsis"
          >
            {description}
          </div>
        );
      }
    },
    {
      title: t('common.blockchain', { ns: 'translation' }),
      dataIndex: 'blockchain',
      render: (data, record: ArchivedQueryItem) => (
        <QueryBlockChains
          data={record}
        />
      )
    },
    {
      title: t('common.createdAt', { ns: 'translation' }),
      dataIndex: 'created_at',
      render: (data) => DateUtils.format(data)
    },
    {
      title: t('common.modifiedAt', { ns: 'translation' }),
      dataIndex: 'updated_at',
      render: (data) => DateUtils.fromNow(data)
    },
    {
      title: t('common.more', { ns: 'translation' }),
      dataIndex: 'id',
      width: px2rem.calcPXByScreen(100),
      render: (uuid: string, record: ArchivedQueryItem) => (
        <OperationButton
          menu={[
            {
              node: 'item',
              name: t('common.edit', { ns: 'translation' }),
              onClick: () => {
                const modal = Modal.info({
                  title: t(`archivedQueries.form.${record?.uuid ? 'updateTitle' : 'createTitle'}`),
                  className: 'archived-queries-modal',
                  footer: null,
                  content: <ArchivedQueryFormModal
                    archivedQueries={record}
                    onCancel={() => modal.destroy()}
                    onComplete={() => {
                      dispatch(fetchProjectList());
                    }}
                  />
                });
              }
            },
            {
              node: 'item',
              name: t('common.delete', { ns: 'translation' }),
              type: 'danger',
              onClick: () => {
                Modal.delete({
                  title: t('archivedQueries.deleteTitle'),
                  tip: t('archivedQueries.deleteTip').replace('{archivedQueries}', record.title),
                  onComplete: () => {
                    dispatch(fetchArchivedQueriesList(search));
                  },
                  onDelete: async () => {
                    const response = await ArchivedQueriesApi.delete(record?.uuid || '');
                    if (response.data.code === 200) {
                      Toast.success(t('toast.deleted', { ns: 'translation', name: record.title, type: t('archivedQueries.type') }));
                    } else {
                      Toast.toastResponse(response);
                    }
                    return response;
                  }
                });
              }
            }
          ]}
        />
      )
    }
  ];

  return (
    <Container className="archived-queries-container max-w-full">
      <HeaderTitle title={t('navigation.archived', { ns: 'translation' })} />

      <Header
        icon="navigation/archivedQuery"
        title={t('navigation.archived', { ns: 'translation' })}
      >
        <Row>
          <Col>
            <Button
              size="large"
              icon={
                <Icon icon="common/create" />
              }
              theme="solid"
              type="primary"
              onClick={handleNewQuery}
            >
              {t('archivedQueries.new')}
            </Button>
          </Col>
        </Row>

      </Header>

      <div className={classNames('archived-queries-list')}>
        <Table
          loading={archivedQueriesTable?.loading}
          columns={columns}
          dataSource={archivedQueriesTable?.list}
          empty={(
            <Empty
              title={t('navigation.archived', { ns: 'translation' })}
              description={t('archivedQueries.empty.description')}
              howTo={t('archivedQueries.empty.howTo')}
              aboutLink={Guide.dataCloudAPI}
              onGetStarted={handleNewQuery}
            />
          )}
        />

        <Pagination
          total={archivedQueriesTable?.pagination?.total || 0}
          showSizeChanger
          currentPage={search?.page}
          pageSize={search?.limit}
          onPageChange={(page) => {
            dispatch(fetchArchivedQueriesList({ page }));
          }}
          onPageSizeChange={(newPageSize) => {
            dispatch(fetchArchivedQueriesList({ limit: newPageSize }));
          }}
        />

      </div>
    </Container>
  );
}
