import React, { MouseEvent, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableTreeSearch from '@/views/dataCloud/index/TableNavigation/TableTreeSearch';
import classNames from 'classnames';
import { TabPane, Tabs, Tooltip } from '@douyinfe/semi-ui';
import TableTree from '@/components/TableTree';
import { TabBarProps } from '@douyinfe/semi-ui/tabs/interface';
import { ColumnSchema, GroupSchema, TableSchema, TreeItemSchema } from '@/model/Table';
import _ from 'lodash';
import { isGroup } from '@/store/reducers/dataCloudReducer';
import { useSelector } from 'react-redux';
import { blockchainList } from '@/store/reducers/constantReducer';
import './index.scss';
import useTableMetadata from '@/utils/hooks/useTableMetadata';
import { Metadata } from '@/model/DataCloudStudio';
import useFilter from './utils/useFilter';

type DataType = 'raw' | 'decoded' | 'abstracted' ;

interface DataExplorerTabsProps {
  previewable?: boolean;
  onTableClick?: (schema: TableSchema | ColumnSchema) => void;
  renderPopoverContent?: (item: TableSchema) => React.ReactElement;
  disabledTabs?: DataType[];
}

export interface DataExplorerTabsRef {
  tableNames: string[];
}

const DataExplorerTabs = React.forwardRef<DataExplorerTabsRef, DataExplorerTabsProps>(({
  previewable = true,
  onTableClick,
  renderPopoverContent,
  disabledTabs
}, ref) => {
  const [tab, setTab] = useState<DataType>('raw');
  const filter = useFilter();
  const { t } = useTranslation();
  const blockchains = useSelector(blockchainList).map((v) => v.toLowerCase());

  const { raw, decoded, abstracted, isLoading } = useTableMetadata();

  useImperativeHandle(ref, () => ({
    tableNames: raw.concat(decoded).concat(abstracted).map((item) => item.table)
  }));

  // Handlers
  const onChainSelected = (chainName: string) => {
    filter.update('byChain', chainName);
  };

  const renderSearcher = () :React.ReactElement => (
    <TableTreeSearch onSearch={(values: any) => {
      const {
        blockchain,
        name
      } = values;
      onChainSelected(blockchain);
      filter.update('byName', name || '');
    }}
    />
  );

  const renderTabItem = (selfTab: DataType, onClick?: (activeKey: string, event: MouseEvent) => void): React.ReactElement => (
    <li
      className={classNames('flex-1 rounded-md transition-[background-color]', {
        'bg-white': tab === selfTab,
        'border-[1px] border-solid !border-[#E3E7EC]': tab === selfTab
      })}
    >
      <button
        className={classNames('w-full h-full text-sm', {
          'text-[#A1AAB0]': tab !== selfTab,
          'font-semibold': tab === selfTab,
          'cursor-not-allowed': disabledTabs?.includes(selfTab)
        })}
        type="button"
        disabled={disabledTabs?.includes(selfTab)}
        onClick={(event) => {
          setTab(selfTab);
          if (onClick) {
            onClick(selfTab, event);
          }
        }}
      >
        <Tooltip
          content="comming soon"
          trigger={disabledTabs?.includes(selfTab) ? 'hover' : 'custom'}
          visible={disabledTabs?.includes(selfTab) ? undefined : false}
        >
          {t(`dataCloud.${selfTab}`)}
        </Tooltip>
      </button>
    </li>
  );

  const handleTableClick = (schema: TableSchema | ColumnSchema) => {
    if (onTableClick) {
      onTableClick(schema);
    }
  };

  const toTree = (branches: TreeItemSchema[], node: Metadata): TreeItemSchema[] => {
    const sortedList = (nodes: TreeItemSchema[]) => _.sortBy(nodes, ['chainPriority', 'priority', 'name']);
    // if we don't have the parent yet

    const chain = _.head(node.chain);
    const chainPriority = chain ? blockchains.indexOf(chain) : Number.MAX_VALUE;
    let leafNode: TableSchema | GroupSchema | null = {
      schema_type: 'table',
      name: node.name,
      description: node.description,
      table_name: node.table,
      schema: node.chain,
      chainPriority: chainPriority === -1 ? Number.MAX_VALUE : chainPriority,
      priority: node.priority,
      type: node.category,
      decodeType: node.functionType,
      latest_block_timestamp: node.latestBlockNumber,
      row_count: node.rowCount,
      columns: _.map(node.column, (col, index) => ({
        index,
        schema_type: 'column',
        name: col.name,
        type: col.dataType,
        description: col.description
      }))
    };

    if (node.project) {
      const targetProject = _.find(branches, (item) => item.name === node.project);

      if (node.contractName) {
        const contractProject = _.find(targetProject?.children, (item) => item.name === node.contractName);
        const table = leafNode;

        if (contractProject?.children) {
          contractProject.children = sortedList([...(contractProject?.children || []), leafNode]);
          // contractProject?.children.push(leafNode);
          leafNode = null;
        } else {
          leafNode = {
            schema_type: 'group',
            name: node.contractName,
            description: '',
            link: '',
            logo: 'dataCloud/contract',
            children: [
              table
            ]
          };
        }
      }

      if (leafNode === null) {
        return branches;
      }

      if (!targetProject) {
        // create a dummy placeholder for now
        const group: GroupSchema = {
          schema_type: 'group',
          name: node.project,
          description: '',
          chainPriority: -1,
          link: '',
          logo: `dataCloud/v2/${node.category}-icon`,
          children: [
            leafNode
          ]
        };
        branches.push(group);
      } else if (isGroup(targetProject)) {
        targetProject.children = sortedList([...targetProject.children, leafNode]);
      }
    } else {
      branches.push(leafNode);
    }
    return sortedList(branches);
  };

  const buildTableTree = (metadataList: Metadata[]): (GroupSchema | TableSchema)[] => _.reduce(metadataList, toTree, []);

  const renderTree = (category: string, tableMetadataList: Metadata[]): React.ReactElement => {
    const filteredRawList = (tableMetadataList || []).filter((table) => filter.byName(table.name) && filter.byChain(table.chain));
    const tableTree = buildTableTree(filteredRawList);

    return (
      <TabPane tab={t(`dataCloud.${category}`)} itemKey={category}>
        <TableTree
          loading={isLoading}
          list={tableTree}
          onTableClick={handleTableClick}
          renderPopoverContent={renderPopoverContent}
          category={category}
          previewable={previewable}
        />
      </TabPane>
    );
  };

  const renderContent = (): React.ReactElement => (
    <>
      {renderTree('raw', raw)}
      {renderTree('decoded', decoded)}
      {renderTree('abstracted', abstracted)}
    </>
  );

  const renderTabBar = (tabBarProps: TabBarProps): React.ReactElement => (
    <div className="px-[24px] mb-[8px]">
      <ul className="mt-[8px] list-none flex justify-between p-[3px] bg-[#EDF1F4] rounded-[4px] h-[30px]">
        {renderTabItem('raw', tabBarProps.onTabClick)}
        {renderTabItem('decoded', tabBarProps.onTabClick)}
        {renderTabItem('abstracted', tabBarProps.onTabClick)}
      </ul>
    </div>
  );

  return (
    <div className="flex flex-col h-full table-navigation overflow-hidden data-explorer-tabs-container">
      {renderSearcher()}
      <div className="w-full h-[calc(100%-500px)] flex-col flex-1">
        <Tabs className="table-type-tabs flex flex-col overflow-hidden h-full" type="line" defaultActiveKey="raw" renderTabBar={renderTabBar}>
          {renderContent()}
        </Tabs>
      </div>
    </div>
  );
});

export default DataExplorerTabs;
