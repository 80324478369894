import _ from 'lodash';

type AssertItem = {
  name: string;
  fullName: string;
  assert: any;
}

const mediaAsserts = require.context('../assets/media', true, /\.(mov|mp4)$/);

function loadList(targetContext: typeof mediaAsserts): AssertItem[] {
  return _.map(targetContext.keys(), (key: string) => {
    const name = key.substring(key.indexOf('/') + 1);
    const assert = targetContext(key);

    const fullName = key.substring(key.indexOf('/') + 1);
    return {
      name,
      fullName,
      assert
    };
  });
}

const mediaList = loadList(mediaAsserts);

export function loadMedia(name: string): any {
  return _.find(mediaList, (item) => item.name === name)?.assert;
}
