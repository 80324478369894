/**
 * host
 * port
 * database
 * username
 * password
 * table_name
 */
import { ConfigurationForm } from '@/model/Integration';

const content = `TiDB

---

This page guides you through the process of setting up the TiDB integration.

- **Host**

  The host name of the TiDB Server.

- **Port**

  The port number the server is listening on. Defaults to the TiDB standard port number (3306).

- **Username**

  The username to connect TiDB

- **Password**

  The password to connect TiDB

- **Default Schema Name**

  Specify the schema (or several schemas separated by commas) to be set in the search-path. These schemas will be used to resolve unqualified object names used in statements executed over this connection.

- **Database**

  The database name. The default is to connect to a database with the same name as the user name.

## Step 1: Set up Your TiDB

**Permissions**

You need a TiDB user with the following permissions:

  - can create tables and write rows.

  - can create schemas

You can also use a pre-existing user but we highly recommend creating a dedicated user for TiDB.

## Step 2: Set up the TiDB Integration in Sync

**Target Database**

You will need to choose an existing database or create a new database that will be used to store synced dataset.

1. Log into your Chainbase Console;

2. In the left navigation bar, click Integrations. In the top-right corner, click + New Integrration

3. On the Set up **integration** page, enter the sink name for the TiDB integration and select TiDB from the Integrations.

4. Enter a name for your source.

5. For the **Host, Port, and DB Name**, enter the **hostname, port number, and name** for your TiDB database.

6. For **User** and **Password**, enter the **username and password** you created in Step-1;`;

export const TiDBTemplate: ConfigurationForm = {
  introduction: {
    logo: 'tidb',
    title: 'TiDB',
    content,
    type: 'tidb'
  },
  formContent: [
    {
      type: 'plain',
      fields: [
        {
          fieldType: 'input',
          label: 'Sink name',
          field: 'integration_name',
          required: true
        }
      ]
    },
    {
      type: 'plain',
      fields: [
        {
          fieldType: 'input',
          field: 'host',
          label: 'Host',
          placeholder: 'tidb_host',
          required: true
        },
        {
          fieldType: 'input',
          field: 'port',
          label: 'Port',
          placeholder: '5432',
          required: true,
          type: 'number'
        },
        {
          fieldType: 'input',
          field: 'database',
          label: 'Database',
          placeholder: 'my_database',
          required: true
        },
        {
          fieldType: 'input',
          field: 'username',
          label: 'Username',
          placeholder: 'tidb_user',
          required: true
        },
        {
          fieldType: 'input',
          field: 'password',
          mode: 'password',
          label: 'Password',
          placeholder: 'yourpass',
          required: true
        }
      ]
    }
  ]
};
