import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/components';

export default function AccountLogin(): React.ReactElement {
  const { t } = useTranslation();
  return (
    <div className="to-login">
      <span className="medium">{t('register.haveAccount')}</span>
      <a
        className="btn-login medium"
        href="/login"
      >
        {t('register.loginButton')}
        <Icon
          className="icon"
          icon="angle-right-blue"
          alt="angle-right-blue"
        />
      </a>
    </div>
  );
}
