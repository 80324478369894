import { ReactNode, createContext, useMemo, useState } from 'react';

export interface ActiveTab {
  current?: string;
  toggle(key: string): void;
}
export const ActiveTabContext = createContext<ActiveTab>({
  toggle(): void {
    throw new Error('Function not implemented.');
  }
});

export default function ActiveTabProvider({ children }: {children: ReactNode}) {
  const [current, setCurrent] = useState<string>();

  const value = useMemo(() => ({
    current,
    toggle(key: string) {
      setCurrent(key);
    }
  }), [current]);

  return (
    <ActiveTabContext.Provider value={value}>
      {children}
    </ActiveTabContext.Provider>
  );
}
