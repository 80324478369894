import React from 'react';
import { Icon } from '@/components';
import {
  Button, Col, Form, Row
} from '@douyinfe/semi-ui';
import { useTranslation } from 'react-i18next';
import { TimeOptions } from '@/config/constant';
import { useAppDispatch } from '@/store/hooks';
import { useSelector } from 'react-redux';
import {
  fetchProjectList,
  projectList
} from '@/store/reducers/constantReducer';
import { DateUtils } from '@/utils';
import moment from 'moment';
import { SelectIcon } from '@/components/Select';
import SearchButton from '@/components/Button/SearchButton';

export declare type SearchProps = {
  onSearch: (values: Record<string, any>) => Promise<void>;
}

export default function Search({ onSearch }: SearchProps): React.ReactElement {
  const { t } = useTranslation(['logExplorer', 'dashboard', 'translation']);

  const defaultTime = '1d';

  const dispatch = useAppDispatch();
  const projects = useSelector(projectList);

  React.useEffect(() => {
    dispatch(fetchProjectList());
  }, [dispatch]);

  const handleSearch = async (values: Record<string, any>): Promise<void> => {
    if (values.date) {
      values.begin_at = DateUtils.calcBeginTime(values.date);
      values.end_at = moment()
        .unix();
    }
    await onSearch(values);
  };

  return (
    <Row className="search-wrapper">
      <Form
        initValues={
          {
            date: defaultTime,
            app_id: 'all'
          }
        }
        labelPosition="inset"
        onSubmit={handleSearch}
        onValueChange={async (values, changedValues) => {
          // on select value change will trigger onSearch
          const hasMethod = Object.prototype.hasOwnProperty.call(changedValues, 'keyword');
          if (!hasMethod) {
            await handleSearch(values);
          }
        }}
      >
        <Row type="flex" gutter={[8, 0]}>
          <Col>
            <Form.Select
              arrowIcon={SelectIcon}
              clickToHide
              noLabel
              field="app_id"
              optionList={[
                {
                  label: t('dashboard.allApplications', { ns: 'dashboard' }),
                  value: 'all'
                },
                ...(
                  projects.map((item) => ({
                    label: item.name,
                    value: item.uuid
                  }))
                )
              ]}
            />
          </Col>

          <Col className="flex-1">
            <Form.Input
              className="input-method"
              noLabel
              size="large"
              field="keyword"
              placeholder={t<string>('logExplorer.search.method')}
              suffix={(
                <SearchButton
                  htmlType="submit"
                />
              )}
            />
          </Col>

          <Col>
            <Form.Select
              arrowIcon={SelectIcon}
              clickToHide
              noLabel
              field="err_code"
              placeholder={t<string>('logExplorer.search.error')}
              optionList={[
                {
                  label: t('common.all', { ns: 'translation' }),
                  value: 'all'
                },
                {
                  label: 'Invalid Request',
                  value: -32600
                },
                {
                  label: 'Method not found',
                  value: -32601
                },
                {
                  label: 'Invalid params',
                  value: -32602
                },
                {
                  label: 'Internal error',
                  value: -32603
                }
              ]}
            />
          </Col>

          <Col>
            <Form.Select
              arrowIcon={SelectIcon}
              clickToHide
              noLabel
              field="http_code"
              placeholder={t<string>('logExplorer.search.status')}
              optionList={[
                {
                  label: t('common.all', { ns: 'translation' }),
                  value: 'all'
                },
                {
                  label: 200,
                  value: 200
                },
                {
                  label: 400,
                  value: 400
                },
                {
                  label: 404,
                  value: 404
                },
                {
                  label: 429,
                  value: 429
                },
                {
                  label: 500,
                  value: 500
                },
                {
                  label: 502,
                  value: 502
                },
                {
                  label: 504,
                  value: 504
                }
              ]}
            />
          </Col>

          <Col>
            <Form.Select
              arrowIcon={SelectIcon}
              clickToHide
              noLabel
              field="date"
              className="capitalize"
              placeholder={t<string>('logExplorer.search.date')}
              optionList={TimeOptions.map((item) => ({
                className: 'capitalize',
                label: t(`dashboard.times.${item}`, { ns: 'dashboard' }),
                value: item
              }))}
            />
          </Col>

          <Col className="button-group">
            <Button
              className="btn-icon btn-refresh"
              theme="borderless"
              htmlType="submit"
              icon={<Icon icon="logExplorer/refresh" className="icon" />}
              aria-label="Refresh"
            />
          </Col>
        </Row>
      </Form>
    </Row>
  );
}
