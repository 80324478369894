import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { AxiosResponse } from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import { Button, Row } from '@douyinfe/semi-ui';
import AnnouncementApi from '@/api/AnnouncementApi';
import './index.scss';
import { useLocation } from 'react-router-dom';
import { Markdown } from '@/components';

const Close: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="8" fill="#444D56" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.29284 8.00047L4.11092 11.1824L4.81802 11.8895L7.99995 8.70758L11.1819 11.8895L11.889 11.1824L8.70705 8.00047L11.8891 4.81844L11.182 4.11133L7.99995 7.29337L4.81795 4.11137L4.11084 4.81847L7.29284 8.00047Z" fill="white" />
  </svg>
);

export default function Announcement() {
  const [visible, setVisible] = useState<boolean>(false);
  const location = useLocation();
  const cachedKey = 'announcementIds';

  const readCache = (): any => {
    try {
      const announcementJSON = localStorage.getItem(cachedKey);
      if (announcementJSON) {
        const obj = JSON.parse(announcementJSON);
        if (obj instanceof Array) {
          return obj;
        }
        localStorage.removeItem(cachedKey);
      }
      return [];
    } catch (e) {
      return [];
    }
  };

  const { data: announcement } = useSWR('/api/v2/announcement', () => AnnouncementApi.getAnnouncementLatest({
    path: location.pathname
  }).then((resp: AxiosResponse) => resp.data?.result), {
    refreshInterval: 60000
  });

  useEffect(() => {
    if (announcement) {
      const list = readCache();
      setVisible(!list.includes(announcement?.id));
    }
  }, [announcement, setVisible]);

  if (!announcement) {
    return null;
  }

  const handleClose = () => {
    setVisible(false);
    const list = readCache();
    list.push(announcement?.id);
    localStorage.setItem(cachedKey, JSON.stringify(list));
  };

  const renderContent = () => {
    if (!visible) {
      return null;
    }
    return (
      <motion.div
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: 'auto' }}
        exit={{ opacity: 0, height: 0 }}
      >
        <Row className="announcement w-full box-border pl-[16px] pr-[36px] bg-[#EAE8FF] relative" type="flex" align="middle">
          <span className="font-semibold leading-[16px]">
            <Markdown>{announcement?.content}</Markdown>
          </span>

          <Button icon={<Close />} theme="borderless" className="w-[36px] h-[36px] absolute right-0 top-0 bottom-0 m-auto hover:!bg-transparent" onClick={handleClose} />
        </Row>
      </motion.div>
    );
  };
  return (
    <AnimatePresence>
      {renderContent()}

    </AnimatePresence>
  );
}
