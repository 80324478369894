import React, { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { TabPane, Tabs } from '@douyinfe/semi-ui';
import { TabBarProps } from '@douyinfe/semi-ui/tabs/interface';
import './index.scss';
import TableTreeScrollList, { TableCategory } from './TableTreeScrollList';

export default function DataExplorer(): React.ReactElement {
  const [tab, setTab] = useState<TableCategory>('raw');
  const { t } = useTranslation();

  const renderTabItem = (selfTab: TableCategory, onClick?: (activeKey: string, event: MouseEvent) => void): React.ReactElement => (
    <li
      className={classNames('flex-1 rounded-md h-[30] transition-[background-color]', {
        'bg-white': tab === selfTab,
        'border-[1px] border-solid !border-[#E3E7EC]': tab === selfTab
      })}
    >
      <button
        className={classNames('w-full h-full text-sm flex justify-center items-center gap-1', {
          'text-[#A1AAB0]': tab !== selfTab,
          'font-semibold': tab === selfTab
        })}
        type="button"
        onClick={(event) => {
          setTab(selfTab);
          if (onClick) {
            onClick(selfTab, event);
          }
        }}
      >
        <span>
          {t(`dataCloud.${selfTab}`)}
        </span>
      </button>
    </li>
  );

  const renderTree = (category: TableCategory): React.ReactElement => (
    <TabPane tab={t(`dataCloud.${category}`)} itemKey={category}>
      <TableTreeScrollList type={category} />
    </TabPane>
  );

  const renderContent = (): React.ReactElement => (
    <>
      {renderTree('raw')}
      {renderTree('decoded')}
      {renderTree('abstracted')}
    </>
  );
  const renderTabBar = (tabBarProps: TabBarProps): React.ReactElement => (
    <div className="px-[24px] mb-[8px]">
      <ul className="mt-[8px] list-none flex justify-between p-[3px] bg-[#EDF1F4] rounded-[4px] h-[30px]">
        {renderTabItem('raw', tabBarProps.onTabClick)}
        {renderTabItem('decoded', tabBarProps.onTabClick)}
        {renderTabItem('abstracted', tabBarProps.onTabClick)}
      </ul>
    </div>
  );

  return (
    <div className="flex flex-col h-full table-navigation overflow-hidden">
      <div className="w-full h-[calc(100%-500px)] flex-col flex-1">
        <Tabs className="table-type-tabs flex flex-col overflow-hidden h-full" type="line" defaultActiveKey="raw" renderTabBar={renderTabBar}>
          {renderContent()}
        </Tabs>
      </div>
    </div>
  );
}
