import React, {
  ChangeEvent,
  ChangeEventHandler,
  FocusEvent,
  FocusEventHandler,
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEventHandler
} from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { Icon } from '@/components';

declare type InputStatusStepProps = {
  active: boolean;
  test: boolean;
  name: string;
  type: string;
  placeholder: string | React.ReactElement;
  onVerify: (event: FocusEvent | ChangeEvent | KeyboardEvent) => boolean;
  onActive: (event: Event) => void;
  onComplete: (event: Event) => void;
  onChange: (event: ChangeEvent) => void;
  onError?: (event: Event) => void;
  t: TFunction;
};

export enum InputStatus {
  INPUT = 'input-blur',
  SUCCESS = 'input-success',
  ERROR = 'input-error'
}

declare interface InputStatusStepState {
  status: InputStatus;
  disableStatus: boolean;
}

class InputStatusStep extends React.Component<InputStatusStepProps
  , InputStatusStepState> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    onError: () => undefined
  };

  constructor(props: InputStatusStepProps) {
    super(props);

    this.state = {
      status: InputStatus.INPUT,
      disableStatus: true
    };
  }

  handleVerify: ChangeEventHandler = (event: ChangeEvent): void => {
    const { onVerify } = this.props;

    const target = event.target as HTMLInputElement;
    if (target.value.length === 0) {
      this.setState({
        disableStatus: true,
        status: InputStatus.INPUT
      });
    } else {
      const valid = onVerify(event);
      this.setState({
        disableStatus: !valid,
        status: valid ? InputStatus.SUCCESS : InputStatus.ERROR
      });
    }
  };

  handleFocus: FocusEventHandler = (): void => {
    const { onActive } = this.props;
    onActive(new Event('active'));
    this.setState({
      status: InputStatus.INPUT
    });
  };

  handleContinue: MouseEventHandler = () => {
    const { disableStatus } = this.state;

    this.handleTrigger(!disableStatus);
  };

  handleKeyDown: KeyboardEventHandler = (e) => {
    const { onVerify } = this.props;
    if (e.key === 'Enter') {
      this.handleTrigger(onVerify(e));
    }
  };

  handleChange: ChangeEventHandler = (event: ChangeEvent): void => {
    const { onChange } = this.props;
    this.handleVerify(event);
    onChange(event);
  };

  handleTrigger(status: boolean): void {
    const {
      onComplete,
      onError
    } = this.props;
    if (status) {
      onComplete(new Event('complete'));
    } else if (onError) {
      onError(new Event('error'));
    }
  }

  render(): JSX.Element {
    const {
      t,
      active,
      test,
      name,
      type,
      placeholder
    } = this.props;

    if (!test) {
      return <div />;
    }

    const {
      status,
      disableStatus
    } = this.state;

    return (
      <div className={`input-status-step ${name}`}>
        <label htmlFor={name}>
          {placeholder}
        </label>
        <div className="input-complex">
          <Icon
            className="icon"
            icon={status.valueOf()}
            alt={status.valueOf()}
          />
          <input
            name={name}
            type={type}
            onBlur={this.handleVerify}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onKeyDown={this.handleKeyDown}
          />

          {
            active ? (
              <button
                className={`btn-next ${disableStatus ? 'disabled' : ''}`}
                type="button"
                onClick={this.handleContinue}
              >
                {t('register.next')}
              </button>
            ) : null
          }

        </div>
      </div>
    );
  }
}

export default withTranslation()(InputStatusStep);
