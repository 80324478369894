import React, { MouseEventHandler } from 'react';
import _ from 'lodash';
import { Button, TabPane, Tabs } from '@douyinfe/semi-ui';
import './index.scss';
import { TabBarProps } from '@douyinfe/semi-ui/lib/es/tabs';
import {
  CodeEditor, EventManager, Modal, Toast
} from '@/components';
import { useTranslation } from 'react-i18next';
import { ArchivedQueryItem } from '@/props';
import { useSelector } from 'react-redux';
import {
  dataCloudActions,
  dataCloudActiveKey,
  dataCloudCurrentTab,
  dataCloudTableList,
  dataCloudTabList,
  dynamicGetTab,
  updateArchivedQuery
} from '@/store/reducers/dataCloudReducer';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { EditorInsert } from '@/components/CodeEditor/CodeEditor';
import { useBlocker, useLoadingCallback } from '@/utils';
import ArchivedQueriesApi from '@/api/ArchivedQueriesApi';
import useMonacoLanguage from '@/views/dataCloud/index/useMonacoLanguage';
import EditorTabBar from '@/views/dataCloud/index/SQLEditor/EditorTabBar';
import { ResultTableType } from '@/model/Table';
import { ClickhouseSQLMonaco } from '@/config/editor/clickhouse';
import { fetchProjectList } from '@/store/reducers/projectReducer';
import DropDownMore from '@/views/dashboard/archivedQueries/DropDownMore';
import UnSaveModal, { UnSaveModalType } from './UnSaveModal';
import ArchivedQueryFormModal from '../../../dashboard/archivedQueries/Form';

export declare type QueryTabItem = {
  key: string;
  id?: string;
  name: string;
  sql: string;
  updated?: boolean;
  resultLoading?: boolean;
  selectText?: string;
  result?: ResultTableType,
}

export default function SQLEditor(): React.ReactElement {
  const { t } = useTranslation(['translation', 'archivedQueries']);

  const tableList = useAppSelector(dataCloudTableList);
  useMonacoLanguage('ClickHouse', ClickhouseSQLMonaco, tableList);

  const [nextLocation, setNextLocation] = React.useState<any>(null);

  const unSaveModalRef = React.useRef<UnSaveModalType>();
  const dispatch = useAppDispatch();
  const tabs = useSelector(dataCloudTabList);
  const tabActiveKey = useSelector(dataCloudActiveKey);
  const currentTab = useSelector(dataCloudCurrentTab);

  const addNewQuery: MouseEventHandler<HTMLButtonElement> = () => {
    dispatch(dataCloudActions.addDefault());
  };

  const handleBack = (): void => {
    if (nextLocation) {
      nextLocation.retry();
    }
  };

  const closeTab = (tabKey: string): void => {
    handleBack();
    dispatch(dataCloudActions.remove(tabKey));
  };

  const handleClose = (tabKey: string): void => {
    const target = _.find(tabs, (item) => item.key === tabKey);
    if (!target) {
      // ERROR
      return;
    }

    if (target.updated && target.sql.trim() !== '') {
      // open modal in save status is false
      unSaveModalRef?.current?.open(tabKey);
    } else {
      closeTab(tabKey);
    }
  };

  useBlocker((next) => {
    const nextKey = currentTab || _.find(tabs, (item) => item.updated) as QueryTabItem;
    if (nextKey?.key) {
      const elementCount = _.size(_.filter(tabs, (i) => i.updated));

      setNextLocation(elementCount === 1 ? next : null);
      handleClose(nextKey?.key);
    }
  }, _.some(tabs, (i) => i.updated));

  const handleUpdated = async (data?: ArchivedQueryItem, tabKey?: string): Promise<void> => {
    handleBack();
    if (data && tabKey) {
      // update currentDialog status
      await dispatch(dataCloudActions.updateCurrentTab({
        data,
        tabKey
      }));
    }
  };

  const handleUnSave = (tabKey: string): void => {
    closeTab(tabKey);
  };

  const {
    loading: saveLoading,
    callback: fetchSave
  } = useLoadingCallback(async (target: QueryTabItem): Promise<void> => {
    if (!target) {
      return;
    }

    const data: ArchivedQueryItem = {
      uuid: target.id,
      title: target.name,
      content: target.sql
    };

    await dispatch(updateArchivedQuery(data));

    handleBack();
  });

  const handleOpenArchivedQueriesModal = (record: ArchivedQueryItem, activeKey: string): void => {
    const modal = Modal.info({
      title: t(`archivedQueries.form.${record?.uuid ? 'updateTitle' : 'createTitle'}`, { ns: 'archivedQueries' }),
      className: 'archived-queries-modal',
      footer: null,
      content: <ArchivedQueryFormModal
        archivedQueries={record}
        onCancel={() => modal.destroy()}
        onComplete={(result) => {
          handleUpdated(result, activeKey);
          dispatch(fetchProjectList());
        }}
      />
    });
  };

  const handleSaveTarget = async (target?: QueryTabItem): Promise<void> => {
    if (!target || !target.updated) {
      return;
    }

    if (!target.id) {
      // open save modal
      const record: ArchivedQueryItem = {
        title: '',
        content: target.sql
      };

      handleOpenArchivedQueriesModal(record, target.key);
    } else {
      // send save request
      await fetchSave(target);
    }
  };

  const handleSave = async (tabKey: string): Promise<void> => {
    const result = await dispatch(dynamicGetTab({ tabKey }));

    if (result?.payload) {
      await handleSaveTarget(result.payload as QueryTabItem);
    }
  };
  return (
    <>
      <Tabs
        className="sql-editor-tabs"
        type="card"
        activeKey={tabActiveKey}
        collapsible
        onTabClose={handleClose}
        tabPaneMotion={false}
        onChange={(key: string) => {
          dispatch(dataCloudActions.changeActiveKey(key));
          setTimeout(() => {
            EventManager.emit(`${EditorInsert}:${key}`, {
              method: 'focus'
            });
          }, 50);
        }}
        tabBarExtraContent={(
          <div className="close-wrapper">
            <Button
              loading={saveLoading}
              disabled={!currentTab?.updated}
              className="btn-save"
              theme="borderless"
              onClick={async () => {
                if (tabActiveKey) {
                  await handleSave(tabActiveKey);
                }
              }}
            >
              {t('common.save')}
            </Button>
            <DropDownMore
              mode={currentTab?.id ? 'saved' : 'unSave'}
              onRename={async () => {
                if (!currentTab?.id) {
                  return;
                }
                const response = await ArchivedQueriesApi.detail(currentTab.id);
                const { data } = response.data;

                handleOpenArchivedQueriesModal(data, currentTab.key);
              }}
              onDelete={async () => {
                if (!currentTab?.id) {
                  return;
                }
                const response = await ArchivedQueriesApi.delete(currentTab.id);
                Toast.toastResponse(response);
                closeTab(currentTab?.id);
              }}
              onClose={() => {
                if (tabActiveKey) {
                  handleClose(tabActiveKey);
                }
              }}
              onSave={() => {
                if (tabActiveKey) {
                  handleSave(tabActiveKey);
                }
              }}
            />
          </div>
        )}
        renderTabBar={(tabBarProps: TabBarProps) => (
          <EditorTabBar
            tabBarProps={tabBarProps}
            onAddQuery={addNewQuery}
          />
        )}
      >
        {
          _.map(tabs, (item) => (
            <TabPane
              key={item.key}
              closable
              tab={item.name}
              itemKey={item.key}
              className="test"
            >
              <CodeEditor
                editorKey={item.key}
                language="ClickHouse"
                onSave={() => {
                  handleSave(item.key);
                }}
                onRun={() => {
                  EventManager.emit('RunQuery');
                }}
                defaultValue={item.sql}
                onChange={(value) => {
                  dispatch(dataCloudActions.updateSQL({
                    tabKey: item.key,
                    sql: value || ''
                  }));
                }}
                onSelectText={(text?: string) => {
                  if (text && text.trim()
                    .split(' ')?.length > 1) {
                    dispatch(dataCloudActions.changeCurrentTabStatus({
                      key: item.key,
                      selectText: text
                    }));
                  } else {
                    dispatch(dataCloudActions.changeCurrentTabStatus({
                      key: item.key,
                      selectText: ''
                    }));
                  }
                }}
              />
            </TabPane>
          ))
        }
      </Tabs>

      <UnSaveModal
        ref={unSaveModalRef}
        onConfirm={handleSave}
        onUnSave={handleUnSave}
      />
    </>
  );
}
