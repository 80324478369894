import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { Icon, Toast } from '@/components';
import { useLoadingCallback } from '@/utils';
import { PlanItem } from '@/views/settings/billing/PlanList';
import TextHighlight from '@/components/Text/TextHighlight';
import { PlanUpgradeDirection } from '@/views/settings/billing/index';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import BillingApi from '@/api/BillingApi';
import { settingBillingThunk } from '@/store/reducers/settingBillingReducer';
import { ModalFooter } from '@/components/Modal';
import { reportEvent } from '@/utils/useTrack';

interface UpgradeModalProps {
  mode: PlanUpgradeDirection;
  plan: PlanItem;
  onBindPaymentCard: () => void;
  onClose: () => void;
}

export default function UpgradeModal({
  mode,
  plan,
  onBindPaymentCard,
  onClose
}: UpgradeModalProps): React.ReactElement {
  const { t } = useTranslation(['settings', 'translation']);
  const modeText = t(`common.${mode}`, { ns: 'translation' });

  const dispatch = useAppDispatch();
  const currentPaymentInfo = useAppSelector((state) => state.settingBilling.current);
  const currentCardInfo = useAppSelector((state) => state.settingBilling.cardInfo);
  const planList = t('billing.plan.list', { returnObjects: true }) as PlanItem[];
  const currentPlan = _.find(planList, (item: PlanItem) => item.id === currentPaymentInfo?.subscription_plan);

  const { loading, callback: handleClick } = useLoadingCallback(async () => {
    /**
     * if (bind card) {
     *    open upgrade or down modal
     * } else {
     *    open bind card and pay model
     * }
     */
    if (!currentCardInfo?.last4) {
      await onBindPaymentCard();
    } else {
      const response = await BillingApi.subscribePlan({
        subscriptionPlan: plan.id
      });

      if (response.data.code === 200) {
        dispatch(settingBillingThunk.getCurrentPaymentInfo());
        Toast.success(t('billing.upgradeSuccess'));
        if (plan.id) {
          reportEvent('subscriptionCreated', {});
        } else if (plan.id === 0) {
          reportEvent('subscriptionCancelled', {});
        }
        onClose();
      } else {
        Toast.toastResponse(response);
      }
      // request upgrade
    }
  });

  return (
    <div className={classNames('upgrade-modal', mode)}>
      <Icon icon={mode === 'upgrade' ? 'billing/upgrade-plan' : 'billing/downgrade-plan'} className="icon" />
      <p className="desc medium">
        {mode === 'upgrade' ? (
          <TextHighlight
            searchWords={[plan.name]}
            textToHighlight={t('billing.changeLevel.upgrade')
              .replace('{mode}', modeText.toLowerCase())
              .replace('{plan}', plan.name)}
            highlightClassName={classNames('medium')}
            highlightStyle={{
              color: 'var(--v2-color-blue400)'
            }}
          />
        ) : (
          t('billing.changeLevel.downgrade')
        )}
      </p>
      <p className={classNames('tip')}>
        {t(`billing.changeLevel.${mode}Tip`)
          .replace('{plan}', currentPlan?.name || '')
          .replace('{price}', plan?.price ? `$${plan?.price}` : '')}
      </p>

      <ModalFooter
        cancelButtonProps={{
          className: 'btn btn-cancel'
        }}
        cancelText={
          mode === 'upgrade' ? t('common.cancel', { ns: 'translation' }) : t('billing.changeLevel.downgradeCancel')
        }
        onCancel={onClose}
        okButtonProps={{
          loading,
          className: classNames('btn-change', 'btn-enhance', modeText),
          size: 'default',
          type: 'primary',
          htmlType: 'button'
        }}
        onOk={handleClick}
        okText={`${modeText} ${t('billing.changeLevel.to')} ${plan.name}`}
      />
    </div>
  );
}
