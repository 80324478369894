import React from 'react';
import _ from 'lodash';
import { AnimatePresence } from 'framer-motion';
import { WalletItem, WalletList } from '@/config/wallet';
import { Icon } from '@/components';
import { useTranslation } from 'react-i18next';
import WalletLoginModal from './WalletLoginModal';

type WalletLoginProps = {
  onAccountChange: (account: string | undefined, sign: string) => void;
};

function WalletLogin({
  onAccountChange
}: WalletLoginProps): React.ReactElement {
  const { t } = useTranslation();
  const [status, setStatus] = React.useState(false);

  const handleChangeStatus = (): void => {
    setStatus(!status);
  };

  return (
    <div>
      <button
        className="wallet-login"
        type="button"
        onClick={handleChangeStatus}
      >
        <div
          className="wallet-icon-list"
        >
          {
            _.map(WalletList, (wallet: WalletItem, index: number) => (
              <div
                key={index}
                className="wallet-item"
              >
                <Icon className="icon" icon={wallet.icon} />
              </div>
            ))
          }
        </div>

        <div className="placeholder-wrapper">
          <span className="placeholder medium">{t('login.loginByWallet')}</span>
          <Icon className="icon" icon="angle-right" alt="angle-right" />
        </div>

      </button>

      <AnimatePresence>
        {status ? (
          <WalletLoginModal
            close={() => {
              setStatus(false);
            }}
            onAccountChange={onAccountChange}
          />
        ) : null}
      </AnimatePresence>
    </div>
  );
}

export default WalletLogin;
