import { Icon } from '@/components';
import React from 'react';
import './index.scss';
import ChainIconGroup
  from '@/views/dataCloudStudio/components/SideExplorer/components/DataExplorer/components/ChainIconGroup';
import { Row } from '@douyinfe/semi-ui';

interface DataToIntegrationProps {
  blockchains: string[];
  tableName: string;
  integration: string;
  onIntegrationClick?: () => void;
}

export default function DataToIntegration({
  blockchains,
  tableName,
  integration,
  onIntegrationClick
}: DataToIntegrationProps): React.ReactElement {
  const renderSyncIconLable = (icon: string, label: string, warpperClass: string): React.ReactElement => (
    <div
      className={`${warpperClass} icon-label-warpper ${onIntegrationClick ? 'cursor-pointer' : ''}`}
      onClick={onIntegrationClick}
    >
      <div className="icon-warpper">
        <Icon className="icon" icon={icon} />
      </div>
      <div className="label">
        {label}
      </div>
    </div>
  );

  return (
    <div className="sync-data-to-integration">
      <Row type="flex" align="middle">
        <div className="blockchains-wrapper">
          <ChainIconGroup chains={blockchains} size={24} maxCount={9999} />
        </div>
        <div className="label">
          {tableName}
        </div>
      </Row>
      <Icon className="five-arrows" icon="common/five-arrows" />
      {renderSyncIconLable(`sync/integrations/engine/${integration.toLowerCase()}`, integration, 'right')}
    </div>
  );
}
