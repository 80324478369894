import moment from 'moment';

export const SyncFrequencyOptions = [{
  label: 'Seconds',
  value: 'seconds'
}, {
  label: 'Hour',
  value: 'hour'
}, {
  label: 'Day',
  value: 'day'
}
// 暂时隐去
// ,{
//   label: 'Ad-hoc',
//   value: 'manual'
// }
];

export const TimezoneOptions = moment.tz.names().map((name) => ({
  label: `${name} (UTC${moment.tz(name).format('Z')})`,
  value: name
}));

export const TimeOptions = Array.from({ length: 24 }, (_, i) => ({
  label: `${i.toString().padStart(2, '0')}:00`,
  value: i
}));

export const HourOptions = Array.from({ length: 24 }, (_, i) => ({
  label: `${i + 1}`,
  value: i + 1
}));
