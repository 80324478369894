/* eslint-disable react/jsx-closing-tag-location */
import { Toast } from '@douyinfe/semi-ui';
import './SolidToast.scss';
import Icon from '../Icon';

export default class SolidToast {
  static success(content: string): void {
    const id = Toast.success({
      icon: (<Icon className="w-5" icon="common/success" />),
      content: <div className="flex items-center w-full justify-between relative">
        <div>{content}</div>
        <button className="p-1 hover:bg-[#EDF1F4] w-4 h-4 flex justify-center items-center box-border rounded-full absolute -right-10" type="button" onClick={() => Toast.close(id)}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.29333 5.99998L2.1114 9.18191L2.81851 9.88901L6.00043 6.70709L9.1824 9.88905L9.8895 9.18195L6.70754 5.99998L9.88958 2.81795L9.18247 2.11084L6.00043 5.29288L2.81843 2.11088L2.11133 2.81798L5.29333 5.99998Z" fill="#3F983D" />
          </svg>
        </button>
      </div>,
      duration: 3,
      className: 'chainbase-solid-toast',
      showClose: false
    });
  }
}
