import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Form } from '@douyinfe/semi-ui';
import AccountApi from '@/api/AccountApi';
import { useLoadingCallback } from '@/utils';
import { CommonRegExp } from '@/config/constant';
import {
  ButtonLoading, Card, HeaderTitle, AuthToast
} from '@/components';
import './index.scss';
import InputGroup from '../login/InputGroup';

export default function SendResetEmail(): React.ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    callback: handleSendResetEmail,
    loading,
    reset
  } = useLoadingCallback(async (data: any) => {
    const response = await AccountApi.sendResetEmail(data);

    if (response.data.code === 200) {
      // switch to change password page
      navigate('/resetPassword', {
        state: data
      });
    }

    AuthToast.showResponseMessage(response);
  });

  const handleSubmit = async (record: any): Promise<void> => {
    await handleSendResetEmail({
      email: record.email
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <HeaderTitle title={t('resetPassword.headerTitle')} />
      <Card className="reset-email">
        <div>
          <InputGroup
            autoFocus
            name="email"
            type="text"
            label={t('resetEmail.emailPlaceholder')}
            rules={[
              {
                type: 'string',
                required: true,
                pattern: CommonRegExp.email,
                message: 'email format error'
              }
            ]}
          />

          <ButtonLoading
            className="btn-enhance uppercase bolder"
            htmlType="submit"
            loading={loading}
          >
            {t('resetEmail.sendButton')}
          </ButtonLoading>
        </div>

        <Button
          theme="borderless"
          className="btn-cancel bolder"
          onClick={() => {
            reset();
            navigate(-1);
          }}
        >
          {t('common.cancel')}
        </Button>
      </Card>
    </Form>
  );
}
