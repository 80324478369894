import React, { ForwardedRef } from 'react';
import { Table as SemiTable } from '@douyinfe/semi-ui';
import { Data, TableProps } from '@douyinfe/semi-ui/lib/es/table/interface';
import './index.scss';
import classNames from 'classnames';

function Table<RecordType extends Record<string, any> = Data>(
  props: TableProps<RecordType>,
  ref?: ForwardedRef<SemiTable<RecordType>>
): React.ReactElement {
  const {
    loading,
    dataSource,
    className,
    empty,
    ...reset
  } = props;
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <SemiTable
      ref={ref}
      className={classNames('chainbase-table', className)}
      pagination={false}
      bordered
      dataSource={dataSource || []}
      loading={loading}
      {...reset}
      empty={
        loading ? null : empty
      }
    />
  );
}

export default React.forwardRef<SemiTable<TableProps>, TableProps>(Table);
