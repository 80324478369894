import React from 'react';
import SplitPane, { Pane } from 'react-split-pane';
import { useAppSelector } from '@/store/hooks';
import { dataCloudCurrentTab } from '@/store/reducers/dataCloudReducer';
import SQLEditor from './SQLEditor';
import QueryResult from './QueryResult';

export default function Content(): React.ReactElement {
  const currentTab = useAppSelector(dataCloudCurrentTab);
  if (currentTab === undefined) {
    return <div />;
  }

  return (
    <div className="content-wrapper data-cloud-content-wrapper">
      <SplitPane
        split="horizontal"
        minSize={200}
        defaultSize="50%"
      >
        <Pane className="sql-editor-pane">
          <SQLEditor />
        </Pane>

        <Pane
          className="query-result-pane"
          style={
          {
            overflow: 'hidden'
          }
        }
        >
          <QueryResult key={currentTab?.key} />
        </Pane>
      </SplitPane>
    </div>
  );
}
