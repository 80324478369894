import { AxiosResponse } from 'axios';
import Request from '@/api/Request';
import { TrinoQuery } from '@/model/TrinoQuery';
import { Response } from '@/model';

export default class TrinoQueriesApi {
  static getList(): Promise<AxiosResponse<{
    code: number,
    message: string,
    data: TrinoQuery[],
  }>> {
    return Request.get('/v2/datacloud/query');
  }

  static delete(id: number): Promise<AxiosResponse<Response>> {
    return Request.delete(`/v2/datacloud/query/${id}`);
  }

  static update(data: any): Promise<AxiosResponse<Response>> {
    return Request.put('/v2/datacloud/query', data);
  }
}
