import React from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { BlockLoading } from '@/components/Loading/BlockLoading';
import JobList from '@/views/sync/commons/JobList';
import { useJobList } from '@/views/sync/utils/JobRequestHooks';
import SyncOverviewHeader from '@/views/sync/commons/SyncOverviewHeader';
import { useTranslation } from 'react-i18next';
import useJobLimitation from '@/views/sync/utils/useJobLimitation';

export default function JobsOverview(): React.ReactElement {
  const { data, isLoading: isFetchingJobList } = useJobList();
  const { t } = useTranslation('translation', { keyPrefix: 'sync.jobs.overviewHeader' });
  const nav = useNavigate();
  const { overLimitation, isLoading: isFetchingJobLimitation } = useJobLimitation();
  const isLoading = isFetchingJobList || isFetchingJobLimitation;

  const onNewJobButtonClick = (): void => {
    nav('/sync/jobs/create');
  };

  if (isLoading) {
    return <BlockLoading />;
  }

  return (
    <div className="bg-[#F6F8FA]">
      <SyncOverviewHeader
        title={t('title')}
        description={t('description')}
        buttonContent={t('buttonContent')}
        onButtonClick={onNewJobButtonClick}
        buttonDisabled={overLimitation}
        buttonTooltip="In the meantime, each person can create a maximum of 10 jobs."
      />
      <JobList jobList={data || []} />
    </div>
  );
}
