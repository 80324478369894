interface TimerProps {
  time: number
}

export default function Timer({
  time
}: TimerProps) {
  const format = (value: number): string => {
    const pad = (_padValue: number) => `${_padValue}`.padStart(2, '0');
    const seconds = Math.floor(value / 1000) % 60;
    const minutes = Math.floor(value / 1000 / 60) % 60;
    const hours = Math.floor(value / 1000 / 60 / 60);

    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  };

  return (
    <div className="timer">{format(time)}</div>
  );
}
