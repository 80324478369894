import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  BlockChain,
  ChainItem,
  NetworkItem,
  SortedBlockChain
} from '@/props';
import ConstantApi from '@/api/ConstantApi';
import { Project } from '@/model/Project';
import { RootState } from '@/store/store';

type StateProps = {
  list: Array<ChainItem>;
  projectList: Project[];
  apiKey?: string
};

const initialState: StateProps = {
  list: [],
  projectList: []
};

export const fetchChainList = createAsyncThunk(
  'constant/getChainList',
  async () => {
    const response = await ConstantApi.getChainList();
    const { chains } = response.data;

    const chainList = _.keys(chains);
    const sortedChainNames = _.filter(
      SortedBlockChain,
      (i) => _.includes(chainList, i)
    );

    return _.map(sortedChainNames, (key) => ({
      name: key,
      networks: chains[key]
    } as ChainItem));
  }
);

export const fetchProjectList = createAsyncThunk(
  'constant/getProjectList',
  async () => {
    const response = await ConstantApi.getProjectList();
    const { result } = response.data;

    return result || [];
  }
);

export const constantReducer = createSlice({
  name: 'ConstantSlice',
  initialState,
  reducers: {
    loadData: (state) => state
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChainList.fulfilled, (
        state,
        action: PayloadAction<Array<ChainItem>>
      ) => {
        state.list = action.payload;
      });

    builder
      .addCase(fetchProjectList.fulfilled, (
        state,
        action: PayloadAction<Project[]>
      ) => {
        const projects = action.payload;
        state.projectList = projects;
        state.apiKey = projects?.find(
          (project: any) => project.secret_key
        )?.secret_key;
      });
  }
});

export const chainList = (state: RootState):
  ChainItem[] => state.constant.list;

export const networkList = (state: RootState):
  NetworkItem[] => _.flatMap(_.values(state.constant.list), (i) => i.networks) as NetworkItem[];

export const projectList = (state: RootState):
  Project[] => state.constant.projectList;

export const blockchainList = (state: RootState):
  BlockChain[] => _.map(state.constant.list, (item) => item.name);

export default constantReducer.reducer;
