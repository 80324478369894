import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import LogExplorerApi, { LogSearchParams } from '@/api/LogExplorerApi';
import { RequestLogItem } from '@/model/Log';
import { DateUtils } from '@/utils';
import moment from 'moment/moment';
import { RootState } from '@/store/store';
import { ChartQueryData, Optional } from '@/props';

type StateProps = {
  listLoading: boolean
  logs: RequestLogItem[],
  query: LogSearchParams,
  pagination: {
    total: number
  },
  chatData: ChartQueryData
};

const initialState: StateProps = {
  listLoading: false,
  logs: [],
  query: {
    app_id: 'all',
    begin_at: DateUtils.calcBeginTime('1d'),
    end_at: moment()
      .unix(),
    date: '1d',
    http_code: 'all',
    err_code: 'all',
    sort: undefined,
    page: undefined,
    limit: 20
  },
  pagination: {
    total: 0
  },
  chatData: {}
};

export const fetchLogList = createAsyncThunk(
  'logs/getList',
  async (query: Optional<LogSearchParams> | undefined, thunkApi): Promise<{
    list: RequestLogItem[],
    total: number,
    chatData: ChartQueryData,
    query: LogSearchParams
  }> => {
    const rootState = thunkApi.getState() as RootState;
    const baseQuery = rootState.logs.query;

    const result = {
      ...baseQuery, ...query
    } as LogSearchParams;

    const response = await LogExplorerApi.getList(result);
    const chatResponse = await LogExplorerApi.getChartData(result);

    const {
      query_data: queryData
    } = chatResponse.data as {
      query_data: ChartQueryData;
    };

    const {
      logs,
      count
    } = response.data;
    return {
      list: logs,
      total: count,
      chatData: queryData,
      query: result
    };
  }
);

export const logReducer = createSlice({
  name: 'LogSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLogList.pending, (state) => {
      state.listLoading = true;
    })
      .addCase(fetchLogList.fulfilled, (state, action) => {
        state.logs = action.payload.list;
        state.pagination.total = action.payload.total;
        state.query = action.payload.query;
        state.chatData = action.payload.chatData;
        state.listLoading = false;
      })
      .addCase(fetchLogList.rejected, (state) => {
        state.listLoading = false;
      });
  }
});

export default logReducer.reducer;
