import { useSearchParams } from 'react-router-dom';

export default function Redirect(): null {
  const [params] = useSearchParams();
  const url = params.get('src') as string;
  const target = new URL(url);
  const rootDomains = ['chainbase.online', 'chainbase.com'];

  for (let i = 0; i < rootDomains.length; i += 1) {
    if (process.env.NODE_ENV !== 'production'
      || target.host.endsWith(`.${rootDomains[i]}`)
      || target.host === rootDomains[i]) {
      window.location.href = target.toString();
    }
  }

  return null;
}
