import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestTable } from '@/model/Table';
import { TrinoQuery } from '@/model/TrinoQuery';
import TrinoQueriesApi from '@/api/TrinoQueriesApi';
import { RootState } from '@/store/store';
import _ from 'lodash';

type StateProps = {
  queries: RequestTable<TrinoQuery>
};

const initialState: StateProps = {
  queries: {
    loading: false,
    list: [],
    pagination: {
      total: 0
    }
  }
};

export const fetchTrinoQueriesList = createAsyncThunk(
  'trinoQueries/getList',
  async () => {
    const response = await TrinoQueriesApi.getList();
    const { data } = response.data;
    const sortedQueries = _.sortBy(data, (item) => -new Date(item.updatedAt));
    return {
      loading: false,
      list: sortedQueries
    };
  }
);

export const deleteTrinoQueries = createAsyncThunk(
  'trinoQueries/delete',
  async (queryId: number) => await TrinoQueriesApi.delete(queryId)
);

export const editRenameTrinoQueries = createAsyncThunk(
  'trinoQueries/editRename',
  async (payload: {id: number, name: string}) => {
    const { name, id } = payload;
    return await TrinoQueriesApi.update({ id, name });
  }
);

export const updateTrinoQueriesList = createAsyncThunk(
  'trinoQueries/updateList',
  async () => {
    const response = await TrinoQueriesApi.getList();
    const { data } = response.data;

    const sortedQueries = _.sortBy(data, (item) => -new Date(item.updatedAt));
    return {
      loading: false,
      list: sortedQueries
    };
  }
);

export const trinoQueriesReducer = createSlice({
  name: 'TrinoQueriesSlice',
  initialState,
  reducers: {
    remove(state, action: PayloadAction<number>) {
      const queryId = action.payload;
      state.queries.list = state.queries.list.filter((item) => item.id !== queryId);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrinoQueriesList.pending, (state) => {
        state.queries.loading = true;
      })
      .addCase(
        fetchTrinoQueriesList.fulfilled,
        (state, action) => {
          state.queries = action.payload;
        }
      )
      .addCase(
        fetchTrinoQueriesList.rejected,
        (state) => {
          state.queries.loading = false;
        }
      );

    builder
      .addCase(
        updateTrinoQueriesList.fulfilled,
        (state, action) => {
          state.queries = action.payload;
        }
      );

    builder
      .addCase(
        deleteTrinoQueries.fulfilled,
        (state, action) => {
          const queryId = action.meta?.arg;
          if (action.payload.data.code === 200) {
            state.queries.list = state.queries.list.filter((item) => item.id !== queryId);
          }
        }
      );

    builder
      .addCase(
        editRenameTrinoQueries.fulfilled,
        (state, action) => {
          const arg = action?.meta?.arg;
          const { id, name } = arg;
          if (action.payload.data.code === 200) {
            const newList = _.cloneDeep(state.queries.list);
            const target = newList.find((item) => item.id === id);
            if (target) {
              target.name = name;
              state.queries.list = newList;
            }
            state.queries = { ...state.queries };
          }
        }
      );
  }
});

export const trinoQueries = (state: RootState):
    RequestTable<TrinoQuery> => state.trinoQueries.queries;

export default trinoQueriesReducer.reducer;
