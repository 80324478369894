import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import classNames from 'classnames';
import { Icon } from '@/components';
import { PlanUpgradeDirection } from '@/views/settings/billing/index';
import { DOMUtils } from '@/utils';
import { useAppSelector } from '@/store/hooks';
import { Skeleton } from '@douyinfe/semi-ui';

export interface PlanItem {
  id: number,
  activeIds: number[],
  name: string,
  type: string,
  popular: string,
  desc: string,
  price: string,
  include: string,
  feature: string[]
}

interface PlanItemProps {
  current: number;
  index: number;
  plan: PlanItem;
  active: boolean
  onPayment: (item: PlanItem, direction: PlanUpgradeDirection) => void;
}

function PlanItemComponent({
  current,
  index,
  plan,
  active,
  onPayment
}: PlanItemProps): React.ReactElement {
  const { t } = useTranslation(['settings', 'translation']);
  const currentPaymentInfo = useAppSelector((state) => state.settingBilling.current);

  const renderCurrentButton = (itemIndex: number, item: PlanItem): React.ReactElement => {
    if (!currentPaymentInfo) {
      return (
        <Skeleton.Button style={
          {
            width: '100%',
            height: '54px'
          }
        }
        />
      );
    }
    if (current === itemIndex) {
      return (
        <button
          className={classNames('btn-plan', 'bolder')}
          type="button"
          disabled
        >
          {t('billing.currentPlan')}
        </button>
      );
    }
    if (itemIndex < current) {
      return (
        <button
          className={classNames('btn-plan', 'bolder')}
          type="button"
          onClick={() => onPayment(item, 'downgrade')}
        >
          {t('common.downgrade', { ns: 'translation' })}
        </button>
      );
    }
    if (plan.price.toLowerCase() === 'custom') {
      return (
        <button
          className={classNames('btn-plan', 'bolder', 'custom')}
          type="button"
          onClick={() => DOMUtils.open('https://chainbase.com/contact')}
        >
          {t('common.contactUs', { ns: 'translation' })}
        </button>
      );
    }
    return (
      <button
        className={classNames('btn-plan', 'bolder', 'upgrade')}
        type="button"
        onClick={() => onPayment(item, 'upgrade')}
      >
        {t('common.upgrade', { ns: 'translation' })}
      </button>
    );
  };

  const renderPrice = (priceStr: string): React.ReactElement => {
    const price = parseInt(priceStr, 10);
    if (_.isNaN(price)) {
      return (
        <h2 className={classNames('price')}>
          <span>{priceStr}</span>
        </h2>
      );
    }

    return (
      <h2 className={classNames('price')}>
        <span className={classNames('doller')}>$</span>
        <span>{priceStr}</span>
        <span className={classNames('month')}>
          {' / '}
          {t('billing.timeline')}
        </span>
      </h2>
    );
  };

  return (
    <div className={classNames('plat-item', plan.type)}>
      <div
        className={classNames('current-outline', {
          active
        })}
      />

      <div className={classNames('card-content')}>
        {
          active
            ? (
              <div
                className={classNames('medium', 'tag')}
              >
                <svg
                  style={{ marginRight: '8px' }}
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.0553 4.06603L5.4562 11.5883L1 7.17714L2.05526 6.11111L5.4562 9.4777L12 3L13.0553 4.06603Z"
                    fill="#2B6EDE"
                  />
                </svg>
                {t('billing.currentPlan')}
              </div>
            )
            : null
        }

        <h2 className={classNames('name', 'bolder')}>{plan.name}</h2>
        <p className={classNames('desc')}>{plan.desc}</p>

        <div className="planItemline" />
        {plan.popular && <div className="planItemPopularTag">{plan.popular}</div>}

        {renderPrice(plan.price)}

        {renderCurrentButton(index, plan)}

        <div className={classNames('feature-wrapper')}>
          <p className={classNames('tip', 'bolder')}>{plan.include}</p>

          {
            _.map(plan.feature, (i) => (
              <div key={i} className={classNames('feature-item')}>
                <Icon
                  icon="settings/feature"
                  className={classNames('icon feature-icon')}
                />
                <p>{i}</p>
              </div>
            ))
          }

          {/* <a
            className="btn-more"
            href="https://chainbase.com/pricing"
            target="_blank"
            rel="noreferrer"
          >
            <Icon
              icon={plan.type !== 'enterprise' ? 'settings/more-detail' : 'settings/more-detail-white'}
              className={classNames('icon', 'more-detail')}
            />
            <span>{t('billing.more')}</span>
          </a> */}
        </div>

      </div>
    </div>
  );
}

interface PlanListProps {
  onPayment: (item: PlanItem, type: PlanUpgradeDirection) => void;
}

export default function PlanList({
  onPayment
}: PlanListProps): React.ReactElement {
  const { t } = useTranslation(['settings']);

  const currentPaymentInfo = useAppSelector((state) => state.settingBilling.current);
  const planList = t('billing.plan.list', { returnObjects: true }) as PlanItem[];
  const currentPlanIndex = currentPaymentInfo?.next_subscription_plan || 0;

  return (
    <div className={classNames('plan-list')}>
      {
        _.map(planList, (item) => (
          <PlanItemComponent
            active={item.activeIds.includes(currentPlanIndex)}
            key={item.name}
            current={currentPlanIndex}
            index={item.id}
            plan={item}
            onPayment={onPayment}
          />
        ))
      }
    </div>
  );
}
