import { createContext, useMemo, useReducer } from 'react';

interface JobCreationProviderProps {
  children: React.ReactNode;
}

export interface TableColumn {
  field: string;
  type: string;
  description: string;
}

export interface SelectTable {
  title: {
    blockchain: string[];
    name: string;
    table_name: string;
    prefixName: string;
    subfixName: string;
  };
  desc: string;
  statistics: {
    rows?: number;
    latestBlockTimeStamp?: number;
  },
  columns: TableColumn[];
}

interface SelectIntegration {
    integrationId: string | null;
    type: string | null;
}

interface ConfigureJob {
  selectedFields: string[]
}

interface JobCreationState {
  selectedTable?: SelectTable;
  selectedIntegration?: SelectIntegration;
  configureJob: ConfigureJob,
  allowedSteps: number;
}

type JobCreationAction = {type: 'selectTable', value: SelectTable}
| {type: 'selectIntegration', value: SelectIntegration}
| {type: 'configureJob', value: ConfigureJob};

interface JobCreationContextProps {
  jobCreationState: JobCreationState;
  jobCreationDispatch: React.Dispatch<JobCreationAction>;
}

const initialState = {
  configureJob: {
    selectedFields: []
  },
  allowedSteps: 0
};

const reducer = (prevState: JobCreationState, action: JobCreationAction) => {
  if (action.type === 'selectTable') {
    return {
      ...prevState,
      selectedTable: action.value,
      allowedSteps: Math.max(prevState.allowedSteps, 1)
    };
  }
  if (action.type === 'selectIntegration') {
    return {
      ...prevState,
      selectedIntegration: action.value,
      allowedSteps: Math.max(prevState.allowedSteps, 2)
    };
  }
  if (action.type === 'configureJob') {
    return {
      ...prevState,
      configureJob: action.value,
      allowedSteps: Math.max(prevState.allowedSteps, 3)
    };
  }
  throw new Error(`Unexpected action type of ${JSON.stringify(action)})}`);
};

export const JobCreationContext = createContext<JobCreationContextProps>({
  jobCreationState: initialState,
  jobCreationDispatch(): void {
    throw new Error('Unexpected call of jobCreationDispatch');
  }
});

export default function JobCreationProvider({ children }: JobCreationProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => ({
    jobCreationState: state,
    jobCreationDispatch: dispatch
  }), [state]);

  return (
    <JobCreationContext.Provider value={value}>
      {children}
    </JobCreationContext.Provider>
  );
}
