import { initializeConnector } from '@web3-react/core';
import { WalletConnect } from '@web3-react/walletconnect-v2';

const APIKey = process.env.REACT_APP_WALLETCONNECT_API_KEY as string;
export const [walletConnect, hooks, store] = initializeConnector<WalletConnect>(
  (actions: any) => new WalletConnect({
    actions,
    options: {
      projectId: APIKey,
      chains: [1],
      rpcMap: {
        1: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`
      },
      showQrModal: true
    }
  })
);
