import React from 'react';
import { useParams } from 'react-router-dom';
import '../modifySuccessful/index.scss';
import { useTranslation } from 'react-i18next';
import { HeaderTitle } from '@/components';
import StatusCard from '../modifySuccessful/StatusCard';

type ActivationStatusParams = {
  status: 'success' | 'fail';
};

export default function ActivationStatus(): React.ReactElement {
  const { status }: ActivationStatusParams = useParams() as ActivationStatusParams;

  const { t } = useTranslation();

  const path = status === 'success' ? 'onboarding' : 'register';

  return (
    <>
      <HeaderTitle title={t('register.headerTitle')} />
      <StatusCard title={t(`register.activation.${status}`)} path={path} />
    </>
  );
}
