import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderTitle } from '@/components';
import './index.scss';
import StatusCard from './StatusCard';

export default function ModifySuccessful(): React.ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <HeaderTitle title={t('resetPassword.headerTitle')} />
      <StatusCard title={t('modifySuccessful.title')} path="login" />
    </>
  );
}
