import React from 'react';
import './index.scss';
import _ from 'lodash';
import {
  NavigationItem,
  NavigationItemProps
} from '@/components/Navigation/index';

export default function SettingNavigation(): React.ReactElement {
  const SettingNavigationItems: NavigationItemProps[] = [
    // {
    //   key: 'navigation.settings.account',
    //   icon: 'navigation/account',
    //   link: '/settings/'
    // },
    {
      key: 'navigation.settings.billing',
      icon: 'navigation/billing',
      link: '/settings/billing'
    }
  ];

  return (
    <div className="navigation settings-navigation">
      <div className="navigation-list">
        {
          _.map(SettingNavigationItems, (item) => (
            <NavigationItem
              key={item.key}
              item={item}
            />
          ))
        }
      </div>
    </div>
  );
}
