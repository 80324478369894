/* eslint-disable react/jsx-closing-tag-location */
import { Card, Form } from '@douyinfe/semi-ui';
import isDB from '@/views/sync/utils/is-db';
import React, { useContext, useImperativeHandle, useRef, useState } from 'react';
import './index.scss';
import Label from '@/views/sync/commons/Label';
import ActivateFieldsToSyncCard from '@/views/sync/commons/ActivateFieldsToSyncCard';
import usePrefixedTranslation from '@/utils/usePrefixedTranslation';
import SyncFrequencyFormCard, { SyncFrequencyFormCardRef } from '@/views/sync/commons/SyncFrequencyFormCard';
import { JobCreationContext } from '../JobCreationProvider';
import CreateTableCard, { CreateTableCardRef } from './components/CreateTableCard';

export interface ConfigureJobRef {
  onSubmit:() => void
}

interface ConfigureJobProps {
  createJob: (data: Record<string, any>) => void;
  onTestTableSucceedChange: (succeed: boolean) => void;
}

const ConfigureJob = React.forwardRef<ConfigureJobRef, ConfigureJobProps>(({
  createJob,
  onTestTableSucceedChange
}, ref) => {
  const configureJobFormRef = useRef<Form>(null);
  const tConfigure = usePrefixedTranslation('sync.jobs.newJobSteps.configureJob.content.');

  const { jobCreationState } = useContext(JobCreationContext);
  const { selectedTable, selectedIntegration } = jobCreationState;
  const fieldList = selectedTable?.columns.map((col) => col.field) ?? [];
  const initialDesitinationFields: Record<string, string> = {};
  fieldList.forEach((field) => { initialDesitinationFields[field] = field; });
  const [selectedFields, setSelectedFields] = useState<string[]>(fieldList);
  const [desitinationFields, setDesitinationFields] = useState<Record<string, string>>(initialDesitinationFields);
  const syncFrequencyFormCardRef = useRef<SyncFrequencyFormCardRef>(null);
  const [desitinationTableName, setDesitinationTableName] = useState<string>(selectedTable?.title.table_name.replaceAll('.', '_') ?? '');
  const createTableCardRef = useRef<CreateTableCardRef>(null);

  if (!selectedTable || !selectedIntegration) {
    throw new Error('Unexpected undefined of selectedTable');
  }

  useImperativeHandle(ref, () => ({
    onSubmit: () => {
      configureJobFormRef.current?.formApi.submitForm();
    }
  }));

  const onFormSubmit = (values: Record<string, any>) => {
    if (selectedIntegration.integrationId === null) {
      return;
    }

    const fields: Record<string, string> = {};
    selectedFields.forEach((fieldName) => {
      fields[fieldName] = values.field_map[fieldName];
    });

    const syncFrequencyFormValues = syncFrequencyFormCardRef.current?.getFormValues();
    const formData: Record<string, any> = {
      job_name: values.job_name,
      source_table: selectedTable.title.table_name,
      sync_mode: values.sync_frequency === 'manual' ? 'manual' : 'batch',
      integration_id: selectedIntegration.integrationId,
      field_map: fields,
      target: selectedIntegration.type ?? '',
      ...syncFrequencyFormValues
    };

    if (values.target_table !== undefined) {
      formData.target_table = values.target_table;
    }

    createJob(formData);
  };

  const onFormValidate = (values: Record<string, any>) => {
    const errors: Record<string, string> = {};

    if (!values.job_name) {
      errors.job_name = 'Job name cannot be empty.';
    }

    // if (values.sync_mode === 'cron') {
    //   try {
    //     if (!values.sync_period) {
    //       errors.sync_period = 'Cron expression cannot be empty.';
    //     } else {
    //       CronParser.parseExpression(values.sync_period);
    //     }
    //   } catch {
    //     errors.sync_period = 'Invalid cron expression.';
    //   }
    // }

    return Object.keys(errors).length === 0 ? '' : errors;
  };

  const onSelectedFieldsChange = (newSelectedFields: string[]): void => {
    setSelectedFields(newSelectedFields);
    onTestTableSucceedChange(false);
    createTableCardRef.current?.resetTestStatus();
  };

  const onDesitinationFieldsChange = (newDesitinationFields: Record<string, string>): void => {
    setDesitinationFields(newDesitinationFields);
    onTestTableSucceedChange(false);
    createTableCardRef.current?.resetTestStatus();
  };

  const onDesitinationTableNameChange = (newDesitinationTableName: string): void => {
    setDesitinationTableName(newDesitinationTableName);
    onTestTableSucceedChange(false);
    createTableCardRef.current?.resetTestStatus();
  };

  const renderJobNameCard = (): React.ReactElement => (
    <Card className="form-field-card">
      <Form.Input
        className="job-name-input"
        required
        field="job_name"
        label={(<Label label={tConfigure('jobName')} />)}
        initValue={`${selectedIntegration.type}_${selectedTable.title.table_name}`}
      />
    </Card>
  );

  const syncFields = selectedTable.columns.map((col) => col.field);
  return (
    <div className="sync-new-job-configure-job">
      <Form
        autoScrollToError
        ref={configureJobFormRef}
        onSubmit={onFormSubmit}
        validateFields={onFormValidate}
      >
        {renderJobNameCard()}
        <SyncFrequencyFormCard
          ref={syncFrequencyFormCardRef}
          table={selectedTable}
        />
        <ActivateFieldsToSyncCard
          blockchains={selectedTable.title.blockchain}
          tableName={selectedTable.title.table_name}
          integration={selectedIntegration.type ?? ''}
          fields={syncFields}
          initialSelectedFields={fieldList}
          onSelectedFieldsChange={onSelectedFieldsChange}
          onDesitinationFieldsChange={onDesitinationFieldsChange}
          onDesitinationTableNameChange={onDesitinationTableNameChange}
        />
        {selectedIntegration.type && isDB(selectedIntegration.type) && (
        <CreateTableCard
          ref={createTableCardRef}
          integrationId={selectedIntegration.integrationId ?? ''}
          tableName={desitinationTableName}
          tableColumns={selectedTable.columns}
          selectedFields={selectedFields}
          desitinationFields={desitinationFields}
          onTestTableSucceedChange={onTestTableSucceedChange}
        />
        )}
      </Form>
    </div>
  );
});

export default ConfigureJob;
