import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Button, Col } from '@douyinfe/semi-ui';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from '@douyinfe/semi-ui/table/interface';
import _ from 'lodash';
import {
  Icon, Layout, Modal, Pagination, Table, Toast
} from '@/components';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { fetchProjectList } from '@/store/reducers/projectReducer';
import { DateUtils } from '@/utils';
import { Project } from '@/model/Project';
import OperationButton from '@/components/Button/OperationButton';
// import AccessToken from '@/components/AccessToken';
import ProjectFormModal from '@/views/dashboard/index/ProjectFormModal';
import ProjectApi from '@/api/ProjectApi';
import TableEmpty from '@/components/Table/TableEmpty';
import AreaLineChart from '@/components/Chart/AreaLineChart';

export default function ProjectList(): React.ReactElement {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['dashboard', 'translation']);
  const { Header } = Layout;

  const projectTable = useAppSelector((state) => state.project.projects);

  useEffect(() => {
    dispatch(fetchProjectList());
  }, [dispatch]);

  const columns: ColumnProps<Project>[] = [
    {
      title: t('projects.table.name'),
      // width: 186,
      dataIndex: 'name',
      className: 'medium min-w-186',
      render: (text) => (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {text}
          <Icon icon="common/key" style={{ color: '#A1AAB0', marginLeft: '12px' }} className="hover-icon" />
        </span>
      )
    },
    {
      title: t('projects.table.description'),
      dataIndex: 'description',
      className: 'min-w-400'
    },
    // {
    //   title: t('projects.table.accessKey'),
    //   width: 350,
    //   dataIndex: 'secret_key',
    //   render: (secretKey) => (
    //     <div onClick={(e) => {
    //       e.stopPropagation();
    //       e.preventDefault();
    //     }}
    //     >
    //       <AccessToken token={secretKey} />
    //     </div>
    //   )
    // },
    {
      title: t('projects.table.requestFlow'),
      dataIndex: 'monitor',
      className: 'min-w-294',
      // width: 294,
      render: (a, record) => {
        const resultData = _.zip(
          _.map(record?.monitor?.query_data?.datetime, (i) => i * 1000),
          record?.monitor?.query_data?.Request
        );
        return <AreaLineChart data={resultData} />;
      }
    },
    {
      title: t('projects.table.created'),
      // width: 150,
      className: 'min-w-150',
      dataIndex: 'created_at',
      render: (time) => DateUtils.fromNow(time)
    },
    {
      title: '',
      dataIndex: 'uuid',
      className: 'min-w-50',
      // width: 50,
      render: (uuid: string, record: Project) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <OperationButton
            menu={[
              {
                node: 'item',
                name: t('common.edit', { ns: 'translation' }),
                onClick: () => {
                  const modal = Modal.info({
                    title: t('projects.updateTitle'),
                    className: 'create-project-popover',
                    footer: null,
                    content: (
                      <ProjectFormModal
                        project={record}
                        onCancel={() => modal.destroy()}
                        onComplete={() => {
                          dispatch(fetchProjectList());
                        }}
                      />
                    )
                  });
                }
              },
              {
                node: 'item',
                name: t('common.delete', { ns: 'translation' }),
                type: 'danger',
                onClick: () => {
                  Modal.delete({
                    title: t('projects.deleteTitle'),
                    tip: t('projects.deleteTip').replace('{project}', record.name),
                    onComplete: () => {
                      dispatch(fetchProjectList());
                    },
                    onDelete: async () => {
                      const response = await ProjectApi.delete(record.uuid);
                      if (response.data.code === 200) {
                        Toast.success(t('toast.deleted', { ns: 'translation', name: record.name, type: t('projects.type') }));
                      } else {
                        Toast.toastResponse(response);
                      }
                      return response;
                    }
                  });
                }
              }
            ]}
          />
        </div>
      )
    }
  ];

  const onRow = useMemo(
    () => (record: any) => ({
      onClick: () => {
        navigate(`/project/${record.uuid}/endpoints`);
      }
    }),
    [navigate]
  );

  return (
    <div className={classNames('projects')}>
      <Header icon="dashboard/projects" title={t('projects.title')}>
        <Col>
          <Button
            size="large"
            icon={<Icon icon="common/create" />}
            theme="solid"
            type="primary"
            onClick={() => {
              const modal = Modal.info({
                title: t('projects.createTitle'),
                className: 'create-project-popover',
                footer: null,
                content: (
                  <ProjectFormModal
                    onCancel={() => modal.destroy()}
                    onComplete={() => {
                      dispatch(fetchProjectList());
                    }}
                  />
                )
              });
            }}
          >
            {t('projects.create')}
          </Button>
        </Col>
      </Header>

      <div className={classNames('project-list')}>
        <Table
          loading={projectTable?.loading}
          columns={columns}
          dataSource={projectTable?.list}
          empty={(
            <TableEmpty
              className="empty"
              icon="dashboard/empty-project"
              title={t('projects.empty.title')}
              desc={t('projects.empty.desc')}
            />
          )}
          onRow={onRow}
        />
        <Pagination
          total={projectTable?.pagination?.total}
          showSizeChanger
          onChange={(currentPage, pageSize) => {
            dispatch(
              fetchProjectList({
                page: currentPage,
                limit: pageSize
              })
            );
          }}
        />
      </div>
    </div>
  );
}
