import React from 'react';
import { Button } from '@douyinfe/semi-ui';
import { ButtonProps } from '@douyinfe/semi-ui/lib/es/button/Button';
import { Loading } from '../index';
import { LoadingTypes } from '../Loading';

export declare type ButtonLoadingProps = {
  children?: React.ReactNode;
  loadingType?: LoadingTypes;
} & ButtonProps

const defaultButtonLoadingProps: ButtonLoadingProps = {
  loading: false,
  loadingType: undefined
};

export default function ButtonLoading({
  loading = false,
  loadingType,
  children,
  ...restProps
} = defaultButtonLoadingProps): React.ReactElement {
  /* eslint-disable react/jsx-props-no-spreading */

  // Hook double click event
  const hookEvent: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ): void => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Button
      theme="solid"
      onClick={loading ? hookEvent : undefined}
      {...restProps}
    >
      {loading ? <Loading type={loadingType} /> : children}
    </Button>
  );
}
