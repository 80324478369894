import { useJobList } from './JobRequestHooks';

export default function useJobLimitation() {
  const { data, isLoading } = useJobList();

  let overLimitation = false;
  if (data && data.length >= 10) {
    overLimitation = true;
  }

  return {
    isLoading,
    overLimitation
  };
}
