import React from 'react';
import { Pagination as SemiPagination } from '@douyinfe/semi-ui';
import { TablePaginationProps } from '@douyinfe/semi-ui/table/interface';
import classNames from 'classnames';
import './index.scss';
import { Icon } from '@/components';

export default function Index(props: TablePaginationProps): React.ReactElement | null {
  const { total, className, ...rest } = props;

  if (!total) {
    return null;
  }

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <SemiPagination
      pageSize={20}
      pageSizeOpts={[10, 20, 50]}
      popoverPosition="bottom"
      total={total}
      {...rest}
      className={classNames('pagination', 'table-pagination', className)}
      prevText={
        <Icon className={classNames('icon', 'icon-prev-arrow')} icon="common/arrow-line" />
      }
      nextText={
        <Icon className={classNames('icon', 'icon-prev-arrow')} icon="common/arrow-line" />
      }
    />
  );
}
