import moment, { unitOfTime } from 'moment';

export default class DateUtils {
  static format(datetime: string | number, format = 'LL'): string {
    if (typeof datetime === 'number') {
      return moment.unix(datetime)
        .format(format);
    }
    return moment(datetime)
      .format(format);
  }

  static calcBeginTime(timeRange: string): number {
    const unit = timeRange.charAt(
      timeRange.length - 1
    ) as unitOfTime.DurationConstructor;

    const amount = parseInt(timeRange.toString(), 10);
    const beginTime = moment()
      .subtract(amount, unit);
    return beginTime.unix();
  }

  static fromNow(datetime: string | number, withoutSuffix?: boolean): string {
    return moment(datetime).fromNow(withoutSuffix);
  }

  static convertSecondToTimeFormat(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const timeArr = [hours, minutes, remainingSeconds];
    const timeFormatArr = ['h', 'm', 's'];

    return timeFormatArr.map((timeFormat, index) => {
      if (timeArr[index] > 0) {
        return `${timeArr[index]}${timeFormat}`;
      }
      return undefined;
    }).filter((item) => !!item).join(' ');
  }
}
