import React from 'react';
import { Select as SemiSection, withField } from '@douyinfe/semi-ui';
import _ from 'lodash';
import './index.scss';
import classNames from 'classnames';
import {
  OptionProps,
  optionRenderProps
} from '@douyinfe/semi-ui/lib/es/select';
import { Icon } from '@/components';

export const SelectIcon = (
  <Icon icon="common/arrow-down-select" />
);

function Select(
  props: React.ComponentProps<typeof SemiSection>
): JSX.Element {
  const {
    className,
    children,
    defaultValue
  } = props;

  const selectRef = React.useRef<SemiSection>();

  const { renderOptionItem, optionList } = props;

  if (renderOptionItem) {
    const renderOptionItemWrapper = (item: optionRenderProps): React.ReactNode => {
      const onSelect = (selectedItem: OptionProps, event: any): void => {
        // hook on select function
        const index = _.findIndex(optionList, { value: selectedItem.value });
        selectRef?.current?.onSelect(selectedItem, index, event);
      };
      return renderOptionItem({
        ...item,
        onSelect
      });
    };

    props = {
      ...props,
      renderOptionItem: renderOptionItemWrapper
    };
  }

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <SemiSection
      ref={selectRef}
      arrowIcon={SelectIcon}
      clickToHide
      defaultValue={defaultValue}
      className={classNames(['chainbase-select', className])}
      {...props}
    >
      {children}
    </SemiSection>
  );
}

Select.Option = SemiSection.Option;

export default Select;

export const FormSelect = withField(Select);
