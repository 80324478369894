import React, { ForwardedRef, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { ButtonLoading, Modal } from '@/components';
import { Button, Form } from '@douyinfe/semi-ui';
import { AxiosResponse } from 'axios';
import { Optional } from '@/props';
import { FormApi } from '@douyinfe/semi-ui/lib/es/form';

type FormModalProps = {
  title: string;
  submitText?: string;
  getFormApi?: (formApi: FormApi) => void;
  onSubmit: (values: Record<string, any>) => Promise<AxiosResponse>
} & PropsWithChildren<any>

function FormModal(
  props: Optional<FormModalProps>,
  ref: ForwardedRef<any>
): React.ReactElement {
  const {
    title,
    submitText,
    getFormApi,
    onSubmit,
    children
  } = props;

  const [visible, setVisible] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>();
  const formApiRef = React.useRef<FormApi | null>(null);

  const { t } = useTranslation();

  React.useImperativeHandle(ref, () => ({
    open(): void {
      setVisible(true);
    }
  }));

  const handleSubmit = async (values: Record<string, any>): Promise<any> => {
    try {
      setLoading(true);
      await formApiRef.current?.validate();
      const result = await onSubmit(values);
      setLoading(false);
      return result;
    } catch (e) {
      setLoading(false);
      return Promise.resolve();
    }
  };

  return (
    <Modal
      title={title}
      className="form-modal"
      onCancel={() => setVisible(false)}
      visible={visible}
      footer={null}
    >
      <Form
        getFormApi={(formApi: FormApi) => {
          formApiRef.current = formApi;
          if (getFormApi) {
            getFormApi(formApi);
          }
        }}
        onValueChange={(values: Record<string, any>, changedValue: Record<string, any>) => {
          const fieldName = _.keys(changedValue)[0];
          formApiRef.current?.setError(fieldName, null);
        }}
        onSubmit={handleSubmit}
      >
        {children}

        <div className="semi-modal-footer-flex">
          <Button
            theme="light"
            type="tertiary"
            htmlType="button"
            className="btn btn-cancel"
            onClick={(): void => setVisible(false)}
          >
            {t('common.cancel')}
          </Button>

          <ButtonLoading
            loading={loading}
            className="btn-create btn-enhance modal-btn-create"
            size="default"
            type="primary"
            htmlType="submit"
          >
            {submitText || t('common.saveChanges')}
          </ButtonLoading>
        </div>
      </Form>
    </Modal>
  );
}

export default React.forwardRef<FormModalType | undefined, FormModalProps>(FormModal);

export declare type FormModalType =
  React.ElementRef<typeof FormModal>
  | {
  open: () => void
};
