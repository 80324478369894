import React from 'react';
import { Input, Select, withField } from '@douyinfe/semi-ui';
import { SelectProps } from '@douyinfe/semi-ui/lib/es/select';
import classNames from 'classnames';
import './index.scss';
import { Icon } from '@/components';
import { useTranslation } from 'react-i18next';

// class FormSearchSelectNative extends Select {
//   render(): React.ReactElement {
//     return super.render();
//   }
// }

function FormSearchSelectNative(props: SelectProps): React.ReactElement {
  const { t } = useTranslation();
  const selectRef = React.useRef<Select>();

  const handleChange = (value: string): void => {
    selectRef.current?.handleInputChange(value);
  };

  const {
    className,
    ...rest
  } = props;
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Select
      ref={selectRef}
      className={classNames([className, 'form-search-select'])}
      {...rest}
      filter
      dropdownClassName="form-search-select-dropdown"
      outerTopSlot={(
        <div className="select-search-input-wrapper">
          <Input
            tabIndex={-1}
            prefix={<Icon className="icon" icon="input/search" />}
            className="select-search-input"
            placeholder={t<string>('common.search')}
            onChange={handleChange}
          />
        </div>
      )}
    />
  );
}

const FormSearchSelect = withField(FormSearchSelectNative);

export default FormSearchSelect;
