/* eslint-disable implicit-arrow-linebreak */
import EventEmitter from 'eventemitter3';
import { useEffect, useRef } from 'react';

export default function useEventEmitter() {
  const eventEmitterRef = useRef(new EventEmitter());

  const useSubcribe = (
    event: string | symbol,
    fn: (...args: any[]) => void,
    context?: any
  ) =>
    useEffect(() => {
      eventEmitterRef.current.on(event, fn, context);

      return () => {
        eventEmitterRef.current.off(event, fn, context);
      };
    }, [context, event, fn]);

  const useSubcribeOnce = (
    event: string | symbol,
    fn: (...args: any[]) => void,
    context?: any
  ) =>
    useEffect(() => {
      eventEmitterRef.current.once(event, fn, context);

      return () => {
        eventEmitterRef.current.off(event, fn, context, true);
      };
    }, [context, event, fn]);

  const emit = (event: string | symbol, ...args: any[]) =>
    eventEmitterRef.current.emit(event, ...args);

  const value = {
    useSubcribe,
    useSubcribeOnce,
    emit
  };

  return value;
}
