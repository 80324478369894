import axios, { AxiosResponse } from 'axios';
import { CredentialItem } from './type';

interface Response<T = any> {
  code: number;
  data: T;
  msg: string;
}

const KAFKA_V1_BASE_URL = '/api/v2/sync/kafka/v1';

export const checkHealth = async (): Promise<any> => {
  const response = await axios.get('/api/v2/sync/kafka/index', {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response;
};

export const checkKafkaIntegration = async (apiKey: string): Promise<any> => {
  const response = await axios.get(
    `${KAFKA_V1_BASE_URL}/CheckKafkaIntegration`,
    {
      headers: {
        'x-api-key': apiKey,
        'Content-Type': 'application/json'
      }
    }
  );
  return response;
};

export const enableKafkaIntegration = async (apiKey: string): Promise<any> => {
  const response = await axios.post(
    `${KAFKA_V1_BASE_URL}/EnableKafkaIntegration`,
    {},
    {
      headers: {
        'x-api-key': apiKey,
        'Content-Type': 'application/json'
      }
    }
  );
  return response;
};

export const fetchDatasetTopicsList = async (
  dataType?: string
): Promise<AxiosResponse<Response<any[]>>> => {
  const response = await axios.get(
    `${KAFKA_V1_BASE_URL}/ListDatasetTopics${
      dataType ? `?data_type=${dataType}` : ''
    }`,
    {
      headers: {
        'x-api-key': 'demo',
        'Content-Type': 'application/json'
      }
    }
  );
  return response;
};

interface FetchKafkaStatsProps {
  page?: number;
  limit?: number;
}
export const fetchKafkaStats = async (
  apiKey: string,
  params?: FetchKafkaStatsProps
): Promise<AxiosResponse<Response<any>>> => {
  const response = await axios.get(`${KAFKA_V1_BASE_URL}/GetKafkaStats`, {
    headers: {
      'x-api-key': apiKey,
      'Content-Type': 'application/json'
    },
    params
  });
  return response;
};

export const fetchKafkaCredentials = async (
  apiKey: string,
  params?: FetchKafkaStatsProps
): Promise<
  AxiosResponse<
    Response<{ keys: CredentialItem[]; next_page: number; totals: number }>
  >
> => {
  const response = await axios.get(
    `${KAFKA_V1_BASE_URL}/ListConsumerGroupKeys`,
    {
      headers: {
        'x-api-key': apiKey,
        'Content-Type': 'application/json'
      },
      params
    }
  );
  return response;
};

export const createCredential = async (apiKey: string): Promise<any> => {
  const response = await axios.post(
    `${KAFKA_V1_BASE_URL}/GenerateCredentials`,
    {},
    {
      headers: {
        'x-api-key': apiKey,
        'Content-Type': 'application/json'
      }
    }
  );
  return response;
};

export const deleteCredential = async (
  apiKey: string,
  id: string
): Promise<any> => {
  const response = await axios.delete(
    `${KAFKA_V1_BASE_URL}/DeleteConsumerGroupKey?key=${id}`,
    {
      headers: {
        'x-api-key': apiKey,
        'Content-Type': 'application/json'
      }
    }
  );
  return response;
};

export const getPreviewData = async (
  topic: string
): Promise<AxiosResponse<Response<any>>> => {
  const response = await axios.get(
    `${KAFKA_V1_BASE_URL}/GetKafkaTopicPreview`,
    {
      headers: {
        'x-api-key': 'demo',
        'Content-Type': 'application/json'
      },
      params: {
        topic
      }
    }
  );
  return response;
};

export const resetGroupConsumeOffset = async (
  apiKey: string,
  topic: string
): Promise<AxiosResponse<Response<any>>> => {
  const response = await axios.post(
    `${KAFKA_V1_BASE_URL}/ResetGroupConsumeOffset`,
    {
      topic_list: [topic],
      reset_latest_offset: true
    },
    {
      headers: {
        'x-api-key': apiKey,
        'Content-Type': 'application/json'
      },
      params: {
        topic
      }
    }
  );
  return response;
};

export const deleteGroupConsumeOffset = async (
  apiKey: string,
  topic: string
): Promise<AxiosResponse<Response<any>>> => {
  const response = await axios.post(
    `${KAFKA_V1_BASE_URL}/DeleteGroupConsumeOffset`,
    {
      topic_list: [topic]
    },
    {
      headers: {
        'x-api-key': apiKey,
        'Content-Type': 'application/json'
      },
      params: {
        topic
      }
    }
  );
  return response;
};
