import ReactMarkdown from 'react-markdown';
import './index.scss';

type MarkdownContentProps = {
  children: string;
};

export default function Markdown({
  children
}: MarkdownContentProps) {
  return (
    <ReactMarkdown className="markdown-container">{children}</ReactMarkdown>
  );
}
