import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SearchPage } from '@/model/Request';
import { RequestTable } from '@/model/Table';
import ArchivedQueriesApi from '@/api/ArchivedQueriesApi';
import { ArchivedQueryItem } from '@/props';

type StateProps = {
  search: SearchPage,
  table: RequestTable<ArchivedQueryItem>
};

const initialState: StateProps = {
  search: {
    page: 1,
    limit: 20
  },
  table: {
    loading: false,
    list: [],
    pagination: {
      total: 0
    }
  }
};

export const fetchArchivedQueriesList = createAsyncThunk(
  'archivedQueries/getList',
  async (page: SearchPage) => {
    const response = await ArchivedQueriesApi.getList(page);
    const { data } = response.data;
    return {
      table: {
        loading: false,
        list: data.data,
        pagination: { total: data.count }
      },
      search: page
    };
  }
);

export const archivedQueriesReducer = createSlice({
  name: 'ArchivedQueriesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchArchivedQueriesList.pending, (state) => {
        state.table.loading = true;
      })
      .addCase(
        fetchArchivedQueriesList.fulfilled,
        (state, action) => {
          state.table = action.payload.table;
          state.search = action.payload.search;
        }
      )
      .addCase(
        fetchArchivedQueriesList.rejected,
        (state) => {
          state.table.loading = false;
        }
      );
  }
});

export default archivedQueriesReducer.reducer;
