import Cookies, { CookieAttributes } from 'js-cookie';

export default class CookieUtils {
  /**
   * Set Cookie
   */
  static set(
    name: string,
    value: string,
    options?: CookieAttributes
  ): string | undefined {
    return Cookies.set(name, value, options);
  }

  /**
   * Read cookie
   */
  static get(name: string): string | undefined {
    return Cookies.get(name);
  }

  /**
   * Read all available cookies
   */
  static getAll(): { [key: string]: string } {
    return Cookies.get();
  }

  /**
   * Delete cookie
   */
  static remove(name: string, options?: CookieAttributes): void {
    return Cookies.remove(name, options);
  }
}
