import React from 'react';
import { Icon, Loading } from '@/components';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '@douyinfe/semi-ui';

export function PaymentProgressing(): React.ReactElement {
  const { t } = useTranslation(['settings', 'translation']);
  return (
    <div className={classNames('payment-status', 'payment-progress')}>
      <div className="content">
        <Loading type="grey" />
        <p className="desc">{t('billing.paymentStatus.processing')}</p>
      </div>

      <Button
        theme="solid"
        size="large"
        type="tertiary"
        disabled
        className={classNames('btn')}
      >
        {t('common.complete', { ns: 'translation' })}
      </Button>
    </div>
  );
}

interface PaymentCallback {
  onClick: () => void;
}

export function PaymentFailed(
  {
    message,
    onClick
  }: PaymentCallback & { message: string | undefined }
): React.ReactElement {
  const { t } = useTranslation(['settings']);
  return (
    <div className={classNames('payment-status', 'payment-failed')}>
      <div className="content">
        <Icon className="icon" icon="billing/payment-failed" />
        <p className="desc">{t('billing.paymentStatus.failed')}</p>
        <p className="tip">{message || t('billing.paymentStatus.failedTip')}</p>
      </div>

      <Button
        theme="solid"
        size="large"
        type="primary"
        className={classNames('btn')}
        onClick={onClick}
      >
        {t('billing.paymentStatus.try')}
      </Button>
    </div>
  );
}

export function PaymentSuccess({ onClick }: PaymentCallback): React.ReactElement {
  const { t } = useTranslation(['settings', 'translation']);

  return (
    <div className={classNames('payment-status', 'payment-success')}>
      <div className="content">
        <Icon className="icon" icon="billing/payment-success" />
        <p className="desc">{t('billing.paymentStatus.success')}</p>
        <p className="tip">{t('billing.paymentStatus.successTip')}</p>
      </div>

      <Button
        theme="solid"
        size="large"
        type="primary"
        onClick={onClick}
        className={classNames('btn')}
      >
        {t('common.complete', { ns: 'translation' })}
      </Button>
    </div>
  );
}

export declare type PaymentStatus = 'success' | string | undefined;

type PaymentStatusComponentProps = {
  status: PaymentStatus,
  onClose: () => void,
  onClear: () => void,
}

export function PaymentStatusComponent({
  status,
  onClose,
  onClear
}: PaymentStatusComponentProps): React.ReactElement {
  if (status === 'success') {
    return <PaymentSuccess onClick={onClose} />;
  }
  if (status) {
    return (
      <PaymentFailed
        onClick={onClear}
        message={status}
      />
    );
  }
  return <div />;
}
