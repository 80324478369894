import React from 'react';
import Loading from '.';

export const BlockLoading: React.FC = () => (
  <div style={{
    width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'
  }}
  >
    <Loading type="grey" />
  </div>
);
