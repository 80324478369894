import _ from 'lodash';
import classNames from 'classnames';
import { Icon } from '@/components';
import { Button } from '@douyinfe/semi-ui';
import React, { MouseEventHandler } from 'react';
import { TabBarProps } from '@douyinfe/semi-ui/lib/es/tabs';

export declare type EditorTabBarProps = {
  tabBarProps: TabBarProps,
  onAddQuery: MouseEventHandler<HTMLButtonElement>;
}

export default function EditorTabBar({ tabBarProps, onAddQuery }: EditorTabBarProps): React.ReactElement {
  const {
    activeKey,
    list,
    onTabClick,
    tabBarExtraContent,
    deleteTabItem
  } = tabBarProps;

  return (
    <div className="semi-tabs-bar semi-tabs-bar-card semi-tabs-bar-top">
      {
        _.map(list, (item, index) => (
          <div
            key={item.itemKey}
            className={classNames(['semi-tabs-tab', {
              'semi-tabs-tab-active': activeKey === item.itemKey
            }])}
            style={
              {
                zIndex: (list?.length || 100) - index
              }
            }
            onClick={(event) => {
              if (onTabClick) {
                onTabClick(item.itemKey, event);
              }
            }}
          >
            {item.tab}
            <span
              onClick={(event) => {
                if (deleteTabItem) {
                  deleteTabItem(item.itemKey, event);
                }
              }}
              className="semi-icon semi-icon-default semi-icon-close semi-tabs-tab-icon-close"
            >
              <Icon icon="common/close" />
            </span>
          </div>
        ))
      }
      <div className="close-wrapper">
        <Button
          className="btn-add"
          theme="borderless"
          icon={<Icon icon="common/add" />}
          onClick={onAddQuery}
        />
      </div>

      <div className="semi-tabs-bar-extra semi-tabs-bar-card-extra">
        {tabBarExtraContent}
      </div>
    </div>
  );
}
