import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Header, Container, HeaderTitle, Icon, Modal
} from '@/components';
import './index.scss';
import classNames from 'classnames';
import { Button, Skeleton } from '@douyinfe/semi-ui';
import AddCard from '@/views/settings/billing/AddCard';
import PlanList, { PlanItem } from '@/views/settings/billing/PlanList';
import RecentInvoiceTable from '@/views/settings/billing/RecentInvoiceTable';
import UpgradeModal from '@/views/settings/billing/UpgradeModal';
import { PaymentCardInfo } from '@/model/BillingPlan';
import { settingBillingThunk } from '@/store/reducers/settingBillingReducer';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import TextHighlight from '@/components/Text/TextHighlight';
import { DateUtils } from '@/utils';
import { reportEvent } from '@/utils/useTrack';

export declare interface SettingPanelProps {
  icon: string;
  name: string;
  children: React.ReactElement;
}

export function SettingPanel({ icon, name, children }: SettingPanelProps): React.ReactElement {
  return (
    <div className={classNames('setting-panel')}>
      <div className={classNames('head')}>
        <Icon className="icon" icon={icon} />
        <h5 className="name medium">{name}</h5>
      </div>
      {children}
    </div>
  );
}

interface PaymentMethodProps {
  card: PaymentCardInfo;
  onChange: () => void;
}

function Dot(): React.ReactElement {
  return <span className="dot" />;
}

function DotGroup(): React.ReactElement {
  return (
    <div className="dot-group">
      {_.times(4).map((index) => (
        <Dot key={`${index}`} />
      ))}
    </div>
  );
}

function PaymentMethod({ card: cardInfo, onChange }: PaymentMethodProps): React.ReactElement {
  const { t } = useTranslation(['settings', 'translation']);
  const currentPaymentInfo = useAppSelector((state) => state.settingBilling.cardInfo);

  const renderCardInfo = (): React.ReactElement => {
    if (cardInfo.last4) {
      return (
        <>
          {/* <Icon */}
          {/*  icon={`billing/${cardInfo?.brand?.toLowerCase()}-card`} */}
          {/*  className="icon" */}
          {/* /> */}
          <span className="brand">{cardInfo?.brand}</span>
          <div className="payment-card">
            {_.times(3).map((i) => (
              <DotGroup key={`${i}`} />
            ))}
            <span className="card-number">{cardInfo.last4}</span>
          </div>
          <span className="delimiter" />
          <span className="expires">
            {`${t('billing.payment.expires')}: `}
            {`${cardInfo.exp_month} / ${cardInfo.exp_year}`}
          </span>
        </>
      );
    }
    return <div className="empty-tip">{t('billing.detail.empty')}</div>;
  };

  return (
    <div className={classNames('payment-method')}>
      <Skeleton placeholder={<Skeleton.Title />} loading={!currentPaymentInfo}>
        {renderCardInfo()}
      </Skeleton>
      <Button theme="solid" className="btn-change" onClick={onChange}>
        {t('common.edit', { ns: 'translation' })}
      </Button>
    </div>
  );
}

function PlanExpiresTip(): React.ReactElement {
  const { t } = useTranslation('settings');

  const currentPaymentInfo = useAppSelector((state) => state.settingBilling.current);
  if (currentPaymentInfo?.subscription_plan === currentPaymentInfo?.next_subscription_plan) {
    return <div />;
  }

  const planList = t('billing.plan.list', { returnObjects: true }) as PlanItem[];
  const currentPlan = _.find(planList, (item: PlanItem) => item.id === currentPaymentInfo?.subscription_plan);

  const endDate = DateUtils.format(currentPaymentInfo?.current_period_end || 0);
  return (
    <div className="plan-expires-tip">
      <TextHighlight
        searchWords={[endDate]}
        textToHighlight={t('billing.planExpires')
          .replace('{plan}', currentPlan?.name || '')
          .replace('{end}', endDate)}
        highlightClassName={classNames('medium')}
        highlightStyle={{
          color: '#000000'
        }}
      />
    </div>
  );
}

export function PowerByStripeTitle({ children }: any): React.ReactElement {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ fontWeight: 600 }}>{children}</span>
      <span
        style={{
          fontSize: '14px',
          lineHeight: '16px',
          marginLeft: '18px',
          color: '#CACDD0',
          marginRight: '6px'
        }}
      >
        Powered by
      </span>
      <Icon icon="pricing/stripe" />
    </div>
  );
}

export declare type PlanUpgradeDirection = 'upgrade' | 'downgrade';

interface PaymentRequest {
  mode: 'pay' | 'change' | PlanUpgradeDirection;
  plan?: PlanItem;
}

export default function Billing(): React.ReactElement {
  const dispatch = useAppDispatch();

  const { t } = useTranslation(['settings', 'translation']);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | undefined>(undefined);
  const paymentCard = useAppSelector((state) => state.settingBilling.cardInfo);
  const currentPlan = useAppSelector((state) => state.settingBilling.current);

  useEffect(() => {
    dispatch(settingBillingThunk.getCurrentPaymentInfo());
    dispatch(settingBillingThunk.getBindCardInfo());
  }, [dispatch]);

  const renderModal = (): React.ReactNode => {
    switch (paymentRequest?.mode) {
      case 'pay':
        return (
          <Modal
            title={<PowerByStripeTitle>{`${t('billing.payment.title')}`}</PowerByStripeTitle>}
            visible
            centered
            maskClosable={false}
            className={classNames('card-modal', 'payment-modal')}
            onCancel={() => setPaymentRequest(undefined)}
            footer={null}
            closeOnEsc={false}
          >
            <AddCard
              payButton={t('common.confirm', { ns: 'translation' })}
              plan={paymentRequest.plan}
              onClose={() => {
                dispatch(settingBillingThunk.getBindCardInfo());
                dispatch(settingBillingThunk.getCurrentPaymentInfo());
                setPaymentRequest(undefined);
              }}
              onBindSuccess={() => {
                reportEvent('cardBinded', {});
              }}
            />
          </Modal>
        );
      case 'change':
        return (
          <Modal
            title={<PowerByStripeTitle>{`${t('billing.payment.title')}`}</PowerByStripeTitle>}
            visible
            centered
            maskClosable={false}
            className={classNames('card-modal', 'payment-modal')}
            onCancel={() => setPaymentRequest(undefined)}
            footer={null}
            closeOnEsc={false}
          >
            <AddCard
              payButton={t('billing.addCard.changeCard')}
              plan={undefined}
              onClose={() => {
                dispatch(settingBillingThunk.getBindCardInfo());
                setPaymentRequest(undefined);
              }}
              onBindSuccess={() => {
                reportEvent('cardBinded', {});
              }}
              last4={paymentCard?.last4}
            />
          </Modal>
        );
      case 'upgrade':
      case 'downgrade':
        if (!paymentRequest.plan) {
          return null;
        }

        return (
          <Modal
            title={
              <span style={{ fontWeight: 600 }}>{t(`common.${paymentRequest?.mode}`, { ns: 'translation' })}</span>
            }
            visible
            centered
            onCancel={() => setPaymentRequest(undefined)}
            className={classNames('payment-modal')}
            footer={null}
          >
            <UpgradeModal
              mode={paymentRequest.mode}
              plan={paymentRequest.plan}
              onBindPaymentCard={() => {
                setPaymentRequest({
                  mode: 'pay',
                  plan: paymentRequest?.plan
                });
              }}
              onClose={() => setPaymentRequest(undefined)}
            />
          </Modal>
        );
      default:
        return null;
    }
  };

  return (
    <Container className="settings-container usage-container">
      <HeaderTitle title={t('billing.headerTitle')} />
      <Header>{t('billing.headerTitle')}</Header>

      <PlanExpiresTip />

      <SettingPanel icon="settings/plan" name={t('billing.plan.title')}>
        <PlanList
          onPayment={(item, direction) => {
            setPaymentRequest({
              mode: direction,
              plan: item
            });
          }}
        />
      </SettingPanel>

      {paymentCard ? (
        <SettingPanel icon="settings/payment" name={t('billing.payment.title')}>
          <PaymentMethod
            card={paymentCard}
            onChange={() => setPaymentRequest({
              mode: 'change'
            })}
          />
        </SettingPanel>
      ) : null}

      <SettingPanel icon="settings/billing" name={t('billing.billing.title')}>
        <RecentInvoiceTable key={`${currentPlan?.next_subscription_plan}`} />
      </SettingPanel>

      {renderModal()}
    </Container>
  );
}
