import React from 'react';
import {
  Button, Col, Form, Row, Select, Typography
} from '@douyinfe/semi-ui';
import _ from 'lodash';
import { BaseFormApi } from '@douyinfe/semi-foundation/lib/es/form/interface';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  blockchainList,
  chainList
} from '@/store/reducers/constantReducer';
import {
  BlockChain,
  ComposerParameterItem,
  ComposerRequestTemplate,
  NetworkItem
} from '@/props';
import {
  ButtonLoading,
  FormIconSelect,
  InputLabel
} from '@/components';
import LocalStorage from '@/utils/LocalStorage';
import FormSearchSelect from '@/components/Select/FormSearchSelect';
import ParameterInput from '@/views/dashboard/composer/ParameterInput';
import { RequestTemplates } from './TemplateLoader';

export default function RequestExecutor({
  requestTemplate,
  onChange,
  onRequest,
  loading
}: {
  requestTemplate: ComposerRequestTemplate,
  onChange: (method: any) => void,
  onRequest: (values: Record<string, any>) => void,
  loading: boolean | undefined
}): React.ReactElement {
  const { t } = useTranslation();
  const formApi = React.useRef<BaseFormApi | undefined>(undefined);
  const cachedKey = 'ComposerOption';

  const initValues = LocalStorage.get(cachedKey, {
    protocol: 'Ethereum' as BlockChain,
    network: 'mainnet'
  });

  const [
    blockchain, setBlockchain
  ] = React.useState<BlockChain | undefined>(initValues?.protocol);

  const networkSelector = createSelector(
    chainList,
    (list): NetworkItem[] => _.find(
      list,
      (item) => item.name === blockchain
    )?.networks || []
  );

  const blockchains = useSelector(blockchainList);
  const networks = useSelector(networkSelector);

  const valueMap = _.flatMapDeep(
    requestTemplate.template.request.parameter,
    (item) => [item, _.flatMapDeep(item.parameter)]
  );

  const initValue = _.mapValues(_.keyBy(valueMap, 'key'), 'defaultValue');

  return (
    <Col className="col request-executor" span={10}>
      <Typography.Title heading={2}>
        {t('common.request')}
      </Typography.Title>

      <div className="content-wrapper">
        <Form
          key={requestTemplate.name}
          initValues={initValue}
          onSubmit={(values) => {
            if (values?.protocol && values?.network) {
              LocalStorage.set(cachedKey, {
                protocol: values?.protocol,
                network: values?.network || undefined
              });
            }

            if (onRequest) {
              onRequest(values);
            }
          }}
          getFormApi={
            (e) => {
              formApi.current = e;
            }
          }
        >
          <Row gutter={19}>
            <Col span={12}>
              <FormIconSelect
                field="protocol"
                label={(
                  <InputLabel
                    label={t('chainNetwork.create.form.protocol.label')}
                  />
                )}
                initValue={initValues?.protocol}
                onChange={(value) => {
                  setBlockchain(value as BlockChain);

                  if (formApi.current) {
                    formApi.current.setValue('network', undefined);
                  }
                }}
                placeholder={
                  {
                    icon: 'protocol/placeholder',
                    value: t('chainNetwork.create.form.protocol.placeholder')
                  }
                }
                list={blockchains.map((item) => ({
                  icon: `blockchain/${item.toLowerCase()}-solid`,
                  value: item
                }))}
              />
            </Col>
            <Col span={12}>
              <FormIconSelect
                field="network"
                initValue={initValues?.network}
                label={(
                  <InputLabel
                    label={t('chainNetwork.create.form.network.label')}
                  />
                )}
                placeholder={
                  {
                    icon: 'network/placeholder',
                    value: t('chainNetwork.create.form.network.placeholder')
                  }
                }
                list={networks.map((item) => ({
                  value: item.chain_network,
                  icon: `network/${
                    item.chain_network === 'mainnet'
                      ? 'mainnet' : 'testnet'
                  }`
                }))}
              />
            </Col>
          </Row>

          <FormSearchSelect
            className="select-icon"
            field="method"
            clickToHide
            label={(
              <InputLabel
                label={t('composer.form.method.label')}
              />
            )}
            onChange={(value) => {
              formApi?.current?.reset();
              onChange(value);
            }}
            initValue={requestTemplate.name}
          >
            {
              _.map(RequestTemplates, (item: ComposerParameterItem) => (
                <Select.Option key={item.name} value={item.name}>
                  {item.name}
                </Select.Option>
              ))
            }
          </FormSearchSelect>

          {
            _.map<ComposerParameterItem>(requestTemplate.template.request.parameter, (item: ComposerParameterItem) => (
              <ParameterInput key={item.key} parameter={item} />
            ))
          }

          <Row className="executor-button" type="flex">
            <Button
              className="btn-reset bolder"
              size="large"
              htmlType="reset"
            >
              {t('composer.reset')}
            </Button>
            <ButtonLoading
              className="btn-enhance uppercase bolder btn-send"
              htmlType="submit"
              loading={loading}
              size="large"
            >
              {t('composer.send')}
            </ButtonLoading>
          </Row>
        </Form>
      </div>
    </Col>
  );
}
