import { Button, Card, Col, Form, Row } from '@douyinfe/semi-ui';
import ComfortProgress, { ComfortProgressRef } from '@/components/ComfortProgress';
import WrappedTooltip from '@/components/Tooltip';
import { Icon, Markdown, Toast } from '@/components';
import CollapsePanel from '@/components/CollapsePanel';
import React, { useCallback, useImperativeHandle, useRef, useState } from 'react';
import request from 'axios';
import { ConfigurationForm, FormItem, FieldItemGroup } from '@/model/Integration';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { sleep } from '@/utils/promise';
import _ from 'lodash';
import { useLoadingCallback } from '@/utils';
import SyncApi from '@/api/SyncApi';
import { GenerateIntegrationDTO } from '@/model/Sync';
import Label from '../Label';
import './index.scss';
import { useCreateIntegration } from '../../utils/JobRequestHooks';

interface IntegrationConfigurationProps {
  configType: 'save' | 'create',
  onCancel: () => void;
  onCreated: (integrationId: string) => void;
  formTemplate: ConfigurationForm;
  initialData?: Record<string, any>;
  isModal?: boolean;
  onConnectableChange?: (connectable: boolean) => void;
}

export interface IntegrationConfigurationRef {
  getFormValues: () => any;
}

const IntegrationConfiguration = React.forwardRef<IntegrationConfigurationRef, IntegrationConfigurationProps>(({
  configType,
  onCancel,
  onCreated,
  formTemplate,
  initialData,
  isModal,
  onConnectableChange
}, ref) => {
  const { t: ti } = useTranslation(['integrations']);
  const { t } = useTranslation();
  const { introduction, formContent } = formTemplate;

  const formRef = useRef<Form>(null);
  const [connectable, setConnectable] = useState<boolean | undefined>(undefined);
  const [controller, setController] = useState<AbortController>();
  const progressRef = useRef<ComfortProgressRef>(null);

  const { trigger, isMutating: loading } = useCreateIntegration(onCreated);

  const handleSubmit = async (values: Record<string, any>) => {
    // valid form field values
    await formRef.current?.formApi.validate();

    /* eslint-disable camelcase */
    const { integrate_type, integration_name, ...extra_config } = values;

    const postData: GenerateIntegrationDTO = {
      integration_name,
      integrate_type,
      extra_config
    };

    trigger(postData);
  };

  const submitForm = () => {
    formRef.current?.formApi.submitForm();
  };

  /**
   * cancel connection test
   */
  const handleCancel = useCallback(() => {
    controller?.abort();
  }, [controller]);

  /**
   * test connection request
   */
  const { loading: testing, callback: handleTestConnect } = useLoadingCallback(async () => {
    await formRef?.current?.formApi?.validate();
    const state = formRef?.current?.formApi?.getFormState();

    if (!state || !state?.values) {
      return Promise.reject();
    }
    progressRef.current?.start();
    /* eslint-disable camelcase */
    const { integrate_type, integration_name, ...extra_config } = state?.values || {};

    const postData: GenerateIntegrationDTO = {
      integration_name,
      integrate_type,
      extra_config
    };

    try {
      const contr = new AbortController();
      setController(contr);
      const response = await SyncApi.testIntegrationConnectivity(postData, contr);
      if (response.status === 200) {
        progressRef.current?.done();
        setConnectable(true);
        if (onConnectableChange) {
          onConnectableChange(true);
        }
        Toast.success('Test connection successful!');
        await sleep(500);
      }
      return response;
    } catch (e) {
      if (request.isAxiosError(e) && e.response) {
        Toast.error({
          content: e?.response?.data?.msg || 'Test connection failed!',
          top: window.innerHeight - 300,
          left: -200
        });
        return e.response;
      }
      return Promise.reject();
    } finally {
      progressRef.current?.stop();
    }
  });

  useImperativeHandle(ref, () => ({
    getFormValues() {
      return formRef.current?.formApi.getValues();
    }
  }), []);

  /* eslint-disable react/jsx-props-no-spreading */
  const renderFormItem = (item: FormItem) => {
    const labelNodeElement = _.isString(item?.label) ? <Label label={item?.label} notice={item?.notice} /> : item?.label;
    const { fieldType } = item;

    const rules = item?.required ? [{ required: true, message: `${item?.label} ${t('common.required', { ns: 'translation' })}` }, ...(item?.rules || [])] : item?.rules;
    switch (item?.fieldType) {
      case 'input':
        return (
          <Form.Input
            {...item}
            rules={rules}
            label={labelNodeElement}
          />
        );

      case 'select':
        return (
          <Form.Select {...item} rules={rules} label={labelNodeElement} className="form-item" clickToHide />
        );
      default:
        throw new Error(`Unexpected form item type of ${fieldType}`);
    }
  };

  const renderFormItemGroup = (group: FieldItemGroup) => {
    const { type, collapsibleLabel, fields } = group;

    let elements;
    if (type === 'plain') {
      elements = (
        <div className="form-item-group">
          {fields.map(renderFormItem)}
        </div>
      );
    }

    if (type === 'collapsible') {
      elements = (
        <div className="form-item-group">
          <CollapsePanel label={collapsibleLabel}>
            {fields.map(renderFormItem)}
          </CollapsePanel>
        </div>
      );
    }

    return (
      <>
        {elements}
        <div className="form-item-group-divider" />
      </>
    );
  };

  const renderConnectionProgress = () => (
    <Row>
      <Row type="flex" align="middle" justify="space-between">
        <Col>{ti<string>('test.testing')}</Col>
        <Col>
          <Button className="btn-cancel-test" icon={<Icon icon="common/close_circle" className="w-[16px] h-[16px]" />} onClick={handleCancel} />
        </Col>
      </Row>
      <div className="mt-2">
        <ComfortProgress ref={progressRef} />
      </div>
    </Row>
  );

  const renderBackButton = () => (
    <Button
      className="cancel-button mr-auto"
      onClick={onCancel}
    >
      {configType === 'create' ? t('common.cancel') : t('common.back')}
    </Button>
  );

  const renderTestButton = () => (
    <Button
      icon={!testing && connectable ? <Icon icon="common/success" /> : null}
      className={classNames({ 'opacity-50': testing })}
      onClick={handleTestConnect}
      loading={testing}
    >
      {testing ? t('test.buttonTesting', { ns: 'integrations' }) : t('common.testConnection')}
    </Button>
  );

  const renderSaveButton = () => (
    <Button
      className={classNames('create-button')}
      onClick={submitForm}
      theme="solid"
      loading={loading}
    >
      {t('common.save')}
    </Button>
  );

  const renderCreateButton = () => (
    <WrappedTooltip
      disabled={connectable}
      content="Connectivity testing must be completed before creation."
    >
      <Button
        className={classNames('create-button')}
        onClick={submitForm}
        theme="solid"
        loading={loading}
        disabled={!connectable}
        style={{
          opacity: connectable ? 1 : 0.5
        }}
      >
        {t('common.create')}
      </Button>
    </WrappedTooltip>
  );

  const renderCreateButtonGroup = () => (
    <>
      {testing && renderConnectionProgress()}
      <div id="integration-test-connect-toast-container" className="button-group-wrapper">
        {renderBackButton()}
        {renderTestButton()}
        {configType === 'save' && renderSaveButton()}
        {configType === 'create' && renderCreateButton()}
      </div>
    </>
  );

  const renderSaveButtonGroup = () => (
    <>
      {testing && renderConnectionProgress()}
      <div id="integration-test-connect-toast-container" className="button-group-wrapper">
        {renderTestButton()}
      </div>
    </>
  );

  return (
    <div className={`integration-configuration-container ${isModal ? 'integration-configuration-modal-container' : ''}`}>
      <Card className="configuration-card">

        <div className="left-wrapper">
          <div className="form-input-wrapper">
            <Form
              onValueChange={() => {
                setConnectable(undefined);
                if (onConnectableChange) {
                  onConnectableChange(false);
                }
              }}
              ref={formRef}
              onSubmit={handleSubmit}
              initValues={{ ...initialData, integrate_type: introduction?.type }}
            >
              {formContent.map(renderFormItemGroup)}
            </Form>
          </div>
          {configType === 'create' && renderCreateButtonGroup()}
          {configType === 'save' && renderSaveButtonGroup()}
        </div>

        <div className="right-wrapper">
          <div className="title-wrapper">
            <Icon className="icon" icon={`sync/integrations/engine/${introduction.logo}`} />
            <div className="title">{introduction.title}</div>
          </div>
          <div className="content-wrapper">
            <Markdown>
              {introduction.content}
            </Markdown>
          </div>
        </div>
      </Card>
    </div>
  );
});

export default IntegrationConfiguration;
