import { SearchPage } from '@/model/Request';
import { RequestTable } from '@/model/Table';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '@/model/API';
import CustomApi from '@/api/CustomApi';

type StateProps = {
  search: SearchPage,
  table: RequestTable<API>
};

const initialState: StateProps = {
  search: {
    page: 1,
    limit: 20
  },
  table: {
    loading: false,
    list: [],
    pagination: {
      total: 0
    }
  }
};

export const fetchApiList = createAsyncThunk(
  'api/getList',
  async (page: SearchPage) => {
    const response = await CustomApi.getList(page);
    const { data, total } = response.data;
    return {
      table: {
        loading: false,
        list: data,
        pagination: { total }
      },
      search: page
    };
  }
);

export const apiReducer = createSlice({
  name: 'APISlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchApiList.pending, (state) => {
        state.table.loading = true;
      })
      .addCase(
        fetchApiList.fulfilled,
        (state, action) => {
          state.table = action.payload.table;
          state.search = action.payload.search;
        }
      )
      .addCase(
        fetchApiList.rejected,
        (state) => {
          state.table.loading = false;
        }
      );
  }
});

export default apiReducer.reducer;
