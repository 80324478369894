import { Icon } from '@/components';
import { Button } from '@douyinfe/semi-ui';
import { useNavigate } from 'react-router';
import './index.scss';

export default function EmptyContent() {
  const nav = useNavigate();

  return (
    <div className="empty-content-wrapper">
      <h1 className="title">No Jobs Found</h1>
      <p className="desc">There is no jobs to sync data to the integrations now, please click New Job to create one</p>
      <Button
        onClick={() => nav('/sync/jobs/create')}
        className="btn"
        theme="solid"
        icon={(<Icon className="w-4 h-4" icon="common/add-auto" />)}
      >
        New Job
      </Button>
    </div>
  );
}
