import React from 'react';
import { Form } from '@douyinfe/semi-ui';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { ComposerParameterItem } from '@/props';
import { InputLabel } from '@/components';

type ParameterItemProps = {
  parameter: ComposerParameterItem
}

export declare type TypeItem = {
  placeholder: string;
  htmlType?: 'text' | 'number';
  verify?: (value: any) => boolean;
  parse?: (value: any) => any;
}

export const TypeConfig: {
  [key: string]: TypeItem
} = {
  address: {
    placeholder: '0xb60e8dd61c5d32be8058bb8eb970870f07233155'
  },
  bit: {
    placeholder: '0xff'
  },
  hex: {
    placeholder: '0xFFFFFFF'
  },
  blockHash: {
    placeholder: '0x15d61515b5334820fbc4ae8d7b6ee059625bdd4c8b95214b114a3591955347f2'
  },
  blockHeight: {
    placeholder: '0xff, latest'
  },
  block: {
    placeholder: '0xff, "256"'
  },
  'json[]': {
    placeholder: '["0x000", "0x001"]',
    parse: (value: string): any => (value ? JSON.parse(value) : value)
  },
  json: {
    placeholder: '["0x000", "0x001"]',
    parse: (value: string): any => (value ? JSON.parse(value) : value)
  },
  number: {
    htmlType: 'number',
    placeholder: '',
    parse: (value: string): number => parseInt(value, 10)
  },
  boolean: {
    placeholder: 'true or false',
    parse: (value: string | boolean): boolean => (typeof value === 'boolean' ? value : value.toLowerCase() === 'true')
  },
  timestamp: {
    htmlType: 'number',
    placeholder: '1653822477'
  }
};

export default function ParameterInput({
  parameter
}: ParameterItemProps): React.ReactElement {
  const { t } = useTranslation();

  if (parameter.type === 'object') {
    return (
      <>
        {_.map(
          parameter.parameter,
          (item) => (<ParameterInput key={item.key} parameter={item} />)
        )}
      </>
    );
  }

  const config = TypeConfig[parameter?.type];
  return (
    <Form.Input
      // required={parameter.required}
      rules={parameter.required ? [
        {
          required: parameter.required,
          message: `${parameter.name} ${t('common.required')}`
        }
      ] : []}
      type={config?.htmlType}
      placeholder={config?.placeholder || parameter.type}
      field={parameter.key}
      label={<InputLabel required={parameter.required} label={parameter.name} />}
    />
  );
}
