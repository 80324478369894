/* eslint-disable max-len */
import { languages } from 'monaco-editor';

const language: languages.IMonarchLanguage = {
  base: 'sql',
  defaultToken: '',
  tokenPostfix: '.sql',
  ignoreCase: true,
  number: /\d+(\.\d+)?/,
  brackets: [
    { open: '[', close: ']', token: 'delimiter.square' },
    { open: '(', close: ')', token: 'delimiter.parenthesis' }
  ],
  fields: [],
  dbtables: [],
  tables: [],
  tabixCommands: [],
  keywordsGlobal: [
    'SELECT',
    'CASE',
    'THEN',
    'INSERT',
    'UPDATE',
    'DELETE',
    'WHERE',
    'OFFSET',
    'HAVING',
    'AS',
    'FROM',
    'WHEN',
    'ELSE',
    'USING',
    'END',
    'TYPE',
    'LEFT',
    'RIGHT',
    'JOIN',
    'ON',
    'OUTER',
    'DESC',
    'ASC',
    'UNION',
    'CREATE',
    'TABLE',
    'PRIMARY',
    'KEY FOREIGN',
    'NOT',
    'REFERENCES',
    'INNER',
    'CROSS',
    'NATURAL',
    'DATABASE',
    'DROP',
    'GRANT',
    'ARRAY JOIN',
    'ANY',
    'BETWEEN',
    'ENGINE',
    'ATTACH',
    'DETACH',
    'CAST',
    'WITH',
    'BIT_AND',
    'BIT_OR',
    'TO',
    'BIT_XOR',
    'DESCRIBE',
    'OPTIMIZE',
    'PREWHERE',
    'TOTALS',
    'DATABASES',
    'PROCESSLIST',
    // 'SHOW',
    'IF'
  ],
  keywords: [
    // FORM
    'FORMAT JSON',
    'FORMAT JSONCompact',
    'FORMAT JSONEachRow',
    'FORMAT TSV',
    'FORMAT TabSeparated',
    'FORMAT TabSeparatedWithNames',
    'FORMAT TabSeparatedWithNamesAndTypes',
    'FORMAT TabSeparatedRaw',
    'FORMAT BlockTabSeparated',
    'FORMAT TSKV',
    'FORMAT CSV',
    'FORMAT CSVWithNames',
    // SYS
    'SYSTEM RELOAD CONFIG',
    'DROP TEMPORARY TABLE',
    'EXISTS TEMPORARY TABLE',
    'SYSTEM RELOAD DICTIONARY',
    'SYSTEM RELOAD DICTIONARIES',
    'SYSTEM DROP DNS CACHE',
    'SYSTEM SHUTDOWN',
    'SYSTEM KILL',
    'CLEAR COLUMN IN PARTITION',
    'SYSTEM FLUSH LOGS'
  ],
  typeKeywords: [
    'date',
    'integer',
    'uint8',
    'uint16',
    'uint32',
    'uint64',
    'int8',
    'int16',
    'int32',
    'int64',
    'float32',
    'float64',
    'datetime',
    'enum8',
    'enum16',
    'fixedstring',
    'array',
    'tuple',
    'string',
    'Distributed',
    'Tinylog',
    'MergeTree',
    'SummingMergeTree',
    'ReplacingMergeTree',
    'ReplicatedMergeTree',
    'Buffer',
    'ReplicatedCollapsingMergeTree',
    'CollapsingMergeTree',
    'AggregatingMergeTree',
    'Merge',
    'Memory',
    'GraphiteMergeTree',
    'ReplicatedAggregatingMergeTree',
    'ReplicatedSummingMergeTree'
  ],
  operators: [
    // Logical
    'ALL',
    'AND',
    'ANY',
    'BETWEEN',
    'EXISTS',
    'IN',
    'LIKE',
    'NOT',
    'OR',
    'SOME',
    // Set
    'EXCEPT',
    'INTERSECT',
    'UNION',
    // Join
    'APPLY',
    'CROSS',
    'FULL',
    'INNER',
    'JOIN',
    'LEFT',
    'OUTER',
    'RIGHT',
    // Predicates
    'CONTAINS',
    'FREETEXT',
    'IS'
  ],
  builtinFunctions: [
    // Aggregate
    'count',
    'min',
    'max',
    'sum',
    'avg',
    'any',
    'stddevPop',
    'stddevSamp',
    'varPop',
    'varSamp',
    'covarPop',
    'covarSamp',

    'plus',
    'minus',
    'multiply',
    'divide',
    'intDiv',
    'intDivOrZero',
    'modulo',
    'moduloOrZero',
    'negate',
    'abs',
    'gcd',
    'lcm',
    'max2',
    'min2',
    'equals',
    'notEquals',
    'less',
    'greater',
    'lessOrEquals',
    'greaterOrEquals',
    'and',
    'or',
    'not',
    'xor',
    'toInt8',
    'toInt16',
    'toInt32',
    'toInt64',
    'toInt128',
    'toInt256',
    'toInt8OrZero',
    'toInt16OrZero',
    'toInt32OrZero',
    'toInt64OrZero',
    'toInt128OrZero',
    'toInt256OrZero',
    'toInt8OrNull',
    'toInt16OrNull',
    'toInt32OrNull',
    'toInt64OrNull',
    'toInt128OrNull',
    'toInt256OrNull',
    'toInt8OrDefault',
    'toInt16OrDefault',
    'toInt32OrDefault',
    'toInt64OrDefault',
    'toInt128OrDefault',
    'toInt256OrDefault',
    'toUInt8',
    'toUInt16',
    'toUInt32',
    'toUInt64',
    'toUInt128',
    'toUInt256',
    'toUInt8OrZero',
    'toUInt16OrZero',
    'toUInt32OrZero',
    'toUInt64OrZero',
    'toUInt128OrZero',
    'toUInt256OrZero',
    'toUInt8OrNull',
    'toUInt16OrNull',
    'toUInt32OrNull',
    'toUInt64OrNull',
    'toUInt128OrNull',
    'toUInt256OrNull',
    'toUInt8OrDefault',
    'toUInt16OrDefault',
    'toUInt32OrDefault',
    'toUInt64OrDefault',
    'toUInt128OrDefault',
    'toUInt256OrDefault',
    'toFloat32',
    'toFloat32OrZero',
    'toFloat32OrNull',
    'toFloat32OrDefault',
    'toFloat64',
    'toFloat64OrZero',
    'toFloat64OrNull',
    'toFloat64OrDefault',
    'toDate',
    'toDateOrZero',
    'toDateOrNull',
    'toDateOrDefault',
    'toDateTime',
    'toDateTimeOrZero',
    'toDateTimeOrNull',
    'toDateTimeOrDefault',
    'toDate32',
    'toDate32OrZero',
    'toDate32OrNull',
    'toDate32OrDefault',
    'toDecimal32',
    'toDecimal64',
    'toDecimal128',
    'toDecimal256',
    'toDecimal32OrNull',
    'toDecimal64OrNull',
    'toDecimal128OrNull',
    'toDecimal256OrNull',
    'toDecimal32OrDefault',
    'toDecimal64OrDefault',
    'toDecimal128OrDefault',
    'toDecimal256OrDefault',
    'toDecimal32OrZero',
    'toDecimal64OrZero',
    'toDecimal128OrZero',
    'toDecimal256OrZero',
    'toString',
    'toFixedString',
    'toStringCutToZero',
    'reinterpretAsInt8',
    'reinterpretAsInt16',
    'reinterpretAsInt32',
    'reinterpretAsInt64',
    'reinterpretAsUInt8',
    'reinterpretAsUInt16',
    'reinterpretAsUInt32',
    'reinterpretAsUInt64',
    'reinterpretAsFloat32',
    'reinterpretAsFloat64',
    'reinterpretAsDate',
    'reinterpretAsDateTime',
    'reinterpretAsString',
    'reinterpretAsFixedString',
    'reinterpretAsUUID',
    'reinterpret',
    'CAST',
    'accurateCast',
    'accurateCastOrNull',
    'accurateCastOrDefault',
    'toIntervalSecond',
    'toIntervalMinute',
    'toIntervalHour',
    'toIntervalDay',
    'toIntervalWeek',
    'toIntervalMonth',
    'toIntervalQuarter',
    'toIntervalYear',
    'parseDateTimeBestEffort',
    'parseDateTime32BestEffort',
    'parseDateTimeBestEffortUS',
    'parseDateTimeBestEffortOrNull',
    'parseDateTime32BestEffortOrNull',
    'parseDateTimeBestEffortOrZero',
    'parseDateTime32BestEffortOrZero',
    'parseDateTimeBestEffortUSOrNull',
    'parseDateTimeBestEffortUSOrZero',
    'parseDateTime64BestEffort',
    'parseDateTime64BestEffortOrNull',
    'parseDateTime64BestEffortOrZero',
    'toLowCardinality',
    'toUnixTimestamp64Milli',
    'toUnixTimestamp64Micro',
    'toUnixTimestamp64Nano',
    'fromUnixTimestamp64Milli',
    'fromUnixTimestamp64Micro',
    'fromUnixTimestamp64Nano',
    'formatRow',
    'formatRowNoNewline',
    'snowflakeToDateTime',
    'snowflakeToDateTime64',
    'dateTimeToSnowflake',
    'dateTime64ToSnowflake',
    'in',
    'notIn',
    'globalIn',
    'globalNotIn',
    'tuple',
    'tupleElement',
    'untuple',
    'tupleHammingDistance',
    'tupleToNameValuePairs',
    'tuplePlus',
    'tupleMinus',
    'tupleMultiply',
    'tupleDivide',
    'tupleNegate',
    'tupleMultiplyByNumber',
    'tupleDivideByNumber',
    'dotProduct',
    'L1Norm',
    'L2Norm',
    'LinfNorm',
    'LpNorm',
    'L1Distance',
    'L2Distance',
    'LinfDistance',
    'LpDistance',
    'L1Normalize',
    'L2Normalize',
    'LinfNormalize',
    'LpNormalize',
    'cosineDistance',
    'encrypt',
    'aes_encrypt_mysql',
    'decrypt',
    'aes_decrypt_mysql',
    'halfMD5',
    'MD4',
    'MD5',
    'sipHash64',
    'sipHash128',
    'cityHash64',
    'intHash32',
    'intHash64',
    'SHA1',
    'BLAKE3',
    'URLHash',
    'farmFingerprint64',
    'farmHash64',
    'javaHash',
    'javaHashUTF16LE',
    'hiveHash',
    'metroHash64',
    'jumpConsistentHash',
    'murmurHash2_32',
    'gccMurmurHash',
    'murmurHash3_32',
    'murmurHash3_128',
    'xxHash32',
    'ngramSimHash',
    'ngramSimHashCaseInsensitive',
    'ngramSimHashUTF8',
    'ngramSimHashCaseInsensitiveUTF8',
    'wordShingleSimHash',
    'wordShingleSimHashCaseInsensitive',
    'wordShingleSimHashUTF8',
    'wordShingleSimHashCaseInsensitiveUTF8',
    'ngramMinHash',
    'ngramMinHashCaseInsensitive',
    'ngramMinHashUTF8',
    'ngramMinHashCaseInsensitiveUTF8',
    'ngramMinHashArg',
    'ngramMinHashArgCaseInsensitive',
    'ngramMinHashArgUTF8',
    'ngramMinHashArgCaseInsensitiveUTF8',
    'wordShingleMinHash',
    'wordShingleMinHashCaseInsensitive',
    'wordShingleMinHashUTF8',
    'wordShingleMinHashCaseInsensitiveUTF8',
    'wordShingleMinHashArg',
    'wordShingleMinHashArgCaseInsensitive',
    'wordShingleMinHashArgUTF8',
    'wordShingleMinHashArgCaseInsensitiveUTF8',
    'visitParamHas',
    'visitParamExtractUInt',
    'visitParamExtractInt',
    'visitParamExtractFloat',
    'visitParamExtractBool',
    'visitParamExtractRaw',
    'visitParamExtractString',
    'isValidJSON',
    'JSONHas',
    'JSONLength',
    'JSONType',
    'JSONExtractUInt',
    'JSONExtractInt',
    'JSONExtractFloat',
    'JSONExtractBool',
    'JSONExtractString',
    'JSONExtract',
    'JSONExtractKeysAndValues',
    'JSONExtractKeys',
    'JSONExtractRaw',
    'JSONExtractArrayRaw',
    'JSONExtractKeysAndValuesRaw',
    'JSON_EXISTS',
    'JSON_QUERY',
    'JSON_VALUE',
    'toJSONString',
    'isNull',
    'isNotNull',
    'coalesce',
    'ifNull',
    'nullIf',
    'assumeNotNull',
    'toNullable',
    'generateUUIDv4',
    'empty',
    'notEmpty',
    'toUUID',
    'toUUIDOrNull',
    'toUUIDOrZero',
    'UUIDStringToNum',
    'UUIDNumToString',
    'serverUUID',
    'See',
    'length',
    'emptyArrayUInt8',
    'emptyArrayInt8',
    'emptyArrayFloat32',
    'emptyArrayDate',
    'emptyArrayString',
    'emptyArrayToSingle',
    'range',
    'array',
    'arrayConcat',
    'arrayElement',
    'has',
    'hasAll',
    'emptyArrayUInt16',
    'emptyArrayUInt32',
    'emptyArrayUInt64',
    'emptyArrayInt16',
    'emptyArrayInt32',
    'emptyArrayInt64',
    'emptyArrayFloat64',
    'emptyArrayDateTime',
    'hasAny',
    'hasSubstr',
    'indexOf',
    'arrayCount',
    'countEqual',
    'arrayEnumerate',
    'arrayEnumerateUniq',
    'arrayPopBack',
    'arrayPopFront',
    'arrayPushBack',
    'arrayPushFront',
    'arrayResize',
    'arraySlice',
    'arraySort',
    'arrayReverseSort',
    'arrayUniq',
    'arrayJoin',
    'arrayDifference',
    'arrayDistinct',
    'arrayEnumerateDense',
    'arrayIntersect',
    'arrayReduce',
    'arrayReduceInRanges',
    'arrayReverse',
    'reverse',
    'arrayFlatten',
    'arrayCompact',
    'arrayZip',
    'arrayAUC',
    'arrayMap',
    'arrayFilter',
    'arrayFill',
    'arrayReverseFill',
    'arraySplit',
    'arrayReverseSplit',
    'arrayExists',
    'arrayAll',
    'arrayFirst',
    'arrayLast',
    'arrayFirstIndex',
    'arrayLastIndex',
    'arrayMin',
    'arrayMax',
    'arraySum',
    'arrayAvg',
    'arrayCumSum',
    'arrayCumSumNonNegative',
    'arrayProduct',
    'floor',
    'ceil',
    'trunc',
    'round',
    'Examples',
    'roundBankers',
    'roundToExp2',
    'roundDuration',
    'roundAge',
    'roundDown',
    'ceiling',
    'truncate',
    'dictGet',
    'dictGetOrDefault',
    'dictGetOrNull',
    'dictHas',
    'dictGetHierarchy',
    'dictIsIn',
    'dictGetChildren',
    'dictGetDescendant',
    'dictGetInt8',
    'dictGetInt16',
    'dictGetInt32',
    'dictGetInt64',
    'dictGetUInt8',
    'dictGetUInt16',
    'dictGetUInt32',
    'dictGetUInt64',
    'dictGetFloat32',
    'dictGetFloat64',
    'dictGetDate',
    'dictGetDateTime',
    'dictGetUUID',
    'dictGetString',
    'lengthUTF8',
    'char_length',
    'character_length',
    'leftPad',
    'leftPadUTF8',
    'rightPad',
    'rightPadUTF8',
    'lower',
    'upper',
    'lowerUTF8',
    'upperUTF8',
    'isValidUTF8',
    'toValidUTF8',
    'repeat',
    'reverseUTF8',
    'format',
    'concat',
    'concatAssumeInjective',
    'substring',
    'substringUTF8',
    'appendTrailingCharIfAbsent',
    'convertCharset',
    'base64Encode',
    'base64Decode',
    'tryBase64Decode',
    'endsWith',
    'startsWith',
    'trim',
    'trimLeft',
    'trimRight',
    'trimBoth',
    'CRC32',
    'CRC32IEEE',
    'CRC64',
    'normalizeQuery',
    'normalizedQueryHash',
    'normalizeUTF8NFC',
    'normalizeUTF8NFD',
    'normalizeUTF8NFKC',
    'normalizeUTF8NFKD',
    'encodeXMLComponent',
    'decodeXMLComponent',
    'extractTextFromHTML',
    'splitByChar',
    'splitByString',
    'splitByRegexp',
    'splitByWhitespace',
    'splitByNonAlpha',
    'arrayStringConcat',
    'alphaTokens',
    'extractAllGroups',
    'ngrams',
    'tokens',
    'position',
    'locate',
    'positionCaseInsensitive',
    'positionUTF8',
    'positionCaseInsensitiveUTF8',
    'multiSearchAllPositions',
    'multiSearchAllPositionsUTF8',
    'multiSearchFirstPosition',
    'multiSearchFirstIndex',
    'multiSearchAny',
    'match',
    'multiMatchAny',
    'multiMatchAnyIndex',
    'multiMatchAllIndices',
    'multiFuzzyMatchAny',
    'multiFuzzyMatchAnyIndex',
    'multiFuzzyMatchAllIndices',
    'extract',
    'extractAll',
    'extractAllGroupsHorizontal',
    'extractAllGroupsVertical',
    'like',
    'notLike',
    'ilike',
    'ngramDistance',
    'ngramSearch',
    'countSubstrings',
    'countSubstringsCaseInsensitive',
    'countSubstringsCaseInsensitiveUTF8',
    'countMatches',
    'replaceOne',
    'replaceAll',
    'replaceRegexpOne',
    'replaceRegexpAll',
    'regexpQuoteMeta',
    'e',
    'pi',
    'exp',
    'log',
    'ln',
    'exp2',
    'log2',
    'exp10',
    'log10',
    'sqrt',
    'cbrt',
    'erf',
    'erfc',
    'lgamma',
    'tgamma',
    'sin',
    'cos',
    'tan',
    'asin',
    'acos',
    'atan',
    'pow',
    'intExp2',
    'intExp10',
    'cosh',
    'acosh',
    'sinh',
    'asinh',
    'atanh',
    'atan2',
    'hypot',
    'log1p',
    'sign',
    'degrees',
    'radians',
    'timeZone',
    'toTimeZone',
    'timeZoneOf',
    'timeZoneOffset',
    'toYear',
    'toQuarter',
    'toMonth',
    'toDayOfYear',
    'toDayOfMonth',
    'toDayOfWeek',
    'toHour',
    'toMinute',
    'toSecond',
    'toUnixTimestamp',
    'toStartOfYear',
    'toStartOfISOYear',
    'toStartOfQuarter',
    'toStartOfMonth',
    'toMonday',
    'toStartOfWeek',
    'toStartOfDay',
    'toStartOfHour',
    'toStartOfMinute',
    'toStartOfSecond',
    'toStartOfFiveMinutes',
    'toStartOfTenMinutes',
    'toStartOfFifteenMinutes',
    'toStartOfInterval',
    'toTime',
    'toRelativeYearNum',
    'toRelativeQuarterNum',
    'toRelativeMonthNum',
    'toRelativeWeekNum',
    'toRelativeDayNum',
    'toRelativeHourNum',
    'toRelativeMinuteNum',
    'toRelativeSecondNum',
    'toISOYear',
    'toISOWeek',
    'toWeek',
    'toYearWeek',
    'date',
    'timestamp',
    'now',
    'today',
    'yesterday',
    'timeSlot',
    'toYYYYMM',
    'toYYYYMMDD',
    'toYYYYMMDDhhmmss',
    'addYears',
    'subtractYears',
    'timeSlots',
    'formatDateTime',
    'dateName',
    'FROM',
    'toModifiedJulianDay',
    'toModifiedJulianDayOrNull',
    'fromModifiedJulianDay',
    'fromModifiedJulianDayOrNull',
    'if',
    'multiIf',
    'char',
    'hex',
    'unhex',
    'bin',
    'unbin',
    'bitmaskToList',
    'bitmaskToArray',
    'bitPositionsToArray',
    'rand',
    'rand32',
    'rand64',
    'randConstant'
  ],
  //
  // formatResult: [
  //     'FORMAT JSON',
  //     'FORMAT JSONCompact',
  //     'FORMAT JSONEachRow',
  //     'FORMAT TSV',
  //     'FORMAT TabSeparated',
  //     'FORMAT TabSeparatedWithNames',
  //     'FORMAT TabSeparatedWithNamesAndTypes',
  //     'FORMAT TabSeparatedRaw',
  //     'FORMAT BlockTabSeparated',
  //     'FORMAT TSKV',
  //     'FORMAT CSV',
  //     'FORMAT CSVWithNames',
  // ],
  builtinVariables: ['true', 'false', 'NULL'],
  pseudoColumns: ['$ROWGUID', '$PARTITION'],
  drawCommands: ['DRAW_CHART', 'DRAW_BAR'],
  tokenizer: {
    root: [
      { include: '@comments' },
      { include: '@whitespace' },
      { include: '@keywordsDouble' },
      { include: '@keywordsDML' },
      { include: '@pseudoColumns' },
      { include: '@numbers' },
      { include: '@strings' },
      { include: '@complexIdentifiers' },
      { include: '@scopes' },
      [/;;|;/, 'warn-token'],
      [/[;,.]/, 'delimiter'],
      [/[()]/, '@brackets'],
      [
        /\b(\w+\.[`"][\w.]+["`])|\b(\w+\.\w+)\b/,
        {
          cases: {
            '@dbtables': 'keyword.dbtable',
            '@default': 'identifier'
          }
        }
      ],
      [
        /[\w@#$]+/,
        {
          cases: {
            '@keywords': 'keyword',
            '@keywordsGlobal': 'keyword',
            '@typeKeywords': 'keyword.type',
            '@fields': 'metatag',
            '@tables': 'keyword.table',
            '@operators': 'operator',
            '@builtinVariables': 'predefined',
            '@builtinFunctions': 'predefined',
            '@drawCommands': 'tabix',
            '@default': 'identifier'
          }
        }
      ],
      // ['/[!<>]?=|<>|<|>/', 'keyword.operator.comparison.sql'],
      [/[<>=!%&+\-*/|~^]/, 'operator']
    ],
    whitespace: [[/\s+/, 'white']],
    comments: [
      [/--+.*/, 'comment'],
      [/\/\*/, { token: 'comment.quote', next: '@comment' }]
    ],
    comment: [
      [/[^*/]+/, 'comment'],
      // Not supporting nested comments, as nested comments seem to not be standard?
      // i.e. http://stackoverflow.com/questions/728172/are-there-multiline-comment-delimiters-in-sql-that-are-vendor-agnostic
      // [/\/\*/, { token: 'comment.quote', next: '@push' }],    // nested comment not allowed :-(
      [/\*\//, { token: 'comment.quote', next: '@pop' }],
      [/./, 'comment']
    ],
    keywordsDML: [
      [
        '\\b(select(\\s+distinct)?|insert\\s+(ignore\\s+)?into|'
        + 'update|delete|from|set|where|group\\sby|'
        + 'or|like|and|union(\\s+all)?|having|order\\sby|limit|LIMIT\\W+\\d+\\W+BY\\W+|'
        + '(GLOBAL)?\\s+(ANY|ALL)?\\s+(INNER|LEFT)\\s+join|'
        + '(ANY|ALL)?\\s+(INNER|LEFT)\\s+join|'
        + '(INNER|LEFT)\\s+join|'
        + '(left|right)(\\s+outer)?\\s+join|'
        + 'ARRAY\\W+JOIN |'
        + 'PREWHERE|'
        + 'JOIN|'
        + 'SAMPLE|'
        + 'INTO\\W+OUTFILE|'
        + 'WITH\\W+TOTALS|'
        + 'WITH\\W+CUBE|'
        + 'natural(\\s+(left|right)(\\s+outer)?)?\\s+join)'
        + '\\b',
        {
          cases: {
            '@default': 'keyword.other.DML'
          }
        }
      ],
      [
        '\\bcreate(\\sMATERIALIZED)?\\s+(database|view)(\\s+if\\s+not\\s+exists)?',
        { cases: { '@default': 'keyword.other.create.sql' } }
      ],
      // "match": "(?i:^\\s*(create(?:\\s+or\\s+replace)?)\\s+(aggregate|conversion|database|domain|function|group|(unique\\s+)?index|language|operator class|operator|rule|schema|sequence|table|tablespace|trigger|type|user|view)\\s+)(['\"`]?)(\\w+)\\4",
      // "name": "meta.create.sql"
      ['\\bAS\\b', { cases: { '@default': 'keyword.other.alias.sql' } }],
      ['\\b(DESC|ASC)\\b', { cases: { '@default': 'keyword.other.order.sql' } }]
      // "keyword.other.object-comments.sql"=>"(?i:^\\s*(comment\\s+on\\s+(table|column|aggregate|constraint|database|domain|function|index|operator|rule|schema|sequence|trigger|type|view))\\s+.*?\\s+(is)\\s+)"
    ],
    // "captures": {
    //   "1": {
    //     "name": "constant.other.database-name.sql"
    //   },
    //   "2": {
    //     "name": "constant.other.table-name.sql"
    //   }
    // },
    // "match": "(\\w+?)\\.(\\w+)"
    // "match": "((?<!@)@)\\b(\\w+)\\b",
    // "name": "text.variable"
    keywordsDouble: [
      [
        'INSERT\\W+INTO|RENAME\\W+TABLE|IF\\W+NOT\\W+EXISTS|IF\\W+EXISTS|'
        + 'SYSTEM\\W+RELOAD\\W+CONFIG|'
        + 'DROP\\W+TEMPORARY\\W+TABLE|'
        + 'EXISTS\\W+TEMPORARY\\W+TABLE|'
        + 'SYSTEM\\W+RELOAD\\W+DICTIONARY|'
        + 'CHECK\\W+TABLE|'
        + 'SYSTEM\\W+RELOAD\\W+DICTIONARIES|'
        + 'SYSTEM\\W+DROP\\W+DNS\\W+CACHE|'
        + 'SYSTEM\\W+SHUTDOWN|'
        + 'SYSTEM\\W+KILL|'
        + 'CLEAR\\W+COLUMN\\W+IN\\W+PARTITION|SYSTEM\\W+FLUSH\\W+LOGS',
        {
          cases: {
            '@default': 'keyword'
          }
        }
      ],
      [
        'FORMAT\\W+XML|FORMAT\\W+Vertical|FORMAT\\W+JSONCompact|FORMAT\\W+Pretty(CompactNoEscapes|Space|SpaceNoEscape|NoEscapes|CompactMonoBlock|Compact)?|FORMAT\\W+TSV|FORMAT\\W+JSONEachRow|FORMAT\\W+TSKV|FORMAT\\W+TabSeparatedWithNames|FORMAT\\W+TabSeparatedWithNamesAndTypes|FORMAT\\W+TabSeparatedRaw|FORMAT\\W+BlockTabSeparated|FORMAT\\W+CSVWithNames|FORMAT\\W+CSV|FORMAT\\W+JSON|FORMAT\\W+TabSeparated',
        {
          cases: {
            '@default': 'storage'
          }
        }
      ]
    ],
    pseudoColumns: [
      [
        /[$][A-Za-z_][\w@#$]*/,
        {
          cases: {
            '@pseudoColumns': 'predefined',
            '@default': 'identifier'
          }
        }
      ]
    ],
    numbers: [
      [/0[xX][0-9a-fA-F]*/, 'number'],
      [/[$][+-]*\d*(\.\d*)?/, 'number'],
      [/((\d+(\.\d*)?)|(\.\d+))([eE][-+]?\d+)?/, 'number']
    ],
    strings: [
      [/N'/, { token: 'string', next: '@string' }],
      [/'/, { token: 'string', next: '@string' }]
    ],
    string: [
      [/[^']+/, 'string'],
      [/''/, 'string'],
      [/'/, { token: 'string', next: '@pop' }]
    ],
    complexIdentifiers: [
      [/\[/, { token: 'identifier.quote', next: '@bracketedIdentifier' }],
      [/"/, { token: 'identifier.quote', next: '@quotedIdentifier' }]
    ],
    bracketedIdentifier: [
      [/[^\]]+/, 'identifier'],
      [/]]/, 'identifier'],
      [/]/, { token: 'identifier.quote', next: '@pop' }]
    ],
    quotedIdentifier: [
      [/[^"]+/, 'identifier'],
      [/""/, 'identifier'],
      [/"/, { token: 'identifier.quote', next: '@pop' }]
    ],
    scopes: [
      [/BEGIN\s+(DISTRIBUTED\s+)?TRAN(SACTION)?\b/i, 'keyword'],
      [/BEGIN\s+TRY\b/i, { token: 'keyword.try' }],
      [/END\s+TRY\b/i, { token: 'keyword.try' }],
      [/BEGIN\s+CATCH\b/i, { token: 'keyword.catch' }],
      [/END\s+CATCH\b/i, { token: 'keyword.catch' }],
      [/(BEGIN|CASE)\b/i, { token: 'keyword.block' }],
      [/END\b/i, { token: 'keyword.block' }],
      [/WHEN\b/i, { token: 'keyword.choice' }],
      [/THEN\b/i, { token: 'keyword.choice' }]
    ]
  }
};

// This config defines the editor's behavior.
const configuration: languages.LanguageConfiguration = {
  comments: {
    lineComment: '--',
    blockComment: ['/*', '*/']
  },
  brackets: [
    ['{', '}'],
    ['[', ']'],
    ['(', ')']
  ],
  folding: {
    markers: {
      // start: new RegExp("^\\s*(#|\/\/)region\\b"),
      // start: /^\s*{\b/,
      start: /\(/,
      end: /\)/
      // end: /^\s*}\b/
    }
  },
  autoClosingPairs: [
    { open: '{', close: '}', notIn: ['string', 'comment'] },
    { open: '[', close: ']' },
    { open: '(', close: ')' },
    { open: '"', close: '"', notIn: ['string', 'comment'] },
    { open: "'", close: "'", notIn: ['string', 'comment'] },
    { open: '`', close: '`' }
  ],
  surroundingPairs: [
    { open: '{', close: '}' },
    { open: '[', close: ']' },
    { open: '(', close: ')' },
    { open: '"', close: '"' },
    { open: "'", close: "'" },
    { open: '`', close: '`' }
  ]
  // wordPattern: /(-?\d*\.\d\w*)|([^\`\~\!\#\%\^\&\*\(\)\-\=\+\[\{\]\}\\\|\;\:\'\"\,\.\<\>\/\?\s]+)/g,
};

export const ClickhouseSQLMonaco = {
  language,
  configuration
};
