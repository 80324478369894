import React from 'react';
import { Icon } from '@/components';
import { useTranslation } from 'react-i18next';
import TipModal from '@/components/Modal/TipModal';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { dataCloudActions, dataCloudTrySwitchToAlpha } from '@/store/reducers/dataCloudReducer';
import { useNavigate } from 'react-router-dom';

type UnSaveModalProps = {
  onConfirm: (tabKey: string) => void;
  onUnSave: (tabKey: string) => void;
}

const UnSaveModal = React.forwardRef((
  {
    onConfirm,
    onUnSave
  }: UnSaveModalProps,
  ref: React.ForwardedRef<any>
): React.ReactElement => {
  const { t } = useTranslation();

  const [tabKey, setTabKey] = React.useState<string>('');

  const [saveModalVisible, setSaveModalVisible] = React.useState<boolean>(false);

  // ==== tmp code for fix version switch ====
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const trySwitchToAlpha = useAppSelector(dataCloudTrySwitchToAlpha);
  const onCloseModal = () => {
    if (trySwitchToAlpha) {
      setTimeout(() => {
        nav('/dataCloud/v2');
        dispatch(dataCloudActions.consumeToAlphaSwitch());
      }, 200);
    }
  };
  // =========

  React.useImperativeHandle(ref, () => ({
    open(target: string) {
      setTabKey(target);
      setSaveModalVisible(true);
    }
  }));

  return (
    <TipModal
      centered
      closeOnEsc
      className="unsave-modal"
      visible={saveModalVisible}
      onClose={() => setSaveModalVisible(false)}
      footer={null}
      icon={<Icon className="icon" icon="dataCloud/unsave-tip" />}
      title={t('dataCloud.saveTip.title')}
      description={t('dataCloud.saveTip.desc')}
      cancelText={t('common.unSave')}
      okText={t('common.save')}
      onCancel={() => {
        onUnSave(tabKey);
        setSaveModalVisible(false);
        onCloseModal();
      }}
      onOk={() => {
        onConfirm(tabKey);
        setSaveModalVisible(false);
      }}
    />
  );
});

export default UnSaveModal;

export declare type UnSaveModalType = React.ElementRef<typeof UnSaveModal> | {
  open: (data: any) => void
}
