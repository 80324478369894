import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { TabPane, Tabs } from '@douyinfe/semi-ui';

import { Block, EventManager } from '@/components';
import { useSelector } from 'react-redux';
import {
  dataCloudActiveKey,
  dataCloudTableTree,
  fetchDataCloudTable,
  queryData,
  tableSchemaLoading
} from '@/store/reducers/dataCloudReducer';
import './index.scss';
import { useAppDispatch } from '@/store/hooks';
import { BlockChain } from '@/props';
import { EditorInsert } from '@/components/CodeEditor/CodeEditor';
import TableDescriptionPopover
  from '@/components/TableTree/TableDescriptionPopover';
import TableTree, { TableTreeProps } from '@/components/TableTree';
import {
  ColumnSchema,
  GroupSchema,
  TableSchema,
  TreeItemSchema
} from '@/model/Table';
import TableTreeSearch from './TableTreeSearch';

function isGroup(object: TableSchema | GroupSchema): object is GroupSchema {
  return 'children' in object;
}

export declare type TableTreeSearch = {
  blockchain?: BlockChain | 'All';
  name?: string;
  type?: string
}

export const filterTable = (list: TreeItemSchema[], search: TableTreeSearch): TreeItemSchema[] => _.filter(
  list,
  (item) => {
    const target = item;

    const keyword = search?.name?.toLowerCase() || '';

    if (isGroup(target)) {
      if (!search?.name || !_.includes(target.name.toLowerCase(), keyword)) {
        const result = filterTable(target.children, search);
        target.children = result as TableSchema[];
      }

      if (target?.children?.length) {
        return true;
      }
    }

    if (!isGroup(target)) {
      const schemas = _.map(target?.schema, (i) => {
        if (_.includes(i, '_')) {
          return i.substring(0, i.indexOf('_'));
        }
        return i;
      });

      return (
        (!search?.type || search?.type === 'All' || search?.type === target.type)
          && (
            (!target?.schema)
            || _.includes(schemas || [], search?.blockchain?.toLowerCase())
            || (!search?.blockchain || search.blockchain === 'All')
          ) && (
          !search.name || _.includes(target.name.toLowerCase(), keyword)
        )
      );
    }

    return false;
  }
);

function TableTreeExpandable({
  loading, list, onTableClick, renderPopoverContent, category
}: TableTreeProps & {category: string}) {
  const [groups, setGroups] = useState<string[]>([]);

  const findByPath = (tree: TreeItemSchema[], paths: string[]) => {
    let temp = tree;

    for (let i = 0; i < paths.length; i += 1) {
      const path = paths[i];
      const subTree = _.find(temp, (item) => item.name === path);
      if (subTree && isGroup(subTree) && subTree.children) {
        temp = subTree.children;
      }
    }

    return temp;
  };
  const filteredList = _.size(groups) > 0 ? findByPath(list, groups) : list;

  return (
    <TableTree
      loading={loading}
      list={filteredList}
      onTableClick={onTableClick}
      renderPopoverContent={renderPopoverContent}
      category={category}
      onExpandGroup={(paths: string[]) => {
        setGroups(paths);
      }}
    />
  );
}

export default function TableNavigation(): React.ReactElement {
  const { t } = useTranslation();

  const currentEditorKey = useSelector(dataCloudActiveKey);
  const [searchData, setSearchData] = React.useState<TableTreeSearch>({
    blockchain: 'All',
    name: '',
    type: 'All'
  });

  const sourceTableList = useSelector(dataCloudTableTree);

  const chainTableList = filterTable(_.cloneDeep(sourceTableList), searchData);

  const loading = useSelector(tableSchemaLoading);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(fetchDataCloudTable());
  }, [dispatch]);

  const handleSearch = (values: any): void => {
    const {
      blockchain,
      name
    } = values;
    setSearchData({
      blockchain,
      name
    });
  };

  function isColumn(object: TableSchema | ColumnSchema): object is ColumnSchema {
    return 'index' in object;
  }

  const handleClick = (value: TableSchema | ColumnSchema): void => {
    if (isColumn(value)) {
      EventManager.emit(`${EditorInsert}:${currentEditorKey}`, {
        method: 'insert',
        text: value.name
      });
    } else {
      EventManager.emit(`${EditorInsert}:${currentEditorKey}`, {
        method: 'insert',
        text: value.table_name
      });
    }
  };

  const handleQuery = (target: TableSchema): void => {
    // if (currentTab?.resultLoading) {
    //   return;
    // }
    const sql = `SELECT * FROM ${target.table_name} LIMIT 20`;
    dispatch(queryData({
      sql,
      tabKey: currentEditorKey
    }));
  };

  const renderPopoverContent = (item: TableSchema): React.ReactElement => (
    <TableDescriptionPopover
      data={item}
      onPreview={() => {
        handleQuery(item);
      }}
    />
  );

  return (
    <Block className="table-navigation">
      <TableTreeSearch onSearch={handleSearch} />

      <Tabs className="table-type-tabs" type="line" defaultActiveKey="overview">
        <TabPane tab={t('dataCloud.overview')} itemKey="overview">
          <TableTreeExpandable
            loading={loading}
            list={_.cloneDeep(chainTableList)}
            onTableClick={handleClick}
            renderPopoverContent={renderPopoverContent}
            category="overview"
          />
        </TabPane>
        <TabPane tab={t('dataCloud.raw')} itemKey="raw">
          <TableTreeExpandable
            loading={loading}
            list={filterTable(_.cloneDeep(chainTableList), {
              type: 'raw'
            })}
            onTableClick={handleClick}
            renderPopoverContent={renderPopoverContent}
            category="raw"
          />
        </TabPane>
        <TabPane tab={t('dataCloud.abstracted')} itemKey="abstracted">
          <TableTreeExpandable
            loading={loading}
            list={filterTable(_.cloneDeep(chainTableList), {
              type: 'abstract'
            })}
            onTableClick={handleClick}
            renderPopoverContent={renderPopoverContent}
            category="abstracted"
          />
        </TabPane>
        <TabPane tab={t('dataCloud.dApp')} itemKey="dApp">
          <TableTreeExpandable
            loading={loading}
            list={filterTable(_.cloneDeep(chainTableList), {
              type: 'dapp'
            })}
            onTableClick={handleClick}
            renderPopoverContent={renderPopoverContent}
            category="dApp"
          />
        </TabPane>
      </Tabs>

    </Block>
  );
}
