import React, { ChangeEventHandler } from 'react';
import { t } from 'i18next';
import _ from 'lodash';

export declare interface PaginationProps {
  total: number;
  pageSize: number;
  pageTotal: number;
  pageSizeOpts: Array<number>;
  currentPage: number;
  // onPageChange?: (currentPage: number) => void;
  // onPageSizeChange?: (newPageSize: number) => void;
  onChange?: (currentPage: number, pageSize: number) => void;
  onSearch?: (keyword: string) => void;
}

const defaultPaginationProps: PaginationProps = {
  currentPage: 1,
  pageSize: 10,
  pageTotal: 1,
  total: 0,
  pageSizeOpts: [10, 20, 50]
};

export default function Pagination({
  pageSize,
  currentPage,
  onChange,
  pageTotal,
  total,
  pageSizeOpts,
  onSearch
} = defaultPaginationProps): React.ReactElement {
  const handleChange = (page: number) => {
    if (onChange) {
      onChange(page, pageSize);
    }
  };
  const handlePageSizeChange = (_pageSize: number) => {
    if (onChange) {
      const page = Math.max(_.floor((currentPage * pageSize) / _pageSize), 1);
      console.log(total, page, _pageSize, (total / _pageSize) < page);
      if (page < (total / _pageSize)) {
        onChange(page, _pageSize);
      } else {
        onChange(1, _pageSize);
      }
    }
  };

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (onSearch) {
      onSearch(event?.target?.value);
    }
  };

  return (
    <div className="box-border h-12 px-4 py-3 flex justify-between items-center border-solid border-[1px] border-x-0 border-b-0 border-[#E3E7EC]">
      <div className="text-[#A1AAB0]">
        <span>
          {t('common.pagination.page')}
        </span>
        <span className="w-12 h-6 ml-2">
          <select
            className="w-12 h-6 border-[#E3E7EC] rounded-md px-2 customize-drop-down-arrow"
            value={currentPage}
            onChange={(e) => {
              handleChange(parseInt(e.target.value, 10));
            }}
          >
            {_.times(pageTotal, ((val) => (
              <option
                key={val + 1}
                value={val + 1}
              >
                {val + 1}
              </option>
            )))}
          </select>
        </span>
        <span className="ml-2">
          {`${t('common.pagination.of')} ${pageTotal}`}
        </span>
        <span>
          <button
            className="m-0 p-0 h-6 w-6 ml-2 border-solid border-[1px] border-[#E3E7EC] rounded-md text-center align-middle"
            type="button"
            disabled={currentPage === 1}
            onClick={() => handleChange(currentPage - 1)}
          >
            <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.64652 0.509766L4.35363 1.21687L1.70708 3.86336L4.35363 6.50977L3.64652 7.21687L0.292969 3.86332L3.64652 0.509766Z" fill={currentPage > 1 ? '#1E1E1E' : '#E3E7EC'} />
            </svg>
          </button>
        </span>
        <span>
          <button
            className="m-0 p-0 h-6 w-6 ml-2 border-solid border-[1px] border-[#E3E7EC] rounded-md text-center align-middle"
            type="button"
            disabled={currentPage === pageTotal}
            onClick={() => handleChange(currentPage + 1)}
          >
            <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="&#232;&#183;&#175;&#229;&#190;&#132;&#229;&#164;&#135;&#228;&#187;&#189; 3" d="M0.706994 0.509766L-0.000113487 1.21687L2.64644 3.86336L-0.000113487 6.50977L0.706994 7.21687L4.06055 3.86332L0.706994 0.509766Z" fill={currentPage < pageTotal ? '#1E1E1E' : '#E3E7EC'} />
            </svg>
          </button>
        </span>
        {/* SHILED: @Dennis searcher */}
        <span className="ml-2 inline-block relative">
          <input
            className="box-border py-1 text-xs pl-[12px] pr-[32px] placeholder:text-[#D1D7DD] w-[200px] h-6 border-solid border-[1px] border-[#E3E7EC] rounded-md shadow-none outline-none"
            placeholder={t<string>('common.pagination.search')}
            onChange={handleSearch}
          />
          <div className="absolute top-0 bottom-0 m-auto right-[12px] flex flex-row align-center align-middle">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M11.4411 10.4985C13.0125 8.4648 12.8655 5.53168 11.0001 3.66624C8.97501 1.6412 5.69177 1.6412 3.66673 3.66624C1.64169 5.69128 1.64169 8.97453 3.66673 10.9996C5.53193 12.8648 8.46453 13.012 10.4982 11.4413L13.3289 14.2719L14.2717 13.3291L11.4411 10.4985ZM10.0572 4.60905C11.5616 6.11339 11.5616 8.55242 10.0572 10.0568C8.5529 11.5611 6.11388 11.5611 4.60954 10.0568C3.1052 8.55242 3.1052 6.11339 4.60954 4.60905C6.11388 3.10471 8.5529 3.10471 10.0572 4.60905Z" fill="#2E363A" />
            </svg>
          </div>
        </span>
      </div>
      {/* Page Size Selector */}
      <div>
        <span>
          <select
            className="text-center pr-[20px] pl-[8px] h-6 border-[1px] rounded-md border-[#E3E7EC] appearance-none align-middle customize-drop-down-arrow"
            value={pageSize}
            onChange={(event) => {
              handlePageSizeChange(parseInt(event.target.value, 10));
            }}
          >
            {pageSizeOpts.map((size) => (
              <option
                key={size}
                value={size}
              >
                {size}
              </option>
            ))}
          </select>
        </span>
        <span className="text-xs text-[#A1AAB0] ml-2">
          {t('common.pagination.size')}
        </span>
      </div>
    </div>
  );
}
