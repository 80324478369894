import { useEffect } from 'react';
import { Monaco, useMonaco } from '@monaco-editor/react';
import { IRange, languages, Position } from 'monaco-editor';
import * as monaco from 'monaco-editor';
import { TableSchema } from '@/model/Table';
import _ from 'lodash';
import { format } from 'sql-formatter';
import { queryAllTable } from '@/views/dashboard/archivedQueries/QueryBlockChains';

const generateKeywords = (
  list: any[],
  kind: languages.CompletionItemKind,
  _range: IRange,
  detail?: string
): languages.CompletionItem[] => _.map<string, languages.CompletionItem>(
  list,
  (item) => (
    {
      label: {
        label: item,
        // detail: ` (${tableItem.table_name})`,
        description: detail
      } as languages.CompletionItemLabel,
      kind,
      insertText: item,
      range: _range
    }
  )
);

const createDependencyProposals = (
  range: IRange,
  languageConfig: languages.IMonarchLanguage
): languages.CompletionItem[] => {
  const operators = generateKeywords(languageConfig.operators, languages.CompletionItemKind.Operator, range);

  const typeKeywords = generateKeywords(languageConfig.typeKeywords, languages.CompletionItemKind.Keyword, range);

  const keywordsGlobal = generateKeywords(languageConfig.keywordsGlobal, languages.CompletionItemKind.Keyword, range);

  // const keywords = generateKeywords(languageConfig.keywords, languages.CompletionItemKind.Keyword);
  const builtinFunctions = generateKeywords(languageConfig.builtinFunctions, languages.CompletionItemKind.Function, range, 'Function');
  const builtinVariables = generateKeywords(languageConfig.builtinVariables, languages.CompletionItemKind.Variable, range);

  return [
    // ...keywords,
    ...keywordsGlobal,
    ...builtinFunctions,
    ...builtinVariables,
    ...operators,
    ...typeKeywords
  ];
};

const createTableDependencyProposals = (
  range: IRange,
  tables: TableSchema[],
  model: monaco.editor.ITextModel
) => {
  const tableNames = _.map<TableSchema, string>(tables || [], (item) => item?.table_name || '');
  const tableNameKeywords = generateKeywords(tableNames, languages.CompletionItemKind.Interface, range, 'Table');

  const sql = model.getValue();

  const sqlTables = queryAllTable(sql, tables);

  const columns: languages.CompletionItem[] = _.flattenDeep(_.map(sqlTables, (tableItem) => (
    _.map(tableItem.columns, (item) => ({
      label: {
        label: item.name,
        detail: ` (${tableItem.table_name})`,
        description: item.type
      } as languages.CompletionItemLabel,
      kind: languages.CompletionItemKind.Field,
      insertText: item.name,
      range
    }))
  )));

  return [
    ...tableNameKeywords,
    ...columns
  ];
};

type LanguageConfig = {
  language: languages.IMonarchLanguage,
  configuration: languages.LanguageConfiguration
}

export default function useMonacoLanguage(
  languageId: string,
  languageConfig: LanguageConfig,
  tableList: TableSchema[]
): Monaco | null {
  const monacoContent = useMonaco();

  // bind language
  useEffect(() => {
    const target = _.find(monacoContent?.languages.getLanguages(), (item) => item.id === languageId);
    if (target) {
      return;
    }

    monacoContent?.languages.register({
      id: languageId,
      extensions: [languageConfig?.language?.tokenPostfix || '.sql']
    });

    // config editor format sql
    monacoContent?.languages.registerDocumentFormattingEditProvider(languageId, {
      provideDocumentFormattingEdits(
        model: monaco.editor.ITextModel,
        options: languages.FormattingOptions
      ): languages.ProviderResult<languages.TextEdit[]> {
        const formatted = format(model.getValue(), {
          language: languageConfig.language.base,
          tabWidth: options.tabSize
        });

        return [
          {
            range: model.getFullModelRange(),
            text: formatted
          }
        ];
      }
    });

    monaco.languages.registerDocumentRangeFormattingEditProvider(languageId, {
      provideDocumentRangeFormattingEdits(model, range, options) {
        const formatted = format(model.getValueInRange(range), {
          language: languageConfig.language.base,
          tabWidth: options.tabSize
        });
        return [
          {
            range,
            text: formatted
          }
        ];
      }
    });

    monacoContent?.languages.setMonarchTokensProvider(languageId, languageConfig.language);
    monacoContent?.languages.setLanguageConfiguration(languageId, languageConfig.configuration);
    monacoContent?.languages.registerCompletionItemProvider(languageId, {
      provideCompletionItems: (
        model: monaco.editor.ITextModel,
        position: Position
      ): monaco.languages.ProviderResult<monaco.languages.CompletionList> => {
        const word = model.getWordUntilPosition(position);
        const range: IRange = {
          startLineNumber: position.lineNumber,
          endLineNumber: position.lineNumber,
          startColumn: word.startColumn,
          endColumn: word.endColumn
        };
        return {
          suggestions: createDependencyProposals(range, languageConfig.language)
        };
      }
    });
  }, [languageId, languageConfig, monacoContent]);

  // bind table completion item
  useEffect(() => {
    const disposable = monacoContent?.languages.registerCompletionItemProvider(languageId, {
      provideCompletionItems: (
        model: monaco.editor.ITextModel,
        position: Position
      ): monaco.languages.ProviderResult<monaco.languages.CompletionList> => {
        const word = model.getWordUntilPosition(position);
        const range: IRange = {
          startLineNumber: position.lineNumber,
          endLineNumber: position.lineNumber,
          startColumn: word.startColumn,
          endColumn: word.endColumn
        };
        return {
          suggestions: createTableDependencyProposals(range, tableList, model)
        };
      }
    });

    return () => {
      disposable?.dispose();
    };
  }, [languageId, tableList, monacoContent]);

  return monacoContent;
}
