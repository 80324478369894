import { Button, Form, Modal as SemiModal } from '@douyinfe/semi-ui';
import React from 'react';
import { ModalReactProps } from '@douyinfe/semi-ui/lib/es/modal/Modal';
import { ButtonLoading } from '@/components';
import { FormApi } from '@douyinfe/semi-ui/lib/es/form';
import { useTranslation } from 'react-i18next';
import { LoadingTypes } from '@/components/Loading';
import DeleteModal from '@/components/Modal/DeleteModal';
import { AxiosResponse } from 'axios';
import { Response } from '@/model';
import Icon from '../Icon';

type ModalProps = ModalReactProps & Omit<{
  loadingType?: LoadingTypes,
  children?: React.ReactNode;
}, ''>

export default function Modal(props: ModalProps): React.ReactElement {
  const {
    children,
    ...rest
  } = props;
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <SemiModal
      closeIcon={<Icon className="icon icon-close" icon="modal/close" />}
      size="medium"
      centered
      {...rest}
    >
      {children}
    </SemiModal>
  );
}

Modal.success = SemiModal.success;
Modal.info = (props: ModalProps): ReturnType<typeof SemiModal.info> => {
  const modal = SemiModal.info({
    icon: null,
    size: 'medium',
    closeIcon: <Icon className="icon icon-close" icon="modal/close" />,
    onCancel: () => modal.destroy(),
    ...props
  });
  return modal;
};

Modal.danger = (props: ModalProps): ReturnType<typeof SemiModal.info> => {
  const modal = SemiModal.error({
    icon: null,
    size: 'medium',
    closeIcon: <Icon className="icon icon-close" icon="modal/close" />,
    onCancel: () => modal.destroy(),
    ...props
  });
  return modal;
};

type DeleteModalProps = {
  title: string,
  tip: string,
  onDelete: () => Promise<AxiosResponse<Response>>,
  onComplete: () => void
}

Modal.delete = ({
  title,
  tip,
  onDelete,
  onComplete
}: DeleteModalProps): ReturnType<typeof SemiModal.info> => {
  const modal = Modal.danger({
    title,
    size: 'large',
    okButtonProps: {
      theme: 'light'
    },
    centered: true,
    className: 'delete-popover',
    footer: null,
    content: (
      <DeleteModal
        title={tip}
        onClose={(isDelete?: boolean) => {
          if (isDelete) {
            onComplete();
          }
          modal.destroy();
        }}
        onDelete={onDelete}
      />
    )
  });
  return modal;
};

export function ModalFooter(props: ModalProps): React.ReactElement {
  const { t } = useTranslation('translation');
  const {
    okText,
    okType,
    onOk,
    okButtonProps,
    confirmLoading,
    cancelLoading,
    cancelButtonProps,
    cancelText = t('common.cancel', { ns: 'translation' }),
    hasCancel = true,
    loadingType = 'primary',
    onCancel
  } = props;

  return (
    <div className="semi-modal-footer">
      <div>
        {
          hasCancel ? (
            <Button
              loading={cancelLoading}
              onClick={onCancel}
              {...cancelButtonProps}
              aria-label="cancel"
            >
              {cancelText}
            </Button>
          ) : null
        }

        <ButtonLoading
          loadingType={loadingType}
          type={okType}
          loading={confirmLoading}
          htmlType="submit"
          onClick={onOk}
          {...okButtonProps}
          aria-label="confirm"
        >
          {okText}
        </ButtonLoading>
      </div>
    </div>
  );
}

export function ModalForm(props: ModalProps & Omit<{
  onSubmit: (values: Record<string, any>) => void
}, ''>): React.ReactElement {
  const formApiRef = React.useRef<FormApi | null>(null);
  const {
    onSubmit,
    children
  } = props;
  return (
    <Form
      getFormApi={(formApi: FormApi): void => {
        formApiRef.current = formApi;
      }}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {children}

      <ModalFooter {...props} />
    </Form>
  );
}
