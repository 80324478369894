import SyncApi from '@/api/SyncApi';
import useSWR from 'swr';

export default function useIntegrationDetail(integrationId: string) {
  const {
    data: integration,
    error: listIntegrationsError,
    isLoading: listIntegrationsIsLoading
  } = useSWR(integrationId, () => SyncApi.getIntegration(integrationId));

  const {
    data: listJobsResponse,
    error: listJobsError,
    isLoading: listJobsIsLoading
  } = useSWR('jobList', () => SyncApi.listJobs());

  const jobList = listJobsResponse?.data.data;
  const relatedJobDatas = jobList ? jobList.filter((job) => job.integration_id === integrationId) : [];

  return {
    integration: integration?.data?.data,
    relatedJobDatas,
    error: listIntegrationsError || listJobsError,
    isLoading: listIntegrationsIsLoading || listJobsIsLoading,
    notFound: !integrationId
  };
}
