import React, { LegacyRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card, HeaderTitle } from '@/components';
import { useAuth } from '@/config/auth/AuthProvider';

type StatusCardProps = {
  title: string;
  path: 'login' | 'register' | 'onboarding';
};

export default function StatusCard({ title, path }: StatusCardProps): React.ReactElement {
  const { t } = useTranslation();
  const auth = useAuth();

  const [seconds, setSeconds] = React.useState(5);
  const ref: LegacyRef<HTMLAnchorElement> = React.createRef();

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
  }, [ref, seconds]);

  useEffect(() => {
    if (seconds === 0 && !auth.authLoading) {
      if (ref.current) {
        ref.current.click();
      }
      fetch('/api/v2/log/registerSuccess');
    }
  }, [auth.authLoading, ref, seconds]);

  return (
    <Card className="activation-status">
      <HeaderTitle title={t('register.headerTitle')} />
      <div className="content">
        <h5 className="title bolder">{title}</h5>
        <p className="description medium">
          {t('modifySuccessful.tip')}
          <Link
            ref={ref}
            to={path === 'onboarding' ? '/onboarding/user-customize' : `/${path}`}
            className="btn-to-login"
          >
            {t(`${path}.headerTitle`)}
          </Link>
          <span>{` (${seconds}s)`}</span>
        </p>
      </div>
    </Card>
  );
}
