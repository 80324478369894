import React from 'react';
import { Row } from '@douyinfe/semi-ui';
import classNames from 'classnames';
import LearnMore from '../Form/LearnMore';

export declare type InputLabelProps = {
  label: string;
  className?: string;
  required?: boolean;
  learnMore?: {
    text?: string;
    to: string;
  };
}

const defaultInputLabelProps: InputLabelProps = {
  label: '',
  required: false,
  learnMore: undefined
};

export default function InputLabel({
  label,
  className,
  required,
  learnMore
} = defaultInputLabelProps): React.ReactElement {
  return (
    <Row
      className={classNames('input-label', className)}
      type="flex"
      justify="space-between"
    >
      <div>
        <span
          className="medium"
        >
          {label}
        </span>

        {
          required ? <span className="icon-required">{' * '}</span> : null
        }
      </div>

      {
        learnMore ? <LearnMore to={learnMore.to} text={learnMore.text} /> : null
      }

    </Row>
  );
}
