import { useState, useMemo } from 'react';

export default function useFilter() {
  const [nameFilter, setNameFilter] = useState('');
  const [chainFilter, setChainFilter] = useState('All');

  const byName = useMemo(() => ((content: string) => {
    const lowerContent = content.toLowerCase();
    const lowerNameFilter = nameFilter.toLowerCase();
    return lowerContent.includes(lowerNameFilter);
  }), [nameFilter]);

  const byChain = useMemo(() => ((content: string | string[]) => {
    if (chainFilter === 'All') {
      return true;
    }
    const lowerChainFilter = chainFilter.toLowerCase();
    if (Array.isArray(content)) {
      return content.includes(lowerChainFilter);
    }
    return content.toLowerCase() === lowerChainFilter;
  }), [chainFilter]);

  const update = (type: 'byName' | 'byChain', value: string) => {
    if (type === 'byChain') {
      setChainFilter(value);
    } else if (type === 'byName') {
      setNameFilter(value);
    } else {
      throw new Error(`Unexpected type of ${type}`);
    }
  };

  const filter = useMemo(() => ({
    byName,
    byChain,
    update
  }), [byChain, byName]);

  return filter;
}
