/* eslint-disable react/jsx-props-no-spreading */
import { Tooltip } from '@douyinfe/semi-ui';
import { TooltipProps } from '@douyinfe/semi-ui/tooltip';
import React from 'react';
import Icon from '../Icon';

interface NoticeTooltipProps extends TooltipProps {
  content: string;
}

export default function NoticeTooltip(props: NoticeTooltipProps): React.ReactElement {
  return (
    <Tooltip {...props}>
      <div className="w-4 h-4 inline-block">
        <Icon icon="common/notice" />
      </div>
    </Tooltip>
  );
}
