import React, { useState } from 'react';
import _ from 'lodash';
import { ResultTableType, TableRow } from '@/model/Table';
import ResultTable from '@/components/Table/ResultTable';
import Pagination
  from '@/views/dataCloudStudio/components/SQLStudio/components/QueryResult/components/Pagination';

type ResultTableProps = {
  resultTable: ResultTableType
  hidePagination?: boolean
}

type TableProps<RecordType> = {
  dataSource: RecordType[],
  defaultPageSize?: number,
  defaultCurrentPage?: number
  onFilter?: (data: RecordType[], _keyword: string) => RecordType[]
}

interface PaginationState {
  pageTotal: number;
  total: number;
  currentPage: number;
  pageSize: number;
}

type TableState<RecordType> = {
  pagination: PaginationState
  currentPageData: RecordType[],
  setCurrentPage: (page: number, size: number) => void,
  onSearch: (keyword: string) => void
}

function useTable<RecordType>({
  dataSource,
  defaultPageSize = 10,
  defaultCurrentPage = 1,
  onFilter = (result) => result
}: TableProps<RecordType>): TableState<RecordType> {
  const [data, setData] = useState<RecordType[]>(dataSource);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageTotal: Math.ceil((dataSource?.length || 0) / defaultPageSize),
    total: data?.length || 0,
    currentPage: defaultCurrentPage || 1,
    pageSize: defaultPageSize
  });

  const [currentPageData, setCurrentPageData] = React.useState<RecordType[]>(
    _.slice<RecordType>(
      data,
      (defaultCurrentPage - 1) * defaultPageSize,
      defaultCurrentPage * defaultPageSize
    )
  );

  const updateCurrent = React.useCallback((paginationState: PaginationState): void => {
    setPagination(paginationState);
    const {
      currentPage,
      pageSize: size
    } = paginationState;

    const pageData = _.slice<RecordType>(data, (currentPage - 1) * size, currentPage * size);
    setCurrentPageData(pageData || []);
  }, [data, setPagination, setCurrentPageData]);

  const setCurrentPage = React.useCallback((page: number, size: number): void => {
    const newPagination = {
      pageTotal: Math.ceil((data?.length || 0) / size),
      total: data?.length || 0,
      pageSize: size,
      currentPage: page
    };
    updateCurrent(newPagination);
  }, [data, updateCurrent]);

  const updatePageData = React.useCallback((newData: RecordType[], paginationState: PaginationState) => {
    const { pageSize: size } = paginationState;

    const pageData = _.slice<RecordType>(newData, 0, size);
    const newPagination = {
      pageTotal: Math.ceil((newData?.length || 0) / pagination.pageSize),
      total: newData?.length || 0,
      pageSize: size,
      currentPage: 1
    };
    setData(newData);
    setPagination(newPagination);
    setCurrentPageData(pageData);
  }, [pagination.pageSize, setData, setPagination, setCurrentPageData]);

  const onSearch = React.useCallback((_keyword: string) => {
    const result = onFilter(dataSource, _keyword);
    updatePageData(result, {
      ...pagination,
      currentPage: 1
    });
  }, [dataSource, onFilter, pagination, updatePageData]);

  return {
    pagination,
    currentPageData,
    setCurrentPage,
    onSearch
  };
}

export default function QueryResultTable({ resultTable, hidePagination }: ResultTableProps): React.ReactElement {
  const pageSizeOptions: number[] = [50, 100, 200];

  const {
    meta,
    kv_result: result = []
  } = resultTable;

  const {
    pagination,
    currentPageData,
    setCurrentPage,
    onSearch
  } = useTable<TableRow>({
    dataSource: result,
    defaultPageSize: pageSizeOptions[0],
    defaultCurrentPage: 1,
    // onFilter: (_result, _keyword) => _.filter(_result, (line: TableRow) => _.some(line, (word: any) => _.toString(word).includes(_keyword)))
    onFilter: (_result, _keyword) => _.filter(_result, (line: TableRow) => _.some(_.values(line), (word: any) => _.toString(word).includes(_keyword)))
  });

  if (!meta) {
    return <div />;
  }
  const handleSearch = (_keyword: string) => {
    onSearch(_keyword);
  };

  const renderPagination = () => (
    <Pagination
      pageSize={pagination.pageSize}
      total={pagination.total}
      currentPage={pagination.currentPage}
      pageTotal={pagination.pageTotal}
      pageSizeOpts={pageSizeOptions}
      onChange={(_currentPage, _pageSize) => {
        setCurrentPage(_currentPage, _pageSize);
      }}
      onSearch={(_keyword) => {
        handleSearch(_keyword);
      }}
    />
  );

  return (
    <>
      <div
        className="result-wrapper"
      >
        <ResultTable meta={meta} data={currentPageData} isKVData />
      </div>
      {/* Pagination */}
      {!hidePagination ? renderPagination() : null}
    </>
  );
}
