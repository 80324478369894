import React from 'react';
import './index.scss';
import ReactJson, { ReactJsonViewProps } from 'react-json-view';
import classNames from 'classnames';
import { Loading } from '@/components';

type JSONViewProps = {
  loading?: boolean;
  className?: string;
} & ReactJsonViewProps

export default function JSONView(props: Omit<JSONViewProps, ''>): React.ReactElement {
  /* eslint-disable react/jsx-props-no-spreading */
  const {
    className,
    loading,
    ...rest
  } = props;

  return (
    <div className={classNames('json-view', className)}>
      {
        loading ? <Loading type="grey" /> : (
          <ReactJson
            style={
              {
                lineHeight: '15px'
              }
            }
            displayDataTypes={false}
            collapsed={2}
            {...rest}
          />
        )
      }

    </div>

  );
}
