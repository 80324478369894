import { ProgramLanguageTemplate } from '@/components/CodeExampleSelector';

export const docLink = 'https://docs.chainbase.com/reference/data-cloud-studio-api';

export const API_DOMAIN = 'https://api.chainbase.com';
export const CUSTOM_API_URL = `${API_DOMAIN}/api/v1/custom`;

export const LanguageList: ProgramLanguageTemplate[] = [
  {
    name: 'Shell',
    language: 'bash',
    escape: true,
    template:
`#!/bin/bash

apiKey="#(!API_KEY!)#"
queryId="#(!QUERY_ID!)#"
urlBase="${API_DOMAIN}/api/v1"
contentType="application/json"

executeQuery() {
    local queryData="{\\"queryParameters\\":{#(!QUERY_PARAMS!)#}}"
    local response=$(curl -s -X POST "$urlBase/query/$queryId/execute" \\
        -H "X-API-KEY: $apiKey" \\
        -H "Content-Type: $contentType" \\
        -d "$queryData")
    echo $response | jq -r '.data[0].executionId'
}

checkStatus() {
    local executionId=$1
    local response=$(curl -s -X GET "$urlBase/execution/$executionId/status" \\
        -H "X-API-KEY: $apiKey")
    echo $response
}

getResults() {
    local executionId=$1
    local response=$(curl -s -X GET "$urlBase/execution/$executionId/results" \\
        -H "X-API-KEY: $apiKey")
    echo $response
}

main() {
    local executionId=$(executeQuery)
    local status
    local progress
    until [[ "$status" == "FINISHED" || "$status" == "FAILED" ]]; do
        local statusResponse=$(checkStatus $executionId)
        status=$(echo $statusResponse | jq -r '.data[0].status')
        progress=$(echo $statusResponse | jq -r '.data[0].progress')
        sleep 1
        echo "\${status} \${progress}%"
    done

    local results=$(getResults $executionId)
    echo $results | jq
}

main
`

  },
  {
    name: 'Python',
    language: 'python',
    template:
`import requests
import time

api_key = "#(!API_KEY!)#"
query_id = "#(!QUERY_ID!)#"
data = {
    "queryParameters": {
#(!QUERY_PARAMS!)#
    }
}

headers = {
    "X-API-KEY": api_key,
    "Content-Type": "application/json",
}

def execute_query(query_id):
    response = requests.post(
        f"${API_DOMAIN}/api/v1/query/{query_id}/execute",
        json=data,
        headers=headers
    )
    return response.json()['data'][0]['executionId']

def check_status(execution_id):
    response = requests.get(
        f"${API_DOMAIN}/api/v1/execution/{execution_id}/status",
        headers=headers
    )
    return response.json()['data'][0]

def get_results(execution_id):
    response = requests.get(
        f"${API_DOMAIN}/api/v1/execution/{execution_id}/results",
        headers=headers
    )
    return response.json()

def main():
    execution_id = execute_query(query_id)
    status = None
    while status != "FINISHED" and status != "FAILED":
        status_response = check_status(execution_id)
        status = status_response['status']
        progress = status_response.get('progress', 0)
        print(f"{status} {progress}%")
        time.sleep(1)
    
    results = get_results(execution_id)
    print(results)

if __name__ == "__main__":
    main()
`
  },
  {
    name: 'Go',
    language: 'Go',
    escape: false,
    template:
    `package main

    import (
      "bytes"
      "encoding/json"
      "fmt"
      "io/ioutil"
      "net/http"
      "time"
    )
    
    const (
      apiKey  = "#(!API_KEY!)#"
      queryId = "#(!QUERY_ID!)#"
      baseURL = "${API_DOMAIN}/api/v1"
    )
    
    type QueryData struct {
      QueryParameters map[string]string \`json:"queryParameters"\`
    }
    
    type ExecutionResponse struct {
      Data []struct {
        ExecutionId string \`json:"executionId"\`
        Status      string \`json:"status"\`
        Progress    int    \`json:"progress"\`
      } \`json:"data"\`
    }
    
    func executeQuery(queryId string) (string, error) {
      url := fmt.Sprintf("%s/query/%s/execute", baseURL, queryId)
    
      queryData := QueryData{}
      queryData.QueryParameters = map[string]string{
#(!QUERY_PARAMS!)#
      }
    
      data, err := json.Marshal(queryData)
      if err != nil {
        return "", err
      }
    
      req, err := http.NewRequest("POST", url, bytes.NewBuffer(data))
      if err != nil {
        return "", err
      }
    
      req.Header.Set("X-API-KEY", apiKey)
      req.Header.Set("Content-Type", "application/json")
    
      client := &http.Client{}
      resp, err := client.Do(req)
      if err != nil {
        return "", err
      }
      defer resp.Body.Close()
    
      body, err := ioutil.ReadAll(resp.Body)
      if err != nil {
        return "", err
      }
    
      var execResponse ExecutionResponse
      if err := json.Unmarshal(body, &execResponse); err != nil {
        return "", err
      }
    
      return execResponse.Data[0].ExecutionId, nil
    }
    
    func checkStatus(executionId string) (ExecutionResponse, error) {
      url := fmt.Sprintf("%s/execution/%s/status", baseURL, executionId)
    
      req, err := http.NewRequest("GET", url, nil)
      if err != nil {
        return ExecutionResponse{}, err
      }
    
      req.Header.Set("X-API-KEY", apiKey)
    
      client := &http.Client{}
      resp, err := client.Do(req)
      if err != nil {
        return ExecutionResponse{}, err
      }
      defer resp.Body.Close()
    
      body, err := ioutil.ReadAll(resp.Body)
      if err != nil {
        return ExecutionResponse{}, err
      }
    
      var statusResponse ExecutionResponse
      if err := json.Unmarshal(body, &statusResponse); err != nil {
        return ExecutionResponse{}, err
      }
    
      return statusResponse, nil
    }
    
    func getResults(executionId string) (interface{}, error) {
      url := fmt.Sprintf("%s/execution/%s/results", baseURL, executionId)
    
      req, err := http.NewRequest("GET", url, nil)
      if err != nil {
        return nil, err
      }
    
      req.Header.Set("X-API-KEY", apiKey)
    
      client := &http.Client{}
      resp, err := client.Do(req)
      if err != nil {
        return nil, err
      }
      defer resp.Body.Close()
    
      body, err := ioutil.ReadAll(resp.Body)
      if err != nil {
        return nil, err
      }
    
      var results interface{}
      if err := json.Unmarshal(body, &results); err != nil {
        return nil, err
      }
    
      return results, nil
    }
    
    func main() {
      executionId, err := executeQuery(queryId)
      if err != nil {
        fmt.Println("Error executing query:", err)
        return
      }
    
      var status string
      for status != "FINISHED" && status != "FAILED" {
        statusResponse, err := checkStatus(executionId)
        if err != nil {
          fmt.Println("Error checking status:", err)
          return
        }
    
        status = statusResponse.Data[0].Status
        progress := statusResponse.Data[0].Progress
        fmt.Printf("%s %d %%\n", status, progress)
        time.Sleep(1 * time.Second)
      }
    
      results, err := getResults(executionId)
      if err != nil {
        fmt.Println("Error getting results:", err)
        return
      }
    
      fmt.Println(results)
    }
    `
  },
  {
    name: 'Node',
    language: 'javascript',
    template:
`const apiKey = "#(!API_KEY!)#";
const queryId = "#(!QUERY_ID!)#";
const data = {
  queryParameters: {
#(!QUERY_PARAMS!)#
  },
};

const headers = {
  "X-API-KEY": apiKey,
  "Content-Type": "application/json",
};

async function executeQuery(queryId) {
  return await fetch(
    \`${API_DOMAIN}/api/v1/query/\${queryId}/execute\`,
    { method: "POST", headers, body: JSON.stringify(data) }
  )
    .then((response) => response.json())
    .then((data) => data.data[0].executionId);
}

async function checkStatus(executionId) {
  return await fetch(
    \`${API_DOMAIN}/api/v1/execution/\${executionId}/status\`,
    { headers }
  )
    .then((response) => response.json())
    .then((data) => data.data[0]);
}

async function getResults(executionId) {
  return await fetch(
    \`${API_DOMAIN}/api/v1/execution/\${executionId}/results\`,
    { headers }
  ).then((response) => response.json());
}

async function main() {
  const executionId = await executeQuery(queryId);
  let status;
  do {
    const statusResponse = await checkStatus(executionId);
    status = statusResponse.status;
    const progress = statusResponse.progress;
    await new Promise((resolve) => setTimeout(resolve, 1000));
    console.log(\`\${status} \${progress} %\`);
  } while (status !== "FINISHED" && status !== "FAILED");

  const results = await getResults(executionId);
  console.log(results);
}

main();
`
  },
  {
    name: 'Java',
    language: 'Java',
    escape: true,
    newlineProcess: true,
    template:
`import org.json.JSONObject;
import org.json.JSONArray;
import java.net.HttpURLConnection;
import java.net.URL;
import java.io.OutputStream;
import java.io.BufferedReader;
import java.io.InputStreamReader;
import java.nio.charset.StandardCharsets;

public class Main {

    private static final String API_KEY = "#(!API_KEY!)#";
    private static final String QUERY_ID = "#(!QUERY_ID!)#";
    private static final String BASE_URL = "${API_DOMAIN}/api/v1";

    public static void main(String[] args) {
        try {
            String executionId = executeQuery(QUERY_ID);
            System.out.println("Execution ID: " + executionId);

            JSONObject statusResponse;
            String status = "";
            while (!status.equals("FINISHED") && !status.equals("FAILED")) {
                statusResponse = checkStatus(executionId);
                status = statusResponse.getJSONArray("data").getJSONObject(0).getString("status");
                int progress = statusResponse.getJSONArray("data").getJSONObject(0).getInt("progress");
                System.out.printf("%s %d %%\n", status, progress);
                Thread.sleep(1000);
            }

            JSONObject results = getResults(executionId);
            System.out.println(results);
        } catch (Exception e) {
            e.printStackTrace();
        }
    }

    private static String executeQuery(String queryId) throws Exception {
        URL url = new URL(BASE_URL + "/query/" + queryId + "/execute");
        HttpURLConnection con = (HttpURLConnection) url.openConnection();
        con.setRequestMethod("POST");
        con.setRequestProperty("X-API-KEY", API_KEY);
        con.setRequestProperty("Content-Type", "application/json");
        con.setDoOutput(true);

        JSONObject queryParameters = new JSONObject();
#(!QUERY_PARAMS!)#

        JSONObject queryData = new JSONObject();
        queryData.put("queryParameters", queryParameters);

        try (OutputStream os = con.getOutputStream()) {
            byte[] input = queryData.toString().getBytes(StandardCharsets.UTF_8);
            os.write(input, 0, input.length);
        }

        try (BufferedReader br = new BufferedReader(new InputStreamReader(con.getInputStream(), StandardCharsets.UTF_8))) {
            StringBuilder response = new StringBuilder();
            String responseLine;
            while ((responseLine = br.readLine()) != null) {
                response.append(responseLine.trim());
            }
            JSONObject jsonResponse = new JSONObject(response.toString());
            return jsonResponse.getJSONArray("data").getJSONObject(0).getString("executionId");
        }
    }

    private static JSONObject checkStatus(String executionId) throws Exception {
        URL url = new URL(BASE_URL + "/execution/" + executionId + "/status");
        HttpURLConnection con = (HttpURLConnection) url.openConnection();
        con.setRequestMethod("GET");
        con.setRequestProperty("X-API-KEY", API_KEY);

        try (BufferedReader br = new BufferedReader(new InputStreamReader(con.getInputStream(), StandardCharsets.UTF_8))) {
            StringBuilder response = new StringBuilder();
            String responseLine;
            while ((responseLine = br.readLine()) != null) {
                response.append(responseLine.trim());
            }
            return new JSONObject(response.toString());
        }
    }

    private static JSONObject getResults(String executionId) throws Exception {
        URL url = new URL(BASE_URL + "/execution/" + executionId + "/results");
        HttpURLConnection con = (HttpURLConnection) url.openConnection();
        con.setRequestMethod("GET");
        con.setRequestProperty("X-API-KEY", API_KEY);

        try (BufferedReader br = new BufferedReader(new InputStreamReader(con.getInputStream(), StandardCharsets.UTF_8))) {
            StringBuilder response = new StringBuilder();
            String responseLine;
            while ((responseLine = br.readLine()) != null) {
                response.append(responseLine.trim());
            }
            return new JSONObject(response.toString());
        }
    }
}
`
  },
  {
    name: 'Ruby',
    language: 'ruby',
    escape: true,
    template:
`require 'net/http'
require 'json'
require 'uri'

API_KEY = "#(!API_KEY!)#"
QUERY_ID = "#(!QUERY_ID!)#"
DATA = {
  queryParameters: {
#(!QUERY_PARAMS!)#
  }
}.to_json

HEADERS = {
  "X-API-KEY" => API_KEY,
  "Content-Type" => "application/json"
}

def execute_query(query_id)
  uri = URI.parse("${API_DOMAIN}/api/v1/query/#{query_id}/execute")
  response = Net::HTTP.post(uri, DATA, HEADERS)
  JSON.parse(response.body)["data"][0]["executionId"]
end

def request_with_uri(uri)
  Net::HTTP.start(uri.host, uri.port, use_ssl: uri.scheme == 'https') do |http|
    request = Net::HTTP::Get.new(uri, HEADERS)
    response = http.request(request)
    JSON.parse(response.body)
  end
end

def check_status(execution_id)
  uri = URI.parse("${API_DOMAIN}/api/v1/execution/#{execution_id}/status")
  request_with_uri(uri)["data"][0]
end

def get_results(execution_id)
  uri = URI.parse("${API_DOMAIN}/api/v1/execution/#{execution_id}/results")
  request_with_uri(uri)
end

def main
  execution_id = execute_query(QUERY_ID)
  status = ""
  begin
    status_response = check_status(execution_id)
    status = status_response["status"]
    progress = status_response["progress"]
    sleep(1)
    puts "#{status} #{progress} %"
  end while status != "FINISHED" && status != "FAILED"
  results = get_results(execution_id)
  puts results
end

main
`
  },
  {
    name: 'PHP',
    language: 'php',
    escape: true,
    template:
`<?php

$apiKey = "#(!API_KEY!)#";
$queryId = "#(!QUERY_ID!)#";
$data = json_encode([
    "queryParameters" => [
#(!QUERY_PARAMS!)#
    ],
]);

$headers = [
    "X-API-KEY: $apiKey",
    "Content-Type: application/json",
];

function executeQuery($queryId, $data, $headers) {
    $url = "${API_DOMAIN}/api/v1/query/$queryId/execute";
    $ch = curl_init($url);
    curl_setopt($ch, CURLOPT_POST, true);
    curl_setopt($ch, CURLOPT_POSTFIELDS, $data);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    $response = curl_exec($ch);
    curl_close($ch);

    return json_decode($response, true)['data'][0]['executionId'];
}

function checkStatus($executionId, $headers) {
    $url = "${API_DOMAIN}/api/v1/execution/$executionId/status";
    $ch = curl_init($url);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    $response = curl_exec($ch);
    curl_close($ch);

    return json_decode($response, true)['data'][0];
}

function getResults($executionId, $headers) {
    $url = "${API_DOMAIN}/api/v1/execution/$executionId/results";
    $ch = curl_init($url);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    $response = curl_exec($ch);
    curl_close($ch);

    return json_decode($response, true);
}

function main($queryId, $data, $headers) {
    $executionId = executeQuery($queryId, $data, $headers);
    do {
        $statusResponse = checkStatus($executionId, $headers);
        $status = $statusResponse['status'];
        $progress = $statusResponse['progress'];
        sleep(1);
        echo "$status $progress %\n";
    } while ($status !== "FINISHED" && $status !== "FAILED");

    $results = getResults($executionId, $headers);
    print_r($results);
}

main($queryId, $data, $headers);
`
  }
];
