import React, { MouseEvent, ReactNode, useRef, useState } from 'react';
import { Button, Dropdown, Input, Tooltip, Tree } from '@douyinfe/semi-ui';
import { TreeNodeData } from '@douyinfe/semi-ui/tree/interface';
import {
  isFile,
  Resource
} from '@/views/dataCloudStudio/components/SideExplorer/components/FileExplorer';
import { Icon, Modal, Toast } from '@/components';
import './index.scss';
import TableEmpty from '@/components/Table/TableEmpty';
import { useTranslation } from 'react-i18next';
import { CreateQueryData } from '@/utils/hooks/dataCloud/useSqlQuery';
import { TrinoQuery } from '@/model/TrinoQuery';
import { DateUtils } from '@/utils';
import { deleteTrinoQueries, editRenameTrinoQueries } from '@/store/reducers/trinoQueriesReducer';
import { useAppDispatch } from '@/store/hooks';
import { AxiosResponse } from 'axios';
import { Response } from '@/model';
import classNames from 'classnames';

interface FileTreeProps {
  resources: Resource[],
  emptyTitle: string,
  onOpen: (e: MouseEvent, node: TreeNodeData) => void;
  onUpdate: (query: TrinoQuery, mode: 'delete' | 'update') => void;
}

interface FileTreeItemProps {
  treeNode?: TreeNodeData,
  onDelete: () => void,
  onRename: (newName: string) => void
}

function FileTreeItem({ treeNode, onDelete, onRename } : FileTreeItemProps) {
  const { t } = useTranslation();

  const [mode, setMode] = useState<'rename' | 'normal'>('normal');
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

  const renderMoreButton = (): React.ReactElement => (
    <Dropdown
      trigger="click"
      className="query-item-dropdown"
      stopPropagation
      clickToHide
      visible={dropdownVisible}
      onClickOutSide={() => {
        setDropdownVisible(false);
      }}
      render={(
        <Dropdown.Menu>
          <Dropdown.Item icon={<Icon className="w-[16px] h-[16px]" icon="dataCloud/v2/edit" />} onClick={() => setMode('rename')}>{t('dataCloud.explorer.file.query.menu.rename')}</Dropdown.Item>
          <Dropdown.Item
            icon={<Icon className="w-[16px] h-[16px]" icon="dataCloud/v2/delete" />}
            onClick={() => {
              onDelete();
            }}
          >
            {t('dataCloud.explorer.file.query.menu.delete')}
          </Dropdown.Item>
        </Dropdown.Menu>
          )}
    >
      <Button
        className={classNames('w-[24px] h-[24px] btn-more', {
          active: dropdownVisible
        })}
        icon={<Icon className="w-[24px] h-[24px]" icon="dataCloud/v2/more" />}
        onClick={() => {
          setDropdownVisible(!dropdownVisible);
        }}
      />
    </Dropdown>
  );

  const renderContent = () => (
    <>
      <Tooltip content={treeNode?.label}>
        <span className="mx-[8px] text-ellipsis text-[#2272B4] text-[14px]">{treeNode?.label}</span>
      </Tooltip>
      <span className="ml-auto mr-[8px] text-[12px] text-[#a4aab0] shrink-0">{DateUtils.fromNow(treeNode?.data?.updatedAt, true)}</span>
    </>
  );

  return (
    <div className="flex flex-row align-center h-[24px]">

      {
        mode === 'normal' ? renderContent()
          : (
            <Input
              autofocus
              className="mx-[8px] h-[24px] text-[#2272B4] flex flex-col align-center"
              inputStyle={
                {
                  fontSize: '14px',
                  lineHeight: '14px',
                  height: '20px',
                  padding: '0 8px'
                }
              }
              onBlur={() => {
                setMode('normal');
              }}
              defaultValue={treeNode?.data?.name}
              onEnterPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                const { value } = event?.target as HTMLInputElement;
                onRename(value);
                setMode('normal');
              }}
            />
          )
      }

      {renderMoreButton()}
    </div>
  );
}

function FileTree({ resources = [], emptyTitle, onOpen, onUpdate }: FileTreeProps, ref: React.ForwardedRef<any>): React.ReactElement {
  const fileTreeRef = useRef<Tree>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const parseTree = (resource: Resource): TreeNodeData => {
    if (isFile(resource)) {
      return {
        label: resource.name,
        value: resource.id,
        key: `${resource.id}`,
        icon: <Icon className="w-[16px] h-[16px]" icon="dataCloud/v2/file-icon" />,
        data: resource.data
      };
    }
    return {
      label: resource.name,
      value: resource.name,
      key: resource.name,
      children: resource.children.map(parseTree),
      icon: <Icon className="w-[16px] h-[16px]" icon="dataCloud/v2/collection-icon" />
    };
  };

  React.useImperativeHandle(ref, () => ({
    search(text: string) {
      fileTreeRef?.current?.search(text);
    }
  }));

  const treeData = resources.map(parseTree);

  const handleDelete = (query: CreateQueryData) => {
    console.log('query', query);
    Modal.delete({
      title: t('dataCloud.explorer.file.query.delete', {
        objName: ''
      }),
      tip: t('dataCloud.explorer.file.query.tip', {
        objName: query.name
      }),
      onComplete: () => {
        onUpdate(query, 'delete');
      },
      onDelete: async () => {
        const action = await dispatch(deleteTrinoQueries(query.id));
        const response = action?.payload as AxiosResponse<Response>;
        if (response?.data.code === 200) {
          Toast.success(t('toast.deleted', { ns: 'translation', name: query.name, type: t('dataCloud.explorer.file.query.type') }));
        } else {
          Toast.toastResponse(response);
        }

        return response;
      }
    });
  };

  const handleRename = async (query: TrinoQuery, name: string) => {
    const action = await dispatch(editRenameTrinoQueries({
      id: query.id,
      name
    }));
    const response = action?.payload as AxiosResponse<Response>;
    if (response.data.code === 200) {
      Toast.success(
        t('toast.saved', { ns: 'translation' })
          .replace('{name}', query.name)
          .replace('{type}', t('dataCloud.explorer.file.query.type'))
      );
    } else {
      Toast.toastResponse(response);
    }
    onUpdate({ ...query, name }, 'update');
  };

  const renderLabel = (label?: ReactNode, treeNode?: TreeNodeData) => (
    <FileTreeItem
      treeNode={treeNode}
      onDelete={() => handleDelete(treeNode?.data)}
      onRename={(name: string) => {
        handleRename(treeNode?.data, name);
      }}
    />
  );

  if (!resources?.length) {
    return (
      <TableEmpty className="my-[32px]" desc={emptyTitle} />
    );
  }

  return (
    <Tree
      ref={fileTreeRef}
      className="file-tree"
      treeData={treeData}
      filterTreeNode
      showFilteredOnly
      renderLabel={renderLabel}
      expandAction="click"
      searchStyle={{
        display: 'none'
      }}
      onDoubleClick={onOpen}
    />
  );
}

export default React.forwardRef(FileTree);

export declare type FileTreeType = {
  search: (text: string) => void
} | React.ElementRef<typeof FileTree>
