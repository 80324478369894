import React from 'react';
import { Button } from '@douyinfe/semi-ui';
import { Icon } from '@/components';
import { useTranslation } from 'react-i18next';
import { Numbers } from '@/utils';

type QueryStatusProps = Omit<{
  rows: number;
  size?: number;
  latency?: number;
  onDownload: () => void;
}, ''>

export default function QueryStatus({
  rows,
  size,
  latency,
  onDownload
}: QueryStatusProps): React.ReactElement {
  const { t } = useTranslation(['archivedQueries', 'translation']);

  const handleDownload = (): void => {
    onDownload();
  };

  return (
    <div className="query-status">
      <Icon className="icon-success" icon="common/success" />
      <span>
        {`${t('archivedQueries.queryStatus.latency')} ${latency?.toFixed(4)}s`}
      </span>

      <span>
        {Numbers.humanFileSize(size || 0, true)}
      </span>

      <span>
        {rows}
        {' '}
        {t('common.rows', { ns: 'translation' })}
      </span>

      <Button
        theme="borderless"
        onClick={handleDownload}
        htmlType="button"
        className="btn-download"
        icon={<Icon icon="dataCloud/download" />}
      />
    </div>
  );
}
