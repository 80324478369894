import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import CryptoJS from 'crypto-js';
import useCountDown from 'react-countdown-hook';
import { useLocation, useNavigate } from 'react-router-dom';
import parseMs from 'parse-ms';
import { Button, Form } from '@douyinfe/semi-ui';
import { useLoadingCallback } from '@/utils';
import {
  ButtonLoading, Card, HeaderTitle, AuthToast
} from '@/components';
import { CommonRegExp } from '@/config/constant';
import InputGroup from '../login/InputGroup';
import './index.scss';
import AccountApi from '../../../api/AccountApi';

function ResendButton({
  className,
  children,
  onClick
}: {
  children: JSX.Element,
  onClick: MouseEventHandler,
  className: string,
}): React.ReactElement {
  const initialTime = 60 * 1000;
  const [timeLeft, {
    start
  }] = useCountDown(0);
  const { seconds } = parseMs(timeLeft);

  const handleClick: MouseEventHandler = (event): void => {
    event.preventDefault();

    if (timeLeft <= 0) {
      start(initialTime);
      if (onClick) {
        onClick(event);
      }
    }
  };

  return (
    <a
      className={`${className} ${timeLeft <= 0 ? '' : 'disabled'}`}
      href="./#"
      onClick={handleClick}
    >
      {children}
      {seconds > 0 ? `(${seconds}s)` : ''}
    </a>
  );
}

export default function ResetPassword(): React.ReactElement {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { email } = location.state as {
    email: string;
  };

  const [error, setError] = React.useState<boolean>(false);

  const notes: Array<string> = t(
    'resetPassword.codeError.notes',
    {
      returnObjects: true
    }
  );

  const {
    callback: handleResetPassword,
    loading
  } = useLoadingCallback(async (record: any) => {
    const postData = {
      password: CryptoJS.MD5(record.password)
        .toString(),
      confirm_code: record.confirmCode
    };
    const response = await AccountApi.resetPassword(postData);

    // Toast.showResponseMessage(response);
    if (response.data.code === 200) {
      // navigate to modify successful page
      AuthToast.showResponseMessage(response);
      navigate('/modifySuccessful');
    } else {
      setError(true);
    }
  });

  const handleResendEmail: MouseEventHandler = async (event) => {
    event.preventDefault();

    const response = await AccountApi.sendResetEmail({
      email
    });

    AuthToast.showResponseMessage(response);
  };

  const handleSubmit = async (record: any): Promise<void> => {
    await handleResetPassword(record);
  };

  return (
    <div className="reset-password">
      <HeaderTitle title={t('resetPassword.headerTitle')} />
      <Form onSubmit={handleSubmit}>
        <Card>
          <h3 className="title bolder">{t('resetPassword.title')}</h3>

          <div>
            <InputGroup
              autoFocus
              name="password"
              type="password"
              rules={[
                {
                  type: 'string',
                  required: true,
                  pattern: CommonRegExp.password,
                  message: 'password format error'
                }
              ]}
              label={t('resetPassword.passwordPlaceholder')}
            />

            <InputGroup
              name="confirmCode"
              type="text"
              label={t('resetPassword.codePlaceholder')}
              rules={[
                {
                  type: 'string',
                  required: true
                }
              ]}
              assist={(
                <div>
                  <span
                    className="not-email"
                  >
                    {t('resetPassword.notEmail')}
                  </span>
                  <ResendButton
                    className="btn-resend medium"
                    onClick={handleResendEmail}
                  >
                    {t('resetPassword.resendEmail')}
                  </ResendButton>
                </div>
              )}
            />

            <ButtonLoading
              className="btn-enhance uppercase bolder"
              htmlType="submit"
              loading={loading}
            >
              {t('resetPassword.saveButton')}
            </ButtonLoading>
          </div>

          <Button
            theme="borderless"
            className="btn-cancel bolder"
            onClick={() => {
              navigate(-1);
            }}
          >
            {t('common.cancel')}
          </Button>
        </Card>
      </Form>

      <div className={`code-error ${error ? 'show' : ''}`}>
        <h5 className="title bolder">{t('resetPassword.codeError.title')}</h5>

        <div className="notes medium">
          <p className="tip medium">{t('resetPassword.codeError.tip')}</p>
          <br />

          {_.map(notes, (item, index) => (
            <div key={`${index}`}>
              <p>{item}</p>
              <br key={`${item}${index}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
