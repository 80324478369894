import React, { useRef, useState } from 'react';
import { ColumnSchema, GroupSchema, TableSchema, TreeItemSchema } from '@/model/Table';
import _ from 'lodash';
import { Icon } from '@/components';
import { Breadcrumb, Button, Popover, Skeleton, Tree, Typography } from '@douyinfe/semi-ui';
import { TreeNodeData } from '@douyinfe/semi-ui/lib/es/tree/interface';
import classNames from 'classnames';
import TableEmpty from '@/components/Table/TableEmpty';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { getChain } from '@/props';
import { ExpandedOtherProps, RenderFullLabelProps } from '@douyinfe/semi-ui/tree/interface';
import { isTable } from '@/store/reducers/dataCloudReducer';
import ChainIconGroup from '@/views/dataCloudStudio/components/SideExplorer/components/DataExplorer/components/ChainIconGroup';

type TableItemProps = {
  item: TreeItemSchema,
  onClick?: () => void,
  renderPopoverContent?: (table: TableSchema) => React.ReactElement,
  previewable?: boolean
}

function isGroup(object: TableSchema | GroupSchema): object is GroupSchema {
  return 'children' in object;
}

function TableItem({
  item,
  onClick,
  renderPopoverContent
}: Omit<TableItemProps, ''>): React.ReactElement {
  const handleGoto: React.MouseEventHandler<HTMLButtonElement> = (e): void => {
    e.preventDefault();
    e.stopPropagation();
    if (onClick) {
      onClick();
    }
  };

  const schemaType = isGroup(item) ? 'group' : 'table';

  const renderIcon = (): React.ReactNode => {
    if (isGroup(item)) {
      if (item?.logo) {
        return <Icon className="icon-type" icon={item.logo} />;
      }
      return null;
    }
    return <Icon className="icon-type" icon={`dataCloud/${schemaType}`} />;
  };

  const flattenObject = (rootElement: TreeItemSchema): string[] => {
    if (rootElement?.children) {
      return _.flatten(_.map(rootElement.children, (subChildren) => flattenObject(subChildren)));
    }
    if (isTable(rootElement)) {
      return rootElement.schema;
    }
    return [];
  };

  const renderChain = (): React.ReactElement => {
    if (isTable(item)) {
      return <ChainIconGroup chains={item.schema} size={14} />;
    }
    const chains = item?.schema || _.uniq(_.flatten(flattenObject(item)));
    return <ChainIconGroup chains={chains} size={14} />;
  };

  const displayBlockchainPrefix = !isGroup(item)
    && item.schema?.length === 1
    && item.type === 'raw';

  const content = (
    <div className="table-item">
      {renderIcon()}
      {displayBlockchainPrefix ? (
        <span className="blockchain">
          {getChain(item.schema[0]) || item.schema[0]}
        </span>
      ) : null}

      <div className="name inline-block">
        <span className="text-ellipsis">{item.name}</span>
      </div>
      {
        isTable(item) ? (
          <span className="column-type flex-shrink-0 bg-[#EFF3F9] mx-[8px] rounded-[2px] text-[#2272B4] text-[12px] leading-[16px] px-[4px]">
            {item.decodeType}
          </span>
        ) : null
      }

      <div className="ml-auto flex flex-row -align-center">
        {renderChain()}
        {
          isTable(item) ? (
            <Button
              className={classNames('operate-btn')}
              htmlType="button"
              theme="borderless"
              onClick={handleGoto}
              icon={onClick ? (
                <Icon
                  className="icon"
                  icon="dataCloud/arrow-border-right"
                  alt=""
                />
              ) : null}
            />
          ) : null
        }
      </div>
    </div>
  );

  if (isGroup(item) || !renderPopoverContent) {
    return content;
  }

  return (renderPopoverContent
    ? (
      <Popover
        showArrow
        trigger="hover"
        className="table-description-popover"
        contentClassName="table-description-popover-content"
        style={
        {
          marginLeft: '10px',
          border: '1px solid #CACDD0',
          borderColor: '#CACDD0'
        }
      }
        content={renderPopoverContent(item)}
        position="right"
      >
        {content}
      </Popover>
    )
    : content
  );
}

type TableColumnProps = {
  column: ColumnSchema
}

function TableColumn({
  column
}: TableColumnProps): React.ReactElement {
  return (
    <div className="table-column">
      <span className="name">{column.name}</span>
      <span className="column-type text-ellipsis">{column.type}</span>
    </div>
  );
}

export declare type TableTreeProps = Omit<{
  list: TreeItemSchema[],
  loading: boolean,
  onTableClick: (value: TableSchema | ColumnSchema) => void,
  renderPopoverContent?: (item: TableSchema) => React.ReactElement,
  onExpandGroup?: (paths: string[]) => void,
  category?: string,
  previewable?: boolean
}, ''>

function TableTreeSkeleton(): React.ReactElement {
  return (
    <div className="table-tree skeleton">
      {
        _.times(20, (item) => (
          <div key={`table-item-${item}`} className="table-item">
            <Skeleton.Image className="icon-expand mr-[8px]" />
            <Skeleton.Image className="icon-type" />
            <Skeleton.Paragraph className="name" rows={1} />
          </div>
        ))
      }
    </div>
  );
}

export default function TableTree(props: TableTreeProps): React.ReactElement {
  const {
    list,
    loading,
    onTableClick,
    renderPopoverContent,
    category,
    onExpandGroup,
    previewable = true
  } = props;
  const { t } = useTranslation();
  const treeRef = useRef<Tree>(null);
  const [groupPath, setGroupPath] = useState<any[]>([]);

  if (loading) {
    return (
      <TableTreeSkeleton />
    );
  }

  const buildTree = (item: TreeItemSchema): TreeNodeData => {
    if (isGroup(item)) {
      const duplicatedNameChild = item.children.find((child) => child.name === item.name);
      if (duplicatedNameChild) {
        item.name += ' ';
      }
      return {
        label: <TableItem
          key={item.name}
          item={item}
          renderPopoverContent={renderPopoverContent}
          previewable={previewable}
        />,
        data: item,
        value: item.name,
        key: `${item.name}`,
        children: _.map(item.children, buildTree)
      };
    }

    let children;
    if (previewable) {
      children = _.map(item.columns || [], (column) => ({
        label: <TableColumn column={column} />,
        value: column.name,
        data: column,
        key: `${item.schema}-${item.name}-${column.name}`
      }));
    }

    return {
      label: <TableItem
        key={item.table_name}
        item={item}
        onClick={previewable ? () => {
          if (item) {
            onTableClick(item);
          }
        } : undefined}
        renderPopoverContent={renderPopoverContent}
      />,
      data: item,
      value: item.name,
      key: `${item.table_name}-${item.name}`,
      children
    };
  };

  const treeData: TreeNodeData[] = _.map(list, buildTree);

  const renderLabel = (labelProps: RenderFullLabelProps): React.ReactElement => {
    const {
      className,
      onExpand,
      onClick,
      data,
      style
    } = labelProps;
    const isLeaf = (data.children?.length || 0) <= 0;
    const isGroupType = isGroup(data?.data);

    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    return (
      <li
        className={classNames([className, {
          column: isLeaf,
          table: !isGroupType,
          group: isGroupType
        }])}
        onClick={isLeaf ? onClick : onExpand}
        style={style}
      >
        {!isLeaf && !isGroupType ? (
          <Icon
            className={
                    classNames([
                      'icon-expand',
                      { table: !isGroupType },
                      { active: labelProps.expandStatus.expanded }
                    ])
                  }
            icon={
                    // eslint-disable-next-line no-nested-ternary
                    !(isGroupType)
                      ? 'dataCloud/arrow-expand'
                      : (labelProps.expandStatus.expanded ? 'common/subtract' : 'common/plus')
                  }
          />
        ) : null}
        {data.label}
      </li>
    );
  };

  const renderEmpty = (): React.ReactElement => (
    <TableEmpty>
      <div>
        {t('dataCloud.missData')}
        <Typography.Text link={{ href: 'http://chainbase.com/support', target: '_blank' }}>
          {t('dataCloud.findUs')}
        </Typography.Text>
      </div>
    </TableEmpty>
  );

  const handleExpand = (pExpandedKeys: string[], expandedOtherProps: ExpandedOtherProps) => {
    const { expanded, node } = expandedOtherProps;
    if (expanded && node.data && isGroup(node.data)) {
      const newGroup = [...groupPath];
      newGroup.push(node.data);
      setGroupPath(newGroup);
      if (onExpandGroup) {
        onExpandGroup(_.map(newGroup, (item) => item.name));
      }
    }
  };

  const handleSelect = (value: string, status: boolean, selectedNode: TreeNodeData) => {
    if (selectedNode.data.schema_type === 'group') {
      handleExpand([], {
        expanded: true,
        node: selectedNode
      } as ExpandedOtherProps);
      return;
    }
    onTableClick(selectedNode.data);
  };

  const handleBreadcrumbClick = (paths: any[]) => {
    setGroupPath(paths);
    if (onExpandGroup) {
      onExpandGroup(_.map(paths, (item) => item.name));
    }
    // clear expand node
    const status = {
      expandedKeys: new Set<string>()
    };
    treeRef.current?.adapter.updateState(status);
  };

  const renderTree = (tree: TreeNodeData[]): React.ReactElement => (
    <Tree
      ref={treeRef}
      className="table-tree"
      emptyContent={renderEmpty()}
      treeData={tree}
      expandAction={false}
      onSelect={handleSelect}
      onExpand={handleExpand}
      renderFullLabel={renderLabel}
    />
  );
  const renderBreadcrumb = () => (
    <Breadcrumb className="group-breadcrumb mb-[4px] px-[24px]">
      <Breadcrumb.Item noLink={_.size(groupPath) === 0} onClick={() => handleBreadcrumbClick([])}>{t<string>(`dataCloud.${category}`)}</Breadcrumb.Item>
      {
        _.map(groupPath, (item, index) => (
          <Breadcrumb.Item
            key={item.name}
            noLink={index === _.size(groupPath) - 1}
            onClick={() => {
              const newGroup = _.slice(groupPath, 0, index + 1);
              handleBreadcrumbClick(newGroup);
            }}
          >
            {
                item?.logo ? <Icon icon={item.logo} className="w-[12px] h-[12px] mr-[2px]" /> : null
              }
            {item.name}
          </Breadcrumb.Item>
        ))
      }
    </Breadcrumb>
  );

  if (_.size(groupPath) >= 0) {
    return (
      <div className="mt-[4px]">
        {renderBreadcrumb()}

        {renderTree(treeData)}
      </div>
    );
  }

  return renderTree(treeData);
}
