import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import settingBillingReducer from '@/store/reducers/settingBillingReducer';
import projectReducer from '@/store/reducers/projectReducer';
import trinoQueriesReducer from '@/store/reducers/trinoQueriesReducer';
import dataCloudStudioReducer from '@/store/reducers/dataCloudStudioReducer';
import apisReducer from '@/store/reducers/apisReducer';
import kafkaTopicReducer from '@/store/reducers/kafkaTopicReducer';
import constantReducer from './reducers/constantReducer';
import dataCloudReducer from './reducers/dataCloudReducer';
import dataCloudGeneratorReducer from './reducers/dataCloudGeneratorReducer';
import logReducer from './reducers/logReducer';
import archivedQueriesReducer from './reducers/archivedQueriesReducer';
import syncReducer from './reducers/syncReducer';
import integrationReducer from './reducers/integrationReducer';

export const store = configureStore({
  reducer: {
    constant: constantReducer,
    dataCloud: dataCloudReducer,
    dataCloudStudio: dataCloudStudioReducer,
    languageGenerator: dataCloudGeneratorReducer,
    settingBilling: settingBillingReducer,
    project: projectReducer,
    logs: logReducer,
    archivedQueries: archivedQueriesReducer,
    sync: syncReducer,
    trinoQueries: trinoQueriesReducer,
    integration: integrationReducer,
    apis: apisReducer,
    kafkaTopic: kafkaTopicReducer
  }
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
  RootState, unknown, Action<string>>;
