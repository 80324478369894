import { AxiosResponse } from 'axios';
import { ArchivedQueryItem } from '@/props';
import Request from '@/api/Request';
import { SearchPage } from '@/model/Request';
import { ResponseData } from '@/model/Response';

export default class ArchivedQueriesApi {
  static getList(data: SearchPage): Promise<AxiosResponse<ResponseData<{
    data: {
      count: number,
      data: Array<ArchivedQueryItem>,
      next_page: number
    }
  }>>> {
    return Request.get('/v1/dw/query/archive/list', {
      params: data
    });
  }

  static detail(uuid: string): Promise<AxiosResponse> {
    return Request.get('/v1/dw/query/archive/detail', {
      params: { uuid }
    });
  }

  static update(data: ArchivedQueryItem): Promise<AxiosResponse> {
    return Request.put('/v1/dw/query/archive/update', data);
  }

  static create(data: Omit<ArchivedQueryItem, 'id'>): Promise<AxiosResponse> {
    return Request.post('/v1/dw/query/archive/create', data);
  }

  static delete(uuid: string): Promise<AxiosResponse> {
    return Request.delete('/v1/dw/query/archive/delete', {
      params: { uuid }
    });
  }
}
