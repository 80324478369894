import React, { useEffect } from 'react';
import {
  Container,
  Empty,
  HeaderTitle,
  Icon,
  Layout,
  Modal,
  Pagination,
  Table,
  Toast
} from '@/components';
import { Button, Checkbox, Col, Input, Modal as SemiModal, Row } from '@douyinfe/semi-ui';
import { useTranslation } from 'react-i18next';
import { Guide } from '@/config/constant';
import { ColumnProps } from '@douyinfe/semi-ui/table/interface';
import { API } from '@/model/API';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { fetchApiList } from '@/store/reducers/apisReducer';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { DateUtils } from '@/utils';
import CustomApi from '@/api/CustomApi';
import { FeatureName, useWhiteList } from '@/hooks/UseWhiteList';

interface ModalContentProps {
  onConfirm: (ignoreRemind: boolean) => void;
}

function ModalContent({ onConfirm }: ModalContentProps): React.ReactElement {
  const { t } = useTranslation(['api']);
  const [ignoreRemind, setIgnoreRemind] = React.useState(false);
  return (
    <div>
      <p className="mt-[42px] mb-[2px]">{t<string>('list.createModal.description', { ns: 'api' })}</p>
      <div className="flex !flex-col items-baseline mt-[32px]">
        <Button
          theme="solid"
          type="primary"
          className="!w-full !mx-[0px] bg-[var(--v2-color-grey800)]"
          onClick={() => {
            onConfirm(ignoreRemind);
          }}
        >
          {t<string>('list.createModal.confirm', { ns: 'api' })}
        </Button>
        <Checkbox
          className="mt-[24px]"
          onChange={(event) => {
            setIgnoreRemind(!!event.target.checked);
          }}
        >
          <span className="ml-[8px]">{t<string>('list.createModal.remind', { ns: 'api' })}</span>
        </Checkbox>
      </div>
    </div>
  );
}

const IGNORE_REMIND_KEY = 'API_CreateModal_IgnoreRemind';

export default function APIList(): React.ReactElement {
  const { t } = useTranslation(['api']);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const apiTable = useAppSelector((root) => root.apis.table);
  const search = useAppSelector((root) => root.apis.search);
  const { checkWhitelist, openRequestModal } = useWhiteList(FeatureName.custom_api);

  useEffect(() => {
    dispatch(fetchApiList({}));
  }, []);

  const handleDeleteApi = (record: API) => {
    Modal.delete({
      title: t<string>('list.deleteModal.confirmDeletionTitle', {
        objName: record.name
      }),
      tip: t<string>('list.deleteModal.confirmDeletionTip'),
      onComplete: () => {
        dispatch(fetchApiList({}));
      },
      onDelete: async () => {
        const response = await CustomApi.delete(record.id);
        if (response.data.code === 0) {
          Toast.success(t('toast.deleted', { ns: 'translation', name: record.name, type: t<string>('common.api') }));
        } else {
          Toast.toastResponse(response);
        }
        return response;
      }
    });
  };

  const columns: ColumnProps<API>[] = [
    {
      title: t<string>('list.tableTitle.name', { ns: 'api' }),
      minWidth: '300px',
      dataIndex: 'name',
      render: (apiName) => (
        <div className="flex flex-row items-center py-[2px]">
          <Icon className="w-[20px] inline-block" icon="api/custom" />
          <span className="ml-[8px]">{apiName}</span>
        </div>
      )
    },
    {
      title: t<string>('list.tableTitle.requestCount', { ns: 'api' }),
      dataIndex: 'requestCount'
    },
    {
      title: t<string>('list.tableTitle.updateTime', { ns: 'api' }),
      dataIndex: 'updateTime',
      render: (data) => DateUtils.format(data)
    },
    {
      title: t<string>('list.tableTitle.createdTime', { ns: 'api' }),
      dataIndex: 'createTime',
      render: (data) => DateUtils.format(data)
    },
    {
      title: t<string>('list.tableTitle.operations', { ns: 'api' }),
      width: '150px',
      dataIndex: 'id',
      render: (id: number, record: API) => (
        <div>
          <Button
            className="w-[36px] h-[36px]"
            icon={<Icon icon="common/setting" />}
            theme="borderless"
            onClick={() => {
              navigate(`/dataCloud/api/custom/${id}`);
            }}
          />
          <Button
            className="w-[36px] h-[36px]"
            icon={<Icon icon="common/delete" />}
            theme="borderless"
            onClick={() => {
              handleDeleteApi(record);
            }}
          />
        </div>
      )
    }
  ];

  const handleCreate = () => {
    if (!checkWhitelist()) {
      openRequestModal();
      return;
    }

    const value = localStorage.getItem(IGNORE_REMIND_KEY);
    if (value) {
      navigate('/dataCloud/v2');
      return;
    }

    const modal = SemiModal.info({
      icon: null,
      size: 'medium',
      title: t<string>('list.createModal.title', { ns: 'api' }),
      centered: true,
      content: <ModalContent onConfirm={(ignoreRemind) => {
        if (ignoreRemind) {
          localStorage.setItem(IGNORE_REMIND_KEY, _.toString(ignoreRemind));
        } else {
          localStorage.removeItem(IGNORE_REMIND_KEY);
        }
        navigate('/dataCloud/v2');
        modal.destroy();
      }}
      />,
      closeIcon: <Icon className="icon icon-close" icon="modal/close" />,
      footer: null
    });
  };

  return (
    <Container className="api-list-container max-w-full">
      <HeaderTitle title={t<string>('navigation.apis', { ns: 'translation' })} />

      <Layout.Header
        title={t<string>('navigation.apis', { ns: 'translation' })}
        description={t<string>('list.description', { ns: 'api' })}
      >
        <a
          className="doc-link flex flex-row items-center"
          href={Guide.customAPI}
        >
          <Icon icon="sync/jobs/overview/docs" className="w-[16px] h-[16px]" />
          <span className="ml-[4px] text-[var(--v2-color-grey500)]">Docs</span>
        </a>
      </Layout.Header>

      <Row className="mx-[32px] mt-[24px]" type="flex" justify="space-between">
        <Col span={6}>
          <Input
            className="w-[240px] h-[36px] bg-white border-[var(--v2-color-grey300)]"
            suffix={<Icon className="mx-[12px] w-[16px] h-[16px]" icon="common/search" />}
            placeholder={t<string>('common.search', { ns: 'translation' })}
          />
        </Col>

        <Col>
          <Button
            size="large"
            icon={<Icon icon="sync/jobs/overview/add" />}
            theme="solid"
            type="primary"
            onClick={handleCreate}
          >
            {t<string>('list.create', { ns: 'api' })}
          </Button>
        </Col>
      </Row>

      <div className="mx-[32px] my-[16px]">
        <Table
          loading={apiTable.loading}
          columns={columns}
          dataSource={apiTable.list || []}
          empty={(
            <Empty
              title={t<string>('navigation.apis', { ns: 'translation' })}
              description={t<string>('list.emptyTip', { ns: 'api' })}
              howTo={t<string>('list.howTo', { ns: 'api' })}
              aboutLink={Guide.customAPI}
              onGetStarted={handleCreate}
            />
          )}
        />

        <Pagination
          total={apiTable?.pagination?.total || 0}
          showSizeChanger
          className="my-[8px]"
          currentPage={search?.page}
          pageSize={search?.limit}
          onChange={(page, pageSize) => {
            dispatch(fetchApiList({ page, limit: pageSize }));
          }}
        />

      </div>

    </Container>
  );
}
