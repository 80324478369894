import { AxiosRequestConfig, AxiosResponse } from 'axios';
import Request from '@/api/Request';
import { ResponseData } from '@/model/Response';
import { TreeItemSchema } from '@/model/Table';

export default class DataCloudApi {
  static getTableList(): Promise<AxiosResponse<ResponseData<{ data: TreeItemSchema[] }>>> {
    return Request.get('/v1/dw/table-schemas');
  }

  static query(data: {
    query: string,
    task_id?: string,
    page?: number
  }, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    return Request.post('/v1/dw/internal/query', data, config);
  }
}
