import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

export default {
  uuid(): string {
    return uuidv4();
  },

  format(content: string, params: {
    [key: string]: any
  }): string {
    let temp = content;
    const keys = _.keys(params);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      const value = params[keys[i]];
      temp = temp.replace(`{${key}}`, value);
    }

    return temp;
  },

  toJSON(content: string): any {
    try {
      const encode = content.replaceAll('\\\'', '\\\\\'');
      return JSON.parse(encode);
    } catch (e) {
      return {};
    }
  }
};
