import React, { ForwardedRef } from 'react';
import {
  SideSheet, TabPane, Tabs, Typography
} from '@douyinfe/semi-ui';
import { useTranslation } from 'react-i18next';
import { useLoadingCallback } from '@/utils';
import LogExplorerApi from '@/api/LogExplorerApi';
import './index.scss';
import classNames from 'classnames';
import { Loading } from '@/components';
import { RequestLogItem } from '@/model/Log';
import DataViewer from './DataViewer';

const LogDetailSideSheet = React.forwardRef((props: any, ref: ForwardedRef<any>): React.ReactElement => {
  const { t } = useTranslation(['logExplorer', 'translation']);
  const [visible, setVisible] = React.useState<boolean>(false);

  const [detail, setDetail] = React.useState<RequestLogItem | undefined>(undefined);

  const {
    loading,
    callback: handleRequestDetail
  } = useLoadingCallback(async (requestId: string): Promise<void> => {
    const response = await LogExplorerApi.detail(requestId);
    const { log } = response.data;
    setDetail(log);
  });

  React.useImperativeHandle(ref, () => ({
    async show(item: RequestLogItem) {
      setVisible(true);
      setDetail(undefined);
      await handleRequestDetail(item?.request_id);
    }
  }));

  const renderResponse = (): React.ReactElement => {
    if (detail?.response?.body) {
      return (
        <DataViewer
          loading={loading}
          className="response-data-viewer"
          name={t('logExplorer.responseTable')}
          heightRate={0.6}
          defaultActiveKey="json"
          json={detail?.response?.body || {}}
        />
      );
    }
    return (
      <div
        className={classNames(['json-viewer', 'response-data-viewer'])}
        style={
            {
              height: `${0.6 * 100}%`
            }
          }
      >
        <Tabs
          type="line"
          defaultActiveKey="json"
        >
          <TabPane tab={t('logExplorer.responseTable')} itemKey="table">
            {
                loading ? <Loading type="grey" />
                  : <div className={classNames('disabled-text')}>{t('logExplorer.emptyResponseTip')}</div>
              }

          </TabPane>
          <TabPane tab={t('common.json', { ns: 'translation' })} itemKey="json">
            {
                loading ? <Loading type="grey" /> : (
                  <div className={classNames('disabled-text')}>{t('logExplorer.emptyResponseTip')}</div>
                )
              }

          </TabPane>
        </Tabs>
      </div>
    );
  };

  return (
    <SideSheet
      className="log-detail-side-sheet"
      closeOnEsc
      title={(
        <>
          <span>{t('logExplorer.detail.title')}</span>
          <Typography.Text type="tertiary">
            {`ID: ${detail?.request_id || ''}`}
          </Typography.Text>
        </>
      )}
      visible={visible}
      width="35vw"
      onCancel={() => setVisible(false)}
      mask={false}
    >
      <DataViewer
        loading={loading}
        name={t('logExplorer.requestTable')}
        heightRate={0.4}
        defaultActiveKey="table"
        json={detail?.request?.body || {}}
      />

      {renderResponse()}
    </SideSheet>
  );
});

export default LogDetailSideSheet;

export declare type LogDetailSideSheetType =
  React.ElementRef<typeof LogDetailSideSheet>
  | {
  show(item: RequestLogItem): void;
}
