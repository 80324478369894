import Request from '@/api/Request';
import useSWR from 'swr';

export type StatusIndicator = 'none' | 'minor' | 'major' | 'critical';

export interface StatusResponse {
  page: {
    id: string;
    name: string;
    time_zone: string;
    updated_at: string;
    url: string;
  };
  status: {
    indicator: StatusIndicator;
    description: string;
  };
}

export default function useStatus() {
  const { data } = useSWR(
    'https://status.chainbase.com/api/v2/status.json',
    async (key) => {
      const axiosResponse = await Request.get<StatusResponse>(key);

      if (axiosResponse.status === 200) {
        return axiosResponse.data;
      }
      throw new Error('Unable to fetch status data');
    }
  );

  return { data };
}
