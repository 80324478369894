import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row } from '@douyinfe/semi-ui';

import {
  EventManager, Icon, Loading, Toast
} from '@/components';
import QueryStatus from '@/views/dataCloud/index/QueryResult/QueryStatus';
import {
  AsyncThunkPromise,
  useAppDispatch,
  useAppSelector
} from '@/store/hooks';
import {
  dataCloudActions,
  dataCloudCurrentTab,
  queryData
} from '@/store/reducers/dataCloudReducer';
import { ExcelUtils } from '@/utils';
import ErrorBanner from '@/components/Table/ErrorBanner';
import QueryResultEmpty from './QueryResultEmpty';
import QueryResultTable from './QueryResultTable';
import GenerateSideSheet, {
  GenerateSideSheetType
} from '../Generator/GenerateSideSheet';
import './index.scss';

export default function QueryResult(): React.ReactElement {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const generateSideSheetRef = React.useRef<GenerateSideSheetType>();

  const currentTab = useAppSelector(dataCloudCurrentTab);
  const [promise, setPromise] = React.useState<AsyncThunkPromise>();
  const resultTable = currentTab?.result;

  const handleOpenSideSheet = (): void => {
    generateSideSheetRef?.current?.open(currentTab);
  };

  const handleCancel = (): void => {
    promise?.abort();
  };

  const handleQuery = React.useCallback((): void => {
    if (!currentTab?.sql || promise) {
      Toast.error(t('dataCloud.nothingRun'));
      return;
    }

    const sql = currentTab?.selectText || currentTab?.sql;
    // clear query result
    const task = dispatch(queryData({
      sql,
      tabKey: currentTab?.key
    }));
    setPromise(task);
    task.then(() => {
      setPromise(undefined);
    });
  }, [t, currentTab, dispatch, promise, setPromise]);

  React.useEffect(() => {
    const listener = EventManager.subscribe('RunQuery', () => {
      handleQuery();
    });

    return () => {
      listener();
    };
  }, [handleQuery]);

  const renderContent = (): React.ReactElement => {
    if (promise || currentTab?.resultLoading) {
      return (
        <div className="flex flex-1 flex-center">
          <Loading type="grey" />
        </div>
      );
    }
    if (!resultTable) {
      return (<QueryResultEmpty />);
    }

    if (resultTable?.err_msg) {
      return (
        <ErrorBanner
          description={resultTable?.err_msg}
          onClose={() => {
            dispatch(dataCloudActions.changeCurrentTabStatus({
              key: currentTab?.key,
              result: undefined
            }));
          }}
        />
      );
    }

    if (resultTable) {
      return (
        <QueryResultTable
          key={resultTable.task_id}
          resultTable={resultTable}
        />
      );
    }
    return (<div />);
  };

  const renderGeneratorButton = (): React.ReactElement => (
    <Button
      className="btn-generate"
      disabled={!currentTab?.sql?.trim()}
      onClick={() => {
        handleOpenSideSheet();
      }}
      icon={<Icon className="icon" icon="dataCloud/generate" />}
    >
      {t('dataCloud.generate.title')}
    </Button>
  );

  const getRunText = (): string => {
    if (promise) {
      return t('common.running');
    }

    if (currentTab?.selectText) {
      return t('common.runSelection');
    }

    return `${t('common.run')} ⌘ + ⏎`;
  };

  return (
    <div className="query-result-content">

      <div className="query-result-header">
        <Row type="flex" align="middle">
          <h3 className="title semi-bold">{t('dataCloud.queryResult')}</h3>
          {
            resultTable && !resultTable?.err_msg
              ? (
                <QueryStatus
                  rows={resultTable?.rows}
                  size={resultTable?.bytes_read}
                  latency={resultTable?.elapsed}
                  onDownload={() => {
                    ExcelUtils.exportCSV(`result-${resultTable.task_id}`, resultTable.meta, resultTable.result);
                  }}
                />
              ) : null
          }
        </Row>

        <div className="operate-group">

          {
            promise ? (
              <Button
                onClick={handleCancel}
                theme="borderless"
                className="btn-cancel"
                htmlType="button"
              >
                {t('common.cancel')}
              </Button>
            ) : (
              renderGeneratorButton()
            )
          }

          <Button
            loading={!!promise}
            onClick={handleQuery}
            theme="solid"
            className="btn-run"
            type="primary"
            htmlType="button"
          >
            {getRunText()}
          </Button>
        </div>
      </div>

      <div className="query-result-body">
        {renderContent()}
      </div>

      <GenerateSideSheet ref={generateSideSheetRef} />
    </div>
  );
}
