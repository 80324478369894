import React from 'react';
import ReactECharts from 'echarts-for-react';
import { EChartsOption } from 'echarts';
import _ from 'lodash';
import moment from 'moment';
import { Loading } from '@/components';
import TableEmpty from '@/components/Table/TableEmpty';
import { useAppSelector } from '@/store/hooks';
import {
  ChartSplitLineStyle,
  ChartTextStyle
} from '../index/RequestChartBlock';

export declare type RequestLogChartProps = {
  timeRange?: string
}

function EmptyWrapper({ children }: React.PropsWithChildren<any>): React.ReactElement {
  return (
    <div
      className="flex flex-center"
      style={
        {
          width: '100%',
          height: '276px'
        }
      }
    >
      {children}
    </div>
  );
}

export default function RequestLogChart(): React.ReactElement {
  const loading = useAppSelector((state) => state.logs.listLoading);
  const searchData = useAppSelector((state) => state.logs.query);
  const { datetime, ...rest } = useAppSelector((state) => state.logs.chatData);

  if (loading) {
    return (
      <EmptyWrapper>
        <Loading type="grey" />
      </EmptyWrapper>
    );
  }
  if (!datetime || datetime?.length <= 0) {
    return (
      <EmptyWrapper>
        <TableEmpty />
      </EmptyWrapper>
    );
  }

  const options: EChartsOption = {
    grid: {
      left: '3.5%',
      right: '1%',
      top: '10%',
      bottom: '15%'
    },
    tooltip: {
      trigger: 'axis',
      position(pt) {
        return [pt[0], '5%'];
      }
    },
    xAxis: {
      type: 'category',
      data: _.map(datetime, (item: any) => {
        let format = 'hh:mm';
        if (searchData.date.endsWith('d')) {
          if (parseInt(searchData.date, 10) > 1) {
            format = 'MM-DD hh:mm';
          } else {
            format = 'hh';
          }
        }
        return moment.unix(item)
          .format(format);
      }),
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      }
    },
    yAxis: {
      type: 'value',
      splitLine: ChartSplitLineStyle
    },
    textStyle: ChartTextStyle,
    series: _.map(rest, (item) => (
      {
        data: item,
        type: 'bar',
        barCategoryGap: 5
      }
    )),
    color: ['#3C4757']
  };

  return (
    <ReactECharts
      option={options}
      style={
        {
          width: 'initial',
          height: '276px'
        }
      }
    />
  );
}
