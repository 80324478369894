import React from 'react';
import { Icon } from '@/components';
import { hasIcon } from '@/components/Icon';

interface ChainIconProps {
  chain: string
  style?: React.CSSProperties
  size?: number
}

const ChainIcon: React.FC<ChainIconProps> = ({ chain, style, size = 18 }) => {
  const blockchain = chain?.split('_')[0]?.toLowerCase();

  if (hasIcon(`blockchain/${blockchain}`)) {
    return (
      <div className={`rounded-full bg-white flex justify-center items-center border-[1px] border-[#FFFFFF] border-solid w-[${size}px] h-[${size}px]`} style={style}>
        <Icon
          icon={`blockchain/${blockchain}-solid`}
          style={{
            width: size,
            height: size,
            borderRadius: 9
          }}
          className="flex-shrink-0"
        />
      </div>
    );
  }
  return null;
};

interface ChainIconGroupProps {
  chains: string[]
  size?: number;
  maxCount?: number
}

const ChainIconGroup: React.FC<ChainIconGroupProps> = ({ chains, size = 18, maxCount = 5 }) => {
  if (!chains) {
    return null;
  }

  if (chains.length < maxCount) {
    return (
      <div className="flex flex-row align-center pl-2">
        {chains.map((item) => <ChainIcon key={item} chain={item} style={{ marginLeft: '-5px' }} size={size} />)}
      </div>
    );
  }
  return (
    <div className="flex align-center">
      <div className="px-1.5 h-[14px] mr-[5px] rounded-full border border-solid border-white flex-grow-0
       bg-[#E3E7EC] text-[#76808A] flex items-center justify-center text-[12px] font-[600]"
      >
        {`+${chains.length - 3}`}
      </div>
      {chains.slice(0, 3).map((item) => <ChainIcon key={item} chain={item} style={{ marginLeft: '-3px' }} size={size} />)}
    </div>
  );
};

export default ChainIconGroup;
