import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Integration } from '@/model/Integration';
import { RequestTable } from '@/model/Table';
import SyncApi from '@/api/SyncApi';
import { RootState } from '@/store/store';
import _ from 'lodash';
import moment from 'moment';

type IntegrationStatus = {
  table: RequestTable<Integration>
}

const initialState: IntegrationStatus = {
  table: {
    loading: false,
    list: []
  }
};

export const fetchIntegrationList = createAsyncThunk(
  'integration/getList',
  async () => {
    const response = await SyncApi.listIntegrations();
    return response?.data?.data || [];
  }
);

export const integrationReducer = createSlice({
  name: 'IntegrationSlice',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(fetchIntegrationList.pending, (state) => {
      state.table.loading = true;
    }).addCase(fetchIntegrationList.fulfilled, (state, action) => {
      state.table = {
        loading: false,
        list: _.sortBy(action?.payload, (item) => -moment(item?.updated_at).unix())
      };
    }).addCase(fetchIntegrationList.rejected, (state) => {
      state.table.loading = false;
    });
  }
});

export default integrationReducer.reducer;

export const integrationTable = (state: RootState): RequestTable<Integration> => state.integration.table;
