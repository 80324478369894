import React from 'react';
import { TimeOptions } from '@/config/constant';

export declare type Optional<T> = Partial<T>;

export declare type ComponentProps = {
  children: React.ReactNode | any;
  className?: string;
  [propsName: string]: string | any;
};

export type ButtonType = 'primary' | 'warning' | 'success' | 'danger';

export const SortedBlockChain = [
  'Ethereum',
  'Polygon',
  'BSC',
  'Avalanche',
  'Solana',
  'Fantom',
  'Arbitrum',
  'Aptos',
  'Optimism',
  'Sui',
  'Bitcoin',
  'Base',
  'zkSync',
  'Starknet',
  'linea',
  'Merlin'
] as const;

export type BlockChain = (typeof SortedBlockChain)[number];

export const getChain = (name: string): BlockChain | null => {
  const target = SortedBlockChain.find(
    (item) => item.toLowerCase() === name.toLowerCase()
  );
  if (target) {
    return target as BlockChain;
  }
  return null;
};

export const getChainIndex = (name: string): number =>
  // eslint-disable-next-line implicit-arrow-linebreak
  SortedBlockChain.findIndex((value) => value.toLowerCase() === name);

export const BlockChainFeatureList = [
  'NFT',
  'Domain',
  'Token',
  'Base'
] as const;

export type BlockChainFeature = (typeof BlockChainFeatureList)[number];

export declare type NetworkItem = {
  chain_id: number;
  chain_name: BlockChain;
  currency: string;
  chain_network: string;
  description: string;
  high: number;
  test_url: string;
  support?: BlockChainFeature[];
};

export declare type ChainItem = {
  name: BlockChain;
  networks: NetworkItem[];
};

// echart Data
export declare type ChartQueryData = {
  [key: string]: any[];
};

export declare type TimeRange = (typeof TimeOptions)[number];

export declare type ArchivedQueryItem = {
  uuid?: string;
  title: string;
  description?: string;
  content?: string;
  created_at?: string;
  updated_at?: string;
};

export declare type LanguageItem = {
  name: string;
  language: string;
  escape?: boolean;
  template?: string;
  newlineProcess?: boolean;
};

export declare type ComposerParameterItem = {
  key: string;
  name: string;
  type: string;
  defaultValue?: any;
  required?: boolean;
  parameter?: ComposerParameterItem[];
};

export declare type ComposerRequestTemplate = {
  name: string;
  type: 'web3Service' | 'chain';
  template: {
    request:
      | any
      | {
          parameter: ComposerParameterItem[];
        };
    response: any;
  };
};
