/* eslint-disable max-len */

export const Guide = {
  fistUseGuide:
    'https://metamask.zendesk.com/hc/en-us/articles/360015489531-Getting-started-with-MetaMask',
  dataCloudAPI: 'https://docs.chainbase.com/docs/sql-api-overview-2',
  projectGuide: 'https://docs.chainbase.com/docs/api-overview',
  customAPI: 'https://docs.chainbase.com/reference/data-cloud-custom-api'
};

export const ContactConfig = {
  discord: 'https://discord.gg/DUWMmtprz4',
  twitter: 'https://twitter.com/ChainbaseHQ',
  linkedin: 'https://www.linkedin.com/company/chainbasehq',
  link3: 'https://link3.to/chainbase',
  guides: 'https://docs.chainbase.com/docs',
  docs: 'https://docs.chainbase.com',
  support: 'https://discord.gg/DUWMmtprz4'
};

export const CommonRegExp = {
  email: /^[\w-+\\.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
  password:
    /(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+-_=.~!@#$%^&*()]{6,20}$/,
  url: /\b(?:https?):\/\/\S+\b/
};

export const TimeOptions = [
  '5m',
  '10m',
  '15m',
  '30m',
  '1h',
  '3h',
  '6h',
  '12h',
  '1d',
  '7d'
] as const;
