import React from 'react';
import { SideSheet } from '@douyinfe/semi-ui';
import { useTranslation } from 'react-i18next';
import px2rem from '@/utils/px2rem';

type CodeGenerateSideSheetProps = {
  children?: React.ReactNode;
  getPopupContainer?: () => HTMLElement;
  afterVisibleChange?: (isVisible: boolean) => void;
}

const CodeGenerateSideSheet = React.forwardRef((
  {
    children,
    getPopupContainer,
    afterVisibleChange
  }: CodeGenerateSideSheetProps,
  ref: React.ForwardedRef<any>
) => {
  const { t } = useTranslation();
  const [visible, setVisible] = React.useState(false);

  React.useImperativeHandle(ref, () => ({
    changeVisible(status: boolean) {
      setVisible(status);
    }
  }));

  return (
    <SideSheet
      className="generate-api-side-sheet"
      closeOnEsc
      title={t('dataCloud.generate.title')}
      visible={visible}
      width={px2rem.calcPXByScreen(507)}
      onCancel={() => setVisible(false)}
      getPopupContainer={getPopupContainer}
      afterVisibleChange={afterVisibleChange}
      mask
    >
      <div className="code-section">
        {children}
      </div>
    </SideSheet>
  );
});

export default CodeGenerateSideSheet;

export declare type CodeGenerateSideSheetType = React.ElementRef<typeof CodeGenerateSideSheet> | {
  changeVisible: (status: boolean) => void
}
