import React, { createContext, ReactNode, useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { TextLink } from '@/components';
import './index.scss';
import { Numbers } from '@/utils';
import LearnMore from '@/components/Form/LearnMore';
import { ResultTableType } from '@/model/Table';
import { AxiosResponse } from 'axios';
import useApiKey from '@/utils/hooks/dataCloud/useApiKey';
import useSqlQuery from '@/utils/hooks/dataCloud/useSqlQuery';
import CodeGenerateSideSheet, {
  CodeGenerateSideSheetType
} from '@/components/SideSheet/CodeGenerateSideSheet';
import CodeExampleSelector, { ProgramLanguageTemplate } from '@/components/CodeExampleSelector';
import { docLink, LanguageList } from './constants';
import { SQLFilesContext } from '../SQLFilesProvider';
import { ActiveTabContext } from '../ActiveTabProvider';
import { Parameter } from '../ParametersProvider';

export interface APIGeneratorValue {
  open: () => void;
}

export interface APIGeneratorProps {
  children: ReactNode
}

export const APIGeneratorContext = createContext<APIGeneratorValue>({
  open(): void {
    throw new Error('Function not implemented.');
  }
});

export default function APIGeneratorProvider({ children }: APIGeneratorProps) {
  const { t } = useTranslation();

  const codeGenerateSideSheetRef = useRef<CodeGenerateSideSheetType>(null);
  const [response, setResponse] = useState<AxiosResponse<{
    code?: number,
    message?: string,
    data: ResultTableType
  }> | null>(null);
  const [isQuerying, setIsQuerying] = useState(false);
  const [progressValue, setProgressValue] = useState(0);

  const sqlFiles = useContext(SQLFilesContext);
  const activeTab = useContext(ActiveTabContext);
  const tabActiveKey = activeTab.current;
  const curFile = sqlFiles?.find((file) => file.uuid === tabActiveKey);

  const { apiKey } = useApiKey();

  const taskClient = useSqlQuery();

  const handleCompile = (language: ProgramLanguageTemplate): string => {
    const exampleCode = language.template?.replace('#(!API_KEY!)#', apiKey ?? '').replace('#(!QUERY_ID!)#', curFile?.queryId?.toString() ?? '') || '';

    const parameterList: Parameter[] = curFile?.parameters ? JSON.parse(curFile.parameters) : [];

    let queryParams = '';
    if (language.language === 'bash') {
      parameterList.forEach((param) => {
        queryParams += `\\"${param.name}\\":\\"${param.defaultValue}\\",`;
      });
      queryParams = queryParams.slice(0, queryParams.length - 1);
    } else if (language.language === 'python') {
      parameterList.forEach((param, index) => {
        queryParams += `        "${param.name}":"${param.defaultValue}"${index !== parameterList.length - 1 ? ',\n' : ''}`;
      });
    } else if (language.language === 'Go') {
      parameterList.forEach((param, index) => {
        queryParams += `         "${param.name}": "${param.defaultValue}",${index < parameterList.length - 1 ? '\n' : ''}`;
      });
    } else if (language.language === 'javascript') {
      parameterList.forEach((param, index) => {
        queryParams += `   ${param.name}: "${param.defaultValue}"${index < parameterList.length - 1 ? ',\n' : ''}`;
      });
    } else if (language.language === 'Java') {
      parameterList.forEach((param, index) => {
        queryParams += `        queryParameters.put("${param.name}", "${param.defaultValue}");${index < parameterList.length - 1 ? '\n' : ''}`;
      });
    } else if (language.language === 'ruby') {
      parameterList.forEach((param, index) => {
        queryParams += `    ${param.name}: "${param.defaultValue}"${index < parameterList.length - 1 ? ',\n' : ''}`;
      });
    } else if (language.language === 'php') {
      parameterList.forEach((param, index) => {
        queryParams += `        "${param.name}" => "${param.defaultValue}"${index < parameterList.length - 1 ? ',\n' : ''}`;
      });
    }
    return exampleCode?.replace('#(!QUERY_PARAMS!)#', queryParams);
  };

  const handleTryRequest = async (): Promise<AxiosResponse<any>> => {
    const queryId = curFile?.queryId;
    if (!queryId) return Promise.reject();
    const parameters = JSON.parse(curFile.parameters) as Parameter[];
    try {
      setIsQuerying(true);
      setProgressValue(0);
      const executionId = await taskClient.executeQueryById(queryId, parameters);
      let count = 0;
      do {
        // eslint-disable-next-line no-await-in-loop
        const execStatus = await taskClient.getExecutionStatus(executionId);
        setProgressValue((prev) => (execStatus.progress && execStatus.progress > prev ? execStatus.progress : prev));
        count += 1;
        if (_.includes(['FINISHED', 'CANCELED', 'FAILED'], execStatus.status)) {
          break;
        }
        const sleepTime = Math.min(Math.floor(count / 10) * 2 + 1000, 1000);
        // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
        await new Promise((resolve) => setTimeout(resolve, sleepTime));
      } while (count < 100);

      return await taskClient.getExecutionResultsRaw(executionId);
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    } finally {
      setIsQuerying(false);
    }
  };

  const getContainer = (): HTMLElement => document.querySelector('.terminal-container') as HTMLElement;

  const afterVisibleChange = (isVisible: boolean) => {
    if (!isVisible) {
      setResponse(null);
      taskClient.cancelAll();
    }
  };

  const renderHeader = () => (
    <div className="get-key">
      <TextLink
        links={{ docs: docLink }}
      >
        {t('dataCloud.explorer.generate.howToGetApi')}
      </TextLink>
    </div>
  );

  const renderProgress = () => (
    <div className="progress-wrapper">
      <div className="percentage">{`${Numbers.formatFractionDigits(progressValue)}%`}</div>
      <progress className="progress" max={100} value={progressValue} />
    </div>
  );

  const renderSideSheet = () => (
    <CodeGenerateSideSheet
      ref={codeGenerateSideSheetRef}
      afterVisibleChange={afterVisibleChange}
      getPopupContainer={getContainer}
    >
      <div className="code-section">
        {renderHeader()}
        <CodeExampleSelector
          languageList={LanguageList}
          onCompile={handleCompile}
          onRequest={handleTryRequest}
          learnMoreLink={(
            <LearnMore
              to={docLink}
              text={t('dataCloud.explorer.generate.howToUse')}
            />
          )}
        />
        {isQuerying && renderProgress()}
      </div>
    </CodeGenerateSideSheet>
  );

  const contextValue = useMemo(() => ({
    open() {
      codeGenerateSideSheetRef?.current?.changeVisible(true);
    }
  }), [codeGenerateSideSheetRef]);

  return (
    <APIGeneratorContext.Provider value={contextValue}>
      {children}
      {renderSideSheet()}
    </APIGeneratorContext.Provider>
  );
}
