import React, { useCallback, useState } from 'react';

import copy from 'copy-text-to-clipboard';

import { Button, Tooltip } from '@douyinfe/semi-ui';
import { Icon } from '@/components';
import classNames from 'classnames';

interface ContainerProps {
    children: React.ReactNode
  }

export const Container: React.FC<ContainerProps> = ({ children }) => (
  <div style={{
    padding: '36px 32px', background: '#FAFBFC', minHeight: '100%', height: 'auto'
  }}
  >
    {children}
  </div>
);

interface TextContentProps {
  children: React.ReactNode
}

export const TextContent: React.FC<TextContentProps> = ({ children }) => (
  <p style={{ marginTop: '16px', color: '#A1AAB0' }}>
    {children}
  </p>
);

type CopyButtonProps = {
  content: string
  ml?: number | string
  size?: number
  style?: React.CSSProperties,
  className?: string
}

export const CopyButton: React.FC<CopyButtonProps> = ({
  size, content, ml, className, style = {}, children
}) => {
  const [copied, setCopied] = useState<boolean>(false);

  const handleCopy = useCallback(() => {
    setCopied(true);
    copy(content);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [content]);
  return (
    <Tooltip
      className="copied-tooltip"
      position="top"
      content="Copied"
      visible={copied}
      trigger="custom"
      showArrow={false}
      spacing={4}
    >
      <Button
        size="small"
        theme="light"
        style={{
          height: size || 36,
          width: size || 36,
          border: '1px solid #D1D7DD',
          marginLeft: ml,
          ...style
        }}
        className={classNames('btn-copy hover:bg-grey100 bg-transparent', className)}
        icon={<Icon icon="common/copy-v2" className="icon" />}
        onClick={handleCopy}
      >
        {children}
      </Button>
    </Tooltip>
  );
};
