import './index.scss';
import { Icon } from '@/components';
import { ConfigurationForm } from '@/model/Integration';
import SyncBreadcrumbHeader from '../SyncBreadcrumbHeader';

interface IntegrationEditPageHeaderProps {
  integrationConfig?: ConfigurationForm | null;
}

/**
 * Common commponent of IntegrationCreation & IntegrationDetail
 */
export default function IntegrationEditPageHeader({
  integrationConfig
}: IntegrationEditPageHeaderProps) {
  const integrationId = 1;

  const breadcrumbItems = [{
    href: '/sync/integrations',
    name: 'Integration'
  }, {
    href: `/sync/integrations/${integrationId}`,
    name: integrationConfig?.introduction?.title || 'New Integration'
  }];

  return (
    <SyncBreadcrumbHeader breadcrumbItems={breadcrumbItems} title="Integrations">
      {integrationConfig && (
        <div className="integration-detail-header-main">
          <div className="left">
            <Icon className="icon w-[28px] h-[28px]" icon={`sync/integrations/engine/${integrationConfig?.introduction.logo}`} />
            <div className="title">{integrationConfig?.introduction?.title}</div>
          </div>
          <a className="right" href="https://docs.chainbase.com/docs/sync-overview">
            <span className="icon-wrapper">
              <Icon className="icon" icon="common/docs" />
            </span>
            <span className="title">
              Docs
            </span>
          </a>
        </div>
      )}
    </SyncBreadcrumbHeader>
  );
}
