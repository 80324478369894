/* eslint-disable react/jsx-closing-tag-location */
import ActivateFieldsToSyncCard from '@/views/sync/commons/ActivateFieldsToSyncCard';
import { Button, Form } from '@douyinfe/semi-ui';
import React, { useRef } from 'react';
import './index.scss';
import usePrefixedTranslation from '@/utils/usePrefixedTranslation';
import SolidToast from '@/components/Toast/SolidToast';
import { JobData } from '@/model/Sync';
import { Modal, Toast } from '@/components';
import SyncFrequencyFormCard from '@/views/sync/commons/SyncFrequencyFormCard';
import { useDeleteJob, useUpdateJob } from '@/views/sync/utils/JobRequestHooks';
import { useNavigate } from 'react-router-dom';
import useSyncFrequencyInitValue from '@/views/sync/commons/SyncFrequencyFormCard/utils/useSyncFrequencyInitValue';

interface ConfigurationsProps {
  jobData: JobData,
  metadata: any,
}

export default function Configurations({ jobData, metadata }: ConfigurationsProps): React.ReactElement {
  const tConfiguration = usePrefixedTranslation('sync.jobs.detail.configurations.');
  const { isDeleting, deleteJob } = useDeleteJob(jobData.job_id);
  const { isUpdating, updateJob } = useUpdateJob(jobData.job_id);
  const syncFrequencyFormInitVal = useSyncFrequencyInitValue(jobData);
  const nav = useNavigate();
  const formRef = useRef<Form>(null);

  const onDeleteClick = () => {
    Modal.info({
      title: 'Confirm deletion',
      content: (<div className="mt-8 mb-10 font-normal text-sm">
        <div>Are you sure to delete the job?</div>
      </div>),
      okText: 'Delete',
      cancelText: 'Cancel',
      okButtonProps: {
        type: 'danger',
        theme: 'borderless',
        className: 'job-detail-modal-button'
      },
      cancelButtonProps: {
        className: 'job-detail-modal-cancel-button job-detail-modal-button'
      },
      onOk() {
        deleteJob({ jobId: jobData.job_id }, {
          onSuccess: () => {
            SolidToast.success(tConfiguration('deleteSuccessfully'));
            nav('/sync/jobs');
          },
          onError: () => {
            Toast.error(tConfiguration('deleteFailed'));
          }
        });
      }
    });
  };

  const onSaveClick = () => {
    Modal.info({
      title: 'Confirm updation',
      content: (<div className="mt-8 mb-10 font-normal text-sm">
        <div>Are you sure to update the job?</div>
      </div>),
      okText: 'Save',
      cancelText: 'Cancel',
      okButtonProps: {
        theme: 'solid',
        className: 'job-detail-modal-button'
      },
      cancelButtonProps: {
        className: 'job-detail-modal-cancel-button job-detail-modal-button'
      },
      onOk() {
        const formValues = formRef.current?.formApi.getValues();
        if (!formValues) {
          return;
        }
        updateJob({ jobId: jobData.job_id, data: formValues }, {
          onSuccess: () => {
            SolidToast.success('Successfully updated job.');
            nav('/sync/jobs');
          },
          onError: () => {
            Toast.error(tConfiguration('Failed to update job.'));
          }
        });
      }
    });
  };

  return (
    <div className="job-detail-configuration-container">
      <Form ref={formRef}>
        <SyncFrequencyFormCard
          initialValue={syncFrequencyFormInitVal}
        />
        <ActivateFieldsToSyncCard
          editable={false}
          blockchains={metadata?.data?.chain}
          tableName={jobData.source_table}
          integration={jobData.target}
          fields={metadata?.data?.column?.map((col:any) => col.name) ?? []}
          initialSelectedFields={Object.keys(jobData.field_map)}
          initialFieldsDesitination={jobData.field_map}
        />
        <div className="bottom-button-group">
          <Button
            type="danger"
            theme="light"
            onClick={onDeleteClick}
            loading={isDeleting}
          >
            {tConfiguration('delete')}
          </Button>
          <Button
            theme="solid"
            onClick={onSaveClick}
            loading={isUpdating}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}
