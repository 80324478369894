import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { history } from '../route';

const Request = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN || '/api'
} as AxiosRequestConfig);

Request.interceptors.response.use(
  (response): AxiosResponse => {
    // hook ajax response
    const { IgnoreAuth } = response.config?.headers || {};

    if (response.data.code === 100001 && !IgnoreAuth) {
      if (!window.location.pathname.startsWith('/login')) {
        history.replace('/login');
      }
      throw new Error('Login expired');
    }
    return response;
  },
  (error) => Promise.reject(error)
);

export const CommonRequest = axios.create({});

export default Request;
