import { HeaderTitle, Icon } from '@/components';
import { Button } from '@douyinfe/semi-ui';
import './index.scss';
import WrappedTooltip from '@/components/Tooltip';

interface SyncOverviewHeaderProps {
  title: string;
  description: string;
  buttonContent: string;
  buttonDisabled?: boolean;
  buttonTooltip?: string;
  onButtonClick: () => void;
}

export default function SyncOverviewHeader({
  title,
  description,
  buttonContent,
  buttonDisabled = false,
  buttonTooltip,
  onButtonClick
}: SyncOverviewHeaderProps): React.ReactElement {
  return (
    <header className="sync-overview-header">
      <HeaderTitle title={title} />
      <div>
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
      <div className="tail">
        <div>
          <a className="doc-link" href="https://docs.chainbase.com/docs/sync-overview">
            <Icon icon="sync/jobs/overview/docs" />
            <span>Docs</span>
          </a>
        </div>
        <div>
          <WrappedTooltip disabled={!buttonTooltip} content={buttonTooltip} position="leftBottom">
            <Button
              theme="solid"
              className="new-job-button"
              onClick={onButtonClick}
              disabled={buttonDisabled}
            >
              <Icon icon="sync/jobs/overview/add" />
              <span>{buttonContent}</span>
            </Button>
          </WrappedTooltip>
        </div>
      </div>
    </header>
  );
}
