import { AxiosResponse } from 'axios';
import Request from '@/api/Request';
import { Response } from '@/model';
import { Project, ProjectMonitor, ProjectJWT } from '@/model/Project';
import { Page } from '@/model/Request';

export default class ProjectApi {
  static getList(page?: Page): Promise<AxiosResponse<Response<Project[]>>> {
    return Request.get('/v2/projects', {
      params: page
    });
  }

  static getById(id: string): Promise<AxiosResponse<Response<Project[]>>> {
    return Request.post('/v2/projects/by_ids', {
      ids: [id]
    });
  }

  static create(data: {
    name: string,
    description?: string
  }): Promise<AxiosResponse<Response<Project>>> {
    return Request.post('/v2/projects', data);
  }

  static update(data: {
    uuid: string,
    name: string,
    description?: string
  }): Promise<AxiosResponse<Response<Project>>> {
    return Request.put('/v2/projects', data);
  }

  static delete(uuid: string): Promise<AxiosResponse<Response>> {
    return Request.delete(`/v2/projects/${uuid}`);
  }

  static getMonitor({
    ids
  }: {
    ids: Array<string>
  }): Promise<AxiosResponse<Response<Array<ProjectMonitor>>>> {
    return Request.post('/v2/projects/monitor', {
      ids
    });
  }

  static getJWTList(projectId: string): Promise<AxiosResponse<Response<ProjectJWT[]>>> {
    return Request.get('/v2/projects/jwt', {
      params: {
        project_id: projectId
      }
    });
  }

  static createJWT(
    projectId: string,
    publicKeyName: string,
    publicKey: string
  ): Promise<AxiosResponse<Response<ProjectJWT>>> {
    return Request.post('/v2/projects/jwt', {
      projectId,
      publicKeyName,
      publicKey
    });
  }

  static toggleJWT(projectId: string, EnableJWT: boolean):Promise<AxiosResponse<Response<Project>>> {
    return Request.post('/v2/projects/jwt/switch', {
      projectId,
      EnableJWT
    });
  }

  static deleteJWT(id: string): Promise<AxiosResponse<Response<null>>> {
    return Request.delete('/v2/projects/jwt', {
      params: {
        id
      }
    });
  }

  static updateWhiteListIps(uuid: string, ips: string[]): Promise<AxiosResponse<Response<Project>>> {
    return Request.post('/v2/projects/whitelist', {
      uuid,
      ips: ips.join(',')
    });
  }

  static updateWhiteListDomains(uuid: string, domains: string[]): Promise<AxiosResponse<Response<Project>>> {
    return Request.post('/v2/projects/whitelist', {
      uuid,
      domains: domains.join(',')
    });
  }
}
