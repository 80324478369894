import { AxiosRequestConfig, AxiosResponse } from 'axios';
import _ from 'lodash';
import Request from './Request';
import CookieUtils from '../utils/CookieUtils';

export default class AccountApi {
  static register(data: Record<string, any>): Promise<AxiosResponse> {
    return Request.post('/v1/account/register', data);
  }

  static resendEmail(data: any): Promise<AxiosResponse> {
    return Request.post('/v1/account/register/resend', data);
  }

  static login(data: {
    username: string;
    password: string;
  }): Promise<AxiosResponse> {
    return Request.post('/v1/account/login', data, {
      withCredentials: true
    });
  }

  static logout(): Promise<AxiosResponse> {
    return Request.post('/v1/account/logout')
      .then((response) => {
        // clear cookie
        if (response.data.code === 200) {
          const cookies = CookieUtils.getAll();
          const cookieKeys = _.keys(cookies);

          const removeKeys = _.filter(
            cookieKeys,
            (item) => (item.startsWith('chainbase_'))
          );

          _.forEach(removeKeys, (item) => {
            CookieUtils.remove(item);
          });
        }

        return response;
      });
  }

  static loginByWallet(data: {
    username: string;
    password: string;
  }): Promise<AxiosResponse> {
    return Request.post('/v1/account/login_by_wallet', data, {
      withCredentials: true
    });
  }

  static sendResetEmail(data: {
    email: string;
  }): Promise<AxiosResponse> {
    return Request.post('/v1/account/reset_password', data);
  }

  static resetPassword(data: {
    password: string,
    confirm_code: string
  }): Promise<AxiosResponse> {
    return Request.post('/v1/account/reset_password/submit', data);
  }

  static currentUserInfo(config: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    return Request.get('/v2/account/current', config);
  }

  static changePassword(data: {
    password: string,
    new_password: string
  }): Promise<AxiosResponse> {
    return Request.post('/v1/account/change_password', data);
  }

  static unbindWallet(): Promise<AxiosResponse> {
    return Request.post('/v1/account/unbind_wallet');
  }

  static bindWallet(data: {
    address: string,
    signature: string
  }): Promise<AxiosResponse> {
    return Request.post('/v1/account/bind_wallet', data);
  }
}
