import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { BlockLoading } from '@/components/Loading/BlockLoading';
import { useAuth } from './AuthProvider';

/**
 * Source -> https://juejin.cn/post/7075948824845352990
 */
export default function RequireAuth({ children }: { children: React.ReactNode }): JSX.Element {
  const auth = useAuth();

  const location = useLocation();

  if (auth && auth.authLoading && !auth.user) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <BlockLoading />
      </div>
    );
  }

  if (!auth || !auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children as React.ReactElement;
}
