import React from 'react';
import { Icon } from '@/components';
import { Banner } from '@douyinfe/semi-ui';
import { BannerProps } from '@douyinfe/semi-ui/banner';
import './index.scss';

export default function ErrorBanner({ description, ...reset }: BannerProps): React.ReactElement {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Banner
      {...reset}
      icon={<Icon className="icon" icon="dataCloud/error" />}
      closeIcon={<Icon className="icon" icon="common/close" />}
      className="query-result-error"
      type="danger"
      description={description}
    />
  );
}
