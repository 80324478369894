import React from 'react';
import { Col, Row } from '@douyinfe/semi-ui';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import { useLoadingCallback, Numbers, DateUtils } from '@/utils';
import { ChartQueryData, TimeRange } from '@/props';
import DashboardApi from '@/api/DashboardApi';
import { useAppDispatch } from '@/store/hooks';
import {
  fetchProjectList,
  projectList
} from '@/store/reducers/constantReducer';
import {
  Container, HeaderTitle, Icon,
  Select
} from '@/components';
import './index.scss';
import { TimeOptions } from '@/config/constant';
import Layout from '@/components/Layout/Layout';
import classNames from 'classnames';
import ProjectList from '@/views/dashboard/index/ProjectList';
import DataItem, { DataItemAttributes } from './DataItem';
import RequestChartBlock from './RequestChartBlock';
import QuickStartModal from './QuickStart/QuickStartModal';

type DashboardInfo = {
  request_count: number;
  median_response: number;
  success_per: number;
  invalid_count: number;
  average_qps: number;
  limited_count: number;

  query_data: ChartQueryData;
}

export default function Dashboard(): React.ReactElement {
  const { Header } = Layout;

  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();
  const projects = useSelector(projectList);

  const [
    dashboardInfo, setDashboardInfo
  ] = React.useState<DashboardInfo | undefined>(undefined);

  const [timeRange, setTimeRange] = React.useState<TimeRange>('1d');
  const [application, setApplication] = React.useState<string>('all');
  const plan = useSelector((state: any) => state?.settingBilling?.current);

  const {
    callback: loadDashboardInfo,
    loading
  } = useLoadingCallback(async (data: any): Promise<void> => {
    const response = await DashboardApi.getDashboardInfo(data);
    const dashboardInfoData = response.data as DashboardInfo;
    setDashboardInfo(dashboardInfoData);
  });

  React.useEffect(() => {
    dispatch(fetchProjectList());
  }, [dispatch]);

  React.useEffect(() => {
    loadDashboardInfo({
      begin_at: DateUtils.calcBeginTime(timeRange),
      application
    }).then();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRange, application]);

  React.useEffect(() => {
    ReactGA.gtag('event', 'conversion', {
      send_to: 'AW-11166775498/QKlqCKeJx7AYEMrx3cwp'
    });
  }, []);

  const timeOptions = (plan?.subscription_plan || 0) > 0 ? TimeOptions : _.xorWith(TimeOptions, ['7d']);

  const DataItems: DataItemAttributes[] = [
    {
      icon: 'dashboard/requestCount',
      title: t('dashboard.data.requestCount'),
      value: Numbers.formatFractionDigits(dashboardInfo?.request_count || 0),
      color: 'info'
    },
    {
      icon: 'dashboard/averageResponse',
      title: t('dashboard.data.medianResponse'),
      value: `${dashboardInfo?.median_response || 0}ms`,
      color: 'info'
    },
    {
      icon: 'dashboard/qps',
      title: t('dashboard.data.averageQPS'),
      value: Numbers.formatFractionDigits(dashboardInfo?.average_qps || 0, 1),
      color: 'success',
      className: 'assist'
    },
    {
      icon: 'dashboard/success',
      title: `${t('dashboard.data.success')} %`,
      value: `${dashboardInfo?.success_per || 100}%`,
      color: 'success'
    }
  ];

  const handleChangeTimeRange = (value: any): void => {
    setTimeRange(value as TimeRange);
  };

  return (
    <Container className="dashboard-container max-w-none">
      <HeaderTitle title={t('dashboard.title')} />

      <Header
        icon="navigation/dashboard"
        title={t('dashboard.title')}
      >
        <Row type="flex" align="middle" className="filter-group" gutter={8}>
          <Col>
            <Select
              defaultValue="all"
              dropdownClassName="center"
              onChange={(value) => {
                setApplication(value as string);
              }}
              prefix={<Icon icon="dashboard/project" />}
            >
              <Select.Option key="all" value="all">
                {t('dashboard.allApplications')}
              </Select.Option>
              {
                _.map(projects, (item) => (
                  <Select.Option
                    key={item.uuid}
                    value={item.uuid}
                  >
                    {item.name}
                  </Select.Option>
                ))
              }
            </Select>
          </Col>

          <Col>
            <Select
              className="select-time capitalize"
              dropdownClassName="center"
              defaultValue={timeRange}
              onChange={handleChangeTimeRange}
              prefix={<Icon icon="dashboard/time" />}
            >
              {
                _.map(timeOptions, (item) => (
                  <Select.Option key={item} value={item} className="capitalize">
                    {t(`dashboard.times.${item}`)}
                  </Select.Option>
                ))
              }
            </Select>
          </Col>
        </Row>
      </Header>

      <Row className={classNames('flex-wrap', 'dashboard-info')}>
        <Col span={12}>
          <Row type="flex">
            {
              _.map(DataItems, (item, index) => (
                <Col key={index} span={12}>
                  <DataItem
                    loading={loading}
                    className={item?.className}
                    data={item}
                    time={timeRange}
                  />
                </Col>
              ))
            }
          </Row>
        </Col>
        <Col span={12}>
          <RequestChartBlock
            loading={loading}
            data={dashboardInfo?.query_data}
            timeRange={timeRange}
          />
        </Col>
      </Row>

      <ProjectList />

      <QuickStartModal />
    </Container>
  );
}
