import { AxiosResponse } from 'axios';
import Request from '@/api/Request';
import { SearchPage } from '@/model/Request';

export default class CustomApi {
  static create(data: any): Promise<AxiosResponse> {
    return Request.post('/v2/datacloud/customapi', data);
  }

  static update(data: any): Promise<AxiosResponse> {
    return Request.put('/v2/datacloud/customapi', data);
  }

  static getList(query: SearchPage): Promise<AxiosResponse> {
    return Request.get('/v2/datacloud/customapi', {
      params: query
    });
  }

  static detail(id: string): Promise<AxiosResponse> {
    return Request.get(`/v2/datacloud/customapi/${id}`);
  }

  static info(): Promise<AxiosResponse> {
    return Request.get('/v2/datacloud/customapi/hash');
  }

  static delete(id: number): Promise<AxiosResponse> {
    return Request.delete(`/v2/datacloud/customapi/${id}`);
  }

  static request(userHash: string, name: string, data: any): Promise<AxiosResponse> {
    return Request.post(`/v2/datacloud/customapi/${userHash}/${name}`, data);
  }
}
