export default class LocalStorage {
  static set<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static get<T>(key: string, defaultValue?: T | undefined): T | undefined {
    const value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(value) as T;
    }
    return defaultValue;
  }

  static remove(key: string): void {
    localStorage.removeItem(key);
  }
}
