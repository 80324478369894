import useStatus, { StatusIndicator } from '@/utils/hooks/request/useStatus';
import Icon from '../Icon';

const IndicatorColors: Record<StatusIndicator, string> = {
  none: '#5DBD5B',
  minor: '#EE9F2A',
  major: '#C6362C',
  critical: '#C6362C'
};
const DefaultDescription = 'All Systems Operational';

export default function StatusButton() {
  const { data } = useStatus();
  const description = data?.status.description || DefaultDescription;
  const color = IndicatorColors[data?.status.indicator ?? 'none'];

  return (
    <button
      type="button"
      className="w-full py-[9px] flex items-center justify-center gap-2 bg-[#2E363A] border border-[#444D56] border-solid rounded hover:bg-[#373F46]"
      onClick={() => window.open('https://status.chainbase.com/')}
    >
      <Icon className="w-2 h-2" icon="navigation/status" style={{ fill: color }} />
      <span className="font-medium text-xs text-[#E3E7EC]">
        {description}
      </span>
    </button>
  );
}
