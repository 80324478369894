import React from 'react';
import { Icon } from '@/components';
import { useTranslation } from 'react-i18next';
import { Button } from '@douyinfe/semi-ui';

type TableEmptyProps = {
  className?: string;
  icon?: string;
  children?: React.ReactElement;
  onClick: () => void;
}

export default function DataCloudNoProject({
  icon,
  onClick
}: Partial<TableEmptyProps>): React.ReactElement {
  const { t } = useTranslation('translation');
  return (
    <div className="flex flex-col items-center justify-center flex-grow h-fit absolute bottom-[50px] left-[20px] w-[440px]">
      <Icon className="w-20 h-20 -mt-[10vh]" icon={icon || 'table/empty'} />

      <h3 className="text-2xl mt-10">{t('sync.webhook.noProjectTitle')}</h3>
      <p className="text-base mt-4 text-[#a4aab0]">
        The saved query to run Task-API required a project (API-Key), Please create one to continue
      </p>
      <Button
        theme="solid"
        className="h-12 text-lg mt-10"
        onClick={() => {
          if (onClick) { onClick(); }
        }}
      >
        {t('sync.webhook.noProjectBtn')}
      </Button>
    </div>
  );
}
