/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable space-infix-ops */
import SyncApi from '@/api/SyncApi';
import useSWR, { Arguments, mutate } from 'swr';
import useSWRMutation from 'swr/mutation';
import useSWRImmutable from 'swr/immutable';
import TaskApi from '@/api/TaskApi';
import { GenerateIntegrationDTO, JobData, UpdateIntegrationDTO } from '@/model/Sync';
import SolidToast from '@/components/Toast/SolidToast';
import { Toast } from '@/components';
import { Integration } from '@/model/Integration';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export const SWREmptyKey = 'SWREmptyKey';
export const ListJobKey = 'initialSyncData';
export const GetJobKey = 'getJob';
export const ListJobHistoryKey = 'listJobHistory';
export const GetBatchJobLogKey = 'getBatchJobLog';
export const ListIntegrationKey = 'listIntegration';
export const IsWhiteListedKey = 'isWhiteListed';

export const useIntegrationList = () => {
  const { data, isLoading } = useSWR(ListIntegrationKey, async () => {
    const axiosResponse = await SyncApi.listIntegrations();
    return axiosResponse.data.data || [];
  });

  return {
    integrationList: data,
    isFetchingIntegrationList: isLoading,
    isEmpty: data ? data.length === 0 : true
  };
};

export const useJobList = () => {
  const { data, error, isLoading } = useSWR(
    ListJobKey,
    async () => {
      const axiosResponse = await SyncApi.listJobs();
      return axiosResponse.data.data;
    },
    {
      shouldRetryOnError(err) {
        if (err?.response?.status === 403) {
          return false;
        }
        return true;
      }
    }
  );

  return {
    data,
    isEmpty: data ? data.length === 0 : true,
    error,
    isLoading
  };
};

interface ToggleJobMutateArg {
  newState: boolean;
}

export const useRunJob = (
  jobId: string,
  config?: { onSuccess?: () => void; onFailed?: () => void }
) => {
  const toggleJobMutate = () => {
    mutate((key: Arguments) => {
      if (key === ListJobKey) {
        return true;
      }

      if (Array.isArray(key)) {
        const [actionKey, actionValue] = key;

        if (actionKey === GetJobKey && actionValue === jobId) {
          return true;
        }

        if (actionKey === ListJobHistoryKey) {
          return true;
        }
      }
      return false;
    });
  };

  const { data, error, isMutating, trigger } = useSWRMutation(
    SWREmptyKey,
    (_key, { arg }: { arg: ToggleJobMutateArg }) => {
      const { newState } = arg;
      return newState ? SyncApi.runJob(jobId) : SyncApi.stopJob(jobId);
    },
    {
      onSuccess: () => {
        toggleJobMutate();
        if (config?.onSuccess) {
          config.onSuccess();
        }
      },
      onError: (err, key) => {
        if (config?.onFailed) {
          config.onFailed();
        }
        console.log(err, key);
      }
    }
  );

  const runJob = (newState: boolean) => {
    trigger({ newState });
  };

  return { data, error, isMutating, runJob };
};

export const useJob = (jobId?: string) => {
  const { data, error, isLoading } = useSWR(
    jobId ? [GetJobKey, jobId] : null,
    async ([, _jobId]) => {
      const response = await SyncApi.getJob(_jobId);
      return response.data.data;
    }
  );

  return { data, error, isLoading };
};

export const useJobHistory = (jobId?: string) => {
  const { data, error, isLoading } = useSWR(
    jobId ? [ListJobHistoryKey, jobId] : null,
    async ([, _jobId]) => {
      const axiosResponse = await SyncApi.listJobHistory(_jobId);
      return axiosResponse.data.data;
    }
  );

  return { data, error, isLoading };
};

export const useJobDetail = (jobId?: string) => {
  const {
    data: jobData,
    error: jobError,
    isLoading: isFetchingJob
  } = useJob(jobId);

  const {
    data: jobHistoryData,
    error: jobHistoryError,
    isLoading: isFetchingJobHistory
  } = useJobHistory(jobId);

  const { data: metadataResponse, error: metadataError } = useSWRImmutable(
    jobData?.source_table ? ['getMetadata', jobData.source_table] : null,
    ([, _sourceTable]) => TaskApi.getMetadata(_sourceTable)
  );

  const metadata = metadataResponse?.data;

  return {
    jobData,
    jobHistoryData,
    metadata,
    jobError,
    jobHistoryError,
    metadataError,
    isLoading: isFetchingJob || isFetchingJobHistory
  };
};

export const useCreateJob = () => {
  const nav = useNavigate();
  const { isMutating, trigger } = useSWRMutation(
    ListJobKey,
    async (_key, { arg }: { arg: { data: Record<string, any> } }) => {
      const axiosResponse = await SyncApi.generateJob(arg.data);
      return axiosResponse.data;
    },
    {
      onSuccess() {
        SolidToast.success('Configurations saved successfully');
        nav('/sync/jobs');
      },
      onError(error) {
        if (axios.isAxiosError(error)) {
          Toast.error(error.response?.data?.msg ?? 'Failed to create job');
        } else if (typeof error?.message === 'string') {
          Toast.error(error.message);
        } else {
          Toast.error('Failed to create job');
        }
      },
      populateCache(result, currentData?: JobData[] | false) {
        if (result.code === 0) {
          const oldData = currentData || [];
          return [...oldData, result.data];
        }
        return currentData ?? [];
      }
    }
  );

  return {
    isCreating: isMutating,
    createJob: (data: Record<string, any>) => trigger({ data })
  };
};

export const useDeleteJob = (jobId?: string) => {
  const { isMutating, trigger } = useSWRMutation(
    ListJobKey,
    (_key, { arg }: { arg: { jobId: string } }) => {
      const { jobId: _jobId } = arg;
      return SyncApi.deleteJob(_jobId);
    },
    {
      populateCache(_, currentData?: JobData[]) {
        if (currentData) {
          return currentData.filter((item) => item.job_id !== jobId);
        }
        return [];
      }
    }
  );

  return { isDeleting: isMutating, deleteJob: trigger };
};

export const useUpdateJob = (jobId?: string) => {
  const { isMutating, trigger } = useSWRMutation(
    jobId ? [GetJobKey, jobId] : null,
    async (
      _key,
      { arg }: { arg: { jobId: string; data: Partial<JobData> } }
    ) => {
      const response = await SyncApi.updateJob(arg.jobId, arg.data);
      return response.data.data;
    },
    {
      populateCache: true
    }
  );

  return {
    isUpdating: isMutating,
    updateJob: trigger
  };
};

interface DeleteBatchJobHistoryArg {
  arg: {
    batchJobId: string;
  };
}

export const useDeleteBatchJobHistory = (jobId?: string) => {
  const key = jobId ? [ListJobHistoryKey, jobId] : null;
  const { isMutating, trigger } = useSWRMutation(
    ListJobHistoryKey,
    async (_key, { arg }: DeleteBatchJobHistoryArg) => {
      const { batchJobId } = arg;
      await SyncApi.deleteBatchJobHistory(batchJobId);
    },
    {
      onSuccess() {
        mutate(key);
      }
    }
  );

  return {
    isDeletingBatchJobHistory: isMutating,
    deleteBatchJobHistory: trigger
  };
};

export const useGetBatchJobLog = (batchJobId?: string) => {
  const { data, isLoading } = useSWR(
    batchJobId ? [GetBatchJobLogKey, batchJobId] : null,
    async ([, _batchJobId]) => {
      const response = await SyncApi.getBatchJobLog(_batchJobId);
      const content: string[] =
        response.data?.data?.map((row: any) => {
          const logContent = row?.content ?? '';
          const timestamp = row?.created_at ?? '';
          return `[${timestamp}] ${logContent}`;
        }) ?? [];
      return content.join('\n');
    }
  );

  return { data, isLoading };
};

export const useEnalbeJob = (jobId: string) => {
  const { isMutating, trigger } = useSWRMutation(
    [GetJobKey, jobId],
    async () => {
      await SyncApi.updateJob(jobId, {
        status: 1
      });
    },
    {
      onSuccess() {
        SolidToast.success('Job enabled.');
      },
      onError() {
        Toast.error('Failed to enable job.');
      },
      // @ts-ignore
      populateCache(_result, currentData?: JobData) {
        if (!currentData) return undefined;
        currentData.status = 1;
        return currentData;
      }
    }
  );

  return {
    isEnabling: isMutating,
    enableJob: trigger
  };
};

export const useDisableJob = (jobId: string) => {
  const { isMutating, trigger } = useSWRMutation(
    [GetJobKey, jobId],
    async () => {
      await SyncApi.updateJob(jobId, {
        status: -1
      });
    },
    {
      onSuccess() {
        SolidToast.success('Job disabled.');
      },
      onError() {
        Toast.error('Failed to disable job.');
      },
      // @ts-ignore
      populateCache(_result, currentData?: JobData) {
        if (!currentData) return undefined;
        currentData.status = -1;
        return currentData;
      }
    }
  );

  return {
    isDisabling: isMutating,
    disableJob: trigger
  };
};

export const useCreateIntegration = (
  onCreated: (integrationId: string) => void
) => {
  const { trigger, isMutating } = useSWRMutation(
    ListIntegrationKey,
    async (_key, { arg }: { arg: GenerateIntegrationDTO }) => {
      const response = await SyncApi.generateIntegration(arg);
      return response.data.data;
    },
    {
      onError() {
        Toast.error('Failed to save configurations');
      },
      onSuccess(data) {
        Toast.success('Ok');
        onCreated(data.integration_id);
      }
    }
  );

  return { trigger, isMutating };
};

export const useDeleteIntegration = () => {
  const nav = useNavigate();
  const { trigger, isMutating } = useSWRMutation(
    ListIntegrationKey,
    async (_key, { arg }: { arg: string }) => {
      const response = await SyncApi.deleteIntegration(arg);
      if (response.status === 200) {
        return arg;
      }
      return null;
    },
    {
      onError() {
        Toast.error('Failed to delete');
      },
      onSuccess() {
        SolidToast.success('Deleted');
        nav('/sync/integrations');
      },
      populateCache(result, currentData?: Integration[]) {
        const newData = currentData || [];
        if (result) {
          return newData.filter((item) => item.integration_id !== result);
        }
        return newData;
      }
    }
  );

  return { trigger, isMutating };
};

export const useUpdateIntegration = (integrationId: string) => {
  const { isMutating, trigger } = useSWRMutation(
    ListIntegrationKey,
    async (_key, { arg }: { arg: UpdateIntegrationDTO }) => {
      const response = await SyncApi.updateIntegration(integrationId, arg);
      return response;
    },
    {
      onSuccess() {
        SolidToast.success('Saved');
      },
      onError(err) {
        if (axios.isAxiosError(err)) {
          Toast.error(err.response?.data?.msg ?? 'Failed to save integration.');
        } else if (typeof err?.message === 'string') {
          Toast.error(err.message);
        } else {
          Toast.error('Failed to save integration.');
        }
      }
    }
  );

  return { isMutating, trigger };
};
