import { v4 } from 'uuid';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { readCookie, setCookie } from './cookie';

function getDomain(domain: string): string {
  const parts = domain.split('.');
  if (parts.length < 2) {
    return `.${domain}`;
  }
  return `.${parts.slice(-2).join('.')}`;
}

const getClientId = (): string => {
  let clientId = readCookie('clientId');
  if (clientId) {
    localStorage.setItem('clientId', clientId);
  }

  if (!clientId) {
    clientId = localStorage.getItem('clientId');
    if (clientId) {
      setCookie('clientId', clientId, {
        domain: getDomain(window.location.host)
      });
    }
  }
  if (!clientId) {
    clientId = v4();
    localStorage.setItem('clientId', clientId);
    setCookie('clientId', clientId, {
      domain: getDomain(window.location.host)
    });
  }
  return clientId;
};

const getSessionId = (): string => {
  let sessionId = sessionStorage.getItem('sessionId');
  if (!sessionId) {
    sessionId = v4();
    sessionStorage.setItem('sessionId', sessionId);
  }
  return sessionId;
};

export const reportEvent = (
  eventName: string,
  options: Record<string, string | number>
): void => {
  const clientId = getClientId();
  const sessionId = getSessionId();
  fetch('/api/v2/event/report', {
    method: 'POST',
    body: JSON.stringify({
      event_name: eventName,
      referer: document.referrer,
      origin: window.location.host,
      path: window.location.pathname,
      query: window.location.search,
      session_id: sessionId,
      client_id: clientId,
      ...options
    })
  });
};

const useTrack = (): void => {
  const location = useLocation();
  const preUrlRef = useRef('');
  const enterTimeRef = useRef(0);

  useEffect(() => {
    const clientId = getClientId();
    const sessionId = getSessionId();

    if (preUrlRef.current === '') {
      enterTimeRef.current = Date.now();

      fetch('/api/v2/event/report', {
        method: 'POST',
        body: JSON.stringify({
          event_name: 'pageView',
          referer: document.referrer,
          origin: window.location.host,
          path: location.pathname,
          query: location.search,
          session_id: sessionId,
          client_id: clientId
        })
      });
      preUrlRef.current = location.pathname;
    }
    if (location.pathname !== preUrlRef.current) {
      const durationTime = Date.now() - enterTimeRef.current;
      const referer = preUrlRef.current;
      enterTimeRef.current = Date.now();
      fetch('/api/v2/event/report', {
        method: 'POST',
        body: JSON.stringify({
          event_name: 'pageDuration',
          origin: window.location.host,
          path: location.pathname,
          query: location.search,
          session_id: sessionId,
          client_id: clientId,
          metric1: Math.floor(durationTime / 1000)
        })
      });
      preUrlRef.current = location.pathname;
      fetch('/api/v2/event/report', {
        method: 'POST',
        body: JSON.stringify({
          event_name: 'pageView',
          referer,
          origin: window.location.host,
          path: location.pathname,
          query: location.search,
          session_id: sessionId,
          client_id: clientId
        })
      });
    }
  }, [location]);

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      const clientId = getClientId();
      const sessionId = getSessionId();
      const durationTime = Date.now() - enterTimeRef.current;
      enterTimeRef.current = Date.now();
      navigator.sendBeacon(
        '/api/v2/event/report',
        JSON.stringify({
          event_name: 'pageDuration',
          origin: window.location.host,
          path: location.pathname,
          query: location.search,
          session_id: sessionId,
          client_id: clientId,
          metric1: Math.floor(durationTime / 1000)
        })
      );
    });
  }, [location]);
};

export default useTrack;
