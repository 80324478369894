import React from 'react';
import { Form, Select } from '@douyinfe/semi-ui';
import _ from 'lodash';
import './index.scss';
import classNames from 'classnames';
import { SelectIcon } from '@/components/Select/index';
import { Icon } from '../index';

type FormIconSelectItem = {
  icon?: string;
  label?: string;
  value: any;
}

type FormIconSelectProps = {
  list: FormIconSelectItem[],
  placeholder: FormIconSelectItem
} & React.ComponentProps<typeof Form.Select>

export default function FormIconSelect(
  props: FormIconSelectProps
): React.ReactElement {
  const {
    list,
    placeholder,
    className,
    onChange,
    initValue,
    ...other
  } = props;

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Form.Select
      arrowIcon={SelectIcon}
      {...other}
      clickToHide
      className={
        classNames(['select-icon', className])
      }
      initValue={initValue}
      dropdownClassName="select-icon"
      onChange={onChange}
      placeholder={(
        <div className="placeholder">
          {
            placeholder.icon
              ? <Icon className="icon" icon={placeholder.icon} /> : null
          }
          {placeholder.value}
        </div>
      )}
    >
      {
        _.map(list, (item) => (
          <Select.Option key={item.value} value={item.value}>
            {
              item.icon
                ? <Icon className="icon" icon={item.icon} /> : null
            }
            {item?.label || item?.value}
          </Select.Option>
        ))
      }
    </Form.Select>
  );
}
