import React from 'react';
import { Form } from '@douyinfe/semi-ui';
import _ from 'lodash';
import { optionRenderProps } from '@douyinfe/semi-ui/lib/es/select';
import { useTranslation } from 'react-i18next';
import { Icon, Select, Image } from '@/components';
import SearchButton from '@/components/Button/SearchButton';
import { FormSelect } from '@/components/Select';
import classNames from 'classnames';
import './TableSearch.scss';
import { FormApi } from '@douyinfe/semi-ui/lib/es/form';
import useSWR from 'swr';
import DataCloudStudioApi from '@/api/DataCloudStudioApi';

function BlockChainItem(item: optionRenderProps & {url?: string}): React.ReactElement {
  const {
    value,
    label,
    url
  } = item;

  return (
    <div className="item">
      {url ? <Image src={url} className="icon rounded-[50%]" />
        : (
          <Icon
            wrapper="img"
            className="icon rounded-[50%]"
            icon={`blockchain/${value === 'All' ? value : `${_.isString(value) ? value.toLowerCase() : value}`}-solid`}
          />
        )}
      <span>{label}</span>
    </div>
  );
}

type TableTreeSearchProps = {
  defaultValue?: any;
  onSearch: (values: any) => void;
};

export default function TableTreeSearch({
  defaultValue = {
    blockchain: 'all',
    keyword: ''
  },
  onSearch
}: Omit<TableTreeSearchProps, ''>): React.ReactElement {
  const { t } = useTranslation();
  const formApiRef = React.useRef<FormApi | null>(null);
  const { data } = useSWR('/v2/datacloud/metadata/blockchains', DataCloudStudioApi.getAllChains);
  const blockchains = data?.data.data ?? [];

  const getContainer = (): HTMLElement => document.querySelector('.table-navigation') as HTMLElement;
  const options = _.map(blockchains, (item) => ({
    label: item.name,
    value: item.name.toLowerCase(),
    url: item.url
  }));

  return (
    <div className="search-wrapper">
      <Form
        getFormApi={(formApi: FormApi) => {
          formApiRef.current = formApi;
        }}
        className="justify-between"
        layout="horizontal"
        initValues={defaultValue}
        labelPosition="inset"
        onSubmit={onSearch}
      >
        <FormSelect
          field="blockchain"
          noLabel
          className={classNames('select-chain', 'chain-list')}
          optionList={[
            {
              label: 'All Chains',
              value: 'all'
            },
            ...options
          ]}
          style={
            {
              width: '100%'
            }
          }
          getPopupContainer={getContainer}
          dropdownClassName="chain-list"
          /* eslint-disable react/jsx-props-no-spreading */
          renderSelectedItem={
            (item: Record<string, string>) => <BlockChainItem label={item.label} value={item.value} url={item.url} />
          }
          renderOptionItem={(item) => (
            <Select.Option
              key={item.value}
              value={item.value}
              {...item}
            >
              <BlockChainItem value={item.value} label={item.label} blockchains={blockchains} url={item.url} />
            </Select.Option>
          )}
          onChange={() => {
            formApiRef.current?.submitForm();
          }}
        />

        <Form.Input
          fieldClassName="input-table-name"
          noLabel
          showClear
          field="keyword"
          placeholder={t<string>('dataCloud.search')}
          suffix={<SearchButton htmlType="submit" />}
        />
      </Form>
    </div>
  );
}
