import React from 'react';
import { InputLabel, Toast } from '@/components';
import { useTranslation } from 'react-i18next';
import { Form } from '@douyinfe/semi-ui';
import { ArchivedQueryItem } from '@/props';
import './index.scss';
import { ModalForm } from '@/components/Modal';
import { useLoadingCallback } from '@/utils';
import ArchivedQueriesApi from '@/api/ArchivedQueriesApi';

type ArchivedQueryFormModalProps = {
  archivedQueries: ArchivedQueryItem,
  onCancel: () => void;
  onComplete: (result: ArchivedQueryItem) => void;
}

function ArchivedQueryFormModal(props: ArchivedQueryFormModalProps): React.ReactElement {
  const {
    archivedQueries,
    onCancel,
    onComplete
  } = props;
  const { t } = useTranslation(['archivedQueries', 'translation']);
  const {
    loading,
    callback: handleSubmit
  } = useLoadingCallback(async (values: Record<string, string>) => {
    let response = null;
    if (archivedQueries?.uuid) {
      response = await ArchivedQueriesApi.update({
        uuid: archivedQueries.uuid,
        title: values.title,
        description: values.description
      });
    } else {
      response = await ArchivedQueriesApi.create({
        title: values.title,
        description: values.description,
        content: archivedQueries?.content
      });
    }

    if (response.data.code !== 200) {
      Toast.toastResponse(response);
    } else {
      // toast success messsage
      const { data: result } = response.data;
      Toast.toastResponse(response);
      onComplete(result);
      onCancel();
    }
  });

  return (

    <ModalForm
      okButtonProps={
        {
          theme: 'solid',
          htmlType: 'submit'
        }
      }
      confirmLoading={loading}
      onSubmit={handleSubmit}
      okText={t(archivedQueries?.uuid ? 'common.save' : 'common.create', { ns: 'translation' })}
      okType="primary"
      cancelText={t('common.cancel', { ns: 'translation' })}
      onCancel={onCancel}
    >
      <div className="create-project-wrapper">
        <Form.Input
          field="title"
          maxLength={100}
          label={t('archivedQueries.form.name.label')}
          placeholder={t<string>('archivedQueries.form.name.placeholder')}
          autofocus
          initValue={archivedQueries?.title}
          rules={[
            {
              required: true,
              message: `${t('archivedQueries.form.name.label')} ${t('common.required', { ns: 'translation' })}`
            }
          ]}
        />

        <Form.Input
          field="description"
          maxLength={512}
          label={t('archivedQueries.form.description.label')}
          placeholder={t<string>('archivedQueries.form.description.placeholder')}
          initValue={archivedQueries?.description}
        />

        <div
          className="editor-params-wrapper"
        >
          <div
            className="semi-form-field flex-1"
            x-label-pos="top"
            x-field-id="sql"
            x-extra-pos="bottom"
          >
            <InputLabel
              label={t('archivedQueries.form.sql.label')}
            />
            <div className="sql-statement">
              <div className="medium">
                {archivedQueries?.content}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalForm>

  );
}

export declare type ArchivedQueryFormModalType =
  React.ElementRef<typeof ArchivedQueryFormModal>
  | {
  show: (value: ArchivedQueryItem) => void
};

export default ArchivedQueryFormModal;
