import SyncBreadcrumbHeader from '@/views/sync/commons/SyncBreadcrumbHeader';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useRef, useState } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { Button } from '@douyinfe/semi-ui';
import { Icon, Toast } from '@/components';
import { Theme } from '@douyinfe/semi-ui/button';
import { BlockLoading } from '@/components/Loading/BlockLoading';
import WrappedTooltip from '@/components/Tooltip';
import classNames from 'classnames';
import { FeatureName, useWhiteList } from '@/hooks/UseWhiteList';
import SetupSource from './components/setupSource';
import SetupIntegration from './components/setupIntegration';
import ConfigureJob, { ConfigureJobRef } from './components/configureJob';
import JobCreationProvider, { JobCreationContext } from './components/JobCreationProvider';
import { useCreateJob } from '../utils/JobRequestHooks';
import useJobLimitation from '../utils/useJobLimitation';
import isDB from '../utils/is-db';

const FirstStep = 1;
const LastStep = 3;

const Breadcrumbs = [{
  name: 'Jobs',
  href: '/sync/jobs'
}, {
  name: 'New Job',
  href: '/sync/jobs/new'
}];

function JobCreationContent(): React.ReactElement {
  const { t } = useTranslation();
  const tTitle = (key: string): string => t(`sync.jobs.newJobSteps.${key}.title`);
  const { checkWhitelist, openRequestModal } = useWhiteList(FeatureName.sync_service);
  const { jobCreationState } = useContext(JobCreationContext);
  const { allowedSteps, selectedIntegration, selectedTable } = jobCreationState;
  const [curStep, setCurStep] = useState(1);
  const configureJobRef = useRef<ConfigureJobRef>(null);
  const nav = useNavigate();
  const { isCreating, createJob } = useCreateJob();
  const { overLimitation, isLoading: isFetchingJobLimitation } = useJobLimitation();
  const [testTableSucceed, setTestTableSucceed] = useState(false);

  useEffect(() => {
    if (overLimitation) {
      nav('/sync/jobs');
    }
  }, [nav, overLimitation]);

  useEffect(() => {
    if (!checkWhitelist()) {
      openRequestModal();
    }
  }, [checkWhitelist, openRequestModal]);

  const back = (): void => setCurStep((current) => (current === FirstStep ? FirstStep : current - 1));
  const next = (): void => {
    if (curStep === 1 && !selectedTable) {
      Toast.info(t<string>('sync.jobs.newJobSteps.configureJob.unselectTable'));
      return;
    }

    if (curStep !== LastStep) {
      setCurStep((current) => (current === LastStep ? LastStep : current + 1));
      return;
    }

    if (isDB(selectedIntegration?.type ?? '') && !testTableSucceed) {
      Toast.info(t<string>('sync.jobs.newJobSteps.configureJob.tableTest'));
      return;
    }
    configureJobRef.current?.onSubmit();
  };

  const renderStep = (
    stepNumber:number,
    title:string,
    notice:string
  ): React.ReactElement => {
    const active = stepNumber <= curStep;
    const isDone = stepNumber < curStep;

    return (
      <div className="sync-jobs-new-header-step">
        {isDone
          ? (
            <div>
              <div className="step-done">
                <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M3.27197 7.50412L3.26888 7.50721L4.4948 8.73313L12.002 1.22591L10.7761 0L4.49789 6.27821L1.60873 3.38905L0.382812 4.61496L3.27197 7.50412Z" fill="#3089D2" />
                </svg>
              </div>
            </div>
          )
          : (
            <div>
              <div className={`step-number ${active ? 'actived' : 'unactived'}`}>
                {stepNumber}
              </div>
            </div>
          )}
        <div className="step-text">
          <div className={`step-title ${active ? 'actived' : 'unactived'}`}>
            {title}
          </div>
          <div className="step-notice">
            {notice}
          </div>
        </div>
      </div>
    );
  };

  const renderStepIndicator = (): React.ReactElement => {
    const tNotice = (key: string): string => t(`sync.jobs.newJobSteps.${key}.notice`);

    return (
      <div className="sync-jobs-step-indicator">
        {renderStep(1, tTitle('setupSource'), tNotice('setupSource'))}
        {renderStep(2, tTitle('setupIntegration'), tNotice('setupIntegration'))}
        {renderStep(3, tTitle('configureJob'), tNotice('configureJob'))}
      </div>
    );
  };

  const renderHeader = (): React.ReactElement => (
    <SyncBreadcrumbHeader title="Jobs" breadcrumbItems={Breadcrumbs}>
      {renderStepIndicator()}
    </SyncBreadcrumbHeader>
  );

  const renderContent = (): React.ReactElement => {
    switch (curStep) {
      case 1:
        return <SetupSource />;
      case 2:
        return <SetupIntegration />;
      case 3:
        return (
          <ConfigureJob
            ref={configureJobRef}
            createJob={createJob}
            onTestTableSucceedChange={setTestTableSucceed}
          />
        );
      default:
        throw new Error(`Unexpected step of ${curStep}`);
    }
  };

  const renderBottomButton = (props: {
    type: string,
    iconPosition: 'left' | 'right' | null,
    onClick: () => void,
    visible: boolean,
    theme?: Theme,
    className?: string,
    disable?: boolean,
    isLoading?: boolean
  }): React.ReactElement => {
    const icon = (
      <Icon
        className="button-content-icon"
        icon={`sync/jobs/new/${props.type.toLowerCase()}`}
      />
    );

    return (
      <WrappedTooltip
        disabled={checkWhitelist()}
        content="Without permission."
      >
        <Button
          theme={props.theme}
          onClick={props.onClick}
          className={props.className}
          style={{ visibility: props.visible ? 'visible' : 'hidden' }}
          disabled={props.disable}
          loading={props.isLoading}
        >
          {props.iconPosition === 'left' && !props.isLoading && icon}
          <span>{props.type}</span>
          {props.iconPosition === 'right' && !props.isLoading && icon}
        </Button>
      </WrappedTooltip>
    );
  };

  const renderBottomButtonGroup = (): React.ReactElement => {
    let nextStepDisabled = false;
    const nextStepLoading = isCreating;
    if (nextStepLoading) {
      nextStepDisabled = false;
    } else if (curStep === FirstStep) {
      nextStepDisabled = !checkWhitelist();
    } else if (curStep !== LastStep) {
      nextStepDisabled = allowedSteps < curStep;
    }

    return (
      <div className="bottom-buttons">
        {renderBottomButton({
          type: 'Back',
          iconPosition: 'left',
          className: 'back-button',
          visible: curStep !== FirstStep,
          onClick: back,
          isLoading: isCreating
        })}
        {renderBottomButton({
          type: curStep === LastStep ? 'Configure' : 'Continue',
          iconPosition: curStep === LastStep ? null : 'right',
          theme: 'solid',
          visible: true,
          onClick: next,
          disable: nextStepDisabled,
          isLoading: nextStepLoading
        })}
      </div>
    );
  };

  const renderMain = (): React.ReactElement => {
    const contentTitle = (): string => {
      switch (curStep) {
        case 1:
          return tTitle('setupSource');
        case 2:
          return tTitle('setupIntegration');
        case 3:
          return tTitle('configureJob');
        default:
          throw new Error(`Unexpected step of ${curStep}`);
      }
    };

    return (
      <main className={classNames('sync-new-job-main', {
        lock: curStep !== 3
      })}
      >
        <h1 className="main-title">{contentTitle()}</h1>
        {renderContent()}
        {renderBottomButtonGroup()}
      </main>
    );
  };

  const renderLoadingMask = () => (
    <div className="loading-mask">
      <BlockLoading />
    </div>
  );

  return (
    <div className="sync-new-job">
      {renderHeader()}
      {renderMain()}
      {checkWhitelist() && isFetchingJobLimitation && renderLoadingMask()}
    </div>
  );
}

export default function JobCreation(): React.ReactElement {
  return (
    <JobCreationProvider>
      <JobCreationContent />
    </JobCreationProvider>
  );
}
