import _ from 'lodash';
import { ColumnSchema, TableRow } from '@/model/Table';

export default class ExcelUtils {
  static exportCSV(
    filename: string,
    col: ColumnSchema[] = [],
    data: TableRow[] = []
  ): void {
    const keys = _.map(col, (c) => c.name);

    const dataRows = _.map(data, (row) => _.map(keys, (cell) => row[cell]));

    const rows = [keys, ...dataRows];

    const csvContent = `data:text/csv;charset=utf-8,${rows
      .map((e) => e.join(','))
      .join('\n')}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${filename}.csv`);

    // Required for FF
    document.body.appendChild(link);

    link.click();

    link.remove();
  }

  static exportCSVWithKVResult(
    filename: string,
    col: ColumnSchema[] = [],
    data: TableRow[] = []
  ): void {
    const tableHeads = col.map((c) => c.name);
    const tableData = data.map((row) => col.map((c) => row[c.key ?? '']));
    const rows = [tableHeads, ...tableData];

    const csvContent = `data:text/csv;charset=utf-8,${rows
      .map((e) => e.join(','))
      .join('\n')}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${filename}.csv`);

    // Required for FF
    document.body.appendChild(link);

    link.click();

    link.remove();
  }
}
