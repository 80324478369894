import React from 'react';
import _ from 'lodash';
import './index.scss';

export declare type Pair = {
  key: string;
  value: any
}

export declare type PayloadPreviewProps<T> = {
  list: T[];
}

export default function PayloadPreview({
  list
}: PayloadPreviewProps<Pair>): React.ReactElement {
  // TODO change virtualized list

  return (
    <div className="payload-preview">
      {
        _.map(list, (item, index) => (
          <p className="payload-item" key={`PayloadPreview:Item:${index}`}>
            <span className="key">{`${item.key}: `}</span>
            <span className="value medium">
              {typeof item.value === 'string' ? item.value : JSON.stringify(item.value)}
            </span>
          </p>
        ))
      }
    </div>
  );
}
