import { IntegrationType } from '@/views/sync/commons/IntegrationSelector';

export const IntegrationTypeList: IntegrationType[] = [
  {
    name: 'PostgreSQL',
    type: 'supported',
    description:
      'Seamlessly integrate Web3 data into your PostgreSQL database for advanced querying.'
  },
  {
    name: 'MySQL',
    type: 'supported',
    description:
      'Effortlessly sync Web3 data to MySQL for reliable and structured data management.'
  },
  {
    name: 'TiDB',
    type: 'supported',
    description:
      'Synchronize Web3 data with TiDB for distributed database scalability and real-time analytics.'
  },
  {
    name: 'S3',
    description:
      'Sync the Web3 Data to your Own AWS S3 bucket for secure and scalable storage.',
    type: 'supported'
  },
  // {
  //   name: 'Webhook',
  //   description:
  //     'Automate real-time notifications with Webhook to sync Web3 data events.'
  // },
  {
    name: 'ElasticSearch',
    description:
      'Enhance search and analytics capabilities by syncing Web3 data with ElasticSearch.'
  },
  {
    name: 'SQL Server',
    description:
      'Integrate Web3 data seamlessly into SQL Server for robust data processing and management.'
  },
  // {
  //   name: 'Kafka',
  //   description:
  //     'Stream Web3 data efficiently with Kafka for real-time data processing and analytics.'
  // },
  {
    name: 'Databricks',
    description:
      'Leverage Databricks for advanced analytics and AI capabilities with synced Web3 data.'
  },
  {
    name: 'Doris',
    description:
      'Sync Web3 data to Doris for real-time, ad-hoc, and batch data analytics.'
  },
  {
    name: 'Snowflake',
    description:
      'Sync the Web3 Data to your Snowflake warehouse for flexible, cloud-based data warehousing.'
  },
  {
    name: 'Hive',
    description:
      'Integrate Web3 data with Hive for improved data querying and analysis in Hadoop ecosystems.'
  },
  {
    name: 'DynamoDB',
    description:
      'Sync Web3 data to DynamoDB for fast, flexible NoSQL database services.'
  }
];
