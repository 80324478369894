import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Typography } from '@douyinfe/semi-ui';
import { InputLabel } from '@/components';
import JSONView from '@/components/JSONView';
import { CopyableButtonNative } from '@/components/Button/CopyableButton';

type ResponseExecutorProps = {
  requestTemplate: any;
  responseData: any;
  loading: boolean;
}

function ResponseExecutor({
  requestTemplate,
  responseData,
  loading
}: ResponseExecutorProps): React.ReactElement {
  const { t } = useTranslation();

  const json = responseData || requestTemplate?.template?.response;
  return (
    <Col className="col response-executor flex-column" span={14}>
      <Typography.Title heading={2}>
        {t('common.response')}
      </Typography.Title>

      <div className="content-wrapper">
        <Row type="flex" align="middle" justify="space-between">
          <Col>
            <InputLabel label={t('composer.form.parameter.label')} />
          </Col>
          <Col>
            <CopyableButtonNative
              className="btn-json-copy"
              content={JSON.stringify(json, null, 2)}
              copyTip={t('common.copy')}
              successTip={`${t('common.copied')}!`}
            />
          </Col>
        </Row>
        <JSONView
          key={requestTemplate?.name}
          loading={loading}
          src={json}
        />
      </div>
    </Col>
  );
}

export default ResponseExecutor;
