import React from 'react';
import Highlighter, { HighlighterProps } from 'react-highlight-words';

export default function TextHighlight(props: HighlighterProps): React.ReactElement {
  /* eslint-disable react/jsx-props-no-spreading */
  const { highlightStyle } = props;
  return (
    <Highlighter
      autoEscape
      {...props}
      highlightStyle={
        {
          backgroundColor: 'transparent',
          ...highlightStyle
        }
      }
    />
  );
}
