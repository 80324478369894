import _ from 'lodash';
import { ComposerRequestTemplate } from '@/props';

const assets = require.context(
  '../../../config/composer',
  true,
  /\.(json)$/
);

export const RequestTemplates = _.map<any, ComposerRequestTemplate>(assets.keys(), (key: string) => {
  const name = key.substring(
    key.lastIndexOf('/') + 1,
    key.lastIndexOf('.')
  );

  const type = key.substring(key.indexOf('/') + 1, key.lastIndexOf('/'));
  const template = assets(key);

  return {
    name,
    type,
    template
  } as ComposerRequestTemplate;
});
