import React from 'react';
import { Typography } from '@douyinfe/semi-ui';
import {
  TypographyBaseType
} from '@douyinfe/semi-ui/lib/es/typography/interface';
import classNames from 'classnames';

// TypographyBaseType
export declare interface StatusTextProps extends React.HTMLAttributes<HTMLDivElement> {
  type: TypographyBaseType | (() => TypographyBaseType);
  preview?: string;
  suffix?: string;
}

export default function StatusText({
  className,
  type,
  preview,
  suffix,
  children
}: Omit<StatusTextProps, ''>): React.ReactElement {
  const targetType = typeof type === 'function' ? type() : type;

  return (
    <Typography.Text className={classNames([className])} type={targetType}>
      {preview}
      {children}
      {suffix}
    </Typography.Text>
  );
}
