/* eslint-disable react/jsx-closing-tag-location */
import { Icon, Modal } from '@/components';
import { SyncJobFilter } from '@/model/Sync';
import { Button, Form } from '@douyinfe/semi-ui';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { SelectTable, TableColumn } from '@/views/sync/job-creation/components/JobCreationProvider';
import _ from 'lodash';

interface UseAddFilterModalProps {
  table?: SelectTable,
  initialValue?: SyncJobFilter[]
}

interface FilterItemType {
  label: string,
  value: string,
  // Determine whether the corresponding operation is supported based on the prefix of the field type.
  unsupportedType?: string[],
}

export const FilterOps: FilterItemType[] = [
  { label: '==', value: 'OP_EQ' },
  { label: '!=', value: 'OP_NE' },
  { label: '>', value: 'OP_GT', unsupportedType: ['varchar', 'bool'] },
  { label: '>=', value: 'OP_GTE', unsupportedType: ['varchar', 'bool'] },
  { label: '<', value: 'OP_LT', unsupportedType: ['varchar', 'bool'] },
  { label: '<=', value: 'OP_LTE', unsupportedType: ['varchar', 'bool'] }
];

const toBoolean = (value: string) => value.trim().toLowerCase() === 'true';

const ValueInputTypeMap = [
  { type: 'varchar', inputType: 'text', convert: _.toString },
  { type: 'timestamp', inputType: 'text', convert: _.toString },
  { type: 'date', inputType: 'text', convert: _.toString },
  { type: 'int', inputType: 'number', convert: _.toInteger },
  { type: 'integer', inputType: 'number', convert: _.toInteger },
  { type: 'bigint', inputType: 'number', convert: _.toInteger },
  { type: 'decimal', inputType: 'number', convert: _.toNumber },
  { type: 'boolean', inputType: 'text', convert: toBoolean },
  { type: 'bool', inputType: 'text', convert: toBoolean }
];

type FilterFormProps = {
  columns?: TableColumn[],
  onSubmit: (values: Record<string, any>) => void,
  onClose: () => void,
}

function FilterForm({ columns, onSubmit, onClose }: FilterFormProps): React.ReactElement {
  const formRef = useRef<Form>(null);

  const [selectField, setSelectedField] = useState<any>(columns?.[0]?.field || '');

  const validateFields = (values: Record<string, string>) => {
    const errors: Record<string, string> = {};
    const { value } = values;
    if (!value) {
      errors.value = 'Value cannot be empty.';
    }
    return Object.keys(errors).length === 0 ? '' : errors;
  };

  const filterOps = useMemo(() : FilterItemType[] => {
    const col = columns?.find((c) => c.field === selectField);
    if (!col) return FilterOps;
    return FilterOps.filter((op) => !op.unsupportedType?.some((item) => col.type.startsWith(item)));
  }, [columns, selectField]);

  const fieldType = useMemo((): string => {
    const selectCol = columns?.find((c) => c.field === selectField);
    const inputTypeItem = ValueInputTypeMap.find((item) => selectCol?.type.startsWith(item.type));
    return inputTypeItem?.inputType || 'text';
  }, [columns, selectField]);

  return (
    <div className="flex flex-col gap-12 justify-between">
      <Form
        ref={formRef}
        className="job-creation-add-filter-modal-content-wrapper"
        validateFields={validateFields}
        onSubmit={onSubmit}
      >
        <Form.Select
          clickToHide
          field="name"
          noLabel
          initValue={columns?.[0]?.field}
          onChange={(value) => setSelectedField(value)}
        >
          {columns?.map((col) => (
            <Form.Select.Option key={col.field} value={col.field}>{col.field}</Form.Select.Option>
          ))}
        </Form.Select>
        <Form.Select key={selectField} clickToHide field="op" noLabel initValue={filterOps[0].value}>
          {filterOps.map((value) => (
            <Form.Select.Option key={value.value} value={value.value}>{value.label}</Form.Select.Option>
          ))}
        </Form.Select>
        <Form.Input type={fieldType} field="value" noLabel required />
      </Form>
      <div className="flex justify-center gap-2">
        <Button
          className="flex-1 h-9"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          className="flex-1 h-9"
          theme="solid"
          onClick={() => {
            formRef.current?.formApi.validate().then(() => {
              formRef.current?.formApi.submitForm();
              onClose();
            });
          }}
        >
          Apply
        </Button>
      </div>
    </div>
  );
}

export default function useAddFilterModal({
  table,
  initialValue = []
}: UseAddFilterModalProps) {
  const [addedFilters, setAddedFilters] = useState<SyncJobFilter[]>(initialValue);

  const onAddFilterFormSubmit = useCallback((values: Record<string, any>) => {
    const filter: SyncJobFilter = {
      name: values.name,
      op: values.op,
      value: values.value
    };
    setAddedFilters([...addedFilters, filter]);
  }, [addedFilters]);

  const openModal = () => {
    const modal = Modal.info({
      title: 'New Filter',
      closeIcon: (<div className="bg-[#F7F7F7] hover:bg-[#E3E7EC] p-1.5 rounded">
        <Icon icon="common/close-2" className="w-3 h-3" />
      </div>),
      centered: true,
      size: 'large',
      content: (<FilterForm columns={table?.columns.filter((col) => !(col.type === 'varbinary' || col.type.startsWith('array'))) || []} onClose={() => modal.destroy()} onSubmit={onAddFilterFormSubmit} />),
      onCancel: () => modal.destroy(),
      footer: null
    });
  };

  const removeFilter = (tagFilter: SyncJobFilter) => setAddedFilters((prev) => prev.filter((f) => f !== tagFilter));

  return { FilterOps, addedFilters, openModal, removeFilter };
}
