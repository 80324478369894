import { Tooltip } from '@douyinfe/semi-ui';
import React from 'react';

interface TooltipProps {
  disabled?: boolean;
  [key: string]: any;
}

export default function WrappedTooltip({ disabled, ...props }: TooltipProps): React.ReactElement {
  if (disabled) {
    return props.children;
  }
  // eslint-disable-next-line react/react-in-jsx-scope, react/jsx-props-no-spreading
  return <Tooltip {...props}>{props.children}</Tooltip>;
}
