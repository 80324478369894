/* eslint-disable react/jsx-closing-tag-location */
import ChainbaseSwitch from '@/components/Switch';
import { Modal } from '@/components';
import { useState } from 'react';
import { useDisableJob, useEnalbeJob } from '../../utils/JobRequestHooks';

interface StateSwitchProps {
  defaultChecked: boolean,
  jobId: string;
  className?: string,
  disabled?: boolean
}

export default function StateSwitch({
  defaultChecked,
  jobId,
  className,
  disabled
}: StateSwitchProps): React.ReactElement {
  const [checked, setChecked] = useState(defaultChecked);
  const { isEnabling, enableJob } = useEnalbeJob(jobId);
  const { isDisabling, disableJob } = useDisableJob(jobId);
  const isMutating = isEnabling || isDisabling;

  const onOk = (newState: boolean) => {
    if (newState) {
      enableJob();
    } else {
      disableJob();
    }
  };

  const openConfirmModal = () => {
    Modal.info({
      title: 'Confirm',
      content: (<div className="mt-8 mb-10 font-normal text-sm">
        <div>Are you sure to pause the job?</div>
        <div>All the In-Sync job instances would be paused.</div>
      </div>),
      okText: 'Pause',
      cancelText: 'Cancel',
      okButtonProps: {
        theme: 'solid',
        className: 'job-detail-modal-button'
      },
      cancelButtonProps: {
        className: 'job-detail-modal-cancel-button job-detail-modal-button'
      },
      onOk: () => {
        onOk(false);
        setChecked(false);
      }
    });
  };

  return (
    <ChainbaseSwitch
      className={className}
      disabled={disabled}
      loading={isMutating}
      onChange={(newState) => {
        if (newState) {
          onOk(newState);
          setChecked(newState);
        } else {
          openConfirmModal();
        }
      }}
      checked={checked}
    />
  );
}
