import { ConfigurationForm } from '@/model/Integration';
import { CommonRegExp } from '@/config/constant';

const content = `# S3

---

This page guides you through the process of setting up the S3 integration.

# Prerequisites

List of required fields:

- S3 Endpoint
- S3 Bucket Name
- S3 Bucket Path
- S3 Bucket Region
- S3 Output Format
- S3 Access Key
- S3 Secret Key

Allow connections from Chainbase server to your AWS S3/ Minio S3 cluster
An S3 bucket with credentials or an instance profile with read/write permissions configured for the host (ec2, eks).

# Setup guide
---

## Step 1: Set up S3

[Sign in](https://us-east-1.console.aws.amazon.com/iam/home#/home) to your AWS account. Use an existing or create new [Access Key ID and Secret Access Key](https://docs.aws.amazon.com/IAM/latest/UserGuide/security-creds.html).

Prepare S3 bucket that will be used as integration, see this to [create](https://docs.aws.amazon.com/AmazonS3/latest/userguide/create-bucket-overview.html) an S3 bucket.

## Step 2: Set up the S3 integration in Chainbase

1. Log into your Chainbase Console.

2. In the left navigation bar, click \`Sync - integrations\`. In the top-right corner, click \`+ New Integration\`.

3. On the integration setup page, select \`AWS S3\` from the integrartion type dropdown and enter a name for this integration.

4. Configuration Fields

   - **S3 Endpoint**

     Leave empty if using AWS S3, fill in S3 URL if using Minio S3.

   - **S3 Bucket Name**

     See this to create an S3 bucket.

   - **S3 Bucket Path**

     Subdirectory under the above bucket to sync the data into.

   - **S3 Bucket Region**

     The geographical area where your S3 Bucket will reside. Choose the region closest to your users to reduce latency.

   - **S3 Output Format**

     The format in which the output files will be saved in the bucket. Examples of formats could be JSON, CSV,

   - **S3 Access Key**

     This serves as your username to authenticate your access to your S3 bucket. Can typically be obtained from your AWS account's Security Credentials page.

   - **S3 Secret Key**

     This serves like your password along with the S3 Access Key to authenticate your access to your S3 bucket. Also obtained from your AWS account's Security Credentials page. Do not share this key publicly as it can be used to access your AWS resources.

5. Click \`Test Connection\` to ensure the S3 Bucket was normal.

6. Once Connection was correct, Click save to create the new integration.

# Note

---

## AWS IAM Policy

In order for integraiton to work correctly, it is also necessary that the user whose "S3 Key Id" and "S3 Access Key" are used have access to both the bucket and its contents. Minimum required Policies to use as below

\`\`\`
{
  "Version": "2023-12-11",
  "Statement": [
    {
      "Effect": "Allow",
      "Action": [
        "s3:PutObject",
        "s3:GetObject",
        "s3:DeleteObject",
        "s3:PutObjectAcl",
        "s3:ListBucket",
        "s3:ListBucketMultipartUploads",
        "s3:AbortMultipartUpload",
        "s3:GetBucketLocation"
      ],
      "Resource": [
        "arn:aws:s3:::YOUR_BUCKET_NAME/*",
        "arn:aws:s3:::YOUR_BUCKET_NAME"
      ]
    }
  ]
}

\`\`\`

## Synced File Path Format

The full path of the sync data with the default S3 Path Format

\`\`\`
{bucket_name}/{bucket_path}/{dataset}/{year}/{month}/{day}/filename
\`\`\`

**Example**

your_bucket/your_folder/ethereum.blocks/2016/04/05/part-e31b3304-5ea2-4de7-9a86-2ceff68418b0-task-0-file-25

The File parts:

- {bucket_name}: Your AWS bucket name to sync the data;
- {bucket_path}: Your bucket path to sync the data;
- {year}: Year in which the sync was writing the output data in.
- {month}: Month in which the sync was writing the output data in.
- {day}: Day in which the sync was writing the output data in.`;

export const S3Form: ConfigurationForm = {
  introduction: {
    logo: 's3',
    title: 'S3',
    type: 's3',
    content
  },
  formContent: [
    {
      type: 'plain',
      fields: [
        {
          fieldType: 'input',
          label: 'Sink name',
          field: 'integration_name',
          required: true
        }
      ]
    },
    {
      type: 'plain',
      fields: [
        {
          fieldType: 'input',
          field: 'endpoint',
          label: 'S3 Endpoint',
          placeholder: 'e.g https://s3.amazonaws.com',
          rules: [
            {
              pattern: CommonRegExp.url,
              message: 'S3 Endpoint must be a URL'
            }
          ]
        },
        {
          fieldType: 'input',
          field: 'bucket_name',
          label: 'S3 Bucket Name',
          placeholder: 'e.g my-s3-bucket',
          required: true
        },
        {
          fieldType: 'input',
          field: 'bucket_path',
          label: 'S3 Bucket Path',
          placeholder: 'e.g my-data/my-sub-folder/',
          required: true
        },
        {
          fieldType: 'input',
          field: 'region',
          label: 'S3 Bucket Region',
          placeholder: 'e.g us-east-1',
          required: true
        },
        {
          fieldType: 'select',
          label: 'S3 Output Format',
          placeholder: 'e.g. JSON/CSV',
          optionList: [
            {
              label: 'CSV',
              value: 'csv'
            },
            {
              label: 'JSON',
              value: 'json'
            },
            {
              label: 'Parquet',
              value: 'parquet'
            }
          ],
          field: 'output_format',
          required: true
        }
      ]
    },
    {
      type: 'collapsible',
      collapsibleLabel: 'Advanced',
      fields: [
        {
          fieldType: 'input',
          field: 'access_key',
          label: 'S3 Access Key',
          placeholder: 'Please enter your Access key',
          required: true,
          mode: 'password'
        },
        {
          fieldType: 'input',
          field: 'secret_key',
          label: 'S3 Secret Key',
          placeholder: 'Please enter your Secret Key',
          required: true,
          mode: 'password'
        }
      ]
    }
  ]
};
