import { SQLFile, sqlFileDB } from '@/utils/db/dataCloud/sqlFileDB';
import { useLiveQuery } from 'dexie-react-hooks';
import { ReactNode, createContext } from 'react';

export type SQLFiles = SQLFile[];

interface ProivderProps {
  children: ReactNode
}

export const SQLFilesContext = createContext<SQLFiles | undefined>(undefined);

export default function SQLFilesProivder({ children }: ProivderProps) {
  const files = useLiveQuery(() => sqlFileDB.sqlFile.toArray());
  if (files !== undefined) {
    files.sort((a, b) => {
      const queryIdA = a.queryId;
      const queryIdB = b.queryId;
      if (queryIdA && queryIdB) {
        return queryIdA - queryIdB;
      }
      if (queryIdA) {
        return -1;
      }
      if (queryIdB) {
        return 1;
      }
      const filenameA = a.filename;
      const filenameB = b.filename;
      const [, indexA] = filenameA.split(' ');
      const [, indexB] = filenameB.split(' ');
      return parseInt(indexA, 10) - parseInt(indexB, 10);
    });
  }

  return (
    <SQLFilesContext.Provider value={files}>
      {children}
    </SQLFilesContext.Provider>
  );
}
