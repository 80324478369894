import SyncApi from '@/api/SyncApi';
import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { Integration } from '@/model/Integration';
import { castJob } from '@/utils/adapters/SyncAdapater';

export interface Job {
  id: number;
  jobId: string;
  integrationId: string;
  name: string;
  datasets: {
    blockchain: string;
    name: string;
  };
  integration: string;
  frequency: string;
  syncMode: string;
  lastSync: string;
  state: boolean;
}

export type NewJobStep =
  | 'setup-source'
  | 'setup-integration'
  | 'configure-job'
  | null;

export interface SyncState {
  jobs: {
    status: 'notStart' | 'loading' | 'done';
    error: SerializedError | null;
    data?: Job[];
  };
  integrations: {
    status: 'notStart' | 'loading' | 'done';
    error: SerializedError | null;
    data?: Integration[];
  };
  createJob: {
    selectedTable?: {
      title: {
        blockchain: string;
        name: string;
      };
      desc: string;
      columns: {
        field: string;
        type: string;
        description: string;
      }[];
    };
    selectedIntegration: {
      integrationId: string | null;
      type: string | null;
    },
    configureJob: {
      selectedFields: string[]
    },
    allowedSteps: number;
  };
}

const initState: SyncState = {
  jobs: {
    status: 'notStart',
    error: null
  },
  integrations: {
    status: 'notStart',
    error: null
  },
  createJob: {
    selectedIntegration: {
      integrationId: null,
      type: null
    },
    configureJob: {
      selectedFields: []
    },
    allowedSteps: 0
  }
};

export const fetchJobList = createAsyncThunk('sync/listJobs', async () => {
  const response = await SyncApi.listJobs();
  const { data } = response.data;
  return data;
});

export const fetchIntegrationList = createAsyncThunk(
  'sync/listIntegrations',
  async () => {
    const response = await SyncApi.listIntegrations();
    const { data } = response.data;
    return data;
  }
);

export const fetchSyncData = createAsyncThunk('sync/all', async () => {
  const [jobsResponse, integrationsResponse] = await Promise.all([
    SyncApi.listJobs(),
    SyncApi.listIntegrations()
  ]);

  const rawJobList = jobsResponse.data.data;
  const jobList: Job[] = rawJobList ? rawJobList.map(castJob) : [];
  const integrationList: Integration[] = integrationsResponse.data.data || [];

  return { jobList, integrationList };
});

export const syncReducer = createSlice({
  name: 'SyncSlice',
  initialState: initState,
  reducers: {
    selectTable(state, action) {
      state.createJob.selectedTable = action.payload;
    },
    selectIntegration(state, action) {
      state.createJob.selectedIntegration = {
        integrationId: action.payload.id,
        type: action.payload.type
      };
    },
    selectSyncField(state, action) {
      const { payload } = action;
      const { configureJob } = state.createJob;
      const { selectedFields } = configureJob;
      if (payload.type === 'add') {
        configureJob.selectedFields = selectedFields.concat(payload.value);
      } else if (payload.type === 'remove') {
        configureJob.selectedFields = selectedFields.filter((field) => field !== payload.value);
      } else if (payload.type === 'all') {
        configureJob.selectedFields = payload.value;
      }
    },
    allowStep(state, action) {
      state.createJob.allowedSteps = Math.max(state.createJob.allowedSteps, action.payload);
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSyncData.pending, (state) => {
        const { integrations, jobs } = state;
        integrations.status = 'loading';
        jobs.status = 'loading';
      })
      .addCase(fetchSyncData.fulfilled, (state, action) => {
        const { jobList, integrationList } = action.payload;
        const { jobs, integrations } = state;

        jobs.data = jobList;
        jobs.status = 'done';

        integrations.data = integrationList;
        integrations.status = 'done';
      })
      .addCase(fetchSyncData.rejected, (state, payload) => {
        const { error } = payload;
        const { jobs, integrations } = state;

        jobs.error = error;
        jobs.status = 'done';

        integrations.error = error;
        integrations.status = 'done';
      });
  }
});

export default syncReducer.reducer;
export const { selectTable, selectIntegration, selectSyncField, allowStep } = syncReducer.actions;
