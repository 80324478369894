import React from 'react';
import { useTranslation } from 'react-i18next';

export default function OrLine(): React.ReactElement {
  const { t } = useTranslation();
  return (
    <div className="or-line">
      <span className="medium">{t('common.or')}</span>
    </div>
  );
}
