import './index.scss';
import { BlockLoading } from '@/components/Loading/BlockLoading';
import React from 'react';
import { Icon } from '@/components';
import EmptyJob from './components/EmptyJob';
import JobsOverview from './components/JobsOverview';
import { useJobList } from '../utils/JobRequestHooks';

export default function SyncJobs(): React.ReactElement {
  const { error, isLoading, isEmpty } = useJobList();

  if (error) {
    const message = error?.response?.data?.msg || error?.message || 'Error';
    return (
      <div className="sync-jobs-error-wrapper">
        <EmptyJob />
        <div className="toast-wrapper">
          <div className="left-wrapper">
            <Icon icon="common/close-icon" />
            <span>
              {message}
            </span>
          </div>
        </div>
        <div className="disable-mask" />
      </div>
    );
  }

  if (isLoading) {
    return <BlockLoading />;
  }

  if (isEmpty) {
    return <EmptyJob />;
  }

  return <JobsOverview />;
}
