interface CookieAttributes {
  domain?: string;
  path?: string;
  expires?: number | Date;
  maxAge?: number;
  secure?: boolean;
  sameSite?: 'strict' | 'lax' | 'none';
}

export function setCookie(name: string, value: string, attributes: CookieAttributes = {}): void {
  let cookieStr = `${name}=${encodeURIComponent(value)}`;

  if (attributes.expires) {
    let expires: Date;
    if (typeof attributes.expires === 'number') {
      expires = new Date(Date.now() + attributes.expires * 24 * 60 * 60 * 1000);
    } else {
      expires = attributes.expires;
    }
    cookieStr += `; expires=${expires.toUTCString()}`;
  }

  if (attributes.maxAge) {
    cookieStr += `; max-age=${attributes.maxAge}`;
  }

  if (attributes.domain) {
    cookieStr += `; domain=${attributes.domain}`;
  }

  if (attributes.path) {
    cookieStr += `; path=${attributes.path}`;
  }

  if (attributes.secure) {
    cookieStr += '; secure';
  }

  if (attributes.sameSite) {
    cookieStr += `; samesite=${attributes.sameSite}`;
  }

  document.cookie = cookieStr;
}

export function readCookie(name: string): string | null {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i += 1) {
    const c = ca[i].trim();

    if (c.indexOf(nameEQ) === 0) {
      return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
  }

  return null;
}
