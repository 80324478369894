import React from 'react';
import * as TypingEffectModule from 'typing-effect-reactjs';

export default function TypingEffect(props: any): React.ReactElement {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TypingEffectModule.Typing {...props} />
  );
}
