import React, { FormEvent, useEffect, useState } from 'react';
import _ from 'lodash';
import { loadStripe, StripeCardElement } from '@stripe/stripe-js';
import {
  Elements, CardElement, useElements, useStripe
} from '@stripe/react-stripe-js';
import { TokenResult } from '@stripe/stripe-js/types/stripe-js/stripe';
import { useTranslation } from 'react-i18next';
import { Button } from '@douyinfe/semi-ui';
import { Icon, Loading, Toast } from '@/components';
import { PaymentStatus, PaymentStatusComponent } from '@/views/settings/billing/PaymentStatus';
import { useLoading, useLoadingCallback } from '@/utils/useLoadingCallback';
import BillingApi from '@/api/BillingApi';
import { PlanItem } from '@/views/settings/billing/PlanList';
import { ModalFooter } from '@/components/Modal';
import { reportEvent } from '@/utils/useTrack';

interface AddCardFormProps {
  payButton: string;
  onSubmit: (promise: Promise<TokenResult>) => void;
}

interface ValidMap {
  card: boolean;
}

function AddCardForm({ payButton, onSubmit }: AddCardFormProps): React.ReactElement {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useLoading(true);

  const [valid, setValid] = useState<ValidMap>({
    card: false
  });

  useEffect(() => {
    elements?.getElement('card')?.on('change', (event) => {
      valid.card = event.complete;
      setValid(_.clone(valid));
    });

    return () => {
      elements?.getElement('card')?.off('change');
    };
  }, [elements, valid]);

  const { loading: requestLoading, callback: handleSubmit } = useLoadingCallback(
    async (e: FormEvent): Promise<void> => {
      e.preventDefault();

      if (stripe && elements) {
        const element: StripeCardElement | null = elements.getElement('card');
        if (element) {
          await onSubmit(stripe.createToken(element));
          return;
        }
      }
      await onSubmit(Promise.reject());
    }
  );

  // TODO onboarding的时候，第一次会加载较慢
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div
          className="card-form"
          style={{
            display: loading ? 'none' : 'block'
          }}
        >
          <CardElement
            options={{
              hidePostalCode: true
            }}
            onReady={() => {
              setTimeout(() => {
                setLoading(false);
              }, 100);
            }}
          />
        </div>

        {loading ? (
          <Loading type="grey" centered />
        ) : (
          <ModalFooter
            hasCancel={false}
            okButtonProps={{
              loading: requestLoading,
              disabled: !valid.card,
              theme: 'solid',
              className: 'btn-create btn-enhance modal-btn-create',
              type: 'primary',
              htmlType: 'submit',
              'aria-label': 'confirm'
            }}
            okText={payButton}
          />
        )}
      </form>
    </div>
  );
}

interface AddCardProps {
  payButton: string;
  plan: PlanItem | undefined;
  onClose: (complete: boolean) => void;
  onBindSuccess?: () => void;
  last4?: string
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK as string);

export default function AddCard({
  payButton, plan, last4, onClose, onBindSuccess
}: AddCardProps): React.ReactElement {
  const { t } = useTranslation('settings');
  const [payStatusMessage, setPayStatusMessage] = useState<PaymentStatus>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const handleSubmit = async (promise: Promise<TokenResult>): Promise<void> => {
    const { token, error } = await promise;

    if (token) {
      const response = await BillingApi.updateCard({
        token: token.id
      });
      if (onBindSuccess) {
        onBindSuccess();
      }

      if (plan?.id) {
        // pay plan
        if (response.data.code !== 200) {
          setPayStatusMessage(response.data.message);
          return;
        }

        const subscribeResponse = await BillingApi.subscribePlan({
          subscriptionPlan: plan.id
        });
        if (plan.id) {
          reportEvent('subscriptionCreated', {});
        } else if (plan.id === 0) {
          reportEvent('subscriptionCancelled', {});
        }

        if (subscribeResponse.data.code !== 200) {
          setPayStatusMessage(subscribeResponse.data.message);
          return;
        }

        setPayStatusMessage('success');
      } else {
        // bind card
        if (response.data.code !== 200) {
          setErrorMessage(response?.data?.message);
          return;
        }
        if (last4) {
          Toast.success({
            content: t('billing.addCard.changeCardSuccessAndNextCard', { last4Number: response?.data?.result?.last4 }),
            duration: 10
          });
        } else {
          Toast.success(t('billing.addCard.changeCardSuccess'));
        }

        onClose(true);
      }
    } else if (error?.decline_code || error?.type) {
      setPayStatusMessage(error.message);
    } else {
      console.log('An unexpected error occurred.', error);
    }
  };

  return (
    <Elements
      stripe={stripePromise}
      options={{
        appearance: {
          disableAnimations: true,
          theme: 'none',
          variables: {
            fontFamily: '"Roboto Flex", sans-serif',
            fontSmooth: '"Roboto Flex", sans-serif',
            fontWeightMedium: '500',
            fontWeightNormal: '500',
            fontSizeSm: '14px'
          },
          rules: {
            '.Label': {
              marginBottom: '12px',
              paddingTop: '14px',
              paddingBottom: '2px'
            },
            '.Input': {
              backgroundColor: '#F7F7F7',
              border: '1px solid #F7F7F7',
              fontSize: '14px',
              padding: '17.5px 19px'
            },
            '.Input:focus': {
              boxShadow: 'none',
              border: '1px solid #D2D2D2',
              outline: 'none'
            },
            '.Input::placeholder': {
              color: '#A0A0A0'
            }
          }
        },
        locale: 'en'
      }}
    >
      {errorMessage ? (
        <div className="error-message">
          <Icon icon="common/error" className="icon-error" />
          {errorMessage}

          <Button
            className="btn-close"
            theme="borderless"
            icon={<Icon icon="common/close" className="icon-close" />}
            onClick={() => setErrorMessage(undefined)}
          />
        </div>
      ) : null}

      <PaymentStatusComponent
        status={payStatusMessage}
        onClose={() => onClose(true)}
        onClear={() => setPayStatusMessage(undefined)}
      />
      <div
        style={{
          display: payStatusMessage ? 'none' : 'block'
        }}
      >
        <AddCardForm payButton={payButton} onSubmit={handleSubmit} />
      </div>
    </Elements>
  );
}
