import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { motion, Target, TargetAndTransition, Transition } from 'framer-motion';
import { Connector, Web3ReactStore } from '@web3-react/types';
import { Typography } from '@douyinfe/semi-ui';
import { WalletItem, WalletList } from '@/config/wallet';
import {
  Card, Icon, Loading, Toast
} from '@/components';
import { ModalProps, MotionModal } from '@/components/MotionModal';
import { useLoadingCallback } from '@/utils';
import { Guide } from '@/config/constant';
import { FortMatic } from '@/utils/wallet';
import WalletApi from '@/api/WalletApi';
import {
  store as walletConnectStore,
  walletConnect
} from '@/utils/wallet/connectors/walletConnect';
import {
  metaMask,
  store as metaMaskStore
} from '@/utils/wallet/connectors/metaMask';

type WalletLoginModalProps = {
  onAccountChange: (account: string | undefined, sign: string) => void;
} & ModalProps;

const defaultWalletLoginModalProps: WalletLoginModalProps = {
  close: () => undefined,
  onAccountChange: () => undefined
};

type WalletItemProps = {
  index: number;
  wallet: WalletItem,
  onClick: () => Promise<void>;
}

function WalletItemComponent({
  index,
  wallet,
  onClick
}: WalletItemProps): React.ReactElement {
  const {
    callback,
    loading
  } = useLoadingCallback(async () => {
    await onClick();
  });

  return (
    <button
      key={index}
      className="wallet-item"
      type="button"
      onClick={callback}
    >
      <div className="image-wrapper">
        {
          loading ? <Loading type="grey" />
            : <Icon className="icon" icon={wallet.icon} />
        }

      </div>

      <p className="name medium">{wallet.name}</p>

      {
        wallet.popular
          ? <div className="popular medium">Popular</div> : null
      }

      <Icon
        className="icon"
        icon="angle-right"
        alt="angle-right"
      />
    </button>
  );
}

function WalletLoginModal(
  props = defaultWalletLoginModalProps
): React.ReactElement {
  const {
    close,
    onAccountChange
  } = props || {};
  const { t } = useTranslation();

  const initial: Target = {
    opacity: 0,
    y: 100
  };

  const transition: Transition = {
    type: 'easyInOut',
    damping: 10,
    stiffness: 50
  };

  const animate: TargetAndTransition = {
    opacity: 1,
    y: 0
  };

  const getWalletToken = async (): Promise<string> => {
    const response = await WalletApi.getWalletToken();
    const { bind_msg: bindMsg } = response.data as {
      bind_msg: string;
    };
    return bindMsg;
  };

  const handleSignMessage = async (
    connector: Connector,
    store: Web3ReactStore
  ): Promise<any> => {
    const { accounts } = store.getState();
    console.log('store.getState()', store.getState());
    const account = accounts ? accounts[0] : undefined;

    const bindMsg = await getWalletToken();

    const sign = await connector.provider?.request({
      method: 'personal_sign',
      params: [account, bindMsg]
    }) as string;

    return Promise.resolve({
      account,
      sign
    });
  };

  const handleFortmaticSign = async (): Promise<{
    account: string,
    signMessage: string
  }> => {
    const bindMsg = await getWalletToken();

    const fortMatic = new FortMatic();
    const account = await fortMatic.getAccount();

    const signMessage = await fortMatic.signMessage(bindMsg);
    return {
      account,
      signMessage
    };
  };

  const handleLogin = async (wallet: WalletItem): Promise<void> => {
    /* eslint-disable no-case-declarations */
    switch (wallet.name) {
      case 'Metamask':
        if (metaMaskStore.getState().activating) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          await metaMask.deactivate();
        } else {
          await metaMask.activate();

          const {
            account,
            sign: signMessage
          } = await handleSignMessage(
            metaMask,
            metaMaskStore
          );

          onAccountChange(account, signMessage);
        }
        break;
      case 'Fortmatic':
        const {
          account,
          signMessage
        } = await handleFortmaticSign();

        onAccountChange(account, signMessage);
        break;
      case 'WalletConnect':
        if (walletConnectStore.getState().activating) {
          await walletConnect.deactivate();
        } else {
          await walletConnect.activate();

          const {
            account: walletConnectAccount,
            sign: walletConnectSignMessage
          } = await handleSignMessage(
            walletConnect,
            walletConnectStore
          );

          onAccountChange(walletConnectAccount, walletConnectSignMessage);
        }
        break;
      default:
        break;
    }
  };

  return (
    <MotionModal onClose={close}>
      <motion.div
        className="card-content-container"
        initial={initial}
        transition={transition}
        animate={animate}
        exit={initial}
      >
        <Card className="card-content">

          <h1 className="title bolder">{t('walletLogin.title')}</h1>
          <div
            className="wallet-icon-list"
          >
            {
              _.map(WalletList, (wallet: WalletItem, index: number) => (
                <WalletItemComponent
                  key={wallet.name}
                  index={index}
                  wallet={wallet}
                  onClick={async () => {
                    await handleLogin(wallet).catch((error) => {
                      if (error?.message) {
                        Toast.error(error?.message);
                      }
                    });
                  }}
                />
              ))
            }
          </div>

          <Typography.Text
            className="btn-guide"
            link={
              {
                href: Guide.fistUseGuide,
                target: '_blank'
              }
            }
          >
            {t('walletLogin.firstGuide')}
          </Typography.Text>
        </Card>
      </motion.div>
    </MotionModal>
  );
}

export default WalletLoginModal;
