import React, { ImgHTMLAttributes } from 'react';

export default function Image({
  src,
  alt,
  ...props
}: ImgHTMLAttributes<HTMLImageElement>): React.ReactElement {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <img src={src} alt={alt} {...props} />
  );
}
