import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from '@douyinfe/semi-ui/lib/es/table';
import { DateUtils, useLoadingCallback } from '@/utils';
import { Icon, StatusText } from '@/components';
import BillingApi from '@/api/BillingApi';
import Table from '@/components/Table';
import classNames from 'classnames';
import _ from 'lodash';
import TableEmpty from '@/components/Table/TableEmpty';
import { PaymentCardInfo, RecentInvoiceItem } from '@/model/BillingPlan';

interface PaymentMethodInfoProps {
  method?: string;
  card: PaymentCardInfo;
}

function PaymentMethodInfo({
  method = 'card',
  card
}: Omit<PaymentMethodInfoProps, ''>): React.ReactElement {
  const { t } = useTranslation('settings');
  return (
    <div className="payment-method-info">
      <Icon icon={`billing/paymentMethod/${method}`} className="icon" />
      <span className="card">
        {`${t('billing.tableTitle.endingIn')} `}
        {card.last4}
      </span>
    </div>
  );
}

type TablePaginationState<T> = {
  data: T[],
  loading: boolean,
  total: number,
  current: number,
  change: (page: number) => void,
}

function useTablePagination<T>(func: (page: number) => Promise<{
  list: T[],
  total: number
}>, current = 0): TablePaginationState<T> {
  const [data, setData] = useState<T[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(current);
  const [total, setTotal] = useState(0);

  const {
    callback: changePage,
    loading
  } = useLoadingCallback(async (page) => {
    const result = await func(page);
    setCurrentPage(current);
    setData(result.list);
    setTotal(result.total);
  });

  return {
    data,
    loading,
    total,
    current: currentPage,
    change: changePage
  };
}

export default function RecentInvoiceTable(): React.ReactElement {
  const { t } = useTranslation(['settings', 'translation']);

  const BillingStatusArray = t('billing.billingStatus', { returnObjects: true }) as {
    [name: string]: string
  };

  const columns: ColumnProps<RecentInvoiceItem>[] = [
    {
      title: t('billing.tableTitle.date'),
      dataIndex: 'created',
      width: 200,
      render: (data) => DateUtils.format(data)
    },
    {
      title: t('billing.tableTitle.amount'),
      dataIndex: 'total',
      minWidth: 150,
      render: (amount: number, record: RecentInvoiceItem) => {
        if (record.currency === 'usd') {
          return `$ ${amount / 100}`;
        }
        return `${amount / 100} ${record.currency.toUpperCase()}`;
      }
    },
    {
      title: t('billing.tableTitle.method'),
      dataIndex: 'payment_card',
      render: (paymentCard: string, record: RecentInvoiceItem) => (
        record?.card ? <PaymentMethodInfo card={record.card} /> : null
      )
    },
    {
      title: t('common.view', { ns: 'translation' }),
      dataIndex: 'invoice_url',
      minWidth: 100,
      maxWidth: 150,
      render: (link) => (
        <a href={link} target="_blank" rel="noreferrer" className="btn-view">
          <Icon icon="common/view" className="icon icon-view" />
          <span>{t('common.view', { ns: 'translation' })}</span>
        </a>
      )
    },
    {
      title: t('billing.tableTitle.status'),
      dataIndex: 'status',
      minWidth: 100,
      maxWidth: 150,
      render: (status) => (
        <StatusText
          type={() => {
            if (status === 'paid') {
              return 'success';
            }
            return 'danger';
          }}
        >
          {BillingStatusArray[status] || BillingStatusArray.failed}
        </StatusText>
      )
    }
  ];

  const {
    loading,
    data,
    // total,
    change
  } = useTablePagination<RecentInvoiceItem>(async (page) => {
    const response = await BillingApi.getInvoiceList(page);
    return {
      list: response.data.result,
      total: response.data.total || 0
    };
  });

  React.useEffect(() => {
    change(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames('invoice-table')}>
      <Table
        loading={loading}
        className={classNames('medium', {
          'table-empty': _.size(data) === 0
        })}
        columns={columns}
        dataSource={data}
        pagination={
          _.size(data) > 10
            ? {
              formatPageText: false
            } : false
        }
        empty={(
          <TableEmpty
            icon="billing/invoice-empty"
            title={t('billing.invoicesEmpty')}
          />
        )}
      />
      {/* {
        total > 0 ? (
          <Pagination
            total={total}
            pageSize={10}
            showQuickJumper
            className="pagination"
            onChange={(currentPage) => {
              change(currentPage);
            }}
          />
        ) : null
      } */}
    </div>

  );
}
