import React from 'react';
import {
  Button, Dropdown, Row, SideSheet
} from '@douyinfe/semi-ui';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { ButtonLoading, Icon, TextLink } from '@/components';
import classNames from 'classnames';
import { CodeBlock, vs2015 } from 'react-code-blocks';
import { CopyableButtonNative } from '@/components/Button/CopyableButton';
import './index.scss';
import { StringUtils, useLoadingCallback } from '@/utils';
import { QueryTabItem } from '@/views/dataCloud/index/SQLEditor';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  currentLanguages,
  currentQuery,
  dataCloudGeneratorActions,
  executeQuery,
  generatorLanguages,
  generatorResponse
} from '@/store/reducers/dataCloudGeneratorReducer';
import { LanguageItem } from '@/props';
import LearnMore from '@/components/Form/LearnMore';
import JSONView from '@/components/JSONView';
import px2rem from '@/utils/px2rem';

const GenerateSideSheet = React.forwardRef((
  props,
  ref: React.ForwardedRef<any>
): React.ReactElement => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const languages = useAppSelector(generatorLanguages);
  const currentLanguage = useAppSelector(currentLanguages);
  const response = useAppSelector(generatorResponse);
  const query = useAppSelector(currentQuery);

  const [visible, setVisible] = React.useState<boolean>(false);

  const handleChangeLanguage = (target: LanguageItem): void => {
    dispatch(dataCloudGeneratorActions.change(target.name));
  };

  const {
    loading,
    callback: handleTryRequest
  } = useLoadingCallback(async () => {
    await dispatch(executeQuery());
  });

  const getContainer = (): HTMLElement => document.querySelector('.terminal-container') as HTMLElement;

  React.useImperativeHandle(ref, () => ({
    open(target: QueryTabItem) {
      dispatch(dataCloudGeneratorActions.setQuery(target.sql));
      setVisible(true);
    }
  }));

  let paramStr: string = JSON.stringify({
    query
  });

  if (currentLanguage?.escape) {
    paramStr = paramStr.replaceAll('"', '\\"');
  }

  if (currentLanguage?.newlineProcess) {
    paramStr = paramStr.replaceAll('\\n', '\\\\n');
  }

  const exampleCode = StringUtils.format(currentLanguage?.template || '', {
    params: paramStr
  });

  return (
    <SideSheet
      className="generate-api-side-sheet"
      closeOnEsc
      title={t('dataCloud.generate.title')}
      visible={visible}
      width={px2rem.calcPXByScreen(507)}
      afterVisibleChange={(isVisible) => {
        if (!isVisible) {
          dispatch(dataCloudGeneratorActions.clearResponse());
        }
      }}
      onCancel={() => setVisible(false)}
      getPopupContainer={getContainer}
      mask
    >

      <div className="code-section">
        <div className="get-key">
          <TextLink
            links={
              {
                docs: 'https://docs.chainbase.com/docs/chainbase-api-quickstart'
              }
            }
          >
            {t('dataCloud.generate.howToGetApi')}
          </TextLink>
        </div>

        <Row type="flex" justify="space-between">
          <h3
            className="title bolder"
          >
            {t('dataCloud.generate.selectLanguage')}
          </h3>

          <LearnMore
            to="https://docs.chainbase.com/reference/sql-api-overview"
            text={t('dataCloud.generate.hotUse')}
          />
        </Row>

        <div className="language-list">
          {
            _.map(_.slice(languages, 0, 5), (item) => (
              <Button
                key={item.name}
                onClick={() => {
                  handleChangeLanguage(item);
                }}
                className={classNames(['language-item', {
                  active: currentLanguage.name === item.name
                }])}
              >
                <Icon className="icon" icon={`codeLanguage/${item.name}`} />
                <span className="name">{item.name}</span>
              </Button>
            ))
          }

          <Dropdown
            position="bottomRight"
            className="language-dropdown"
            render={(
              <Dropdown.Menu>
                {
                  _.map(_.slice(languages, 5), (item) => (
                    <Dropdown.Item
                      key={item.name}
                      onClick={() => {
                        handleChangeLanguage(item);
                      }}
                    >
                      <div className="language-item">
                        <Icon
                          className="icon"
                          icon={`codeLanguage/${item.name}`}
                        />
                        <span className="name">{item.name}</span>
                      </div>
                    </Dropdown.Item>
                  ))
                }
              </Dropdown.Menu>
            )}
          >
            <Button
              className="btn-more"
              theme="borderless"
              icon={<Icon className="icon" icon="dataCloud/generate-more" />}
            />
          </Dropdown>

        </div>

        <div className="code-area">
          <div className="code">
            <CodeBlock
              key={currentLanguage.name}
              text={exampleCode}
              language={currentLanguage.language}
              showLineNumbers
              startingLineNumber
              wrapLines
              theme={vs2015}
            />
          </div>

          <div className="operate-wrapper">
            <CopyableButtonNative
              className="semi-button btn-copy"
              content={exampleCode}
              copyTip={(
                <div className="flex-row align-center uncopy">
                  <Icon className="icon" icon="common/copy" />
                  {t('dataCloud.generate.copy')}
                </div>
              )}
              successTip={(
                <div className="flex-row align-center">
                  <Icon className="icon" icon="common/correct" style={{ color: 'black' }} />
                  {`${t('common.copied')}!`}
                </div>
              )}
            />
            <ButtonLoading
              theme="borderless"
              loading={loading}
              onClick={handleTryRequest}
            >
              <div className="flex-row align-center">
                <Icon className="icon" icon="dataCloud/run" />
                {t('dataCloud.generate.try')}
              </div>
            </ButtonLoading>
          </div>
        </div>

        {
          response
            ? (
              <h3
                className="title bolder"
              >
                {t('common.response')}
              </h3>
            ) : null
        }

        <div
          className={classNames(['response-wrapper', {
            hidden: loading || !response
          }])}
        >
          <div className="response-header">
            <h5 className="title">{t('dataCloud.generate.statusCode')}</h5>

            <div className="status-code">
              <span className="status">●</span>
              <span>{response?.status}</span>
            </div>

            <div className="flex flex-1" />

            <CopyableButtonNative
              className="btn-json-copy"
              content={response ? JSON.stringify(response.data, null, 2) : ''}
              copyTip={t('common.copy')}
              successTip={`${t('common.copied')}!`}
            />
          </div>

          <div className="response-body">
            <JSONView
              src={response?.data || {}}
            />
          </div>
        </div>
      </div>
    </SideSheet>
  );
});

export default GenerateSideSheet;

export declare type GenerateSideSheetType = {
  open: (target: QueryTabItem) => void;
} | React.ElementRef<typeof GenerateSideSheet>
