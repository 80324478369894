import * as monaco from 'monaco-editor';
import { ReactNode, createContext, useMemo, useContext, useState } from 'react';
import { ActiveTabContext } from './ActiveTabProvider';

export type MonacoEditor = monaco.editor.IStandaloneCodeEditor;

export interface EditorRef {
  editor?: MonacoEditor,
  register(key: string, editor: MonacoEditor): void,
  insert(text: string): void;
}

export const EditorRefContext = createContext<EditorRef>({
  editor: undefined,
  register(): void {
    throw new Error('Function not implemented.');
  },
  insert(): void {
    throw new Error('Function not implemented.');
  }
});

export default function EditorRefProvider({ children }: {children: ReactNode}) {
  const [editorRefList, setEditorList] = useState<{key:string, ref: MonacoEditor}[]>([]);
  const activeTab = useContext(ActiveTabContext);

  const curEditor = editorRefList.find((editorRef) => editorRef.key === activeTab.current)?.ref;

  const value = useMemo(() => ({
    editor: curEditor,
    register(key: string, editor: MonacoEditor) {
      const registeredEditor = editorRefList.find((editorRef) => editorRef.key === key);
      if (!registeredEditor) {
        setEditorList((prevEditorList) => prevEditorList.concat({ key, ref: editor }));
      }
    },
    insert(text: string) {
      const selection = curEditor?.getSelection();
      if (selection) {
        const op = {
          range: selection,
          text,
          forceMoveMarkers: true
        };
        curEditor?.executeEdits('my-source', [op]);
      }
    }
  }), [curEditor, editorRefList]);

  return (
    <EditorRefContext.Provider value={value}>
      {children}
    </EditorRefContext.Provider>
  );
}
