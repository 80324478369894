import axios from 'axios';
import useSWR from 'swr';
import { Metadata } from '@/model/DataCloudStudio';

export interface Response {
  abstracted: Metadata[],
  raw: Metadata[],
  decoded: Metadata[],
}

const defaultData = {
  raw: [],
  decoded: [],
  abstracted: []
};

export default function useTableMetadata() {
  const { data, isLoading } = useSWR('/', () => axios.get('/api/v2/datacloud/metadata').then((r) => r.data.data as Response));

  return {
    ...defaultData,
    ...data,
    isLoading
  };
}
