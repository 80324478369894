import './index.scss';
import React, { useState, useRef, useImperativeHandle } from 'react';

export interface ComfortProgressRef {
  start(): void;
  stop(): void;
  done(): void;
  reset(): void;
}

const STOP_GRWOTH = 0;
const DEFAULT_GROWTH = 1;
const DONE_GROWTH = 100;

const PROGRESS_PAUSE_VALUE = 80;
const PROGRESS_MAX_VALUE = 100;

const INTERVAL_TIMEOUT = 100;

const ComfortProgress = React.forwardRef<ComfortProgressRef>((_, ref) => {
  const [visbile, setVisible] = useState(false);
  const [value, setValue] = useState(0);
  const growth = useRef(STOP_GRWOTH);
  const interval = useRef<number>();
  const done = useRef(false);

  useImperativeHandle(ref, () => ({
    start() {
      setVisible(true);

      let countValue = 0;
      growth.current = DEFAULT_GROWTH;
      clearInterval(interval.current);

      interval.current = window.setInterval(() => {
        countValue += growth.current;
        setValue(Math.min(countValue, PROGRESS_MAX_VALUE));

        const isDone = countValue >= PROGRESS_MAX_VALUE;

        if (countValue === PROGRESS_PAUSE_VALUE) {
          growth.current = STOP_GRWOTH;
        }
        if (done.current) {
          growth.current = DONE_GROWTH;
        }
        if (isDone) {
          clearInterval(interval.current);
          growth.current = STOP_GRWOTH;
        }
      }, INTERVAL_TIMEOUT);
    },
    stop() {
      clearInterval(interval.current);
      growth.current = STOP_GRWOTH;
    },
    reset() {
      setValue(0);
      done.current = false;
      growth.current = STOP_GRWOTH;
    },
    done() {
      done.current = true;
    }
  }), []);

  return (
    <div className="comfort-progress" style={{ visibility: visbile ? 'visible' : 'hidden' }}>
      <div className="progress-value" style={{ width: `${value}%` }} />
    </div>
  );
});

export default ComfortProgress;
