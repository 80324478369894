import NoticeTooltip from '@/components/NoticeTooltip';
import React from 'react';
import './index.scss';

interface LabelProps {
  label: string;
  notice?: string;
}

export default function Label({
  label,
  notice
}: LabelProps): React.ReactElement {
  return (
    <div className="sync-common-notice-label">
      <div className="label-title">{label}</div>
      {notice && <NoticeTooltip content={notice} />}
    </div>
  );
}
