import React from 'react';
import { Col, Row } from '@douyinfe/semi-ui';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import { CommonRequest } from '@/api/Request';
import { useLoadingCallback } from '@/utils';
import { chainList } from '@/store/reducers/constantReducer';
import {
  Block,
  Container,
  HeaderTitle,
  Icon,
  Layout,
  LearnMore
} from '@/components';
import './index.scss';
import { ComposerRequestTemplate } from '@/props';
import {
  TypeConfig,
  TypeItem
} from '@/views/dashboard/composer/ParameterInput';
import RequestExecutor from './RequestExecutor';
import { RequestTemplates } from './TemplateLoader';
import ResponseExecutor from './ResponseExecutor';

export default function Composer(): React.ReactElement {
  const { t } = useTranslation('translation');
  const { Header } = Layout;

  const chains = useSelector(chainList);
  const [
    requestTemplate,
    setRequestTemplate
  ] = React.useState<ComposerRequestTemplate>(
    _.find(
      RequestTemplates,
      (item) => item.name === 'getTokenHolders'
    ) || RequestTemplates[0]
  );

  const [responseData, setResponseData] = React.useState<any>(undefined);

  const getParameterValue = (value: string, type: string): any => {
    const typeConfig: TypeItem = TypeConfig[type];
    if (typeConfig?.parse) {
      return typeConfig?.parse(value as string);
    }

    return value;
  };

  const parseChainParameter = (params: any): any[] => _.map(requestTemplate.template.request.parameter, (item) => {
    if (item.type === 'object') {
      return _.mapValues(_.keyBy(_.map(item.parameter, (i) => ({
        key: i.key,
        value: getParameterValue(params[i.key], i.type)
      })), 'key'), 'value');
    }
    return getParameterValue(params[item.key], item.type);
  });

  const parseWeb3ServiceParameter = (params: any): any => {
    _.forEach(requestTemplate.template.request.parameter, (item) => {
      params[item.key] = getParameterValue(params[item.key], item.type);
    });
    return params;
  };

  const {
    callback: handleRequest,
    loading
  } = useLoadingCallback(async (values: Record<string, any>): Promise<void> => {
    const targetNetwork = _.find(
      _.find(
        chains,
        (c) => c.name === values.protocol
      )?.networks || [],
      (i) => i.chain_network === values.network
    );

    if (!targetNetwork) {
      return;
    }

    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      method,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      network,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      protocol,
      ...ext
    } = values;

    const postData = requestTemplate.type === 'chain' ? {
      url: targetNetwork?.test_url,
      data: {
        jsonrpc: '2.0',
        method,
        params: parseChainParameter(ext),
        id: targetNetwork?.chain_id
      }
    } : {
      url: process.env.REACT_APP_WEB3_SERVICE_URL as string,
      data: {
        action: method,
        chain_id: targetNetwork?.chain_id,
        ...parseWeb3ServiceParameter(ext)
      },
      config: {
        headers: {
          'X-API-KEY': process.env.REACT_APP_WEB3_SERVICE_API_KEY as string
        }
      }
    };

    try {
      const response = await CommonRequest.post(postData.url, postData.data, postData.config);
      setResponseData(response?.data);
    } catch (err: any) {
      if (err?.response) {
        setResponseData(err?.response?.data);
      }
    }
  });

  const handleChange = (record: any): void => {
    const target = _.find(
      RequestTemplates,
      (item) => item.name === record
    );
    if (target) {
      setResponseData(undefined);
      setRequestTemplate(target || {});
    }
  };

  return (
    <Container className="composer-container max-w-full">
      <HeaderTitle title={t('navigation.composer')} />

      <Header
        icon="navigation/composer"
        title={t('navigation.composer')}
      >
        <Row>
          <Col>
            <LearnMore
              className="api-document medium"
              icon={<Icon className="icon" icon="common/document" />}
              text={(
                <span className="btn-document">
                  {t('composer.apiDocument')}
                </span>
              )}
              to="https://docs.chainbase.com/docs/api-overview"
            />
          </Col>
        </Row>

      </Header>

      <Block className="composer-executor">
        <Row type="flex">

          <RequestExecutor
            requestTemplate={requestTemplate}
            onChange={handleChange}
            onRequest={handleRequest}
            loading={loading}
          />

          <ResponseExecutor
            loading={loading}
            responseData={responseData}
            requestTemplate={requestTemplate}
          />
        </Row>
      </Block>
    </Container>
  );
}
