/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable camelcase */
import { useRef, useState } from 'react';
import { Icon, Modal } from '@/components';
import './index.scss';
import { useParams } from 'react-router';
import { BlockLoading } from '@/components/Loading/BlockLoading';
import { Button, TabPane, Tabs } from '@douyinfe/semi-ui';
import { useTranslation } from 'react-i18next';
import { IntegrationConfigMapping } from '@/config/sync';
import { useLocation, useNavigate } from 'react-router-dom';
import IntegrationEditPageHeader from '@/views/sync/commons/IntegrationEditPageHeader';
import { UpdateIntegrationDTO } from '@/model/Sync';
import useIntegrationDetail from './utils/useIntegrationDetail';
import IntegrationConfiguration, { IntegrationConfigurationRef } from '../commons/IntegrationConfiguration';
import JobList from '../commons/JobList';
import EmptyContent from './components/EmptyContent';
import { useDeleteIntegration, useUpdateIntegration } from '../utils/JobRequestHooks';

export const JobsTabKey = '#jobs';
export const ConfigurationsTabKey = '#configurations';

export default function IntegrationDetail() {
  const nav = useNavigate();
  const { hash } = useLocation();
  const defaultActiveKey = hash === ConfigurationsTabKey ? ConfigurationsTabKey : JobsTabKey;
  const { t } = useTranslation();
  const { integrationId } = useParams();
  const { isMutating: isDeleting, trigger: deleteIntegration } = useDeleteIntegration();
  const { isMutating: isSaving, trigger: saveIntegration } = useUpdateIntegration(integrationId || '');
  const { integration, isLoading, notFound, relatedJobDatas } = useIntegrationDetail(integrationId || '');
  const [allowSave, setAllowSave] = useState(false);
  const integrationConfigurationRef = useRef<IntegrationConfigurationRef>(null);

  if (isLoading) {
    return <BlockLoading />;
  }
  if (notFound || !integration || !relatedJobDatas || !integration) {
    nav('/sync/integrations');
    return (
      <div>404 NOT FOUND</div>
    );
  }

  const integrationTemplate = IntegrationConfigMapping[integration?.integrate_type?.toLowerCase()];

  const onCreated = () => {
    nav('/sync/integrations');
  };

  const onDeleteClick = () => {
    const runningJobCount = relatedJobDatas.filter((job) => job.status === 1).length;
    if (runningJobCount === 0) {
      Modal.info({
        title: 'Confirm deletion',
        content: (<div className="mt-8 mb-10 font-normal text-sm">
          <div>Are you sure to delete the integration?</div>
          <div>the corresponded jobs have all been deleted</div>
        </div>),
        okText: 'Delete',
        cancelText: 'Cancel',
        okButtonProps: {
          type: 'danger',
          theme: 'borderless',
          className: 'job-detail-modal-button'
        },
        cancelButtonProps: {
          className: 'job-detail-modal-cancel-button job-detail-modal-button'
        },
        onOk() {
          if (!integrationId) return;
          deleteIntegration(integrationId);
        }
      });
    } else {
      Modal.info({
        title: 'Confirm deletion',
        content: (<div className="mt-8 mb-10 font-normal text-sm">
          <div>
            {`There are still ${runningJobCount} jobs in the integration \
            that must be removed before integration need to be deleted`}
          </div>
        </div>),
        okText: 'Continue',
        cancelText: 'Cancel',
        okButtonProps: {
          theme: 'solid'
        },
        cancelButtonProps: {
          className: 'job-detail-modal-cancel-button job-detail-modal-button'
        },
        onOk() {
          nav('/sync/jobs');
        }
      });
    }
  };

  const onSaveClick = () => {
    Modal.info({
      title: 'Confirm save',
      content: (<div className="mt-8 mb-10 font-normal text-sm">
        <div>Configuration changes will affects new job instances.</div>
      </div>),
      okText: 'Save',
      cancelText: 'Cancel',
      okButtonProps: {
        theme: 'solid',
        className: 'job-detail-modal-button'
      },
      cancelButtonProps: {
        className: 'job-detail-modal-cancel-button job-detail-modal-button'
      },
      onOk() {
        if (!integrationId) return;
        const formValues = integrationConfigurationRef.current?.getFormValues();
        if (!formValues) {
          return;
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { integration_type, integration_name, ...extraConfig } = formValues;
        const dto: UpdateIntegrationDTO = {
          integration_name,
          extra_config: {
            ...extraConfig
          }
        };
        saveIntegration(dto);
      }
    });
  };

  const renderTab = (tab: string) => (
    <div className={`tab ${tab}`}>
      <Icon
        className="icon"
        icon={`sync/tabs/${tab}`}
      />
      <div className="title">
        {t(`sync.tabs.${tab}`)}
      </div>
    </div>
  );

  const { integrate_type, integration_name } = integration;

  return (
    <div className="integration-detail-container">
      <IntegrationEditPageHeader integrationConfig={integrationTemplate} />
      <div className="integration-edit-page-tabs-container">
        <Tabs
          defaultActiveKey={defaultActiveKey}
          onChange={(key) => nav(key)}
        >
          <TabPane
            tab={renderTab('jobs')}
            itemKey={JobsTabKey}
          >
            {relatedJobDatas.length === 0 ? <EmptyContent />
              : (
                <JobList
                  jobList={relatedJobDatas}
                  integrationTitle={integrationTemplate.introduction.title}
                />
              )}
          </TabPane>
          <TabPane
            tab={renderTab('configurations')}
            itemKey={ConfigurationsTabKey}
          >
            <div className="configuration-wrapper">
              <IntegrationConfiguration
                ref={integrationConfigurationRef}
                configType="save"
                onCancel={() => nav('/sync/integrations')}
                onCreated={onCreated}
                initialData={{ integrate_type, integration_name, ...JSON.parse(integration.extra_config ?? '{}') }}
                formTemplate={integrationTemplate}
                onConnectableChange={(newConnectable) => setAllowSave(newConnectable)}
              />
              <div className="delete-button-wrapper">
                <Button
                  type="danger"
                  theme="light"
                  className="delete-button bottom-button-item"
                  loading={isDeleting}
                  onClick={onDeleteClick}
                >
                  Delete
                </Button>
                <Button
                  theme="solid"
                  className="bottom-button-item"
                  loading={isSaving}
                  onClick={onSaveClick}
                  disabled={!allowSave}
                >
                  Save
                </Button>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}
