import ProjectApi from '@/api/ProjectApi';
import useSWRImmutable from 'swr/immutable';

const fetcher = () => ProjectApi.getList().then((res) => res.data);

export default function useApiKey() {
  const { data, isLoading } = useSWRImmutable('/v2/projects', fetcher);

  const apiKey = data?.result?.find(
    (project: any) => project.secret_key
  )?.secret_key;

  return { apiKey, isLoading };
}
