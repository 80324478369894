import React from 'react';
import { Icon } from '@/components';
import './index.scss';
import { Optional } from '@/props';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type TableEmptyProps = {
  className?: string;
  icon?: string;
  title?: string;
  desc?: string | React.ReactNode;
  children?: React.ReactElement
}

export default function TableEmpty({
  className,
  icon,
  title,
  desc,
  children
}: Optional<TableEmptyProps>): React.ReactElement {
  const { t } = useTranslation('translation');
  return (
    <div className={classNames('table-empty-default', className)}>
      <Icon className="icon" icon={icon || 'table/empty'} />

      {
        !title && !desc ? <p className="desc">{t('common.noData')}</p> : null
      }

      {title ? <h3 className="title">{title}</h3> : null}
      {desc ? <p className="desc">{desc}</p> : null}

      {children}
    </div>
  );
}
