import axios, { AxiosInstance } from 'axios';
import {
  CreateQueryDTO,
  CreateQueryData,
  ExecuteQueryByIdDTO,
  ExecuteQueryData,
  ExecuteSQLQueryDTO,
  ExecutionResultData,
  ExecutionStatusData,
  TaskResponse,
  UpdateQueryDTO,
  UpdateQueryData
} from './index.d';
import Request from '../Request';

export default class TaskApi {
  client: AxiosInstance;

  constructor() {
    this.client = axios.create();
  }

  static getMetadata(table: string) {
    return Request.get(`v2/datacloud/metadata/${table}`);
  }

  async createQuery(data: CreateQueryDTO) {
    const response = await this.client.post<TaskResponse<CreateQueryData[]>>(
      '/api/v2/datacloud/query',
      data
    );
    return response;
  }

  // async listQueries() {}

  // async deleteQuery() {}

  async updateQuery(data: UpdateQueryDTO) {
    const response = await this.client.put<TaskResponse<UpdateQueryData[]>>(
      '/api/v2/datacloud/query',
      data
    );
    return response;
  }

  async executeSQLQuery(data: ExecuteSQLQueryDTO) {
    const response = await this.client.post<TaskResponse<ExecuteQueryData[]>>(
      '/api/v2/datacloud/query/execute',
      data
    );
    return response;
  }

  async executeQueryById(queryId: number, data: ExecuteQueryByIdDTO) {
    const response = await this.client.post<TaskResponse<ExecuteQueryData[]>>(
      `/api/v2/datacloud/query/${queryId}/execute`,
      data
    );
    return response;
  }

  async getExecutionStatus(executionId: string) {
    const response = await this.client.get<TaskResponse<ExecutionStatusData[]>>(
      `/api/v2/datacloud/execution/${executionId}/status`
    );
    return response;
  }

  async getExecutionResult(executionId: string) {
    const response = await this.client.get<TaskResponse<ExecutionResultData>>(
      `/api/v2/datacloud/execution/${executionId}/results`
    );
    return response;
  }

  async cancelExecution(executionId: string) {
    const response = await this.client.get(`/api/v2/datacloud/execution/${executionId}/cancel`);
    return response;
  }

  setSignal(signal: AbortSignal) {
    this.client.defaults.signal = signal;
  }
}

export * from './index.d';
