/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Icon, Toast } from '@/components';
import { sqlFileDB as db } from '@/utils/db/dataCloud/sqlFileDB';
import { MouseEventHandler, ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WrappedTooltip from '@/components/Tooltip';
import { Input, InputNumber, Select } from '@douyinfe/semi-ui';
import { ActiveTabContext } from './ActiveTabProvider';
import { SQLFilesContext } from './SQLFilesProvider';
import useDynamicParametersBuilder from './utils/useDynamicParametersBuilder';
import { EditorRefContext } from './EditorRefProvider';
import './ParametersProvider.scss';

export type ParameterKey = 'name' | 'dataType' | 'defaultValue';

export interface Parameter {
  name: string;
  dataType: string;
  defaultValue: string;
  value?: any;
}

export interface ParametersContext {
  parameterList: Parameter[],
  edit(index: number): void;
  add(): void;
  onTabClose(tabKey: string): void;
  dynamicBuild(text: string): Parameter[];
}

export interface ParametersProviderProps {
  children: ReactNode
}

export interface EditingParameter {
  mode: 'add' | 'edit',
  index: number,
  data: Parameter,
}

export const ParametersContext = createContext<ParametersContext>({
  parameterList: [],
  edit(): void {
    throw new Error('Function not implemented.');
  },
  add(): void {
    throw new Error('Function not implemented.');
  },
  onTabClose() {
    throw new Error('Function not implemented.');
  },
  dynamicBuild(): Parameter[] {
    throw new Error('Function not implemented.');
  }
});

export default function ParametersProvider({ children }: ParametersProviderProps) {
  const nameNotice = 'Note your parameter should be wrapped in mustache {{ }}';
  const sqlParameterTypes = useMemo(() => ['Text', 'Integer', 'Boolean'], []);

  const { t } = useTranslation();
  const editParameterRef = useRef<HTMLDialogElement>(null);
  const [editingParameter, setEditingParameter] = useState<EditingParameter | null>(null);
  const [displayNotice, setDisplayNotice] = useState<string | null>(null);
  const [validate, setValidate] = useState(false);
  const { build: dynamicBuild, remove } = useDynamicParametersBuilder();
  const editorRef = useContext(EditorRefContext);

  const activeTab = useContext(ActiveTabContext);
  const tabActiveKey = activeTab.current;
  const sqlFiles = useContext(SQLFilesContext);
  const curSqlFile = sqlFiles?.find((file) => file.uuid === tabActiveKey);
  const currentTab = curSqlFile ? {
    key: curSqlFile.uuid,
    name: curSqlFile.filename,
    sql: curSqlFile.content
  } : undefined;
  const parameterList: Parameter[] = useMemo(() => (curSqlFile ? JSON.parse(curSqlFile.parameters) : []), [curSqlFile]);

  useEffect(() => {
    if (!editParameterRef.current) {
      return undefined;
    }

    const dialog = editParameterRef.current;

    const cancelEventListener = (event: Event) => {
      event.preventDefault();
    };

    dialog.addEventListener('cancel', cancelEventListener);

    return () => {
      dialog.removeEventListener('cancel', cancelEventListener);
    };
  }, []);

  const toggleModal = (props: {
    dialogClassToken: string,
    warpperClassToken: string,
    action: 'open' | 'close',
    beforeAnimation?: (dialog: HTMLDialogElement) => void,
    afterAnimation?: (dialog: HTMLDialogElement) => void
  }) => {
    const { dialogClassToken, warpperClassToken, action, beforeAnimation, afterAnimation } = props;
    if (editParameterRef.current) {
      const dialog = editParameterRef.current;
      const warpper = dialog.parentElement;

      dialog.classList.add(dialogClassToken);
      if (warpper) {
        warpper.classList.add(warpperClassToken);
        if (action === 'open') {
          warpper.classList.remove('hidden');
        }
      }

      if (beforeAnimation) {
        beforeAnimation(dialog);
      }

      const listener = () => {
        dialog.classList.remove(dialogClassToken);

        if (afterAnimation) {
          afterAnimation(dialog);
        }

        if (warpper) {
          warpper.classList.remove(warpperClassToken);
          if (action === 'close') {
            warpper.classList.add('hidden');
          }
        }

        dialog.removeEventListener('animationend', listener);
      };

      dialog.addEventListener('animationend', listener);
    }
  };

  const openModal = useCallback(() => {
    toggleModal({
      dialogClassToken: 'parameter-editor',
      warpperClassToken: 'parameter-editor-warpper-open',
      action: 'open',
      beforeAnimation(dialog) {
        dialog.showModal();
      }
    });
  }, []);

  const closeModal = () => {
    toggleModal({
      dialogClassToken: 'parameter-editor-close',
      warpperClassToken: 'parameter-editor-warpper-close',
      action: 'close',
      afterAnimation(dialog) {
        dialog.close();
      }
    });

    setValidate(false);
    setEditingParameter(null);
  };

  const parametersContext: ParametersContext = useMemo(() => ({
    parameterList,
    edit(index: number): void {
      setEditingParameter({
        index,
        mode: 'edit',
        data: {
          ...parameterList[index]
        }
      });
      openModal();
    },
    add(): void {
      setEditingParameter({
        index: parameterList.length,
        mode: 'add',
        data: {
          name: '',
          dataType: sqlParameterTypes[0],
          defaultValue: ''
        }
      });
      openModal();
    },
    onTabClose(tabKey: string) {
      db.sqlFile.delete(tabKey);
    },
    dynamicBuild
  }), [dynamicBuild, openModal, parameterList, sqlParameterTypes]);

  const isValidName = (): boolean => (editingParameter ? editingParameter.data.name.length > 0 : false);

  const isValidDefaultValue = (): boolean => {
    const inputValue = editingParameter?.data.defaultValue.toLowerCase();
    const selectedDataType = editingParameter?.data.dataType ?? sqlParameterTypes[0];
    switch (selectedDataType.toLowerCase()) {
      case 'integer':
        if (inputValue === undefined
          || inputValue === ''
          || inputValue.endsWith('.')
          || !Number.isInteger(Number(inputValue))
        ) {
          return false;
        }
        break;
      case 'boolean':
        if (inputValue !== 'true' && inputValue !== 'false') {
          return false;
        }
        break;
      case 'text':
        if (inputValue === undefined) {
          return false;
        }
        break;
      default:
        break;
    }
    return true;
  };

  /**
   * Handlers
   */
  const onInputChange = (value: string, label: ParameterKey) => {
    setValidate(false);
    if (editingParameter === null) {
      return;
    }
    const newEditingParameter = {
      ...editingParameter
    };
    newEditingParameter.data[label] = value;
    setEditingParameter(newEditingParameter);
  };

  const onSave = async () => {
    setValidate(true);
    if (editingParameter === null) {
      return;
    }
    if (currentTab === undefined) {
      return;
    }
    if (!isValidDefaultValue() || !isValidName()) {
      return;
    }
    const newParameterList = [...parameterList];
    if (editingParameter.mode === 'add') {
      if (parameterList.find((val) => val.name === editingParameter.data.name)) {
        Toast.error({
          content: 'Name existed'
        });
        return;
      }
      newParameterList.push(editingParameter.data);
      const newParam = newParameterList[newParameterList.length - 1];
      const appendContent = `{{${newParam.name}}}`;
      if (!curSqlFile?.content.includes(appendContent)) {
        editorRef.insert(appendContent);
      }
    } else {
      const { index, data } = editingParameter;
      newParameterList[index] = data;
    }
    db.sqlFile.update(currentTab.key, {
      parameters: JSON.stringify(newParameterList)
    });

    closeModal();
  };

  const onCancel = () => {
    if (editingParameter === null) {
      return;
    }

    closeModal();
  };

  const onRemove = () => {
    if (editingParameter === null) {
      return;
    }

    if (currentTab === undefined) {
      return;
    }
    const newParameterList = parameterList.filter((_, index) => editingParameter.index !== index);

    remove(editingParameter.data.name);
    db.sqlFile.update(currentTab.key, {
      parameters: JSON.stringify(newParameterList)
    });

    closeModal();
  };

  const onDialogClick: MouseEventHandler<HTMLDialogElement> = (e) => {
    const element = e.currentTarget;
    const rect = element.getBoundingClientRect();
    if ((e.clientX > rect.left && e.clientX < rect.right)
      && (e.clientY > rect.top && e.clientY < rect.bottom)) {
      e.stopPropagation();
    }
  };

  /**
   * Renders
   */
  const renderNameInput = () => (
    <div
      id="name-input-label"
      className="w-full h-[58px] flex flex-col justify-between box-border mt-4 relative editor-name-input"
    >
      <label
        className="flex items-center"
        htmlFor="name"
      >
        <span className="font-[500]">
          {t('dataCloud.editParameter.name')}
        </span>
        <WrappedTooltip
          disabled={displayNotice !== 'name'}
          content={nameNotice}
          position="right"
          getPopupContainer={() => document.querySelector('#name-input-label') as HTMLElement}
        >
          <span
            id="name-notice-icon"
            className="ml-1 relative"
            onMouseEnter={() => setDisplayNotice('name')}
            onMouseLeave={() => setDisplayNotice(null)}
          >
            <Icon
              icon="dataCloud/edit-parameter-info"
              className="w-4 h-4"
            />
          </span>
        </WrappedTooltip>
      </label>
      <Input
        className="parameter-editor-input"
        onChange={(value:any) => onInputChange(value, 'name')}
        value={editingParameter ? editingParameter.data.name : ''}
        validateStatus={!validate || isValidName() ? 'default' : 'error'}
      />
    </div>
  );

  const renderDataTypeSelect = () => (
    <div
      className="w-full h-[58px] flex flex-col justify-between box-border mt-[23px] relative editor-data-type-select"
      id="data-type-selector-container"
    >
      <label
        className="flex items-center relative"
        htmlFor="dataType"
      >
        <span className="font-[500]">
          {t('dataCloud.editParameter.dataType')}
        </span>
      </label>
      <Select
        size="default"
        className="parameter-editor-selector"
        defaultValue={sqlParameterTypes[0]}
        getPopupContainer={() => document.querySelector('#data-type-selector-container') as HTMLElement}
        onChange={(type: any) => {
          if (typeof type === 'string') {
            onInputChange(type, 'dataType');
          }
        }}
        value={editingParameter?.data.dataType ?? sqlParameterTypes[0]}
      >
        {sqlParameterTypes.map((type) => (
          <Select.Option key={type} value={type}>
            {type}
          </Select.Option>
        ))}
      </Select>
    </div>
  );

  const renderDefaultValueInput = () => {
    let input;
    if (editingParameter?.data.dataType === 'Text') {
      input = (
        <Input
          className="parameter-editor-input"
          onChange={(value: any) => onInputChange(value, 'defaultValue')}
          value={editingParameter.data.defaultValue || ''}
          validateStatus={!validate || isValidDefaultValue() ? 'default' : 'error'}
        />
      );
    } else if (editingParameter?.data.dataType === 'Integer') {
      input = (
        <InputNumber
          className="parameter-editor-input"
          formatter={(value: any) => `${value}`.replace(/\D/g, '')}
          onChange={(value: any) => onInputChange(value.toString(), 'defaultValue')}
          value={editingParameter.data.defaultValue || ''}
          validateStatus={!validate || isValidDefaultValue() ? 'default' : 'error'}
        />
      );
    } else {
      let defaultValue = editingParameter?.data.defaultValue;
      if (defaultValue !== 'True' && defaultValue !== 'False') {
        defaultValue = 'True';
        if (editingParameter) {
          editingParameter.data.defaultValue = defaultValue;
        }
      }
      input = (
        <Select
          id="parameter-editor-default-value"
          className="parameter-editor-selector"
          getPopupContainer={() => document.querySelector('#parameter-editor-default-value') as HTMLElement}
          onChange={(e: any) => onInputChange(e?.toString() ?? '', 'defaultValue')}
          value={defaultValue}
        >
          <Select.Option value="True">True</Select.Option>
          <Select.Option value="False">False</Select.Option>
        </Select>
      );
    }

    return (
      <div
        className="w-full h-[58px] flex flex-col justify-between box-border mt-4"
      >
        <label
          className="flex items-center relative"
          htmlFor="defaultValue"
        >
          <span className="font-[500]">
            {t('dataCloud.editParameter.defaultValue')}
          </span>
        </label>
        {input}
      </div>
    );
  };

  const renderDialog = () => (
    <div
      className="parameter-editor-warpper hidden"
      onClick={onCancel}
      onKeyDown={(e) => e.key === 'Escape' && onCancel()}
    >
      <dialog
        className="w-[480px] h-[420px] z-50 rounded-sm border-none p-8 box-border"
        ref={editParameterRef}
        onClick={onDialogClick}
      >
        <form className="h-full flex flex-col justify-between">
          <h1>{t('dataCloud.editParameter.title')}</h1>
          <div className="border-solid border-[1px] border-[#F7F7F7] mt-4" />
          {renderNameInput()}
          {renderDataTypeSelect()}
          {renderDefaultValueInput()}
          <div className="mt-8 flex justify-between">
            <div>
              <button
                className="w-20 h-9 m-0 p-0 bg-[#FFEBEA] text-[#C6362C] rounded-[4px] font-medium font-[Roboto] hover:bg-[#FFC1BD]"
                style={{ display: editingParameter?.mode === 'add' ? 'none' : '' }}
                type="button"
                onClick={onRemove}
              >
                {t('dataCloud.editParameter.remove')}
              </button>
            </div>
            <div className="flex gap-2">
              <button
                className="w-20 h-9 m-0 p-0 bg-[#F6F8FA] text-black rounded-[4px] font-medium font-[Roboto] hover:bg-[#EDF1F4]"
                type="button"
                onClick={onCancel}
              >
                {t('dataCloud.editParameter.cancel')}
              </button>
              <button
                className="w-20 h-9 m-0 p-0 bg-[#2E363A] text-white rounded-[4px] font-medium font-[Roboto] hover:bg-[#191E21]"
                type="button"
                onClick={onSave}
              >
                {t('dataCloud.editParameter.save')}
              </button>
            </div>
          </div>
        </form>
      </dialog>
    </div>
  );

  return (
    <ParametersContext.Provider value={parametersContext}>
      {children}
      {renderDialog()}
    </ParametersContext.Provider>
  );
}
