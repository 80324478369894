/* eslint-disable max-len */
import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DataExplorerTabs, { DataExplorerTabsRef } from '@/components/DataExplorerTabs';
import { isTable } from '@/store/reducers/dataCloudReducer';
import { ColumnSchema, TableSchema } from '@/model/Table';
import TableDetail from '@/views/sync/job-creation/components/setupSource/TableDetail';
import './index.scss';
import useSWRImmutable from 'swr/immutable';
import TaskApi from '@/api/TaskApi/index';
import { JobCreationContext } from '../JobCreationProvider';

export const useTableMetadataDetail = (tableName?: string) => useSWRImmutable(`TableMetadataDetail:${tableName}`, async () => {
  if (!tableName) {
    return undefined;
  }

  try {
    const response = await TaskApi.getMetadata(tableName);
    return response.data.data;
  } catch {
    return undefined;
  }
});

export default function SetupSource(): React.ReactElement {
  const { t } = useTranslation();
  const { jobCreationState, jobCreationDispatch } = useContext(JobCreationContext);
  const { selectedTable } = jobCreationState;
  const dataExplorerTabs = useRef<DataExplorerTabsRef>(null);
  const { data: tableDetail, isLoading: tableDetailLoading } = useTableMetadataDetail(selectedTable?.title?.table_name);

  const onTableClick = (table: TableSchema | ColumnSchema) => {
    if (!isTable(table)) {
      return;
    }
    const [prefixName, suffixName] = table.table_name.split('.');
    const newTable = {
      title: {
        blockchain: table.schema,
        name: table.name,
        table_name: table.table_name,
        prefixName,
        subfixName: suffixName
      },
      desc: table.description,
      statistics: {
        rows: table.row_count,
        latestBlockTimeStamp: table.latest_block_timestamp
      },
      columns: table.columns.map((col) => ({
        field: col.name,
        type: col.type,
        description: col.description || ''
      }))
    };

    jobCreationDispatch({ type: 'selectTable', value: newTable });
  };

  const renderLeftCard = (): React.ReactElement => (
    <div className="left-card card">
      <DataExplorerTabs
        ref={dataExplorerTabs}
        previewable={false}
        onTableClick={onTableClick}
      />
    </div>
  );

  const renderRightCard = (): React.ReactElement => <TableDetail key={tableDetail?.table_name || ''} selectedTable={selectedTable} detail={tableDetail} isLoading={tableDetailLoading} />;

  const renderBottomDisplay = (): React.ReactElement => {
    if (!selectedTable) {
      return <div />;
    }

    return (
      <div className="bottom-display">
        <span>
          {t('sync.jobs.newJobSteps.setupSource.content.selected')}
        </span>
        <span className="font-[600]">{selectedTable?.title?.table_name}</span>
      </div>
    );
  };

  return (
    <div className="setup-source-container">
      <div className="two-cards">
        {renderLeftCard()}
        {renderRightCard()}
      </div>
      {renderBottomDisplay()}
    </div>
  );
}
