import React, { ComponentType } from 'react';
import ReactDOM from 'react-dom';
import {
  motion,
  MotionStyle,
  Target,
  TargetAndTransition,
  Transition
} from 'framer-motion';
import { ComponentProps } from '@/props';
import './index.scss';

export declare type OverlayProps = {
  onClick?: VoidFunction;
}

const defaultOverlayProps: OverlayProps = {
  onClick: () => undefined
};

export function Overlay({ onClick } = defaultOverlayProps): React.ReactElement {
  const initial: Target = {
    opacity: 0
  };
  const animate: Target = {
    opacity: 1
  };

  const exit: TargetAndTransition = {
    opacity: 0,
    transition: { duration: 0.2 }
  };

  const transition: Transition = {
    duration: 0.2,
    delay: 0.15
  };

  const style: MotionStyle = { pointerEvents: 'auto' };

  return (
    <motion.div
      initial={initial}
      animate={animate}
      exit={exit}
      transition={transition}
      style={style}
      className="overlay"
      onClick={onClick}
    />
  );
}

export declare type ModalProps = {
  close: VoidFunction;
};

export function openModal(
  Dialog: React.FC | ComponentType<any>,
  props: any
): void {
  const div = document.createElement('div');

  document.body.appendChild(div);

  ReactDOM.render(React.createElement(
    Dialog,
    {
      ...props,
      close: () => {
        ReactDOM.unmountComponentAtNode(div);
        div.remove();
      }
    }
  ), div);
}

declare interface ModalComponentProps extends ComponentProps {
  overlay?: React.FC | React.ReactNode;
  onClose?: VoidFunction;
}

const defaultModalComponentProps: ModalComponentProps = {
  overlay: undefined,
  onClose: () => undefined,
  children: undefined
};

export function MotionModal({
  children,
  overlay,
  onClose
} = defaultModalComponentProps): React.ReactElement {
  return (
    <div className="modal">
      {overlay || <Overlay onClick={onClose} />}

      {children}
    </div>
  );
}
