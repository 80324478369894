import React from 'react';
import './index.scss';
import { ComponentProps } from '../props';

const defaultCardProps: ComponentProps = {
  children: null,
  className: ''
};

export default function Card({
  className,
  children
} = defaultCardProps): React.ReactElement {
  return (
    <div className={`card ${className}`}>
      {children}
    </div>
  );
}
