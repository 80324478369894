import './index.scss';
import ComfortProgress, { ComfortProgressRef } from '@/components/ComfortProgress';
import { Button, Card } from '@douyinfe/semi-ui';
import { CodeBlock } from 'react-code-blocks';
import SQLTheme from '@/components/CodeBlock/themes/sql';
import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import useSWRMutation from 'swr/mutation';
import { Icon } from '@/components';
import Request from '@/api/Request';
import axios from 'axios';
import CollapsePanel from '@/components/CollapsePanel';
import { CopyButton } from '@/views/project/Components';
import { useTranslation } from 'react-i18next';
import { TableColumn } from '../../../JobCreationProvider';

interface CreateTableCardProps {
  integrationId: string;
  tableName: string;
  tableColumns: TableColumn[];
  selectedFields: string[];
  desitinationFields: Record<string, string>;
  onTestTableSucceedChange: (succeed: boolean) => void;
}

export interface CreateTableCardRef {
  resetTestStatus: () => void;
}

const CreateTableCard = React.forwardRef<CreateTableCardRef, CreateTableCardProps>(({
  integrationId,
  tableName,
  tableColumns,
  selectedFields,
  desitinationFields,
  onTestTableSucceedChange
}, ref) => {
  const { t } = useTranslation();
  const [progressKey, setProgressKey] = useState(0);
  const progressRef = useRef<ComfortProgressRef>(null);

  const { data, error, isMutating, trigger: test, reset } = useSWRMutation('1', async () => {
    const response = await Request.post('/v2/sync/integrations/check_table', {
      integration_id: integrationId,
      table_name: tableName
    });
    return response;
  }, {
    onSuccess() {
      onTestTableSucceedChange(true);
    },
    onError() {
      onTestTableSucceedChange(false);
    }
  });

  useImperativeHandle(ref, () => ({
    resetTestStatus() {
      reset();
      setProgressKey((key) => key + 1);
    }
  }), [reset]);

  const errorMessage = useMemo(() => {
    if (axios.isAxiosError(error)) {
      return error.response?.data?.msg ?? 'Unknown error';
    }
    if (typeof error?.message === 'string') {
      return error.message;
    }
    return 'Unknown error';
  }, [error]);

  useEffect(() => {
    if (data !== undefined) {
      progressRef.current?.done();
    }
  }, [data]);

  useEffect(() => {
    if (error === undefined) {
      return;
    }

    progressRef.current?.stop();
  }, [error]);

  const createTableSql = useMemo(() => {
    let sql = `CREATE TABLE ${tableName} (\n`;
    const tableColumnMap = new Map<string, TableColumn>();
    tableColumns?.forEach((col) => {
      tableColumnMap.set(col.field, col);
    });
    selectedFields.forEach((field) => {
      const column = tableColumnMap.get(field);
      const desitinationFieldName = desitinationFields[field] ?? field;
      if (!column) {
        throw new Error(`Unexpected undefined of column: ${field}`);
      }
      sql += `    ${desitinationFieldName} ${column.type},\n`;
    });
    sql = sql.slice(0, -2);
    sql += '\n);';
    return sql;
  }, [desitinationFields, selectedFields, tableColumns, tableName]);

  const onTestTableClick = () => {
    setProgressKey((key) => key + 1);
    reset();
    test();
    setTimeout(() => progressRef.current?.start(), 300);
  };

  return (
    <div className="create-table-card">
      <Card>
        <CollapsePanel
          label={<h2 className="title">Create Table SQL</h2>}
          iconPosition="right"
        >
          <div className="code-wrapper">
            <div className="code">
              <CodeBlock
                text={createTableSql}
                language="sql"
                showLineNumbers
                startingLineNumber
                wrapLines
                theme={SQLTheme}
              />
            </div>
            <div className="test-table-connection">
              <ComfortProgress key={progressKey} ref={progressRef} />
              <div className="button-stauts-row">
                <Button
                  loading={isMutating}
                  className="test-button"
                  onClick={onTestTableClick}
                >
                  Test Table
                </Button>
                <div className="status-info">
                  {data && <Icon className="success-icon" icon="common/success" />}
                  {error && (
                  <div className="error-status">
                    <Icon className="error-icon" icon="common/error" />
                    {errorMessage}
                  </div>
                  )}
                </div>
              </div>

              <CopyButton
                content={createTableSql}
                className="copy-button ml-auto"
                style={
                {
                  width: undefined,
                  height: undefined,
                  border: 'none'
                }
              }
              >
                {t('common.copy')}
              </CopyButton>
            </div>
          </div>
        </CollapsePanel>
      </Card>
    </div>
  );
});

export default CreateTableCard;
