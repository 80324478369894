export const defaultConfig = {
  baseDpr: 2, // base device pixel ratio (default: 2)
  remUnit: 144, // rem unit value (default: 75)
  remPrecision: 4, // rem value precision (default: 6)
  forcePxComment: 'px', // force px comment (default: `px`)
  keepComment: 'no' // no transform value comment (default: `no`)
};

export function calcREM(type: any, value: number, dpr = 2): any {
  const config = defaultConfig;

  function getValue(val: any): any {
    val = parseFloat(val.toFixed(config.remPrecision)); // control decimal precision of the calculated value
    return val === 0 ? val : val + type;
  }

  return (type === 'px' ? getValue((value * dpr) / config.baseDpr) : getValue(value / config.remUnit));
}

export function calcPX(value: number): number {
  // const rate = value / (defaultConfig.remUnit * 10);
  // const targetWidth = (width * rate).toFixed(0);
  // return parseInt(targetWidth, 10);
  return value;
}

export function calcPXByScreen(value: number): number {
  return calcPX(value);
}

export default {
  calcREM,
  calcPXByScreen
};
