import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { ColumnProps } from '@douyinfe/semi-ui/lib/es/table/interface';
import { ColumnSchema, TableRow } from '@/model/Table';
import './ResultTable.scss';
import {
  ColumnRenderReturnType
} from '@douyinfe/semi-ui/table/interface';

type ResultTableProps = Omit<{
  meta: Array<ColumnSchema>,
  data: Array<TableRow> | Array<Array<any>>,
  reverse?: boolean,
  isKVData?: boolean,
}, ''>

export default function ResultTable({
  meta,
  data,
  reverse = false,
  isKVData = false
}: ResultTableProps): React.ReactElement {
  const columns: ColumnProps<TableRow>[] = _.map(meta, (item) => ({
    title: item.name,
    dataIndex: isKVData ? item.key : item.name,
    className: classNames([
      { 'text-right': _.includes(['UInt64', 'UInt32', 'bigint', 'integer'], item.type) }
    ]),
    render: (value: any, record: TableRow | Array<any>, index): ColumnRenderReturnType => {
      if (_.isArray(record)) {
        value = (record as Array<any>)[index];
      }

      if (_.isObject(value)) {
        return JSON.stringify(value);
      }

      if (isKVData) {
        if (value === null) {
          return <span className="text-[#A1AAB0]">Null</span>;
        }

        if (typeof value === 'boolean') {
          return <span>{value ? 'True' : 'False'}</span>;
        }
      }

      return value;
    }
  }));

  const result = reverse ? _.reverse(_.cloneDeep(data)) : data;

  return (
    <table className="result-table">
      <thead>
        <tr>
          {_.map(columns, (item, index) => (
            <th
              role="columnheader"
              key={`Header-${index}`}
              className={classNames([item.className])}
            >
              {item.title}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {
        _.map(result, (item: any, index) => (
          <tr
            key={`${index}`}
            role="row"
            aria-rowindex={index}
          >
            {
              _.map(columns, (col, cIndex) => (
                <td
                  key={`col-${cIndex}`}
                  role="cell"
                  aria-colindex={cIndex}
                  className={classNames([col.className])}
                >
                  {
                    col?.render && col?.render(item[col?.dataIndex || ''], item, cIndex, {})
                  }
                  {/* {`${item[col.dataIndex || '']}`} */}
                </td>
              ))
            }
          </tr>
        ))
      }
      </tbody>
    </table>
  );
}
