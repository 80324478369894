import React from 'react';
import { UserInfo } from '@/model/UserInfo';
import LocalStorage from '../../utils/LocalStorage';

type AuthContextProps = {
  authLoading: boolean;
  setAuthLoading: (_authLoading: boolean) => void;
  user: UserInfo;
  setCurrentUser: (currentUser: UserInfo) => void;
};

const AuthContext = React.createContext<AuthContextProps>({
  authLoading: false,
  setAuthLoading: () => undefined,
  user: null,
  setCurrentUser: () => undefined
});

export function useAuth(): AuthContextProps {
  return React.useContext(AuthContext);
}

export default function AuthProvider({ children }: { children: React.ReactElement }): React.ReactElement {
  const key = 'USERINFO';

  // load userinfo by localstorage
  const defaultValue: UserInfo = LocalStorage.get<UserInfo>(key);
  const [authLoading, setAuthLoading] = React.useState<boolean>(false);

  const [user, setUser] = React.useState<UserInfo>(defaultValue);

  const setCurrentUser = React.useCallback((currentUser: UserInfo): void => {
    LocalStorage.set<UserInfo>(key, currentUser);
    setUser(currentUser);
  }, []);

  const value = React.useMemo(
    () => ({
      user,
      authLoading,
      setAuthLoading,
      setCurrentUser
    }),
    [user, setCurrentUser, authLoading]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
