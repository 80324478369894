import React from 'react';
import { Form } from '@douyinfe/semi-ui';
import { useTranslation } from 'react-i18next';
import { LearnMore, Toast } from '@/components';
import { Guide } from '@/config/constant';
import { ModalForm } from '@/components/Modal';
import { useLoadingCallback } from '@/utils';
import ProjectApi from '@/api/ProjectApi';
import { Project } from '@/model/Project';
import { reportEvent } from '@/utils/useTrack';

export declare type ProjectFormProps = Omit<
  {
    project?: Project;
    onCancel: () => void;
    onComplete: () => void;
  },
  ''
>;

export default function ProjectFormModal({ project, onComplete, onCancel }: ProjectFormProps): React.ReactElement {
  const { t } = useTranslation(['dashboard', 'translation']);

  const { loading, callback: handleSubmit } = useLoadingCallback(async (values: Record<string, string>) => {
    let response = null;
    if (project?.uuid) {
      response = await ProjectApi.update({
        uuid: project.uuid,
        name: values.name,
        description: values.description
      });
    } else {
      response = await ProjectApi.create({
        name: values.name,
        description: values.description
      });
      reportEvent('projectCreated', {});
    }

    if (response.data.code !== 200) {
      Toast.toastResponse(response);
    } else {
      // toast success messsage

      Toast.success(
        (project?.uuid
          ? t<string>('toast.saved', { ns: 'translation' })
          : t<string>('toast.created', { ns: 'translation' })
        )
          .replace('{name}', response.data.result.name)
          .replace('{type}', t('projects.type'))
      );
      onComplete();
      onCancel();
    }
  });

  return (
    <ModalForm
      okButtonProps={{
        theme: 'solid',
        htmlType: 'submit'
      }}
      confirmLoading={loading}
      onSubmit={handleSubmit}
      okText={t(project?.uuid ? 'common.save' : 'common.create', { ns: 'translation' })}
      okType="primary"
      cancelText={t('common.cancel', { ns: 'translation' })}
      onCancel={onCancel}
    >
      <div className="create-project-wrapper">
        {project?.uuid ? null : (
          <>
            <p className="tip">{t('projects.createTip')}</p>
            <LearnMore to={Guide.projectGuide} text={t('projects.howToUse')} />
          </>
        )}

        <Form.Input
          field="name"
          maxLength={100}
          label={t('projects.createForm.name.label')}
          placeholder={t<string>('projects.createForm.name.placeholder')}
          autofocus
          initValue={project?.name}
          rules={[
            {
              required: true,
              message: `${t('projects.createForm.name.label')} ${t('common.required', { ns: 'translation' })}`
            }
          ]}
        />

        <Form.Input
          field="description"
          maxLength={200}
          initValue={project?.description}
          label={t('projects.createForm.description.label')}
          placeholder={t<string>('projects.createForm.description.placeholder')}
        />
      </div>
    </ModalForm>
  );
}
