import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NavHeader from '@/components/Layout/NavHeader';
import ProjectDetailNavigation from '@/components/Navigation/ProjectDetailNavigation';
import { useParams } from 'react-router-dom';
import { fetchProject } from '@/store/reducers/projectReducer';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import Layout from '../layout';

const ProjectLayout: React.FC = () => {
  const { t } = useTranslation('translation');
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const projectMap = useAppSelector((state) => state.project.projectMap);
  useEffect(() => {
    if (projectId && !projectMap[projectId]) {
      dispatch(fetchProject(projectId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const projectItem = projectId ? projectMap[projectId] : null;

  return (
    <Layout
      header={(
        <NavHeader
          routers={[
            { path: '/', href: '/', name: t('project.projects') },
            projectItem?.data?.name || ''
          ]}
          hideGoback
        />
      )}
      className="settings"
      navigation={<ProjectDetailNavigation />}
    />
  );
};

export default ProjectLayout;
