import {
  createAsyncThunk,
  createSlice,
  Draft,
  PayloadAction
} from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '@/store/store';
import { StringUtils } from '@/utils';
import { QueryTabItem } from '@/views/dataCloud/index/SQLEditor';
import {
  ArchivedQueryItem
} from '@/props';
import {
  TreeItemSchema,
  ResultTableType, TableSchema, GroupSchema, ColumnSchema
} from '@/model/Table';
import ArchivedQueriesApi from '@/api/ArchivedQueriesApi';
import { Toast } from '@/components';
import DataCloudApi from '@/api/DataCloudApi';

function isGroup(object: TableSchema | GroupSchema): object is GroupSchema {
  return 'children' in object;
}

function isTable(object: TableSchema | GroupSchema | ColumnSchema): object is TableSchema {
  return 'table_name' in object;
}

export { isGroup, isTable };

type StateProps = {
  tableSchemaLoading: boolean,
  activeKey?: string,
  tabs: QueryTabItem[],
  dataCloudTableTree: TreeItemSchema[];
  switchToAlpha: boolean,
};

export interface InitType {
  tabs: QueryTabItem[];
  from: 'v1' | 'v2';
}

const initialState: StateProps = {
  tableSchemaLoading: false,
  activeKey: undefined,
  tabs: [],
  dataCloudTableTree: [],
  switchToAlpha: false
};

export const fetchDataCloudTable = createAsyncThunk(
  'constant/getDataCloudTableTree',
  async () => {
    const response = await DataCloudApi.getTableList();
    const { data } = response.data;
    return data || [];
  }
);

export const updateArchivedQuery = createAsyncThunk(
  'dataCloud/update',
  async (data: ArchivedQueryItem): Promise<ArchivedQueryItem> => {
    const response = await ArchivedQueriesApi.update(data);
    Toast.toastResponse(response);

    const { data: result } = response.data;
    return result;
  }
);

type QueryPayload = { data: ResultTableType; tabKey?: string }

export const queryData = createAsyncThunk(
  'dataCloud/query',
  async (params: {
    sql: string,
    tabKey?: string,
    taskId?: string,
    page?: number
  }, thunkAPI): Promise<QueryPayload> => {
    const {
      sql,
      tabKey,
      taskId,
      page
    } = params;
    const response = await DataCloudApi.query({
      query: sql,
      task_id: taskId,
      page
    }, {
      signal: thunkAPI?.signal
    });

    const {
      code,
      data,
      message
    } = response.data;

    return {
      data: (code === 200 ? data : {
        err_msg: message
      }) as ResultTableType,
      tabKey
    };
  }
);

const addDefault = (state: Draft<StateProps>): void => {
  const pre = 'Query ';
  const maxName = _.maxBy(
    _.filter(
      state.tabs,
      (item) => item.name.startsWith(pre)
    ),
    (item) => _.toNumber(item.name.substring(item.name.indexOf(' ')))
  )?.name || 'Query 0';
  const max = _.toNumber(maxName.substring(maxName.indexOf(' ')));

  const defaultTabConfig: QueryTabItem = {
    key: StringUtils.uuid(),
    name: `${pre}${max + 1}`,
    sql: '',
    updated: false
  };

  state.tabs = [...state.tabs, defaultTabConfig];
  state.activeKey = defaultTabConfig.key;
};

export const dynamicGetTab = createAsyncThunk(
  'dataCloud/query',
  async (params: {
    tabKey: string
  }, thunkAPI): Promise<QueryTabItem | undefined> => {
    const {
      tabKey
    } = params;

    const rootState = thunkAPI.getState() as RootState;
    return _.find(rootState?.dataCloud?.tabs, (item) => item.key === tabKey);
  }
);

const clearQueryResult = (state: Draft<StateProps>): void => {
  const target = _.find(state.tabs, (item) => item.key === state.activeKey);
  if (!target) {
    return;
  }

  target.resultLoading = true;
  target.result = undefined;
};

export const dataCloudReducer = createSlice({
  name: 'ConstantSlice',
  initialState,
  reducers: {
    changeActiveKey: (state, action: PayloadAction<string>) => {
      state.activeKey = action.payload;
    },
    clear: (state) => {
      // clear tabs list
      state.tabs = [];
    },
    init: (state, action: PayloadAction<InitType>) => {
      if (_.size(action.payload.tabs) === 0) {
        state.tabs = [];
        if (action.payload.from === 'v1') {
          addDefault(state);
        }
      } else {
        const tabList = action.payload.tabs.sort((a, b) => {
          const nameA = a.name;
          const nameB = b.name;
          const idxA = parseInt(nameA.split(' ').pop() ?? '-1', 10);
          const idxB = parseInt(nameB.split(' ').pop() ?? '-1', 10);
          return idxA - idxB;
        });
        state.tabs = tabList;
        state.activeKey = tabList[0]?.key;
      }
    },
    updateSQL: (state, action: PayloadAction<{
      tabKey: string,
      sql: string
    }>) => {
      const target = _.find(state.tabs, (item) => item.key === action.payload.tabKey);
      if (!target) {
        return;
      }

      target.sql = action.payload.sql;
      target.updated = true;
    },

    changeCurrentTabStatus(state, action: PayloadAction<Partial<QueryTabItem>>) {
      const index = _.findIndex(state.tabs, (item) => item.key === action.payload.key);
      state.tabs[index] = {
        ...state.tabs[index],
        ...action.payload
      };
    },

    updateCurrentTab: (state, action: PayloadAction<{
      data: ArchivedQueryItem,
      tabKey: string
    }>) => {
      const {
        data,
        tabKey: targetTabKey
      } = action.payload;
      const index = _.findIndex(state.tabs, (item) => item.key === targetTabKey);

      const tabKey = data.uuid || StringUtils.uuid();
      state.tabs[index] = {
        updated: false,
        key: tabKey,
        id: tabKey,
        sql: data.content || '',
        name: data.title,
        result: state.tabs[index].result
      };
      state.activeKey = tabKey;
    },
    remove: (state, action: PayloadAction<string>) => {
      state.tabs = _.filter(state.tabs, (item) => item.key !== action.payload);

      if (state.tabs.length === 0) {
        state.tabs = [
          {
            key: StringUtils.uuid(),
            name: 'Query 1',
            sql: '',
            updated: false
          }
        ];
      }

      if (action.payload === state.activeKey) {
        state.activeKey = state.tabs[0].key;
      }
    },
    add: (state, action: PayloadAction<QueryTabItem>) => {
      state.activeKey = action.payload.key;
      state.tabs = [...state.tabs, action.payload];
    },
    addDefault,
    clearQueryResult,
    produceToAlphaSwitch: (state) => {
      state.switchToAlpha = true;
    },
    consumeToAlphaSwitch: (state) => {
      state.switchToAlpha = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataCloudTable.pending, (state) => {
        state.tableSchemaLoading = true;
      })
      .addCase(fetchDataCloudTable.fulfilled, (state, action: PayloadAction<TreeItemSchema[]>) => {
        state.tableSchemaLoading = false;

        state.dataCloudTableTree = _.map(action.payload);
      })
      .addCase(fetchDataCloudTable.rejected, (state) => {
        state.tableSchemaLoading = false;
      });

    builder
      .addCase(updateArchivedQuery.fulfilled, (state, action: PayloadAction<ArchivedQueryItem>) => {
        const data = action.payload;
        const target = _.find(state.tabs, (item) => item.key === data.uuid);
        if (target) {
          target.updated = false;
        }
      });

    const updateResult = (state: Draft<StateProps>, action: PayloadAction<QueryPayload>): void => {
      const {
        data,
        tabKey = state.activeKey
      } = action.payload;

      const target = _.find(state.tabs, (item) => item.key === tabKey);

      if (target) {
        if (_.size(data?.result) > 0) {
          target.result = {
            ...data,
            result: _.filter(data?.result || [], _.isObject)
          };
        } else {
          target.result = data;
        }

        target.resultLoading = false;
      }
    };

    builder
      .addCase(queryData.pending, clearQueryResult)
      .addCase(queryData.fulfilled, updateResult)
      .addCase(queryData.rejected, (state) => {
        // TODO tab switch error
        const target = _.find(state.tabs, (item) => item.key === state.activeKey);

        if (target) {
          target.resultLoading = false;
        }
      });
  }
});

export function flattenTree(tree: TreeItemSchema[]): TableSchema[] {
  function recurse(tables: TreeItemSchema[]): any {
    return _.map(tables, (table) => {
      if (isGroup(table)) {
        return recurse(table.children);
      }
      return table;
    });
  }

  return _.flattenDeep(recurse(tree));
}

export const dataCloudActions = dataCloudReducer.actions;

export const dataCloudTabList = (state: RootState):
  QueryTabItem[] => state.dataCloud.tabs;

export const dataCloudActiveKey = (state: RootState):
  string | undefined => state.dataCloud.activeKey;

export const dataCloudTableTree = (state: RootState):
  TreeItemSchema[] => state.dataCloud.dataCloudTableTree;

export const dataCloudTableList = (state: RootState):
  TableSchema[] => flattenTree(state.dataCloud.dataCloudTableTree);

export const tableSchemaLoading = (state: RootState):
  boolean => state.dataCloud.tableSchemaLoading;

export const dataCloudCurrentTab = (state: RootState):
  QueryTabItem | undefined => _.find(state.dataCloud.tabs, (item) => item.key === state.dataCloud.activeKey);

export const dataCloudTrySwitchToAlpha = (state: RootState): boolean => state.dataCloud.switchToAlpha;

export default dataCloudReducer.reducer;
