/* eslint-disable camelcase */
/**
 * 第二版 SyncFrequencyFormCard
 */

import { Button, Card, Form } from '@douyinfe/semi-ui';
import './index.scss';
import usePrefixedTranslation from '@/utils/usePrefixedTranslation';
import moment from 'moment-timezone';
import { ForwardedRef, forwardRef, useImperativeHandle, useState } from 'react';
import { Icon, Select } from '@/components';
import { OptionProps } from '@douyinfe/semi-ui/select';
import { SelectTable } from '@/views/sync/job-creation/components/JobCreationProvider';
import Label from '../Label';
import useAddFilterModal from '../../job-creation/components/configureJob/utils/useAddFilterModal';
import FilterTag from '../../job-creation/components/configureJob/components/FilterTag';
import { HourOptions, SyncFrequencyOptions, TimeOptions, TimezoneOptions } from './constants';

type Frequency = 'seconds' | 'day' | 'hour' | 'manual';

interface SecondsFormValues {
  sync_frequency: 'seconds';
}

interface DayFormValues {
  sync_frequency: 'day';
  utc_timezone: string;
  utc_offset: number;
  schedule_time: string;
}

interface HourFormValues {
  sync_frequency: 'hour';
  utc_timezone: string;
  utc_offset: number;
  schedule_time: string;
}

interface ManualFormValues {
  sync_frequency: 'manual';
  filters: {
    name: string;
    op: string;
    value: string;
  }[];
}

export type SyncFrequencyFormValues = SecondsFormValues | DayFormValues | HourFormValues | ManualFormValues;

interface SyncFrequencyFormCardProps {
  initialValue?: SyncFrequencyFormValues;
  table?: SelectTable
}

export interface SyncFrequencyFormCardRef {
  getFormValues: () => SyncFrequencyFormValues
}

const SyncFrequencyFormCard = forwardRef(({
  initialValue = {
    sync_frequency: 'seconds'
  },
  table
}: SyncFrequencyFormCardProps, ref: ForwardedRef<SyncFrequencyFormCardRef>) => {
  const [frequency, setFrequency] = useState<Frequency>(initialValue.sync_frequency);
  const requiredTimezone = frequency === 'day' || frequency === 'hour';
  const [timezone, setTimezone] = useState(() => {
    if (initialValue?.sync_frequency === 'day' || initialValue?.sync_frequency === 'hour') {
      return initialValue.utc_timezone;
    }
    return moment.tz.guess();
  });
  const [scheduleHour, setScheduleHour] = useState(() => {
    if (initialValue?.sync_frequency === 'hour') {
      const { schedule_time } = initialValue;
      return Number.parseInt(schedule_time.split(':')[0], 10);
    }
    return 1;
  });
  const [scheduleTime, setScheduleTime] = useState(() => {
    if (initialValue?.sync_frequency === 'day') {
      return initialValue.schedule_time;
    }
    return '00:00';
  });
  const utcOffset = moment.tz(timezone).utcOffset();

  const tp = usePrefixedTranslation('sync.commons.syncFrequencyFormCard.');
  const tConfigure = usePrefixedTranslation('sync.jobs.newJobSteps.configureJob.content.');

  const initialFilters = initialValue?.sync_frequency === 'manual' ? initialValue?.filters ?? [] : [];
  const { addedFilters, openModal, removeFilter } = useAddFilterModal({ table, initialValue: initialFilters });

  useImperativeHandle(ref, () => ({
    getFormValues() {
      if (frequency === 'seconds') {
        return {
          sync_frequency: frequency
        };
      }

      if (frequency === 'hour') {
        return {
          sync_frequency: frequency,
          utc_timezone: timezone,
          utc_offset: (utcOffset / 60),
          schedule_time: `${scheduleHour}:00`
        };
      }

      if (frequency === 'day') {
        return {
          sync_frequency: frequency,
          utc_timezone: timezone,
          utc_offset: (utcOffset / 60),
          schedule_time: scheduleTime
        };
      }

      if (frequency === 'manual') {
        return {
          sync_frequency: frequency,
          filters: addedFilters
        };
      }

      throw new Error(`Unknown frequency: ${frequency}`);
    }
  }));

  const renderSchedule = ({
    headDesc,
    note,
    nextScheduleTime,
    exampleItemAddHourMultiple,
    defaultSelectValue,
    optionList,
    onSelectChange
  }: {
    headDesc: string,
    note: string,
    nextScheduleTime: moment.Moment,
    exampleItemAddHourMultiple: number,
    defaultSelectValue: number | string,
    optionList: OptionProps[]
    onSelectChange: (value: string | number | any[] | Record<string, any> | undefined) => void,
  }) => {
    const format = (scheduleMoment: moment.Moment) => scheduleMoment.format('YYYY-MM-DDTHH:00:00');

    const tailDesc = tp('nextScheduleDesc').replace('{{time}}', format(nextScheduleTime));
    const [headDescPrefix, headDescSuffix] = headDesc.split('{{time}}');
    const exampleItemSlice = tp('exampleItem').split(/{{fired_time}}|block_timestamp|\[{{start_time}}, {{end_time}}\]/);

    const renderExampleItem = (index: number) => {
      const toAddHour = index * exampleItemAddHourMultiple;
      const firedTime = nextScheduleTime.clone().add(toAddHour, 'hour');
      const filterStartTime = firedTime.clone().subtract(1, 'day').format('YYYY-MM-DDTHH:00:00');
      const filterEndTime = firedTime.clone().subtract(1, 'hour').format('YYYY-MM-DDTHH:00:00');
      const timeRange = `[${filterStartTime}, ${filterEndTime}]`;

      return (
        <p>
          <span>{exampleItemSlice[0]}</span>
          <span className="bold">{format(firedTime)}</span>
          <span>{exampleItemSlice[1]}</span>
          <span className="bold">block_timestamp</span>
          <span>{exampleItemSlice[2]}</span>
          <span className="bold">{timeRange}</span>
        </p>
      );
    };

    return (
      <div className="schedule-time-container">
        <div className="schedule-time-title">
          <Label label={tp('scheduleTime')} />
        </div>
        <div className="schedule-time-content">
          <div className="left">
            <span>{headDescPrefix}</span>
            <Select
              optionList={optionList}
              defaultValue={defaultSelectValue}
              onChange={onSelectChange}
            />
            <span>{headDescSuffix}</span>
          </div>
          <div className="right">
            {tailDesc}
          </div>
        </div>
        <div className="schedule-time-notice">
          <h3 className="flex items-center gap-1">
            <Icon icon="common/notice-solid" className="w-3.5 h-3.5" />
            {note}
          </h3>
          <div className="mt-2">
            <p>
              {tp('example')}
            </p>
            {Array.from({ length: 3 }).map((_, i) => i).map(renderExampleItem)}
          </div>
        </div>
      </div>
    );
  };

  const renderScheduleHour = () => {
    let nextScheduleTime = moment().utcOffset(utcOffset);
    nextScheduleTime = nextScheduleTime.add(scheduleHour, 'hour').minute(0).second(0);

    return renderSchedule({
      headDesc: tp('scheduleByHourDesc'),
      note: tp('noteByHour'),
      nextScheduleTime,
      exampleItemAddHourMultiple: scheduleHour,
      defaultSelectValue: scheduleHour,
      optionList: HourOptions,
      onSelectChange(value) {
        if (typeof value === 'number') {
          setScheduleHour(value);
        }
      }
    });
  };

  const renderScheduleDay = () => {
    let nextScheduleTime = moment().utcOffset(utcOffset);
    if (nextScheduleTime.hour() >= scheduleHour) {
      nextScheduleTime = nextScheduleTime.add(1, 'day').hour(scheduleHour).minute(0).second(0);
    } else {
      nextScheduleTime = nextScheduleTime.hour(scheduleHour).minute(0).second(0);
    }

    return renderSchedule({
      headDesc: tp('scheduleByDayDesc'),
      note: tp('noteByDay'),
      nextScheduleTime,
      exampleItemAddHourMultiple: 24,
      defaultSelectValue: scheduleTime,
      optionList: TimeOptions,
      onSelectChange(value) {
        if (typeof value === 'string') {
          setScheduleTime(value);
        }
      }
    });
  };

  const renderFilter = () => (
    <div className="filter-container mt-[12px]">
      <div>
        <Label label={tConfigure('filters')} />
      </div>
      <div className="title-notice">
        {tConfigure('filterNotice')}
      </div>
      <div className="filter-tags">
        {addedFilters.map((filter) => (
          <FilterTag
            key={filter.name}
            filter={filter}
            onClose={removeFilter}
          />
        ))}
        <Button
          className="filter-add-filter-button"
          onClick={openModal}
        >
          <span>+</span>
          <span>Add Filter</span>
        </Button>
      </div>
    </div>
  );

  return (
    <Card className="sync-frequency-form-card">
      <div className="top-zone">
        <Form.Select
          className="sync-frequency-input"
          field="sync_frequency"
          label={(<Label label={tp('syncFrequency')} />)}
          optionList={SyncFrequencyOptions}
          initValue={initialValue.sync_frequency}
          onChange={(val) => setFrequency(val as Frequency)}
        />
        {requiredTimezone && (
        <Form.Select
          className="timezone-input"
          field="sync_timezone"
          label={(<Label label={tp('timezone')} />)}
          optionList={TimezoneOptions}
          initValue={timezone}
          filter
          onChange={(val) => setTimezone(val as string)}
        />
        )}
      </div>
      <div className="bottom-zone">
        {frequency === 'hour' && renderScheduleHour()}
        {frequency === 'day' && renderScheduleDay()}
        {renderFilter()}
      </div>
    </Card>
  );
});

export default SyncFrequencyFormCard;
