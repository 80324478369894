import React from 'react';
import { Toast as SemiToast } from '@douyinfe/semi-ui';
import { AxiosResponse } from 'axios';
import { ToastReactProps } from '@douyinfe/semi-ui/lib/es/toast';
import { Icon } from '../index';

export default class Toast extends SemiToast {
  static success(opts: string | Omit<ToastReactProps, 'type'>): string {
    if (typeof opts === 'string') {
      return super.success({
        content: opts,
        icon: <Icon className="icon" icon="toast/success" />,
        showClose: false
      });
    }
    return super.success({
      icon: <Icon className="icon" icon="toast/success" />,
      showClose: false,
      ...opts
    });
  }

  static error(opts: string | Omit<ToastReactProps, 'type'>): string {
    if (typeof opts === 'string') {
      return super.success({
        content: opts,
        icon: <Icon className="icon" icon="toast/error" />,
        showClose: false
      });
    }
    return super.success({
      icon: <Icon className="icon" icon="toast/error" />,
      showClose: false,
      ...opts
    });
  }

  static toastResponse(response: AxiosResponse): string {
    const {
      code,
      message
    } = response.data;

    if (code === 200) {
      // return SemiToast.success(message);
      return Toast.success({
        content: message,
        showClose: false
      });
    }
    return Toast.error({
      content: message,
      showClose: false
    });
  }
}
