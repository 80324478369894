import { Button, Dropdown, Row } from '@douyinfe/semi-ui';
import _ from 'lodash';
import classNames from 'classnames';
import { ButtonLoading, Icon } from '@/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CodeBlock, vs2015 } from 'react-code-blocks';
import { CopyableButtonNative } from '@/components/Button/CopyableButton';
import { useLoadingCallback } from '@/utils';
import JSONView from '@/components/JSONView';
import axios, { AxiosResponse } from 'axios';

export declare type ProgramLanguageTemplate = {
  name: string,
  language: string,
  template?: string
  escape?: boolean,
  newlineProcess?: boolean
}

type CodeExampleSelectorProps = {
  languageList: ProgramLanguageTemplate[],
  onCompile: (language: ProgramLanguageTemplate) => string
  onRequest?: () => Promise<AxiosResponse<any>>,
  learnMoreLink?: React.ReactElement,
  className?: string
}

export default function CodeExampleSelector({ className, languageList, onRequest, onCompile, learnMoreLink }: CodeExampleSelectorProps) {
  const [currentLanguage, setCurrentLanguage] = React.useState(languageList[0]);
  const { t } = useTranslation();
  const [response, setResponse] = React.useState<AxiosResponse<any>>();

  const { loading, callback: handleTryRequest } = useLoadingCallback(async () => {
    try {
      if (onRequest) {
        const tempResponse = await onRequest();
        setResponse(tempResponse);
      }
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        setResponse(e.response);
      }
    }
  });

  const exampleCode = onCompile(currentLanguage);

  const renderResponse = () => {
    if (!response) {
      return null;
    }

    return (
      <div>
        <h3
          className="title bolder"
        >
          {t('common.response')}
        </h3>
        <div
          className={classNames(['response-wrapper', {
            hidden: loading || !response
          }])}
        >
          <div className="response-header">
            <h5 className="title">{t('dataCloud.generate.statusCode')}</h5>

            <div className="status-code">
              <span className={classNames('status', {
                '!text-[var(--v2-color-red600)]': response?.status !== 200
              })}
              >
                ●
              </span>
              <span>{response?.status}</span>
            </div>

            <div className="flex flex-1" />

            <CopyableButtonNative
              className="btn-json-copy"
              content={response ? JSON.stringify(response.data, null, 2) : ''}
              copyTip={t('common.copy')}
              successTip={`${t('common.copied')}!`}
            />
          </div>

          <div className="response-body">
            <JSONView
              src={response?.data || {}}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classNames('code-example-selector', className)}>
      <Row type="flex" justify="space-between">
        <h3 className="title bolder">
          {t('dataCloud.generate.selectLanguage')}
        </h3>
        {learnMoreLink}
      </Row>
      <div className="language-list">
        {_.map(_.slice(languageList, 0, 5), (item) => (
          <Button
            key={item.name}
            onClick={() => setCurrentLanguage(item)}
            className={classNames(['language-item', {
              active: currentLanguage.name === item.name
            }])}
          >
            <Icon className="icon" icon={`codeLanguage/${item.name}`} />
            <span className="name">{item.name}</span>
          </Button>
        ))}
        <Dropdown
          position="bottomRight"
          className="language-dropdown"
          render={(
            <Dropdown.Menu>
              {_.map(_.slice(languageList, 5), (item) => (
                <Dropdown.Item
                  key={item.name}
                  onClick={() => setCurrentLanguage(item)}
                >
                  <div className="language-item">
                    <Icon
                      className="icon"
                      icon={`codeLanguage/${item.name}`}
                    />
                    <span className="name">{item.name}</span>
                  </div>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
              )}
        >
          <Button
            className="btn-more"
            theme="borderless"
            icon={<Icon className="icon" icon="dataCloud/generate-more" />}
          />
        </Dropdown>
      </div>

      <div className="code-area">
        <div className="code">
          <CodeBlock
            key={currentLanguage.name}
            text={exampleCode}
            language={currentLanguage.language}
            showLineNumbers
            startingLineNumber
            wrapLines
            theme={vs2015}
          />
        </div>

        <div className="operate-wrapper">
          <CopyableButtonNative
            className="semi-button btn-copy"
            content={exampleCode}
            copyTip={(
              <div className="flex-row align-center uncopy">
                <Icon className="icon" icon="common/copy" />
                {t('dataCloud.generate.copy')}
              </div>
                )}
            successTip={(
              <div className="flex-row align-center">
                <Icon className="icon" icon="common/correct" style={{ color: 'white' }} />
                {`${t('common.copied')}!`}
              </div>
                )}
          />
          {
            onRequest ? (
              <ButtonLoading
                theme="borderless"
                loading={loading}
                onClick={handleTryRequest}
              >
                <div className="flex-row align-center">
                  <Icon className="icon" icon="dataCloud/run" />
                  {t('dataCloud.generate.try')}
                </div>
              </ButtonLoading>
            ) : null
          }

        </div>
      </div>

      {renderResponse()}
    </div>
  );
}
