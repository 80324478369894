import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { LanguageItem } from '@/props';
import { RootState } from '@/store/store';
import { LocalStorage } from '@/utils';
import { AxiosResponse } from 'axios';
import DataCloudApi from '@/api/DataCloudApi';
import { ResultTableType } from '@/model/Table';

const LanguageList = [
  {
    name: 'Shell',
    language: 'bash',
    escape: true,
    template: 'curl -X "POST" "https://api.chainbase.online/v1/dw/query" \\\n'
      + '     -H \'x-api-key: [api-key]\' \\\n'
      + '     -H \'Content-Type: application/json; charset=utf-8\' \\\n'
      + '     -d "{params}"\n'
  },
  {
    name: 'Python',
    language: 'python',
    template: 'import requests\n'
      + '\n'
      + 'url = "https://api.chainbase.online/v1/dw/query"\n'
      + '\n'
      + 'payload = {params}\n'
      + '\n'
      + 'headers = {\n'
      + '    "x-api-key": "[api-key]"\n'
      + '}\n'
      + '\n'
      + 'response = requests.post(url, json=payload, headers=headers)\n'
      + '\n'
      + 'print(response.text)\n'
  },
  {
    name: 'Go',
    language: 'Go',
    escape: false,
    template: `package main

import (
\t"bytes"
\t"fmt"
\t"io/ioutil"
\t"net/http"
)

func main() {

\t_json := []byte(\`{params}\`)
\tbody := bytes.NewBuffer(_json)

\tclient := &http.Client{}

\treq, err := http.NewRequest("POST", "https://api.chainbase.online/v1/dw/query", body)

\treq.Header.Add("x-api-key", "[api-key]")

\tresp, err := client.Do(req)

\tif err != nil {
\t\tfmt.Println("Failure : ", err)
\t}

\trespBody, _ := ioutil.ReadAll(resp.Body)

\tfmt.Println("response Status : ", resp.Status)
\tfmt.Println("response Headers : ", resp.Header)
\tfmt.Println("response Body : ", string(respBody))

}
`
  },
  {
    name: 'Node',
    language: 'javascript',
    template: 'const axios = require(\'axios\');\n'
      + '\n'
      + 'axios.post(\'https://api.chainbase.online/v1/dw/query\', {params}, {\n'
      + '  headers: {\n'
      + '    \'x-api-key\': \'[api-key]\'\n'
      + '  }\n'
      + '});\n'
  },
  {
    name: 'Java',
    language: 'Java',
    escape: true,
    newlineProcess: true,
    template: 'OkHttpClient client = new OkHttpClient();\n'
      + '\n'
      + 'MediaType mediaType = MediaType.parse("application/json");\n'
      + 'RequestBody body = RequestBody.create(mediaType, "{params}");\n'
      + '\n'
      + 'Request request = new Request.Builder()\n'
      + '        .url("https://api.chainbase.online/v1/dw/query")\n'
      + '        .post(body)\n'
      + '        .addHeader("x-api-key", "[api-key]")\n'
      + '        .build();\n'
      + '\n'
      + 'Response response = client.newCall(request).execute();\n'
  },
  {
    name: 'Ruby',
    language: 'ruby',
    escape: true,
    template: 'require \'uri\'\n'
      + 'require \'net/http\'\n'
      + 'require \'openssl\'\n'
      + '\n'
      + 'url = URI("https://api.chainbase.online/v1/dw/query")\n'
      + '\n'
      + 'http = Net::HTTP.new(url.host, url.port)\n'
      + 'http.use_ssl = true\n'
      + '\n'
      + 'request = Net::HTTP::Post.new(url)\n'
      + 'request["x-api-key"] = \'[api-key]\'\n'
      + 'request.body = "{params}"\n'
      + '\n'
      + 'response = http.request(request)\n'
      + 'puts response.read_body\n'
  },
  {
    name: 'PHP',
    language: 'php',
    escape: true,
    template: '<?php\n'
      + '\n'
      + '$curl = curl_init();\n'
      + '\n'
      + 'curl_setopt_array($curl, [\n'
      + '  CURLOPT_URL => "https://api.chainbase.online/v1/dw/query",\n'
      + '  CURLOPT_RETURNTRANSFER => true,\n'
      + '  CURLOPT_ENCODING => "",\n'
      + '  CURLOPT_MAXREDIRS => 10,\n'
      + '  CURLOPT_TIMEOUT => 30,\n'
      + '  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n'
      + '  CURLOPT_CUSTOMREQUEST => "POST",\n'
      + '  CURLOPT_POSTFIELDS => "{params}",\n'
      + '  CURLOPT_HTTPHEADER => [\n'
      + '    "x-api-key: [api-key]"\n'
      + '  ],\n'
      + ']);\n'
      + '\n'
      + '$response = curl_exec($curl);\n'
      + '$err = curl_error($curl);\n'
      + '\n'
      + 'curl_close($curl);\n'
      + '\n'
      + 'if ($err) {\n'
      + '  echo "cURL Error #:" . $err;\n'
      + '} else {\n'
      + '  echo $response;\n'
      + '}\n'
  }
];

type StateProps = {
  currentLanguage: string,
  languages: LanguageItem[],

  query?: string,
  response?: AxiosResponse
}

const defaultState: StateProps = {
  currentLanguage: 'Shell',
  languages: LanguageList
};

const StoreKey = 'LanguageScoreArr';

export const executeQuery = createAsyncThunk(
  'dataCloudGenerator/executeQuery',
  async (prop, thunkAPI): Promise<AxiosResponse<{
    code?: number,
    message?: string,
    data: ResultTableType
  }> | undefined> => {
    const { languageGenerator } = thunkAPI.getState() as RootState;
    const { query } = languageGenerator;

    if (!query) {
      return undefined;
    }

    const response = await DataCloudApi.query({
      query
    }, {
      signal: thunkAPI?.signal
    });

    return response;
  }
);

export const dataCloudGeneratorReducer = createSlice({
  name: 'DataCloudGeneratorReducer',
  initialState: () => {
    const initState = _.cloneDeep(defaultState);

    const score = LocalStorage.get<{
      [key: string]: number
    }>(StoreKey, {}) || {};

    initState.languages = _.sortBy(LanguageList, (item) => -(score[item.name] || 0));
    initState.currentLanguage = initState.languages[0].name;
    return initState;
  },
  reducers: {
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    clearResponse: (state) => {
      state.response = undefined;
    },
    change: (state, action: PayloadAction<string>) => {
      const base = 10000000;
      const score = LocalStorage.get<{
        [key: string]: number
      }>(StoreKey, {}) || {};

      const language = action.payload;

      score[state.currentLanguage] = (score[state.currentLanguage] || 0) % base;
      score[language] = ((score[language] || 0) % base) + base + 1;

      LocalStorage.set(StoreKey, score);
      state.currentLanguage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(executeQuery.fulfilled, (state, action: PayloadAction<AxiosResponse | undefined>) => {
      // split response

      if (action.payload) {
        const response = action.payload;

        const data = response?.data?.data;

        if (data) {
          data.result = _.slice(data?.result, 0, 100);
        }
        state.response = response;
      }
    });
  }
});

export const generatorLanguages = (state: RootState):
  LanguageItem[] => state.languageGenerator.languages;

export const generatorResponse = (state: RootState):
  AxiosResponse | undefined => state.languageGenerator.response;

export const currentQuery = (state: RootState):
  string | undefined => state.languageGenerator.query;

export const currentLanguages = (state: RootState):
  LanguageItem => _.find(
  state.languageGenerator.languages,
  (i) => i.name === state.languageGenerator.currentLanguage
) || state.languageGenerator.languages[0];

export default dataCloudGeneratorReducer.reducer;

export const dataCloudGeneratorActions = dataCloudGeneratorReducer.actions;
