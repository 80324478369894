import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentCardInfo, PaymentInfo } from '@/model/BillingPlan';
import BillingApi from '@/api/BillingApi';

type StateProps = {
  current?: PaymentInfo,
  cardInfo?: PaymentCardInfo
};

const initialState: StateProps = {
  current: undefined,
  cardInfo: undefined
};

export const settingBillingThunk = {
  getCurrentPaymentInfo: createAsyncThunk(
    'settingBilling/getCurrentPaymentInfo',
    async () => {
      const response = await BillingApi.getPaymentInfo();
      return response?.data?.result;
    }
  ),
  getBindCardInfo: createAsyncThunk(
    'settingBilling/getBindCardInfo',
    async () => {
      const response = await BillingApi.getBindCardInfo();
      return response?.data?.result;
    }
  )
};

export const settingBillingReducer = createSlice({
  name: 'SettingBillingSlice',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(
      settingBillingThunk.getCurrentPaymentInfo.fulfilled,
      (state, action: PayloadAction<PaymentInfo | undefined>) => {
        state.current = action.payload;
      }
    );
    builder.addCase(
      settingBillingThunk.getBindCardInfo.fulfilled,
      (state, action: PayloadAction<PaymentCardInfo | undefined>) => {
        state.cardInfo = action.payload;
        // if (action.payload?.last4) {
        // } else {
        //   state.cardInfo = undefined;
        // }
      }
    );
  }
});

export default settingBillingReducer.reducer;

export const settingBillingActions = settingBillingReducer.actions;
