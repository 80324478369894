import React from 'react';
import Copyable from '@douyinfe/semi-ui/lib/es/typography/copyable';
import { useTranslation, withTranslation } from 'react-i18next';
import { Icon } from '@/components';
import { CopyableProps } from '@douyinfe/semi-ui/typography/copyable';
import classNames from 'classnames';

type CopyableButtonNativeProps = {
  className?: string;
} & CopyableProps

export class CopyableButtonNative extends Copyable {
  // eslint-disable-next-line no-useless-constructor
  constructor(props: CopyableButtonNativeProps) {
    super(props);
  }

  render(): JSX.Element {
    const {
      copyTip,
      successTip,
      className
    } = this.props;
    const { copied } = this.state;

    return (
      <button
        type="button"
        className={classNames([className])}
        onClick={this.copy}
      >
        {copied ? (successTip || copyTip) : copyTip}
      </button>
    );
  }
}

const CopyableButtonTranslation = withTranslation()(CopyableButtonNative);

export declare type CopyableButtonProps = {
  content: string;
  copyTip?: string;
}

const defaultCopyableButtonProps: CopyableButtonProps = {
  content: '',
  copyTip: undefined
};

export default function CopyableButton({
  content,
  copyTip
} = defaultCopyableButtonProps): React.ReactElement {
  const { t } = useTranslation();

  return (
    <CopyableButtonTranslation
      className="normal"
      content={content}
      successTip={(
        <span className="btn-copy">
          {/* <RiFileCopyLine className="icon" /> */}
          <Icon className="icon" icon="common/copy" />
          {`${t('common.copied')}!`}
        </span>
      )}
      copyTip={
        (
          <span className="btn-copy">
            {/* <RiFileCopyLine className="icon" /> */}
            <Icon className="icon" icon="common/copy" />
            {copyTip || t('common.copy')}
          </span>
        )
      }
    />
  );
}
