import React from 'react';
import { useTranslation } from 'react-i18next';
import './LearnMore.scss';
import classNames from 'classnames';
import { Icon } from '@/components';

type LearnMoreProps = Omit<{
  to: string;
  className?: string;
  text?: React.ReactElement | string;
  icon?: React.ReactElement;
}, ''>

export default function LearnMore({
  to,
  className,
  text,
  icon
}: LearnMoreProps): React.ReactElement {
  const { t } = useTranslation();
  return (
    <a
      className={classNames('learn-more', className)}
      href={to}
      target={to.startsWith('http') ? '_blank' : '_self'}
      rel="noreferrer"
    >
      {icon || <Icon icon="common/question" style={{ color: '#A4AAB0' }} className="icon" />}
      {text || t('common.learn')}
    </a>
  );
}
