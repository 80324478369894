import React, { useEffect } from 'react';
import { AnimatePresence, motion, Target, TargetAndTransition, Transition } from 'framer-motion';
import _ from 'lodash';
import { AxiosResponse } from 'axios';
import { EventManager, Icon } from '../index';
import './index.scss';

declare interface ToastProps {
  text: string;
  type?: 'success' | 'error' | 'info';
  duration?: number;
}

interface ToastClassProps extends ToastProps {
  onRemove: () => void;
}

const defaultToastProps: ToastClassProps = {
  text: '',
  type: 'success',
  duration: undefined,
  onRemove: () => undefined
};

export function AuthToast({
  text,
  type,
  duration = 2500,
  onRemove
} = defaultToastProps): React.ReactElement {
  const toastIconName = `toast-${type}`;

  const initial: Target = {
    opacity: 0,
    y: 100
  };

  const transition: Transition = {
    type: 'easyInOut',
    duration: 0.35
  };

  const animate: TargetAndTransition = {
    opacity: 1,
    y: 0
  };

  useEffect(() => {
    setTimeout(() => {
      onRemove();
    }, duration);
  }, [onRemove, duration]);

  return (
    <motion.div
      initial={initial}
      transition={transition}
      animate={animate}
      exit={initial}
      className={`toast medium ${type}`}
    >
      {text}
      <button
        className="close"
        type="button"
        onClick={() => {
          onRemove();
        }}
      >
        <Icon className="icon" icon={toastIconName} alt={toastIconName} />
      </button>
    </motion.div>
  );
}

declare type ToastItemProps = {
  id: string;
  item: ToastProps;
  status?: 'show' | 'hide' | 'removed';
}

AuthToast.showToast = (item: ToastProps): void => {
  EventManager.emit('toast', item);
};

AuthToast.success = (text: string): void => {
  const item: ToastProps = {
    type: 'success',
    text
  };
  EventManager.emit('toast', item);
};

AuthToast.error = (text: string): void => {
  const item: ToastProps = {
    type: 'error',
    text
  };
  EventManager.emit('toast', item);
};

AuthToast.showResponseMessage = (response: AxiosResponse): AxiosResponse => {
  const {
    code,
    message
  } = response.data;
  if (code === 200) {
    AuthToast.success(message);
  } else {
    AuthToast.error(message);
  }
  return response;
};

export function ToastContainer(): React.ReactElement {
  const [toasts, setToasts] = React.useState<ToastItemProps[]>([]);

  useEffect(() => {
    const remove = EventManager.subscribe(
      'toast',
      (event: CustomEvent): void => {
        const item = event.detail as ToastProps;
        setToasts(_.uniqBy([...toasts, {
          // id: _.uniqueId('toast-'),
          id: item.text,
          item,
          status: 'show'
        }], 'id'));
      }
    );

    return () => {
      remove();
    };
  }, [toasts]);

  const onRemove = (id: string): void => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    setToasts((toasts) => {
      const removedList = _.filter(
        toasts,
        (toast: ToastItemProps) => toast.id !== id
      );
      return [...removedList];
    });
  };

  return (
    <div className="toast-container">
      <AnimatePresence>
        {
          _.map(toasts, (toast: ToastItemProps) => {
            if (toast.status === 'show') {
              return (
                <AuthToast
                  key={toast.id}
                  text={toast.item.text}
                  type={toast.item.type}
                  duration={toast.item.duration}
                  onRemove={() => onRemove(toast.id)}
                />
              );
            }
            return null;
          })
        }
      </AnimatePresence>
    </div>
  );
}
