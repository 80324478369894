export default {
  formatFractionDigits(value: number, fractionDigits = 0): string {
    return value.toLocaleString('en-US', {
      minimumFractionDigits: fractionDigits
    });
  },

  /**
   * Format bytes as human-readable text.
   *
   * @param bytes Number of bytes.
   * @param si True to use metric (SI) units, aka powers of 1000. False to use
   *           binary (IEC), aka powers of 1024.
   * @param dp Number of decimal places to display.
   * @param gap Leave a space between number and unit.
   * @return Formatted string.
   */
  humanFileSize(bytes: number, si = false, dp = 1, gap = false): string {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return `${bytes}B`;
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      u += 1;
    } while (
      // eslint-disable-next-line operator-linebreak
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return `${bytes.toFixed(dp)}${gap ? ' ' : ''}${units[u]}`;
  },

  addCustomCommasToNumber(number: number, separator?: string, groupSize?: number): string {
    if (typeof number !== 'number' || Number.isNaN(number)) {
      return 'Invalid Number';
    }

    if (!separator || typeof separator !== 'string') {
      separator = ',';
    }

    if (!groupSize || typeof groupSize !== 'number') {
      groupSize = 3;
    }

    const numberString = number.toString();
    const regexPattern = new RegExp(`\\B(?=(\\d{${groupSize}})+(?!\\d))`, 'g');
    return numberString.replace(regexPattern, separator);
  }
};
