import React from 'react';
import './index.scss';
import _ from 'lodash';
import {
  NavigationItem,
  NavigationItemProps
} from '@/components/Navigation/index';
import { useParams } from 'react-router-dom';

export default function ProjectDetailNavigation(): React.ReactElement {
  const { projectId } = useParams();
  const SettingNavigationItems: NavigationItemProps[] = [
    // {
    //   key: 'navigation.settings.account',
    //   icon: 'navigation/account',
    //   link: '/settings/'
    // },
    {
      key: 'project.detail',
      icon: 'common/detail',
      link: `/project/${projectId}/endpoints`,
      replace: true
    },
    {
      key: 'project.security',
      icon: 'common/lock',
      link: `/project/${projectId}/security`,
      replace: true
    }

  ];

  return (
    <div className="navigation settings-navigation">
      <div className="navigation-list">
        {
          _.map(SettingNavigationItems, (item) => (
            <NavigationItem
              key={item.key}
              item={item}
            />
          ))
        }
      </div>
    </div>
  );
}
