import React from 'react';
import { ColumnProps } from '@douyinfe/semi-ui/table/interface';
import { ColumnSchema } from '@/model/Table';
import { Button, TabPane, Tabs, Tag } from '@douyinfe/semi-ui';
import SyncTable from '@/views/sync/commons/SyncTable';
import MetadataPreviewer
  from '@/views/sync/job-creation/components/setupSource/components/MetadataPreviewer';
import { SelectTable } from '@/views/sync/job-creation/components/JobCreationProvider';
import { Icon } from '@/components';
import { Numbers } from '@/utils';
import { useTranslation } from 'react-i18next';
import { Metadata } from '@/model/DataCloudStudio';

export declare interface TableDetailProps {
  selectedTable?: SelectTable,
  detail?: Metadata,
  isLoading?: boolean
}

export default function TableDetail({
  selectedTable,
  detail,
  isLoading
}: TableDetailProps) {
  const { t } = useTranslation();

  if (!selectedTable) {
    return (
      <div className="right-card card">
        <div className="flex flex-center h-full text-[16px] text-[#A1AAB0]">Please select the table you want to sync in the left panel.</div>
      </div>
    );
  }

  const columnProps: ColumnProps<ColumnSchema>[] = [{
    title: 'Field',
    dataIndex: 'field'
  }, {
    title: 'Type',
    dataIndex: 'type',
    render(type: string) {
      return <Tag className="sync-tag">{type}</Tag>;
    }
  }, {
    title: 'Description',
    dataIndex: 'description'
  }];

  const renderTableInfo = (table: SelectTable) => {
    const { rows, latestBlockTimeStamp } = table.statistics;

    return (
      <div>
        <h2 className="detail-title">
          <span className="detail-title-blockchain">
            {selectedTable?.title.prefixName}
          </span>
          <span>.</span>
          <span>{selectedTable?.title.subfixName}</span>
        </h2>
        {(latestBlockTimeStamp || rows) && (
          <div className="flex my-2 justify-start items-center gap-2 text-[#444D56] font-normal text-xs">
            {latestBlockTimeStamp && (
            <div className="flex items-center gap-1.5">
              <span>
                <Icon icon="refillable/clock" className="w-3.5 h-3.5" style={{ fill: '#444D56' }} />
              </span>
              <span>
                {(new Date(latestBlockTimeStamp * 1000)).toLocaleString()}
              </span>
            </div>
            )}
            {rows && (
            <div className="flex items-center gap-1.5">
              <span>
                <Icon icon="refillable/db" className="w-3.5 h-3.5" style={{ fill: '#444D56' }} />
              </span>
              <span>
                {rows}
                {Numbers.addCustomCommasToNumber(rows)}
              </span>
            </div>
            )}
          </div>
        )}
        <p className="detail-desc">{selectedTable?.desc}</p>
      </div>
    );
  };

  const renderLoading = () => (
    <Button
      className="preview-data-button disabled"
      theme="light"
      icon={isLoading ? (<Icon className="animate-spin w-3.5 h-3.5" icon="sync/jobs/detail/status/in-sync" />) : (<Icon icon="refillable/play-circle" />)}
    >
      {t('dataCloud.previewData')}
    </Button>
  );

  return (
    <div className="right-card card">
      {renderTableInfo(selectedTable)}
      <Tabs className="right-card-tabs">
        <TabPane tab="Schema" itemKey="schema">
          <SyncTable
            dataSource={selectedTable?.columns}
            columnProps={columnProps}
          />
        </TabPane>
        <TabPane tab="Preview" itemKey="preview">
          <div className="metadata-previewer-wrapper">
            {
              isLoading ? renderLoading() : <MetadataPreviewer tableDetail={detail} />
            }
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
}
