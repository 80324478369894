/* eslint-disable max-len */
import { Button, Collapse, Dropdown, Select, Skeleton } from '@douyinfe/semi-ui';
import React, { useReducer, useState } from 'react';
import './index.scss';
import { Icon, Markdown, Toast } from '@/components';
import { useTranslation } from 'react-i18next';
import { JobData, JobHistoryData, JobHistoryRecord, JobStatus } from '@/model/Sync';
import { DateUtils, Numbers } from '@/utils';
import { useDeleteBatchJobHistory, useGetBatchJobLog } from '@/views/sync/utils/JobRequestHooks';
import { BlockLoading } from '@/components/Loading/BlockLoading';
import SyncApi from '@/api/SyncApi';
import SolidToast from '@/components/Toast/SolidToast';
import axios from 'axios';

interface JobHistoryProps {
  jobHistoryData: JobHistoryData
  jobData: JobData
}

function RetryButton({ batchJobId }: { batchJobId:string }): React.ReactElement {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className={`relative ${isLoading ? 'left-11' : 'left-16'}`}>
      <Button
        loading={isLoading}
        onClick={(e) => {
          e.stopPropagation();
          setIsLoading(true);
          axios.get(`/api/v2/sync/jobs/retry/${batchJobId}`).then(() => {
            Toast.success('Re-Sync successfully');
          }).catch(() => {
            Toast.error('Re-Sync failed');
          }).finally(() => {
            setIsLoading(false);
          });
        }}
        theme="solid"
      >
        Re-Sync
      </Button>
    </div>
  );
}

function BatchJobLog({ batchJobId }: { batchJobId: string }): React.ReactElement {
  const { data, isLoading } = useGetBatchJobLog(batchJobId || undefined);

  const renderLoading = () => <Skeleton.Paragraph style={{ width: '100%' }} rows={2} />;
  return (
    <div className="log-container">
      {isLoading && renderLoading()}
      {data && <Markdown>{data}</Markdown>}
    </div>
  );
}

type DowloadingReducer = (prevState: Record<string, boolean | undefined>, action: {key: string, value: boolean}) => Record<string, boolean | undefined>;

export default function JobHistory({
  jobData,
  jobHistoryData
}: JobHistoryProps): React.ReactElement {
  const { t } = useTranslation();
  const tJobHistory = (text: string): string => t(`sync.jobs.detail.jobHistory.${text}`);
  const tHeader = (key: string): string => tJobHistory(`header.${key}`);
  const { deleteBatchJobHistory } = useDeleteBatchJobHistory(jobData.job_id);
  const [isDownloadingOf, setIsDownloadingOf] = useReducer<DowloadingReducer>((prev, { key, value }) => {
    const newState = {
      ...prev
    };
    newState[key] = value;
    return newState;
  }, {});
  const [filter, setFilter] = useState('all');
  let filteredJobHistoryData;
  if (jobHistoryData === null) {
    filteredJobHistoryData = null;
  } else if (filter === 'all') {
    filteredJobHistoryData = jobHistoryData;
  } else if (filter === 'in-sync') {
    filteredJobHistoryData = jobHistoryData.filter((data) => data.status === 'CREATED' || data.status === 'RUNNING');
  } else if (filter === 'succeed') {
    filteredJobHistoryData = jobHistoryData.filter((data) => data.status === 'SUCCEED');
  } else if (filter === 'failed') {
    filteredJobHistoryData = jobHistoryData.filter((data) => data.status === 'FAILED' || data.status === 'STOPPED');
  }

  const renderContainerHeader = (): React.ReactElement => {
    // TODO: #sync get status from backend
    // const status = 'on-time';
    // const nextSyncTime = '3';
    const status = null;
    const nextSyncTime = null;

    return (
      <div className="job-history-container-header">
        <div className="left">
          <Select className="selector" value={filter} onChange={(value) => setFilter(value as string)}>
            <Select.Option value="all">All</Select.Option>
            <Select.Option value="in-sync">In Sync</Select.Option>
            <Select.Option value="succeed">Succeed</Select.Option>
            <Select.Option value="failed">Failed</Select.Option>
          </Select>
        </div>
        <div className="right">
          {status && (
          <div className="status">
            <Icon icon={`sync/jobs/detail/status/${status}`} />
            <div className="text">
              {tHeader('on-time')}
            </div>
          </div>
          )}
          {nextSyncTime && (
          <div className="next-sync">
            {tHeader('nextSync').replace('{{HOUR}}', nextSyncTime)}
          </div>
          )}
          {/* <div className="sync-button">
            <Button
              loading={isRunningJob}
              icon={isRunningJob ? undefined : <Icon icon="sync/jobs/detail/sync-button" />}
              theme="solid"
              onClick={() => runJob(true)}
            >
              {tHeader('buttonContent')}
            </Button>
          </div> */}
        </div>
      </div>
    );
  };

  const renderPanelHeader = (data: JobHistoryRecord): React.ReactElement => {
    const mapSyncCopywriting = (key: JobStatus): string => {
      switch (key) {
        case 'CREATED':
        case 'RUNNING':
          return 'In Sync';
        case 'SUCCEED':
          return 'Sync Succeed';
        case 'FAILED':
        case 'STOPPED':
          return 'Sync Failed';
        default:
          return 'In Sync';
      }
    };

    const status = mapSyncCopywriting(data.status);
    const icon = status.toLowerCase().replaceAll(' ', '-');
    const size = Numbers.humanFileSize(data.record_size);
    const records = `${Numbers.addCustomCommasToNumber(data.record_count)} records read`;
    const jobId = `Job ID: ${data.batch_job_id}`;
    const timeused = DateUtils.fromNow(data.created_at);
    const date = DateUtils.format(data.created_at, 'HH:mm DD/MM/YYYY');

    const expandOrCollapseIcon = (
      <Dropdown
        render={(
          <Dropdown.Menu>
            <Dropdown.Item onClick={(e) => {
              e.stopPropagation();
              if (isDownloadingOf[data.batch_job_id]) {
                return;
              }
              setIsDownloadingOf({ key: data.batch_job_id, value: true });
              SyncApi.getJobExceptions(data.batch_job_id)
                .then((res) => {
                  const { data: logData } = res.data;
                  const blob = new Blob([logData], { type: 'text/plain;charset=utf-8' });
                  const url = URL.createObjectURL(blob);
                  const a = document.createElement('a');
                  a.href = url;
                  a.download = `${data.batch_job_id}.json`;
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                  URL.revokeObjectURL(url);
                  Toast.success('Download successfully');
                }).catch(() => {
                  Toast.error('Download failed');
                }).finally(() => {
                  setIsDownloadingOf({ key: data.batch_job_id, value: false });
                });
            }}
            >
              {isDownloadingOf[data.batch_job_id]
              && (
              <div className="w-full h-full p-1 box-border flex justify-center">
                <BlockLoading />
              </div>
              )}
              {!isDownloadingOf[data.batch_job_id] && tJobHistory('downloadLogs')}
            </Dropdown.Item>
            <Dropdown.Item onClick={(e) => {
              e.stopPropagation();
              deleteBatchJobHistory({ batchJobId: data.batch_job_id }, {
                onSuccess() {
                  SolidToast.success('Delete successfully');
                },
                onError() {
                  Toast.error('Delete failed');
                }
              });
            }}
            >
              {tJobHistory('deleteHistory')}
            </Dropdown.Item>
          </Dropdown.Menu>
  )}
        trigger="click"
        position="bottomLeft"
      >
        <button type="button" className="m-0 p-1 hover:bg-[#FAFBFC] rounded" onClick={(e) => { e.stopPropagation(); }}>
          <Icon className="expand-icon" icon="common/three-dots-horizontal" />
        </button>
      </Dropdown>
    );

    return (
      <div className="panel-header">
        <div className="left">
          <div className="icon-wrapper">
            <Icon
              className={status === 'In Sync' ? 'animate-spin' : ''}
              icon={`sync/jobs/detail/status/${icon}`}
            />
          </div>
          <div className="text-wrapper">
            <div className="status">
              {status}
            </div>
            <div className="detail">
              <span>{size}</span>
              <span>{records}</span>
              <span>{jobId}</span>
              <span>{timeused}</span>
            </div>
          </div>
        </div>
        <div className="right relative">
          <div>
            <div className="date">
              {date}
            </div>
            <div className="absolute -top-1 -right-10">
              {expandOrCollapseIcon}
            </div>
          </div>
          <div className="w-full mt-4">
            {data.status === 'FAILED' && (
              <RetryButton batchJobId={data.batch_job_id} />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`job-history-container ${jobHistoryData && jobHistoryData.length > 0 ? 'history-non-empty' : 'history-empty'}`}>
      {renderContainerHeader()}
      <Collapse
        className="collapse-container"
        expandIcon={null}
        collapseIcon={null}
      >
        {filteredJobHistoryData?.map((data) => (
          <Collapse.Panel
            key={data.id}
            className="job-history-panel"
            header={renderPanelHeader(data)}
            itemKey={data.id.toString()}
          >
            <BatchJobLog
              key={data.batch_job_id}
              batchJobId={data.batch_job_id}
            />
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
}
