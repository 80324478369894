import React, { useEffect } from 'react';
import { Form } from '@douyinfe/semi-ui';
import { InputProps } from '@douyinfe/semi-ui/lib/es/input';

type InputGroupProps = {
  autoFocus?: boolean;
  name: string;
  type?: string;
  label?: string;
  value?: string;
  trigger?: 'blur' | 'change' | 'custom' | 'mount' | Array<string>;
  rules?: any[];
  assist?: React.ReactNode;
} & InputProps;

const InputGroupDefaultProps: InputGroupProps = {
  autoFocus: false,
  name: '',
  label: '',
  value: '',
  trigger: 'custom',
  rules: [],
  type: 'text'
};

export default function InputGroup({
  autoFocus,
  name,
  label,
  type,
  rules,
  trigger,
  validateStatus,
  assist,
  ...rest
} = InputGroupDefaultProps): React.ReactElement {
  const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const { Input } = Form;

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div className={`input-control ${validateStatus || ''}`}>
      <Input
        label={(
          <div className="label-wrapper">
            <label className="medium" htmlFor={name}>{label}</label>
            {assist}
          </div>
        )}
        ref={inputRef}
        className="bolder"
        field={name}
        type={type}
        trigger={trigger}
        rules={rules}
        {...rest}
        validateStatus={validateStatus}
      />
    </div>
  );
}
