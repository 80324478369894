/**
 * Global Event Manager
 */
export default class EventManager {
  // eslint-disable-next-line @typescript-eslint/ban-types
  static subscribe(
    eventName: string,
    callback: (this: Window, ev: any) => any
  ): VoidFunction {
    window.addEventListener(eventName, callback);
    return (): void => {
      window.removeEventListener(eventName, callback);
    };
  }

  static emit(eventName: string, data?: any): void {
    window.dispatchEvent(new CustomEvent(eventName, { detail: data }));
  }
}
