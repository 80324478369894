/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col, Input, Row
} from '@douyinfe/semi-ui';
import { Icon } from '@/components';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { IntegrationTypeList } from '@/config/sync';
import classNames from 'classnames';
import { useCommunityAssistance } from '@/hooks/UseCommunityAssistance';

export interface IntegrationType {
  name: string,
  type?: 'supported' | undefined;
  description: string;
}

interface IntegrationItemProps {
  item: IntegrationType,
  onItemClick?: (item: IntegrationType) => void;
}

function IntegrationItem({ item, onItemClick }: IntegrationItemProps): React.ReactElement {
  const { t } = useTranslation();

  const onClick = () => {
    if (item.type === 'supported' && onItemClick) {
      onItemClick(item);
    }
  };

  const renderStatus = (): React.ReactElement => {
    if (item?.type === 'supported' || item.type === 'old') {
      return (
        <Icon icon="sync/integrations/verified" />
      );
    }
    return (
      <div className="status">
        {t('common.comingSoon')}
      </div>
    );
  };

  return (
    <Col span={6}>
      <div
        className={classNames('integration-item', {
          'coming-soon': item?.type !== 'supported' && item.type !== 'old'
        })}
        onClick={onClick}
      >
        <div className="first-row">
          <Icon className="icon" icon={`sync/integrations/engine/${item?.name.toLowerCase()}`} />
          <span className="name">{item?.name}</span>
          {renderStatus()}
        </div>
        <div className="second-row">
          {item.description}
        </div>
      </div>
    </Col>
  );
}

interface IntegrationSelectorProps {
  onItemClick?: (item: IntegrationType) => void;
}

export default function IntegrationSelector({
  onItemClick
}: IntegrationSelectorProps): React.ReactElement {
  const { t } = useTranslation(['translation', 'integrations']);

  const [keyword, setKeyword] = useState<string>('');
  const [integrationList, setIntegrations] = useState<IntegrationType[]>([]);
  const { openCommunityAssistanceModal } = useCommunityAssistance();

  useEffect(() => {
    const searched = IntegrationTypeList
      .filter((item) => item?.name.toLowerCase().includes(keyword.toLowerCase()));
    setIntegrations(searched);
  }, [keyword]);

  const renderSearch = (): React.ReactElement => (
    <Row type="flex" justify="space-between">
      <Col>
        <Input
          className="input-search"
          suffix={<Icon className="mx-[12px]" icon="common/search" />}
          placeholder={t<string>('common.search', { ns: 'translation' })}
          onChange={setKeyword}
        />
      </Col>
    </Row>
  );

  return (
    <div className="integration-selector">
      {renderSearch()}

      <Row className="integration-types" type="flex" gutter={16} justify="space-between">
        {
          integrationList.map((item) => (
            <IntegrationItem
              key={item?.name}
              item={item}
              onItemClick={onItemClick}
            />
          ))
        }

        <Col span={6} />
        <Col span={6} />
      </Row>

      <Row>
        <Button
          className="btn-request"
          icon={<Icon icon="common/add" />}
          onClick={() => {
            openCommunityAssistanceModal();
          }}
        >
          {t('integrationSelector.requestButton', { ns: 'integrations' })}
        </Button>
      </Row>
    </div>
  );
}
