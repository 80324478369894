import React, { useEffect, useRef, useState } from 'react';
import SplitPane, { Pane } from 'react-split-pane';
import { fetchTablePrompt } from '@/store/reducers/dataCloudStudioReducer';
import { useAppDispatch } from '@/store/hooks';
import SQLEditor from './components/SQLEditor';
import ResultViewer from './components/QueryResult';
import TextSelectorProvider from './components/SelectTextProvider';

export default function SQLStudio(): React.ReactElement {
  const contentRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState(() => (contentRef.current
    ? contentRef.current.clientHeight - 250
    : window.innerHeight - 300));
  const dispatch = useAppDispatch();

  useEffect(() => {
    const resizeOvserver = new ResizeObserver((entries) => {
      const content = entries[0];
      setMaxHeight(content.target.clientHeight - 250);
    });

    dispatch(fetchTablePrompt());

    if (contentRef.current) {
      resizeOvserver.observe(contentRef.current);
    }
    return () => {
      resizeOvserver.disconnect();
    };
  }, []);

  return (
    <div
      className="content-wrapper data-cloud-content-wrapper"
      ref={contentRef}
    >
      <TextSelectorProvider>
        <SplitPane
          split="horizontal"
          minSize={200}
          maxSize={maxHeight}
          defaultSize="50%"
        >
          <Pane className="sql-editor-pane">
            <SQLEditor />
          </Pane>
          <Pane
            className="query-result-pane"
            style={{ overflow: 'hidden' }}
          >
            <ResultViewer />
          </Pane>
        </SplitPane>
      </TextSelectorProvider>
    </div>
  );
}
