import Request from '@/api/Request';
import { AxiosResponse } from 'axios';
import _ from 'lodash';

export default class DataCloudStudioApi {
  static getMetaDataList(category: string, params: any = {}) : Promise<AxiosResponse> {
    if (params?.blockchain === 'all') {
      delete params.blockchain;
    }
    return Request.get(`/v2/datacloud/metadata/${category}/tables`, {
      params: _.mapValues(params, (item) => (_.isObject(item) ? JSON.stringify(item) : item))
    });
  }

  static getDefaultPrompt() : Promise<AxiosResponse> {
    return Request.get('/v2/datacloud/metadata/defaultPrompt');
  }

  static getAllChains(): Promise<AxiosResponse<{ data: { name: string, url: string }[] }>> {
    return Request.get('/v2/datacloud/metadata/blockchains');
  }
}
