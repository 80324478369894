import useEventEmitter from '@/utils/hooks/useEventEmitter';
import React, { useCallback, useMemo } from 'react';
import { Parameter } from './ParametersProvider';

type RequestTrigger = (key: string, sql: string, parameters: Parameter[]) => void;
type PreviewTrigger = (key: string, tableName: string) => void;
type UseQueryTriggerParams = {event: 'request', callback: RequestTrigger} | {event: 'preview', callback: PreviewTrigger};

interface QueryTriggerContext {
  request: RequestTrigger;
  preview: PreviewTrigger;
  useQueryTrigger: (params: UseQueryTriggerParams) => void;
}

export const QueryTriggerContext = React.createContext<QueryTriggerContext>({
  request() {
    throw new Error('Function not implemented.');
  },
  preview() {
    throw new Error('Function not implemented.');
  },
  useQueryTrigger() {
    throw new Error('Function not implemented.');
  }
});

export default function QueryTriggerProvider({ children }: {children: React.ReactNode}) {
  const { emit, useSubcribe } = useEventEmitter();

  const request = useCallback((key: string, sql: string, parameters: Parameter[]) => {
    emit('request', key, sql, parameters);
  }, [emit]);

  const preview = useCallback((key: string, tableName: string) => {
    emit('preview', key, tableName);
  }, [emit]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const useQueryTrigger = ({ event, callback }: UseQueryTriggerParams) => {
    useSubcribe(event, callback);
  };

  const value = useMemo(() => ({
    request,
    preview,
    useQueryTrigger
  }), [preview, request, useQueryTrigger]);

  return (
    <QueryTriggerContext.Provider value={value}>
      {children}
    </QueryTriggerContext.Provider>
  );
}
