import React, { MouseEventHandler, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Carousel, Typography } from '@douyinfe/semi-ui';
import _ from 'lodash';
import { Icon, Modal } from '@/components';
import { LocalStorage } from '@/utils';
import './QuickStartModal.scss';
import { loadMedia } from '@/utils/AssertUtils';
import classNames from 'classnames';

type CarouselItem = {
  video: string;
  title: string;
  desc: string;
  getStarted?: string;
}

export default function QuickStartModal(): React.ReactElement {
  const { t } = useTranslation();

  const carouselRef = React.useRef<Carousel>(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = React.useState<number>(0);

  useEffect(() => {
    const value = LocalStorage.get<boolean>('QuickStart', false);
    setVisible(!!value);
  }, []);

  const onClose: React.MouseEventHandler = () => {
    setVisible(false);
    LocalStorage.remove('QuickStart');
  };

  const carouselI18n = t('quickStart.carousels', { returnObjects: true }) as {
    [key: string]: {
      title: string,
      description: string
    }
  };

  const CarouselList: CarouselItem[] = [
    {
      video: loadMedia('quickStart/welcome.mp4'),
      title: carouselI18n.welcome.title,
      desc: carouselI18n.welcome.description
    },
    {
      video: loadMedia('quickStart/apiService.mp4'),
      title: carouselI18n.apiService.title,
      desc: carouselI18n.apiService.description
    },
    {
      video: loadMedia('quickStart/dataCloud.mp4'),
      title: carouselI18n.dataCloud.title,
      desc: carouselI18n.dataCloud.description
    },
    {
      video: loadMedia('quickStart/dashboard.mp4'),
      title: carouselI18n.dashboard.title,
      desc: carouselI18n.dashboard.description
    },
    {
      video: loadMedia('quickStart/start.mp4'),
      title: carouselI18n.start.title,
      desc: carouselI18n.start.description
    }
  ];

  const handleNext: MouseEventHandler = (e): void => {
    if (currentIndex >= CarouselList.length - 1) {
      onClose(e);
    }
    carouselRef?.current?.next();
  };

  return (
    <Modal
      className="quick-start-modal"
      centered
      size="large"
      closable={false}
      visible={visible}
      footer={null}
    >
      <div className="quick-start-container">

        <div className="close-wrapper">
          <Button
            className="btn-close"
            theme="borderless"
            onClick={onClose}
            htmlType="button"
            icon={<Icon className="icon icon-close" icon="modal/close" />}
          />
        </div>

        <div className="next-wrapper">
          <Button
            className={classNames(['btn-next', {
              finish: currentIndex >= CarouselList.length - 1
            }])}
            theme="solid"
            onClick={handleNext}
            htmlType="button"
          >
            {
              currentIndex >= CarouselList.length - 1
                ? (
                  <span>
                    {t('common.finish')}
                  </span>
                )
                : (
                  <>
                    <span>{t('common.next')}</span>
                    <Icon className="icon" icon="quickStart/next" />
                  </>
                )
            }

          </Button>
        </div>

        <Carousel
          ref={carouselRef}
          activeIndex={currentIndex}
          animation="fade"
          theme="dark"
          autoPlay={false}
          // speed={1000}
          // autoPlay
          indicatorPosition="right"
          onChange={(index: number) => {
            setCurrentIndex(index);
            const mediaElement = document.getElementById(`carousel-video-${index}`) as HTMLVideoElement;
            if (mediaElement) {
              mediaElement.currentTime = 0;
            }
          }}
          showArrow={false}
        >

          {
            _.map(CarouselList, (item, index) => (
              <div key={index} className="carousel-item">
                <div className="video-container">
                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  <video
                    id={`carousel-video-${index}`}
                    width="320"
                    height="240"
                    muted
                    autoPlay
                    loop
                  >
                    <source
                      src={item.video}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>

                <div className="content-container">
                  <Typography.Title
                    heading={2}
                    className="bolder"
                  >
                    {item.title}
                  </Typography.Title>

                  <Typography.Paragraph
                    className="desc"
                  >
                    {item.desc}
                  </Typography.Paragraph>

                  {
                    item.getStarted ? (
                      <Typography.Text
                        className="link-get-started"
                        link={
                          {
                            href: item.getStarted,
                            target: '_blank'
                          }
                        }
                      >
                        {t('common.getStarted')}
                      </Typography.Text>
                    ) : null
                  }

                </div>
              </div>
            ))
          }
        </Carousel>
      </div>
    </Modal>
  );
}
