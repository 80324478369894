import React, { MouseEvent } from 'react';
import { Button, Popover, Row } from '@douyinfe/semi-ui';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { ButtonLoading } from '@/components';
import { useLoadingCallback } from '@/utils';

type DeletePopoverBodyProps = {
  text?: {
    title: string | React.ReactElement,
    delete?: string,
    cancel?: string
  },
  onCancel: () => void;
  onDelete: React.MouseEventHandler<HTMLButtonElement>;
}

export default function DeletePopoverBody({
  text,
  onDelete,
  onCancel
}: Omit<DeletePopoverBodyProps, ''>): React.ReactElement {
  const { t } = useTranslation();

  const {
    loading,
    callback: handleDelete
  } = useLoadingCallback(async (event: MouseEvent<HTMLButtonElement>) => {
    await onDelete(event);
  });

  return (
    <div className="delete-popover-body">
      <h3
        className="title"
      >
        {text?.title || t('common.deleteSurePlaceholder')}
      </h3>
      <Row className="button-group">
        <ButtonLoading
          loading={loading}
          loadingType="danger"
          theme="solid"
          className="btn btn-delete"
          type="danger"
          onClick={handleDelete}
        >
          {text?.delete || t('common.delete')}
        </ButtonLoading>

        <Button
          theme="solid"
          type="secondary"
          className="btn btn-cancel"
          onClick={onCancel}
        >
          {text?.cancel || t('common.cancel')}
        </Button>
      </Row>
    </div>
  );
}

type DeletePopoverProps = {
  children: React.ReactElement
} & Pick<DeletePopoverBodyProps, 'text' | 'onDelete'>

export function DeletePopover({
  children,
  text,
  onDelete
}: DeletePopoverProps): React.ReactElement {
  const [visible, setVisible] = React.useState<boolean>(false);

  return (
    <Popover
      visible={visible}
      content={(
        <DeletePopoverBody
          text={text}
          onCancel={() => setVisible(false)}
          onDelete={async (event) => {
            event.stopPropagation();
            if (onDelete) {
              await onDelete(event);
            }
            setVisible(false);
          }}
        />
      )}
      onVisibleChange={setVisible}
      onClickOutSide={() => {
        setVisible(false);
      }}
      trigger="click"
      position="top"
    >
      {children}
    </Popover>
  );
}
