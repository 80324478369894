import { Icon } from '@/components';
import { Button } from '@douyinfe/semi-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function UpgradePlanButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Button
      theme="solid"
      type="secondary"
      size="small"
      className="bg-[#94CEFD] text-[#191E21] font-medium text-[12px] hover:bg-[#6AB6F3]"
      onClick={() => {
        navigate('/settings/billing');
      }}
    >
      <div className="flex flex-row align-center">
        <Icon icon="dataCloud/upgrade" className="w-[16px] h-[16px] mr-[4px]" />
        {t('common.upgrade')}
      </div>
    </Button>
  );
}
