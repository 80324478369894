import React from 'react';
import { Icon } from '@/components';
import {
  Breadcrumb, Button, Col, Row
} from '@douyinfe/semi-ui';
import './index.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RouteProps } from '@douyinfe/semi-ui/lib/es/breadcrumb';

export declare type NavHeaderProps = Omit<
  {
    routers: Array<RouteProps | string>;
    menu?: React.ReactElement;
    hideGoback?: boolean;
    backUrl?: string;
    afterBreadcrumb?: React.ReactNode;
  },
  ''
>;

export default function NavHeader({
  routers, menu, hideGoback, backUrl, afterBreadcrumb
}: NavHeaderProps): React.ReactElement {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get('returnTo');

  const handleBack = (): void => {
    if ((window.history?.state?.idx || 0) > 0) {
      navigate(-1);
    } else if (returnTo) {
      navigate(window.decodeURIComponent(returnTo), { replace: true });
    } else if (backUrl) {
      navigate(backUrl);
    } else {
      navigate('/');
    }
  };

  return (
    <Row className="nav-header bg-[#252B2E]" type="flex" align="middle" justify="space-between">
      <Col className="flex flex-center">
        <a href="/" className="logo">
          <Icon icon="header/Logo-icon-light" className="icon" />
        </a>
        {!hideGoback && (
          <Button
            className="btn-back"
            theme="borderless"
            icon={<Icon icon="common/back" className="icon" />}
            onClick={handleBack}
          />
        )}

        <Breadcrumb className="header-breadcrumb" routes={routers} />
        {afterBreadcrumb}
      </Col>

      <Col>{menu}</Col>
    </Row>
  );
}
