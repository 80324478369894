import { Button } from '@douyinfe/semi-ui';
import { useTranslation } from 'react-i18next';
import { Toast } from '@/components';
import { useRunJob } from '../../utils/JobRequestHooks';

interface SyncButtonProps {
  jobId: string;
}

export default function SyncButton({ jobId }: SyncButtonProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'sync.jobs.jobList' });
  const { isMutating, runJob } = useRunJob(jobId, {
    onSuccess: () => {
      Toast.success('Sync job started');
    },
    onFailed: () => {
      Toast.error('Sync job failed');
    }
  });

  return (
    <Button
      theme="solid"
      loading={isMutating}
      onClick={() => runJob(true)}
    >
      {t('syncNow')}
    </Button>
  );
}
