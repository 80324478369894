import IntegrationList from '@/views/sync/commons/IntegrationList';
import React, { useContext } from 'react';
import { Radio } from '@douyinfe/semi-ui';
import { Integration } from '@/model/Integration';
import { JobCreationContext } from '../../../JobCreationProvider';

export default function SetupExistsIntegration(): React.ReactElement {
  const { jobCreationState, jobCreationDispatch } = useContext(JobCreationContext);
  const { selectedIntegration } = jobCreationState;

  const handleSelected = (item: Integration | undefined) => {
    if (!item || !item.integration_id) {
      return;
    }

    jobCreationDispatch({ type: 'selectIntegration',
      value: {
        integrationId: item.integration_id,
        type: item.integrate_type
      } });
  };

  return (
    <IntegrationList
      onRowSelected={handleSelected}
      renderColumn={(columns) => [
        {
          title: '',
          width: 50,
          render: (item: Integration) => (
            <Radio
              checked={item.integration_id === selectedIntegration?.integrationId}
              className="radio"
              onChange={() => handleSelected(item)}
            />
          )
        },
        ...columns
      ]}
    />
  );
}
