import { AxiosResponse } from 'axios';
import { Project } from '@/model/Project';
import { Response } from '@/model';
import Request from './Request';

/**
 * constant async request api
 */
export default class ConstantApi {
  static getChainList(): Promise<AxiosResponse> {
    return Request.get('/v1/project/chain_list');
  }

  static getProjectList(): Promise<AxiosResponse<Response<Project[]>>> {
    return Request.get('/v2/projects/all');
  }
}
