import React from 'react';
import './TipModal.scss';
import { Modal } from '@/components';
import { Button } from '@douyinfe/semi-ui';
import classNames from 'classnames';
import { ModalReactProps } from '@douyinfe/semi-ui/lib/es/modal/Modal';
import { useTranslation } from 'react-i18next';

type TipModalProps = {
  title?: string;
  icon?: React.ReactNode;
  description?: string;
  // close modal ignore event
  onClose?: () => void;
  } & ModalReactProps

export default function TipModal({
  title,
  icon,
  description,
  className,
  cancelText,
  onClose,
  onCancel,
  okType,
  onOk,
  okText,
  ...rest
}: Omit<TipModalProps, ''>): React.ReactElement {
  const { t } = useTranslation();
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Modal
      centered
      closeOnEsc
      {...rest}
      className={classNames(['tip-modal', className])}
      footer={null}
      onCancel={onClose}
    >
      <div className={classNames(['modal-tip-content', className])}>
        {icon}
        <h3 className="title semi-bold">{title}</h3>
        <p className="desc">{description}</p>

        <div className="flex-row">
          <Button
            onClick={onCancel}
            theme="light"
            type="tertiary"
          >
            {cancelText || t('common.cancel')}
          </Button>
          <Button
            theme="solid"
            type={okType}
            onClick={onOk}
          >
            {okText}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
