import Editor, { EditorProps } from '@monaco-editor/react';
import * as monaco from 'monaco-editor';
import React from 'react';
import { Button } from '@douyinfe/semi-ui';
import { useTranslation } from 'react-i18next';
import { EventManager, Icon } from '@/components';
import './CodeEditor.scss';
import px2rem from '@/utils/px2rem';

export const EditorInsert = 'EditorInsert';

type CodeEditorProps = {
  editorKey?: string;
  onSelectText?: (text?: string) => void;
  onSave?: () => void;
  onRun?: () => void;
};

export default function CodeEditor(props: EditorProps & Omit<CodeEditorProps, ''>): React.ReactElement {
  const { t } = useTranslation();
  const editorRef = React.useRef<monaco.editor.IStandaloneCodeEditor>();

  const {
    editorKey, options = {}, onSelectText, onSave, onRun, ...rest
  } = props;

  /**
   * format code
   */
  const handleFormatCode = (): void => {
    editorRef?.current?.getAction('editor.action.formatDocument')?.run();
  };

  const handleEditorDidMount = (editor: monaco.editor.IStandaloneCodeEditor): void => {
    // here is the editor instance
    // you can store it in `useRef` for further usage

    editorRef.current = editor;

    editorRef.current?.setPosition({
      lineNumber: 1000,
      column: 1000
    });

    editorRef.current?.focus();

    /* eslint-disable no-bitwise */
    editorRef.current?.addAction({
      id: 'run',
      label: 'Run Query',
      keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter],
      run(): void | Promise<void> {
        if (onRun) {
          onRun();
        }
      }
    });

    editorRef.current?.addAction({
      id: 'save',
      label: 'Save Query',
      keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS],
      run(): void | Promise<void> {
        if (onSave) {
          onSave();
        }
      }
    });

    editorRef.current?.onDidChangeCursorSelection(() => {
      const selection = editorRef?.current?.getSelection();

      if (selection) {
        const selectionText = editorRef?.current?.getModel()?.getValueInRange(selection);
        // console.log('onDidChangeCursorSelection', selectionText);
        if (onSelectText) {
          onSelectText(selectionText);
        }
      }
    });
  };
  const insertText = (text: string): void => {
    const selection = editorRef?.current?.getSelection();
    const id = {
      major: 1,
      minor: 1
    };
    const op = {
      identifier: id,
      range: selection,
      text,
      forceMoveMarkers: true
    };
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    editorRef?.current?.executeEdits('my-source', [op]);
  };

  React.useEffect(() => {
    const listener = EventManager.subscribe(
      `${EditorInsert}:${editorKey}`,
      ({
        detail: { method, text }
      }: {
        detail: {
          method: 'insert' | 'focus';
          text: string;
        };
      }): void => {
        if (method === 'insert') {
          insertText(text);
        } else if (method === 'focus') {
          editorRef?.current?.focus();
        }
      }
    );

    return () => {
      listener();
    };
  }, [editorKey]);

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div className="code-editor code-editor-v1">
      <Editor
        className="code-editor"
        theme="vs-dark"
        onMount={handleEditorDidMount}
        options={{
          minimap: {
            enabled: false
          },
          fontSize: px2rem.calcPXByScreen(14),
          wordWrap: 'on',
          cursorWidth: 0,
          contextmenu: false,
          ...options
        }}
        {...rest}
      />

      <Button
        icon={<Icon icon="common/format" className="icon" />}
        className="btn-format"
        id="code-editor__format"
        theme="solid"
        onClick={handleFormatCode}
      >
        <span>{t('common.format')}</span>
      </Button>
    </div>
  );
}
