/* eslint-disable operator-linebreak */
import { JobData } from '@/model/Sync';
import moment from 'moment';
import { SyncFrequencyFormValues } from '..';

export default function useSyncFrequencyInitValue(
  jobData?: JobData
): SyncFrequencyFormValues | undefined {
  if (!jobData) {
    return undefined;
  }

  if (jobData.sync_frequency === 'seconds') {
    return {
      sync_frequency: 'seconds'
    };
  }
  if (jobData.sync_frequency === 'hour' || jobData.sync_frequency === 'day') {
    if (!jobData.schedule_time || !jobData.time_zone) {
      console.log('jobData is invalid', jobData);
      return undefined;
    }

    return {
      sync_frequency: 'hour',
      utc_timezone: jobData.time_zone,
      utc_offset: moment.tz(jobData.time_zone).utcOffset(),
      schedule_time: jobData.schedule_time
    };
  }
  if (jobData.sync_frequency === 'manual') {
    return {
      sync_frequency: 'manual',
      filters: jobData.filters
    };
  }
  console.log('jobData is invalid', jobData);
  return undefined;
}
