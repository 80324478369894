import React from 'react';
import { Markdown, Modal } from '@/components';
import { Button } from '@douyinfe/semi-ui';
import { DOMUtils } from '@/utils';
import { useTranslation } from 'react-i18next';

export const useCommunityAssistance = () => {
  const { t } = useTranslation();

  const openCommunityAssistanceModal = () => {
    const modal = Modal.info({
      title: t('communityAssistanceModal.title'),
      content: (
        <div className="mt-[32px]">
          <Markdown>{t<string>('communityAssistanceModal.description', { ns: 'translation' })}</Markdown>

          <div className="semi-modal-footer">
            <div>
              <Button
                theme="solid"
                type="secondary"
                onClick={() => {
                  DOMUtils.open(process.env.REACT_APP_TELEGRAM_BUSINESS_CONTACT as string, '_blank');
                  modal.destroy();
                }}
              >
                {t('communityAssistanceModal.joinTelegram', { ns: 'translation' })}
              </Button>

              <Button
                theme="solid"
                type="primary"
                onClick={() => {
                  DOMUtils.open(process.env.REACT_APP_DISCORD_BUSINESS_CONTACT as string, '_blank');
                  modal.destroy();
                }}
              >
                {t('communityAssistanceModal.joinDiscord', { ns: 'translation' })}
              </Button>
            </div>
          </div>
        </div>
      ),
      footer: null
    });
  };

  return {
    openCommunityAssistanceModal
  };
};
