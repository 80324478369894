import React, { ForwardedRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/components';
import classNames from 'classnames';
import { TabPane, Tabs } from '@douyinfe/semi-ui';
import DataExplorer from './components/DataExplorer';
import FileExplorer from './components/FileExplorer';

export type Explorer = 'data' | 'file' | null;

interface SideExplorerProps {
  foldPanel: () => void;
  expandPanel: () => void;
}

function SideExplorer({
  foldPanel,
  expandPanel
}: SideExplorerProps, ref: ForwardedRef<any>): React.ReactElement {
  const [curExplorer, setCurExplorer] = useState<Explorer>('data');
  const [panelVisible, setPanelVisible] = useState<boolean>(true);

  const { t } = useTranslation();

  // Handlers
  const onExplorerMenuItemClick = (explorer: Explorer): void => {
    if (explorer === curExplorer) {
      setCurExplorer(null);
      foldPanel();
    } else {
      setCurExplorer(explorer);
      expandPanel();
      setPanelVisible(true);
    }
  };

  const onFoldButtonClick = (): void => {
    setPanelVisible(false);
    foldPanel();
  };

  React.useImperativeHandle(ref, () => ({
    changePanelVisible(status: boolean) {
      setPanelVisible(status);
    }
  }));

  // Renders
  const renderExplorerButton = (explorer: Explorer): React.ReactElement => {
    const iconStyle = {
      fill: curExplorer === explorer ? 'black' : '#A1AAB0'
    };

    return (
      <li className="list-none my-[5px]">
        <button
          type="button"
          className={classNames('w-[28px] h-[28px] p-1.5 rounded-[4px]', { 'bg-[#E0F2FF]': curExplorer === explorer && panelVisible })}
          onClick={() => onExplorerMenuItemClick(explorer)}
        >
          <Icon
            wrapper="svg"
            style={iconStyle}
            icon={`dataCloud/${explorer}-explorer`}
          />
        </button>
      </li>
    );
  };

  const renderSideMenu = (): React.ReactElement => (
    <div className="w-[42px] p-[7px] border-0 border-r-[1px] border-[#E3E7EC] border-solid box-border">
      <ul className="m-auto flex flex-col justify-start h-screen">
        {renderExplorerButton('data')}
        {/* SHIELD: */}
        {renderExplorerButton('file')}
      </ul>
    </div>
  );

  const renderGeneralHeader = (title: string): React.ReactElement => (
    <div className="flex justify-between mb-4 px-[24px] pt-[12px]">
      <h2 className="text-[18px] leading-[25px]">{title}</h2>
      <div
        className="w-[24px] h-[24px] rounded-sm cursor-pointer"
        onClick={onFoldButtonClick}
      >
        <Icon className="m-auto hover:bg-[#F6F8FA] rounded-[4px]" icon="dataCloud/explorer-header-back" />
      </div>
    </div>
  );

  const renderExplorer = (): React.ReactElement => (
    <Tabs
      tabPosition="left"
      activeKey={curExplorer ?? ''}
      tabPaneMotion={false}
      renderTabBar={() => null}
      className="h-full"
      contentStyle={
              {
                padding: 0,
                height: '100%'
              }
            }
    >
      <TabPane itemKey="data" className="w-full h-full">
        <div className="flex flex-col overflow-hidden h-full">
          {renderGeneralHeader(t('dataCloud.explorer.menu.data'))}
          <DataExplorer />
        </div>
      </TabPane>
      <TabPane itemKey="file" className="w-full h-full">
        <div className="h-full">
          {renderGeneralHeader(t('dataCloud.explorer.menu.file'))}
          <FileExplorer />
        </div>
      </TabPane>
    </Tabs>
  );

  return (
    <div className="flex justify-start h-full overflow-hidden">
      {renderSideMenu()}
      <div className="box-border w-[calc(100%)] flex flex-col overflow-hidden">
        {renderExplorer()}
      </div>
    </div>
  );
}

export declare type SideExplorerType =
    React.ElementRef<typeof SideExplorer>
    | {
  changePanelVisible: (status: boolean) => void
};

export default React.forwardRef<SideExplorerType | undefined, SideExplorerProps>(SideExplorer);
