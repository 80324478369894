import {
  GenerateIntegrationDTO,
  GenerateIntegrationData,
  JobData,
  SupportedIntegrationsData,
  SyncResponse,
  JobHistoryData,
  BatchJobLogData,
  UpdateIntegrationDTO
} from '@/model/Sync';
import { Integration } from '@/model/Integration';
import { AxiosResponse } from 'axios';
import Request from './Request';

export default class SyncApi {
  static client = Request;

  static listJobs() {
    return this.client.get<SyncResponse<JobData[] | false>>('/v2/sync/jobs');
  }

  static getJob(jobId: string) {
    return this.client.get<SyncResponse<JobData>>(`/v2/sync/jobs/${jobId}`);
  }

  static updateJob(jobId: string, data: Partial<JobData>) {
    return this.client.put<SyncResponse<JobData>>(
      `/v2/sync/jobs/${jobId}`,
      data
    );
  }

  static deleteJob(jobId: string) {
    return this.client.delete(`/v2/sync/jobs/${jobId}`);
  }

  static listJobHistory(jobId: string): Promise<AxiosResponse> {
    return this.client.get<SyncResponse<JobHistoryData>>(
      '/v2/sync/jobs/history',
      {
        params: {
          job_id: jobId
        }
      }
    );
  }

  static deleteBatchJobHistory(batchJobId: string) {
    return this.client.delete(`/v2/sync/jobs/history/${batchJobId}`);
  }

  static getBatchJobLog(batchJobId: string) {
    return this.client.get<SyncResponse<BatchJobLogData>>(
      `/v2/sync/jobs/log/${batchJobId}`
    );
  }

  static getJobExceptions(batchJobId: string) {
    return this.client.get<SyncResponse<string>>(
      `/v2/sync/jobs/exception/${batchJobId}`
    );
  }

  // TODO: #sync update data type
  static generateJob(data: Record<string, any>) {
    return this.client.post<SyncResponse<JobData>>('/v2/sync/jobs', data);
  }

  static runJob(jobId: string) {
    return this.client.post(`/v2/sync/jobs/${jobId}/start`);
  }

  static stopJob(jobId: string) {
    return this.client.get(`/v2/sync/jobs/${jobId}/stop`);
  }

  static listIntegrations() {
    return this.client.get<SyncResponse<Integration[] | false>>(
      '/v2/sync/integrations'
    );
  }

  static listSupportedIntegrations() {
    return this.client.get<SyncResponse<SupportedIntegrationsData>>(
      '/v2/sync/integrations/supported'
    );
  }

  static generateIntegration(data: GenerateIntegrationDTO) {
    return this.client.post<SyncResponse<GenerateIntegrationData>>(
      '/v2/sync/integrations',
      data
    );
  }

  static getIntegration(integrationId: string) {
    return this.client.get<SyncResponse<Integration>>(
      `/v2/sync/integrations/${integrationId}`
    );
  }

  static deleteIntegration(integrationId: string) {
    return this.client.delete(`/v2/sync/integrations/${integrationId}`);
  }

  static updateIntegration(integrationId: string, data: UpdateIntegrationDTO) {
    return this.client.put<SyncResponse<Integration>>(
      `/v2/sync/integrations/${integrationId}`,
      data
    );
  }

  static testIntegrationConnectivity(
    data: GenerateIntegrationDTO,
    controller: AbortController
  ) {
    return this.client.post('/v2/sync/integrations/check', data, {
      signal: controller.signal
    });
  }

  static isWhiteListed() {
    return this.client.get('/v2/sync/isWhiteListed');
  }
}
