import Request from '@/api/Request';
import { AxiosResponse } from 'axios';

export default class AnnouncementApi {
  static getAnnouncementLatest(data: any): Promise<AxiosResponse> {
    return Request.get('/v2/announcement/latest', {
      params: data
    });
  }
}
