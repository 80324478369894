/* eslint-disable @typescript-eslint/no-use-before-define */
import { Icon, Loading } from '@/components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileTree, {
  FileTreeType
} from '@/views/dataCloudStudio/components/SideExplorer/components/FileExplorer/FileTree';
import { Collapse, Input } from '@douyinfe/semi-ui';
import _ from 'lodash';
import { CreateQueryData } from '@/utils/hooks/dataCloud/useSqlQuery';
import './index.scss';
import classNames from 'classnames';
import { StringUtils } from '@/utils';
import { sqlFileDB } from '@/utils/db/dataCloud/sqlFileDB';
import {
  SQLFilesContext
} from '@/views/dataCloudStudio/components/Providers/components/SQLFilesProvider';
import {
  ActiveTabContext
} from '@/views/dataCloudStudio/components/Providers/components/ActiveTabProvider';
import { Parameter } from '@/views/dataCloudStudio/components/Providers/components/ParametersProvider';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  fetchTrinoQueriesList,
  trinoQueries,
  updateTrinoQueriesList
} from '@/store/reducers/trinoQueriesReducer';
import { TrinoQuery } from '@/model/TrinoQuery';

export declare interface Collection {
  name: string,
  type: 'collection',
  children: Resource[]
}

export declare interface File {
  id: number,
  name: string,
  type: 'file',
  description?: string,
  updatedAt?: number,
  data?: any
}

function isFile(object: Resource): object is File {
  return 'id' in object && object.type === 'file';
}
function isCollection(object: Resource): object is Collection {
  return object.type === 'collection';
}

export { isFile, isCollection };
export declare type Resource = Collection | File;

export default function FileExplorer(): React.ReactElement {
  const { t } = useTranslation();
  const fileTreeRef = useRef<FileTreeType>();
  const [filterMode, setFilterMode] = useState<boolean>(false);

  const [activeKey, setActiveKey] = useState<string | string[] | undefined>(['recent', 'collections']);
  const [collectionResources, setCollectionResources] = useState<Resource[]>([]);
  const [recentResources, setRecentResources] = useState<Resource[]>([]);
  const sqlFiles = useContext(SQLFilesContext);
  const activeTab = useContext(ActiveTabContext);
  const queries = useAppSelector(trinoQueries);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchTrinoQueriesList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const resources: Resource[] = queries.list.map((item) => ({
      id: item.id,
      name: item.name,
      type: 'file',
      updatedAt: +new Date(item.updatedAt),
      data: item
    }));
    setCollectionResources(resources);

    setRecentResources(_.slice(resources, 0, 10));
  }, [queries]);

  const handleOpenInEditor = (query: CreateQueryData) => {
    const targetQuery = sqlFiles?.filter((item) => item.queryId === query.id);
    if (targetQuery?.length && targetQuery?.length > 0) {
      // move target query to window 1
      activeTab.toggle(targetQuery[0].uuid);
      return;
    }

    const newGenerateUUID = StringUtils.uuid();
    sqlFileDB.sqlFile.add({
      uuid: newGenerateUUID,
      filename: query.name,
      content: query.sql,
      parameters: JSON.stringify(query.parameters.map((param): Parameter => ({
        name: param.key,
        defaultValue: param.value,
        dataType: param.type
      }))),
      queryId: query.id
    }).then(() => {
      setTimeout(() => {
        activeTab.toggle(newGenerateUUID);
      }, 10);
    });
  };

  /**
   * Search Input
   */
  const renderSearchInput = (): React.ReactElement => (
    <div className="search-input-wrapper">
      <Input
        placeholder={t<string>('dataCloud.explorer.file.search.placeholder')}
        suffix={<Icon className="mr-[12px]" icon="dataCloud/search-magnifier" />}
        onChange={(value) => {
          if (filterMode !== !!value) {
            setFilterMode(!!value);
          }
          fileTreeRef?.current?.search(value);
        }}
      />
    </div>
  );

  const handleUpdateQueries = (query: TrinoQuery, mode: 'delete' | 'update') => {
    dispatch(updateTrinoQueriesList()).then(() => {
      const targetQuery = sqlFiles?.find((item) => item.queryId === query.id);

      if (targetQuery?.uuid) {
        switch (mode) {
          case 'delete':
            sqlFileDB.sqlFile.delete(targetQuery?.uuid);
            break;
          case 'update':
            sqlFileDB.sqlFile.update(targetQuery.uuid, {
              filename: query.name
            });
            break;
          default:
            break;
        }
      }
    });
  };

  const renderCollapse = () => {
    if (queries.loading) {
      return (
        <div className="flex flex-center h-[200px]">
          <Loading type="grey" />
        </div>
      );
    }

    return (
      <Collapse
        className={classNames({
          filtered: filterMode
        })}
        expandIconPosition="left"
        activeKey={activeKey}
        onChange={(_activeKey) => {
          setActiveKey(_activeKey);
        }}
      >
        <Collapse.Panel reCalcKey={activeKey?.toString()} header={t('dataCloud.explorer.file.recent.title')} itemKey="recent" className="recent">
          <FileTree
            resources={recentResources}
            emptyTitle={t<string>('dataCloud.explorer.file.recent.empty')}
            onOpen={(event, node) => {
              handleOpenInEditor(node.data);
            }}
            onUpdate={handleUpdateQueries}
          />
        </Collapse.Panel>
        <Collapse.Panel
          header={t('dataCloud.explorer.file.collections.title')}
          itemKey="collections"
          reCalcKey={activeKey?.toString()}
        >
          <FileTree
            ref={fileTreeRef}
            resources={_.sortBy(collectionResources, 'updatedAt')}
            emptyTitle={t<string>('dataCloud.explorer.file.collections.empty')}
            onOpen={(event, node) => {
              handleOpenInEditor(node.data);
            }}
            onUpdate={handleUpdateQueries}
          />
        </Collapse.Panel>
      </Collapse>
    );
  };

  return (
    <div className="file-explorer">
      {renderSearchInput()}

      <div className="file-tree-wrapper">
        {renderCollapse()}
      </div>
    </div>
  );
}
