import React from 'react';
import { InputLabel, Toast } from '@/components';
import { useTranslation } from 'react-i18next';
import { Form } from '@douyinfe/semi-ui';
import { ArchivedQueryItem } from '@/props';
import { ModalForm } from '@/components/Modal';
import { useLoadingCallback } from '@/utils';
import TaskApi, { CreateQueryData, TaskResponse } from '@/api/TaskApi';
import { SQLFile } from '@/utils/db/dataCloud/sqlFileDB';
import { Parameter } from '@/views/dataCloudStudio/components/Providers/components/ParametersProvider';
import { parseSql } from '@/utils/hooks/dataCloud/useSqlQuery';

type SaveModalProps = {
  toSave: SQLFile
  onCancel:() => void;
  onComplete: (result: TaskResponse<Partial<CreateQueryData>[]>) => void;
}

function SaveModal(props: SaveModalProps): React.ReactElement {
  const {
    toSave,
    onCancel,
    onComplete
  } = props;
  const { t } = useTranslation(['archivedQueries', 'translation']);
  const taskClient = new TaskApi();
  const {
    loading,
    callback: handleSubmit
  } = useLoadingCallback(async (values: Record<string, string>) => {
    if (!taskClient) return;

    try {
      let response = null;
      if (toSave.queryId) {
        const parameters = (JSON.parse(toSave.parameters) as Parameter[]).map((val) => ({
          key: val.name,
          type: val.dataType,
          value: val.defaultValue
        }));
        response = await taskClient.updateQuery({
          id: toSave.queryId,
          name: values.title,
          description: values.description,
          sql: parseSql(toSave.content),
          parameters
        });
      } else {
        response = await taskClient.createQuery({
          name: values.title,
          sql: parseSql(toSave.content),
          parameters: [],
          description: values.description,
          private: false
        });
      }

      const { data: result } = response;

      if (result.message !== 'success') {
        Toast.error({
          content: result.message,
          showClose: false
        });
      } else {
        Toast.success({
          content: result.message,
          showClose: false
        });
        onComplete(result);
        onCancel();
      }
    } catch (e: any) {
      Toast.error(e?.response?.data?.message ?? 'Failed');
    }
  });

  return (

    <ModalForm
      okButtonProps={
        {
          theme: 'solid',
          htmlType: 'submit'
        }
      }
      confirmLoading={loading}
      onSubmit={handleSubmit}
      okText={t(toSave.queryId ? 'common.save' : 'common.create', { ns: 'translation' })}
      okType="primary"
      cancelText={t('common.cancel', { ns: 'translation' })}
      onCancel={onCancel}
    >
      <div className="create-project-wrapper">
        <Form.Input
          field="title"
          maxLength={100}
          label={t('archivedQueries.form.name.label')}
          placeholder={t<string>('archivedQueries.form.name.placeholder')}
          autofocus
          initValue={toSave.filename}
          rules={[
            {
              required: true,
              message: `${t('archivedQueries.form.name.label')} ${t('common.required', { ns: 'translation' })}`
            }
          ]}
        />

        <Form.Input
          field="description"
          maxLength={512}
          label={t('archivedQueries.form.description.label')}
          placeholder={t<string>('archivedQueries.form.description.placeholder')}
          initValue={toSave.description}
        />

        <div
          className="editor-params-wrapper"
        >
          <div
            className="semi-form-field flex-1"
            x-label-pos="top"
            x-field-id="sql"
            x-extra-pos="bottom"
          >
            <InputLabel
              label={t('archivedQueries.form.sql.label')}
            />
            <div className="sql-statement">
              <div className="medium">
                {toSave.content}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalForm>

  );
}

export declare type SaveModalType =
  React.ElementRef<typeof SaveModal>
  | {
  show: (value: ArchivedQueryItem) => void
};

export default SaveModal;
