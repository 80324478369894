import React, { ChangeEvent, MouseEventHandler, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { AnimatePresence } from 'framer-motion';
import AccountApi from '@/api/AccountApi';
import { useLoadingCallback, LocalStorage } from '@/utils';
import { CommonRegExp } from '@/config/constant';
import { reportEvent } from '@/utils/useTrack';
import ReactGA from 'react-ga4';
import {
  TextLink,
  ButtonLoading,
  Card,
  HeaderTitle,
  AuthToast,
  TypingEffect
} from '@/components';
import AccountLogin from './AccountLogin';
import WalletLoginModal from '../login/WalletLoginModal';
import InputStatusStep from './InputStatusStep';
import './index.scss';

export default function Register(): React.ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [step, setStep] = React.useState(0);
  const [activeIndex, setActiveIndex] = React.useState(1);
  const [bindWallet, setBindWallet] = React.useState<string>('');
  const [modalStatus, setModalStatus] = React.useState<boolean>(false);
  const [formData, setFormData] = React.useState({
    email: '',
    username: '',
    password: '',
    confirmPassword: '',
    account: '',
    sign: ''
  });

  const handleNext = (stepIndex: number): void => {
    if (step < stepIndex) {
      setStep(stepIndex);
    }
    setTimeout(() => {
      const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll(
        '.register-card input'
      );
      inputs[stepIndex - 1]?.focus();
    }, 50);
  };
  React.useEffect(() => {
    setTimeout(() => {
      ReactGA.gtag('event', 'conversion', {
        send_to: 'AW-11166775498/QKlqCKeJx7AYEMrx3cwp'
      });
    }, 3000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      handleNext(1);
    }, 1250);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeValue = (e: ChangeEvent, key: string): void => {
    const target = e.target as HTMLInputElement;
    setFormData({
      ...formData,
      [key]: target.value
    });
  };

  const { callback: handleLogin, loading } = useLoadingCallback(async () => {
    const passwordMD5 = CryptoJS.MD5(formData.password).toString();
    const postData = {
      email: formData.email,
      username: formData.username,
      address: formData.account,
      signature: formData.sign,
      password: passwordMD5
    };

    reportEvent('register', { metric2: formData.email, path: '/register' });

    // TODO verify form data
    const response = await AccountApi.register(postData);

    const { data } = response;
    if (data.code === 200) {
      AuthToast.showToast({
        type: 'success',
        text: 'Success'
      });

      // localStorage.setItem('token', data.data.token);
      LocalStorage.set<boolean>('QuickStart', true);

      setTimeout(() => {
        navigate('/emailActivation', {
          state: {
            email: formData.email,
            username: formData.username
          }
        });
      }, 100);
    } else {
      AuthToast.showToast({
        type: 'error',
        text: data.message
      });
    }
  });

  const handleSubmit: MouseEventHandler = async (event) => {
    event.preventDefault();
    await handleLogin();
  };

  return (
    <div className="register">
      <HeaderTitle title={t('register.headerTitle')} />

      <Card className="register-card">
        <h5 className="title">
          <TypingEffect
            typeSpeed={15}
            text={t('register.title')}
            smartBackspace
            cursorColor={step === 0 ? '#A0A0A0' : 'transparent'}
          />
        </h5>

        <InputStatusStep
          test={step >= 1}
          active={activeIndex === 1}
          onActive={() => setActiveIndex(1)}
          name="email"
          type="email"
          placeholder={t('register.emailPlaceholder')}
          onChange={(e: ChangeEvent) => changeValue(e, 'email')}
          onVerify={(event) => {
            const target = event.target as HTMLInputElement;
            return CommonRegExp.email.test(target.value);
          }}
          onComplete={() => {
            handleNext(2);
          }}
        />

        <InputStatusStep
          test={step >= 2}
          active={activeIndex === 2}
          onActive={() => setActiveIndex(2)}
          name="password"
          type="password"
          placeholder={t('register.passwordPlaceholder')}
          onChange={(e: ChangeEvent) => changeValue(e, 'password')}
          onVerify={(event) => {
            const target = event.target as HTMLInputElement;
            return CommonRegExp.password.test(target.value);
          }}
          onError={() => {
            AuthToast.showToast({
              type: 'error',
              text: t('register.passwordError')
            });
          }}
          onComplete={() => {
            handleNext(3);
          }}
        />

        <InputStatusStep
          test={step >= 3}
          active={activeIndex === 3}
          onActive={() => setActiveIndex(3)}
          name="username"
          type="text"
          placeholder={t('register.usernamePlaceholder')}
          onChange={(e: ChangeEvent) => changeValue(e, 'username')}
          onVerify={(event) => {
            const target = event.target as HTMLInputElement;
            return !!target.value;
          }}
          onComplete={() => handleNext(4)}
        />

        <InputStatusStep
          test={step >= 4}
          active={activeIndex === 4}
          onActive={() => setActiveIndex(4)}
          name="bindWallet"
          type="text"
          onChange={(e: ChangeEvent) => {
            const target = e.target as HTMLInputElement;
            setBindWallet(target.value);
          }}
          placeholder={t('register.bindWalletPlaceholder')}
          onVerify={(event) => {
            const target = event.target as HTMLInputElement;
            return _.includes(
              ['y', 'yes', 'yeah', 'n', 'no'],
              target.value.toLowerCase()
            );
          }}
          onComplete={() => {
            if (_.includes(['y', 'yes', 'yeah'], bindWallet.toLowerCase())) {
              setModalStatus(true);
            }
            handleNext(5);
          }}
        />

        {step >= 5 ? (
          <>
            <TextLink
              className="privacy"
              links={t('register.privacyLink', { returnObjects: true })}
            >
              {t('register.privacy')}
            </TextLink>

            <ButtonLoading
              className="btn-create bolder"
              htmlType="button"
              loading={loading}
              onClick={handleSubmit}
            >
              {t('register.createButton')}
            </ButtonLoading>
          </>
        ) : null}
      </Card>

      <AnimatePresence>
        {modalStatus ? (
          <WalletLoginModal
            close={() => {
              setModalStatus(false);
            }}
            onAccountChange={(account, sign) => {
              setFormData({
                ...formData,
                account: account || '',
                sign
              });
              setModalStatus(false);
            }}
          />
        ) : null}
      </AnimatePresence>

      <AccountLogin />
    </div>
  );
}
