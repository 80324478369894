import React, { useContext, useEffect, useRef, useState } from 'react';
import { sqlFileDB } from '@/utils/db/dataCloud/sqlFileDB';
import { useTranslation } from 'react-i18next';
import SplitPane, { Pane } from 'react-split-pane';
import { loader } from '@monaco-editor/react';
import * as monaco from 'monaco-editor';
import { StringUtils } from '@/utils';
import { HeaderTitle } from '@/components';
import NavHeader from '@/components/Layout/NavHeader';
import UpgradePlanButton from '@/components/Button/UpgradePlanButton';
import VersionSwitch from '@/views/dataCloudStudio/components/VersionSwitch';
import SideExplorer, { SideExplorerType } from './components/SideExplorer';
import SQLStudio from './components/SQLStudio';
import { DataCloudProviders } from './components/Providers';
import { ActiveTabContext } from './components/Providers/components/ActiveTabProvider';
import { SQLFilesContext } from './components/Providers/components/SQLFilesProvider';
import './index.scss';

loader.config({ monaco });

function Main() {
  const [isPanelFolded, setIsPanelFolded] = useState(false);

  const sideExplorerRef = useRef<SideExplorerType>();
  const visibleStyle = { overflow: 'visible' };

  const { t } = useTranslation();

  const files = useContext(SQLFilesContext);
  const activeTab = useContext(ActiveTabContext);

  useEffect(() => {
    if (files === undefined) return;
    if (activeTab.current === undefined) {
      if (files.length > 0) {
        const first = files[0];
        activeTab.toggle(first.uuid);
      } else {
        sqlFileDB.sqlFile.add({
          uuid: StringUtils.uuid(),
          filename: 'Query 1',
          content: '',
          parameters: '[]'
        });
      }
    } else if (files.length > 0) {
      const curTab = files.find((file) => file.uuid === activeTab.current);
      if (curTab === undefined) {
        activeTab.toggle(files[0].uuid);
      }
    } else {
      sqlFileDB.sqlFile.add({
        uuid: StringUtils.uuid(),
        filename: 'Query 1',
        content: '',
        parameters: '[]'
      });
    }
  }, [activeTab, files]);

  const panelSize = {
    sideWidth: 42,
    menuWidth: 424
  };

  return (
    <>
      <HeaderTitle title={t('dataCloud.alpha.title')} />
      <NavHeader
        routers={[t('dataCloud.alpha.title')]}
        afterBreadcrumb={<VersionSwitch version="v2" />}
        menu={<UpgradePlanButton />}
      />
      {/* Split Main Body */}
      <div className="panel-wrapper">
        <SplitPane
          split="vertical"
          minSize={isPanelFolded ? panelSize.sideWidth : panelSize.menuWidth}
          maxSize={window.window.innerWidth / 2}
          size={isPanelFolded ? panelSize.sideWidth : panelSize.menuWidth}
          pane2Style={{
            overflow: 'visible'
          }}
          onDragStarted={() => {
            // if (isPanelFolded) {
            //   setIsPanelFolded(false);
            // }
          }}
          onDragFinished={(size) => {
            if (size > panelSize.sideWidth) {
              setIsPanelFolded(false);
              sideExplorerRef.current?.changePanelVisible(false);
            }
          }}
        >
          <Pane
            className="navigation-pane"
            size={isPanelFolded ? panelSize.sideWidth : undefined}
            split="vertical"
            style={visibleStyle}
          >
            <SideExplorer
              ref={sideExplorerRef}
              foldPanel={() => setIsPanelFolded(true)}
              expandPanel={() => setIsPanelFolded(false)}
            />
          </Pane>
          <Pane className="relative">
            <SQLStudio />
          </Pane>
        </SplitPane>
      </div>
    </>
  );
}

export default function DataCloudTerminalV2() {
  return (
    <DataCloudProviders>
      <Main />
    </DataCloudProviders>
  );
}
