import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TopicItem } from '@/model/Kafka';
import { fetchDatasetTopicsList } from '@/views/sync/kafka/request';
import { RootState } from '@/store/store';
import { TreeItemSchema } from '@/model/Table';

type StateProps = {
  topics: TopicItem[],
  loading: boolean
};

const initialState: StateProps = {
  topics: [],
  loading: false
};

export const fetchKafkaTopicList = createAsyncThunk(
  'kafkaTopic/getList',
  async () => {
    const response = await fetchDatasetTopicsList();
    return response.data.data;
  }
);

export const kafkaTopicReducer = createSlice({
  name: 'KafkaTopicSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchKafkaTopicList.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchKafkaTopicList.fulfilled,
        (state, action) => {
          state.topics = action.payload;
        }
      )
      .addCase(
        fetchKafkaTopicList.rejected,
        (state) => {
          state.loading = false;
        }
      );
  }
});

export default kafkaTopicReducer.reducer;

export const kafkaRawTopics = (state: RootState):
    TopicItem[] => state.kafkaTopic.topics.filter((topic) => topic.layer === 'raw');

export const kafkaAbstractedTopics = (state: RootState):
    TopicItem[] => state.kafkaTopic.topics.filter((topic) => topic.layer === 'abstracted');
