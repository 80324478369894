import React from 'react';
import _ from 'lodash';
import { Typography } from '@douyinfe/semi-ui';
import classNames from 'classnames';
import './index.scss';

type TextLinkProps = {
  links: {
    [key: string]: string
  },
  children: string,
  className?: string
}

export default function TextLink({
  className,
  links,
  children
}: Omit<TextLinkProps, ''>): React.ReactElement {
  const words = children.split(/[[,\]]/);
  return (
    <p className={classNames(['text-link', className])}>
      {
        _.map(words, (item) => {
          if (links[item]) {
            return (
              <Typography.Text
                key={item}
                link={
                  {
                    href: links[item],
                    target: '_blank'
                  }
                }
              >
                {item}
              </Typography.Text>
            );
          }
          return item;
        })
      }
    </p>
  );
}
