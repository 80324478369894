import React from 'react';
import _ from 'lodash';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import Image from '../Image';

const assets = require.context(
  '../../assets/icon',
  true,
  /\.(png|ico|svg|jpg|gif|jpeg)$/
);

const ImageList = _.map(assets.keys(), (key: string) => {
  const filename = key.substring(key.indexOf('/') + 1, key.lastIndexOf('.'));
  const image = assets(key);

  const fullName = key.substring(key.indexOf('/') + 1);
  return {
    filename,
    fullName,
    image
  };
});

export const AssetsImageMap = _.mapValues(_.keyBy(ImageList, 'filename'), (item) => item);

export declare interface IconProp {
  icon: string;
  wrapper?: 'img' | 'svg';
  className?: string;
  alt?: string;
  style?: React.CSSProperties,
  hideOnError?: boolean
}

const defaultProps: IconProp = {
  icon: '',
  className: '',
  alt: '',
  hideOnError: false
};

const hasIcon = (icon: string): boolean => !!AssetsImageMap[icon];

export {
  hasIcon
};

/**
 * dynamic import icon
 */
export default function Icon({
  icon,
  wrapper,
  alt = icon,
  className,
  style,
  hideOnError
} = defaultProps): React.ReactElement | null {
  const item = AssetsImageMap[icon];

  if (hideOnError && !item) {
    return null;
  }

  if (wrapper !== 'img' && item && item?.fullName?.endsWith('.svg')) {
    return (
      <ReactSVG
        style={style}
        className={classNames([className, 'dynamic-svg-icon'])}
        beforeInjection={(svg) => {
          svg?.classList?.add('full-svg');
        }}
        src={item?.image}
        wrapper={undefined}
      />
    );
  }
  return (
    <Image style={style} className={className} src={item?.image} alt={alt} />
  );
}
