import React from 'react';
import { Dropdown, Button } from '@douyinfe/semi-ui';
import { DropDownMenuItem } from '@douyinfe/semi-ui/dropdown';
import { Icon } from '@/components';
import './OperationButton.scss';

type OperationButtonProps = {
  menu: DropDownMenuItem[]
}

export default function OperationButton({
  menu
}: OperationButtonProps): React.ReactElement {
  return (
    <Dropdown
      trigger="click"
      position="left"
      clickToHide
      menu={menu}
    >
      <Button
        size="small"
        theme="light"
        className="btn-operation border-none bg-transparent hover:border-none"
        icon={<Icon icon="common/more" className="icon" />}
      />
    </Dropdown>

  );
}
