import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import dashboard from './en-US/dashboard.json';
import logExplorer from './en-US/logExplorer.json';
import translation from './en-US/translation.json';
import settings from './en-US/settings.json';
import datasets from './en-US/datasets.json';
import pipelines from './en-US/pipelines.json';
import archivedQueries from './en-US/archivedQueries.json';
import integrations from './en-US/integrations.json';
import api from './en-US/api.json';

export function config(): void {
  i18n
    .use(initReactI18next)
    .init({
      resources: {
        en: {
          dashboard,
          logExplorer,
          translation,
          settings,
          datasets,
          pipelines,
          archivedQueries,
          integrations,
          api
        }
      },
      lng: 'en',
      fallbackLng: 'en',
      detection: {
        caches: ['localStorage', 'sessionStorage', 'cookie']
      },
      interpolation: {
        escapeValue: false
      }
    });
}
