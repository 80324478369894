import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { LocaleProvider } from '@douyinfe/semi-ui';
import enUs from '@douyinfe/semi-ui/lib/es/locale/source/en_US';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactGA from 'react-ga4';
import Route from './route';
import './styles/App.scss';
import { ToastContainer } from './components';
import AuthProvider from './config/auth/AuthProvider';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.05,
  beforeSend: (event, hint) => {
    const error = hint.originalException as Error;
    if (error && error?.message && (
      error.message.match(/Login expired/i)
       || error.message.match(/User denied account access/i)
    )) {
      // do not send known issue
      return null;
    }
    return event;
  },
  denyUrls: [
    // Chrome extensions
    /extensions:/i,
    /^chrome:\/\//i
  ]
});

export default function App(): React.ReactElement {
  React.useEffect(() => {
    const gaKey = 'G-RR0KP5ZS1K';
    ReactGA.initialize(gaKey);
    ReactGA.gtag('js', new Date());
    ReactGA.gtag('config', gaKey);
  }, []);
  return (
    <LocaleProvider locale={enUs}>
      <AuthProvider>
        <HelmetProvider>
          <div className="app">
            <Helmet titleTemplate="%s | Chainbase Console">
              <title>Home</title>
            </Helmet>

            <Route />

            <ToastContainer />

          </div>
        </HelmetProvider>
      </AuthProvider>
    </LocaleProvider>
  );
}
