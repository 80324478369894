import React from 'react';
import { TextLink } from '@/components';
import { useTranslation } from 'react-i18next';
import TableEmpty from '@/components/Table/TableEmpty';

export default function QueryResultEmpty(): React.ReactElement {
  const { t } = useTranslation();

  return (
    <TableEmpty
      icon="dataCloud/empty"
      title={t('dataCloud.empty.ready')}
      className="query-result-empty"
    >
      <TextLink
        className="tip"
        links={
          {
            docs: 'https://docs.chainbase.com/docs/data-cloud-overview'
          }
        }
      >
        {t('dataCloud.empty.docs')}
      </TextLink>
    </TableEmpty>
  );
}
