import { Icon } from '@/components';
import { BlockLoading } from '@/components/Loading/BlockLoading';
import { TabPane, Tabs } from '@douyinfe/semi-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { useIntegrationList } from '@/views/sync/utils/JobRequestHooks';
import SetupExistsIntegration from './components/SetupExistsIntegration';
import SetupNewIntegration from './components/SetupNewIntegration';

const SelectExistsTabKey = 'selectExists';
const SetupNewTabKey = 'setupNew';

export default function SetupIntegration(): React.ReactElement {
  const { t } = useTranslation();
  const tTab = (tab: string): string => t(`sync.jobs.newJobSteps.setupIntegration.content.tabs.${tab}`);

  const [activeTabKey, setActiveTabKey] = useState('1');
  const { isEmpty, isFetchingIntegrationList } = useIntegrationList();
  const defaultTabKey = isEmpty ? SetupNewTabKey : SelectExistsTabKey;

  if (isFetchingIntegrationList) {
    return <BlockLoading />;
  }

  const renderTab = (key: string): React.ReactElement => {
    const iconColor = activeTabKey === key ? '#191E21' : '#A1AAB0';

    return (
      <div className="tab">
        <span>
          <Icon
            icon={`sync/jobs/new/setupIntegration/${key}`}
            style={{
              fill: iconColor,
              stroke: iconColor
            }}
          />
        </span>
        <span>
          {tTab(`${key}Integration`)}
        </span>
      </div>
    );
  };

  return (
    <div className="sync-new-job-setup-integration">
      <Tabs
        onChange={(newActiveTabKey) => setActiveTabKey(newActiveTabKey)}
        defaultActiveKey={defaultTabKey}
      >
        <TabPane
          className="sync-tab-pane"
          itemKey={SelectExistsTabKey}
          tab={renderTab('selectExists')}
        >
          <SetupExistsIntegration />
        </TabPane>
        <TabPane
          className="sync-tab-pane"
          itemKey={SetupNewTabKey}
          tab={renderTab('setupNew')}
        >
          <div className="w-full h-full overflow-x-auto px-2">
            <SetupNewIntegration />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
}
