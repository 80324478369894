import React from 'react';
import ReactECharts from 'echarts-for-react';
import { EChartsOption } from 'echarts';
import './index.scss';

export default function AreaLineChart({
  data
}: {
  data: any[];
}): React.ReactElement {
  const chartsRef = React.useRef<ReactECharts | null>(null);

  const options: EChartsOption = {
    width: 'auto',
    grid: {
      left: 0,
      right: 0,
      bottom: '1%',
      height: '100%'
    },
    tooltip: {
      trigger: 'axis',
      position(pt) {
        return [pt[0], '5%'];
      }
    },
    xAxis: {
      type: 'time',
      boundaryGap: false,
      show: false
    },
    yAxis: {
      type: 'value',
      boundaryGap: false,
      show: false
    },
    series: [
      {
        name: '',
        type: 'line',
        smooth: true,
        symbol: 'none',
        areaStyle: {
          color: '#EAF3FA'
        },
        lineStyle: {
          width: 1,
          color: '#2E363D'
        },
        data
      }
    ],
    color: ['#3C4757']
  };
  return (
    <ReactECharts
      ref={(e) => {
        chartsRef.current = e;
      }}
      className="area-line-chart data-chart"
      option={options}
      style={
        {
          width: '240px',
          height: '32px'
        }
      }
    />
  );
}
