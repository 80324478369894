import { ConfigurationForm } from '@/model/Integration';
import { PostgresqlTemplate } from '@/config/sync/integration-config-forms/Postgresql';
import { S3Form } from './S3';
import { TiDBTemplate } from './TiDB';
import { MySQLTemplate } from './MySQL';

export const IntegrationConfigMapping: { [type: string]: ConfigurationForm } = {
  s3: S3Form,
  postgresql: PostgresqlTemplate,
  tidb: TiDBTemplate,
  mysql: MySQLTemplate
};
