import Dexie, { Table } from 'dexie';

export interface SQLFile {
  uuid: string;
  filename: string;
  content: string;
  parameters: string;
  queryId?: number;
  saved?: boolean;
  description?: string;
}

export class SQLFileDexie extends Dexie {
  sqlFile!: Table<SQLFile>;

  constructor() {
    super('sqlFile');
    this.version(4).stores({
      sqlFile: 'uuid, filename, content, parameters, queryId'
    });
  }
}

export const sqlFileDB = new SQLFileDexie();
