import React from 'react';
import { Loading } from '@/components';
import AccountApi from '@/api/AccountApi';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function PayLink(): React.ReactElement {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const handleLoadUserInfo = async (): Promise<void> => {
    const response = await AccountApi.currentUserInfo({
      headers: {
        IgnoreAuth: true
      }
    });
    const { account } = response.data;
    const productId = params.get('productId');
    const { location } = window;
    if (account?.uuid) {
      location.href = `${location.protocol}//${location.host}/api/v1/sub/checkout?product=${productId}`;
    } else {
      const targetUrl = `/payment?productId=${productId}`;
      navigate(`/login?redirectUrl=${encodeURIComponent(targetUrl)}`);
    }
  };

  React.useEffect(() => {
    handleLoadUserInfo().then();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="flex flex-center"
      style={
        {
          height: '100vh'
        }
      }
    >
      <Loading type="grey" />
    </div>
  );
}
