/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable arrow-spacing */
import { useCallback, useState } from 'react';

type Page = {
  page: number;
  size: number;
};

export default function usePagination<T>(data: T[], pageSize = 10, pageNo = 1) {
  const [page, setPage] = useState<Page>({
    page: pageNo,
    size: pageSize
  });

  const pageData = data.slice(
    page.size * (page.page - 1),
    page.size * page.page
  );

  const onChange = useCallback(
    (_page: number, _pageSize: number) => {
      const maxPage = Math.ceil(data.length / _pageSize);
      if (_page >= 1 && _page <= maxPage) {
        setPage({
          page: _page,
          size: _pageSize
        });
      }
    },
    [setPage]
  );

  if (!data) {
    return {};
  }

  return {
    pageData,
    total: data.length,
    pageNo: page.page,
    pageSize: page.size,
    onChange
  };
}
