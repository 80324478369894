import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@douyinfe/semi-ui';
import { Link } from 'react-router-dom';
import { DOMUtils } from '@/utils';
import './Empty.scss';
import TableEmpty from '@/components/Table/TableEmpty';

type EmptyProps = {
  icon?: string;
  title: string;
  description: string;
  howTo: string;
  aboutLink?: string;
  onGetStarted?: () => void;
}

const defaultEmptyProps: EmptyProps = {
  icon: 'chainNetwork/empty',
  title: '',
  description: '',
  howTo: '',
  aboutLink: '/'
};

type EmptyAboutProps = Omit<{
  text: string,
  link: string
}, ''>

export function EmptyAbout({
  text,
  link
}: EmptyAboutProps): React.ReactElement {
  const { t } = useTranslation();
  return (
    <div className="table-about">
      {t('empty.about')}
      <Link
        to={{}}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          DOMUtils.open(link);
        }}
        target="_blank"
      >
        {text}
      </Link>
    </div>
  );
}

export default function Empty({
  icon,
  title,
  description,
  howTo,
  aboutLink = '/',
  onGetStarted
} = defaultEmptyProps): React.ReactElement {
  const { t } = useTranslation();
  return (
    <div className="empty">

      <div className="content">
        <TableEmpty
          icon={icon || 'chainNetwork/empty'}
          title={title}
          desc={description}
        />

        <Button
          size="large"
          theme="solid"
          type="primary"
          className="btn-get-started btn-enhance uppercase semi-bold"
          onClick={onGetStarted}
        >
          {t('common.getStarted')}
        </Button>
      </div>

      <EmptyAbout link={aboutLink} text={howTo} />
    </div>
  );
}
