import React from 'react';
import { Outlet } from 'react-router-dom';
import Base from './Base';
import './index.scss';

type LayoutProps = {
  header?: React.ReactElement,
  className?: string;
  navigation: React.ReactElement
}

export default function Layout({
  header,
  className,
  navigation
}: Omit<LayoutProps, ''>): React.ReactElement {
  return (
    <Base className={className} header={header}>
      {navigation}
      <div className="main">
        <Outlet />
      </div>
    </Base>
  );
}
