import { Button, Dropdown, Popover } from '@douyinfe/semi-ui';
import React from 'react';
import { Icon } from '@/components';
import { useTranslation } from 'react-i18next';
import DeletePopoverBody from '@/components/Common/DeletePopoverBody';

type DropDownMoreProps = {
  mode?: 'saved' | 'unSave';
  onRename: () => void;
  onDelete: () => void;
  onSave?: () => void;
  onClose?: () => void;
}
export default function DropDownMore({
  mode = 'saved',
  onRename,
  onDelete,
  onSave,
  onClose
}: Omit<DropDownMoreProps, ''>): React.ReactElement {
  const { t } = useTranslation();
  const [visible, setVisible] = React.useState(false);
  const [deleteVisible, setDeleteVisible] = React.useState(false);

  return (
    <Popover
      visible={deleteVisible}
      content={(
        <DeletePopoverBody
          onCancel={() => setDeleteVisible(false)}
          onDelete={async () => {
            if (onDelete) {
              await onDelete();
            }
            setDeleteVisible(false);
          }}
        />
    )}
      onVisibleChange={setDeleteVisible}
      trigger="custom"
      onClickOutSide={() => {
        setDeleteVisible(false);
      }}
      position="topRight"
    >
      <Dropdown
        trigger="click"
        clickToHide
        position="bottom"
        visible={visible}
        onVisibleChange={setVisible}
        render={(
          mode === 'saved'
            ? (
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={onRename}
                  className="btn-remove"
                  type="tertiary"
                >
                  {t('common.rename')}
                </Dropdown.Item>

                <Dropdown.Divider />
                <Dropdown.Item
                  onClick={() => {
                    setTimeout(() => {
                      setDeleteVisible(true);
                    }, 100);
                  }}
                  type="danger"
                >
                  {t('common.delete')}
                </Dropdown.Item>
              </Dropdown.Menu>
            )
            : (
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={onSave}
                  type="primary"
                >
                  {t('common.save')}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={onClose}
                  type="tertiary"
                >
                  {t('common.close')}
                </Dropdown.Item>
              </Dropdown.Menu>
            )
        )}
      >
        <Button
          onClick={() => {
            setDeleteVisible(false);
            setVisible(!visible);
          }}
          className="btn-more"
          theme="borderless"
          icon={(
            <Icon
              className="icon"
              icon="dataCloud/more"
            />
          )}
        />
      </Dropdown>
    </Popover>
  );
}
