import { useTranslation } from 'react-i18next';
import Icon from '../Icon';

interface Props {
  data: {
    key: string;
    icon: string;
    link?: string;
  }
}

export default function FooterLink({ data }: Props) {
  const { t } = useTranslation();

  return (
    <div className="w-1/2">
      <a href={data.link ?? ''} className="flex items-center justify-center gap-[5px] hover:bg-[#373F46] h-full p-2 rounded">
        <Icon icon={data.icon} className="w-4 h-4" />
        <div className="text-[#A1AAB0] font-normal">
          {t(data.key)}
        </div>
      </a>
    </div>
  );
}
