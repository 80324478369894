import { SyncJobFilter } from '@/model/Sync';
import { Tag } from '@douyinfe/semi-ui';
import { FilterOps } from '../utils/useAddFilterModal';

interface FilterTagProps {
  filter: SyncJobFilter;
  onClose: (filter: SyncJobFilter) => void;
}

export default function FilterTag({ filter, onClose }: FilterTagProps) {
  const displayOp = FilterOps.find((op) => filter.op === op.value);
  return (
    <Tag closable onClose={() => onClose(filter)} className="filter-tag">
      {`${filter.name} ${displayOp?.label} ${filter.value}`}
    </Tag>
  );
}
