import { AxiosResponse } from 'axios';
import Request from '@/api/Request';
import { Response } from '@/model';
import {
  PaymentCardInfo, PaymentInfo,
  RecentInvoiceItem
} from '@/model/BillingPlan';

export default class BillingApi {
  static getInvoiceList(page: number): Promise<AxiosResponse<Response<RecentInvoiceItem[]>>> {
    return Request.get('/v2/payment/invoices', { params: { page } });
  }

  static getBindCardInfo(): Promise<AxiosResponse<Response<PaymentCardInfo>>> {
    return Request.get('/v2/payment/card');
  }

  static updateCard({
    token
  }: {
    token: string
  }): Promise<AxiosResponse<Response<any>>> {
    return Request.post('/v2/payment/card/update', { token });
  }

  static getPaymentInfo(): Promise<AxiosResponse<Response<PaymentInfo>>> {
    return Request.get('/v2/payment/info');
  }

  /**
   * 订阅套餐
   * @param subscriptionPlan 要订阅定套餐
   */
  static subscribePlan({
    subscriptionPlan
  }: {
    subscriptionPlan: number
  }): Promise<AxiosResponse<Response<any>>> {
    return Request.post('/v2/payment/subscription/update', {
      subscription_plan: subscriptionPlan
    });
  }
}
