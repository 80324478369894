import React from 'react';
import { Outlet } from 'react-router-dom';
import Base from './Base';

export default function DefaultLayout(): React.ReactElement {
  return (
    <Base>
      <Outlet />
    </Base>
  );
}
