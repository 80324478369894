const DOMUtils = {

  open(href: string, target = '_blank') {
    const a = document.createElement('a');
    a.href = href;
    a.target = target;
    document.body.appendChild(a);
    a.click();

    a.remove();
  }
};

export default DOMUtils;
