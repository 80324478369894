import React from 'react';
import { Icon } from '@/components';
import { Button } from '@douyinfe/semi-ui';
import { ButtonProps } from '@douyinfe/semi-ui/button';
import './index.scss';

export default function SearchButton(props: ButtonProps): React.ReactElement {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Button
      className="btn-search"
      theme="borderless"
      icon={<Icon className="icon icon-search" icon="common/search" />}
      {...props}
    />
  );
}
