/* eslint-disable no-plusplus */
import { JobData } from '@/model/Sync';
import { Job } from '@/store/reducers/syncReducer';

export const castJob = (from: JobData): Job => ({
  id: from.id,
  jobId: from.job_id,
  integrationId: from.integration_id,
  name: from.job_name,
  datasets: {
    blockchain: '',
    name: from.source_table
  },
  integration: from.target,
  frequency: from.sync_frequency,
  syncMode: from.sync_frequency === 'manual' ? 'manual' : 'batch',
  lastSync: from.updated_at,
  state: Boolean(from.status).valueOf()
});
