import { Parameter } from '@/views/dataCloudStudio/components/Providers/components/ParametersProvider';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';

export interface Response<D> {
  code: number;
  data: D;
  message: string;
}

export type QueryParameter = {
  key: string;
  type: string;
  value: string;
};

export type CreateQueryData = {
  id: number;
  name: string;
  sql: string;
  parameters: QueryParameter[];
  private: boolean;
  createdAt: string;
  updatedAt: string;
};

export type ExecuteQueryData = {
  executionId: string;
  status: string;
};

export type ExecutionStatusData = {
  executionId: string;
  status: string;
  queueLength: string;
  queryId: number;
  message: string;
  progress: number;
  submittedAt: string;
  expiresAt: string;
  executionStartedAt: string;
  executionEndedAt: string;
};

export type ExecutionResults = {
  columns: {
    name: string;
    type: string;
    typeSignature: {
      arguments: {
        kind: string;
        value: number;
      }[];
      rawType: string;
    };
  }[];
  data: any[][];
  cpu_time_millis: number;
  execution_ended_at: string;
  execution_id: string;
  execution_started_at: string;
  execution_time_millis: number;
  expires_at: string;
  peak_memory_bytes: number;
  pending_time_millis: number;
  query_id: number;
  status: string;
  submitted_at: string;
  total_row_count: number;
};

export const parseSql = (sql: string) => {
  const trimed = sql.trim();
  return trimed.endsWith(';') ? trimed.slice(0, trimed.length - 1) : trimed;
};

export default function useSqlQuery(config?: AxiosRequestConfig) {
  config = config ?? {};

  const [abortController, setAbortController] = useState(new AbortController());
  const requestConfig: AxiosRequestConfig = {
    ...config,
    signal: abortController.signal
  };

  const client = axios.create(requestConfig);

  const listQueries = useCallback(async () => {
    const axiosResponse = await client.get<Response<CreateQueryData[]>>(
      '/api/v2/datacloud/query'
    );
    const listQueriesData = axiosResponse.data;
    return listQueriesData.data;
  }, [client]);

  const createQuery = useCallback(
    async (name: string, sql: string, parameters: Parameter[]) => {
      const requestData = {
        name,
        sql: parseSql(sql),
        parameters: parameters.map((param) => ({
          key: param.name,
          type: param.dataType,
          value: param.defaultValue
        }))
      };

      const response = await client.post<Response<CreateQueryData[]>>(
        '/api/v2/datacloud/query',
        requestData
      );

      const responseData = response.data;
      const task = responseData.data;
      const { id } = task[0];
      return id;
    },
    [client]
  );

  const updateQuery = useCallback(
    async (data: {
      id: number;
      name: string;
      sql: string;
      parameters: QueryParameter[];
      private: boolean;
    }) => {
      data.sql = parseSql(data.sql);
      await client.put('/api/v2/datacloud/query', data);
    },
    [client]
  );

  const executeQuery = useCallback(
    async (sql: string, parameters: Parameter[]) => {
      const requestData = {
        sql: parseSql(sql),
        parameters: parameters.map((param) => ({
          key: param.name,
          type: param.dataType,
          value: param.defaultValue
        }))
      };

      const response = await client.post<Response<ExecuteQueryData[]>>(
        '/api/v2/datacloud/query/execute',
        requestData
      );

      const responseData = response?.data;
      const executeQueryData = responseData?.data;
      const { executionId } = executeQueryData[0];

      return executionId;
    },
    [client]
  );

  const executeQueryById = useCallback(
    async (queryId: number, parameters: Parameter[]) => {
      const queryParameters: Record<string, string> = {};

      parameters.forEach((item) => {
        queryParameters[item.name] = item.defaultValue;
      });

      const response = await client.post<Response<ExecuteQueryData[]>>(
        `/api/v2/datacloud/query/${queryId}/execute`,
        {
          queryParameters
        }
      );
      const responseData = response.data;
      const { executionId } = responseData.data[0];
      return executionId;
    },
    [client]
  );

  const getExecutionStatus = useCallback(
    async (executionId: string) => {
      const response = await client.get<Response<ExecutionStatusData[]>>(
        `/api/v2/datacloud/execution/${executionId}/status`
      );
      const responseData = response.data;
      const executionStatus = responseData.data;
      const { status, message, progress } = executionStatus[0];
      return { status, message, progress };
    },
    [client]
  );

  const getExecutionResults = useCallback(
    async (executionId: string) => {
      const response = await client.get<Response<ExecutionResults>>(
        `/api/v2/datacloud/execution/${executionId}/results`
      );
      return response?.data?.data;
    },
    [client]
  );

  const getExecutionResultsRaw = useCallback(
    async (executionId: string) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      client.get<AxiosResponse>(
        `/api/v2/datacloud/execution/${executionId}/results`
      ),
    [client]
  );

  const cancelAll = () => {
    abortController.abort();
    const newController = new AbortController();
    client.defaults.signal = newController.signal;
    setAbortController(newController);
  };

  return {
    createQuery,
    listQueries,
    updateQuery,
    executeQuery,
    executeQueryById,
    getExecutionStatus,
    getExecutionResults,
    getExecutionResultsRaw,
    cancelAll
  };
}
