import React from 'react';
import classNames from 'classnames';
import { ColumnProps } from '@douyinfe/semi-ui/table/interface';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button } from '@douyinfe/semi-ui';
import { TypographyBaseType } from '@douyinfe/semi-ui/lib/es/typography/interface';
import { useSelector } from 'react-redux';
import LogSideSheet, { LogDetailSideSheetType } from '@/views/dashboard/log/detail';
import TableEmpty from '@/components/Table/TableEmpty';
import { DateUtils } from '@/utils';
import {
  Icon, Pagination, StatusText, Table
} from '@/components';
import { networkList, projectList } from '@/store/reducers/constantReducer';
import { RequestLogItem } from '@/model/Log';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { fetchLogList } from '@/store/reducers/logReducer';
import { Optional } from '@/props';
import { LogSearchParams } from '@/api/LogExplorerApi';

export default function LogsList(): React.ReactElement {
  const { t } = useTranslation('logExplorer');
  const detailRef = React.useRef<LogDetailSideSheetType>(null);
  const dispatch = useAppDispatch();
  const logs = useAppSelector((state) => state.logs);

  const projects = useSelector(projectList);
  const networks = useSelector(networkList);
  const loading = useAppSelector((state) => state.logs.listLoading);

  const handleOpenDetailSideSheet = (item: RequestLogItem): void => {
    detailRef?.current?.show(item);
  };

  const columns: ColumnProps<RequestLogItem>[] = [
    {
      title: '',
      dataIndex: 'index',
      width: 60,
      className: 'expand-column',
      render: (text, record) => (
        <Button
          onClick={() => {
            handleOpenDetailSideSheet(record);
          }}
          className="btn-expand"
          theme="borderless"
          icon={<Icon icon="logExplorer/full" />}
        />
      )
    },
    {
      title: t('logExplorer.tableTitle.app'),
      dataIndex: 'app_id',
      minWidth: 200,
      render: (appId) => _.find(projects, { uuid: appId })?.name ?? ''
    },
    {
      title: t('logExplorer.tableTitle.method'),
      dataIndex: 'chainbase_method',
      minWidth: 200,
      render: (text) => <p className="text-ellipsis">{text}</p>
    },
    {
      title: t('logExplorer.tableTitle.errorCode'),
      dataIndex: 'err_code',
      width: 150,
      render(errCode: number, record: any): React.ReactNode {
        return (
          <StatusText preview="● " type={() => (errCode === 0 ? 'success' : 'danger')}>
            {errCode || record.http_code}
          </StatusText>
        );
      }
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components,react-hooks/rules-of-hooks
      title: t('logExplorer.tableTitle.ip'),
      width: 150,
      dataIndex: 'request.headers.x-forwarded-for',
      render(data: string): React.ReactNode {
        return <span>{data.split(',')[0]}</span>;
      }
    },
    {
      title: t('logExplorer.tableTitle.response'),
      dataIndex: 'latency',
      sorter: true,
      render(duration: number, record: RequestLogItem): React.ReactNode {
        const calcFunc: () => TypographyBaseType = record.chainbase_method === 'dwQuery'
          ? () => {
            if (duration < 10 * 1000) {
              return 'success';
            }
            if (duration < 60 * 1000) {
              return 'warning';
            }
            return 'danger';
          }
          : () => {
            if (duration < 1.5 * 1000) {
              return 'success';
            }
            if (duration < 3 * 1000) {
              return 'warning';
            }
            return 'danger';
          };

        return (
          <StatusText type={calcFunc()} suffix=" s">
            {(duration / 1000).toFixed(2)}
          </StatusText>
        );
      }
    },
    {
      title: t('logExplorer.tableTitle.sent'),
      dataIndex: 'start_time',
      sorter: true,
      render: (reqTimestamp: number) => DateUtils.fromNow(reqTimestamp)
    },
    {
      title: t('logExplorer.tableTitle.network'),
      dataIndex: 'chain_id',
      render(chainId: string | number): React.ReactNode {
        const network = _.find(networks, (item) => item.chain_id === parseInt(`${chainId}`, 10));
        if (!network) {
          return '';
        }
        return `${network.chain_name}-${network.chain_network}`;
      }
    }
  ];

  return (
    <div className={classNames('logs-list')}>
      <Table
        loading={loading}
        columns={columns}
        dataSource={logs.logs}
        empty={<TableEmpty />}
        onRow={(record) => ({
          onClick: () => {
            handleOpenDetailSideSheet(record);
          }
        })}
      />

      <Pagination
        total={logs.pagination.total}
        showSizeChanger
        onChange={(currentPage, pageSize) => {
          dispatch(
            fetchLogList({
              page: currentPage,
              limit: pageSize
            } as Optional<LogSearchParams>)
          );
        }}
      />

      <LogSideSheet ref={detailRef} />
    </div>
  );
}
