import './index.scss';
import { ColumnSchema, ResultTableType } from '@/model/Table';
import QueryResultTable from '@/views/dataCloudStudio/components/SQLStudio/components/QueryResult/components/QueryResultTable';
import { Metadata } from '@/model/DataCloudStudio';

interface MetadataPreviewerProps {
  tableDetail?: Metadata
}

export default function MetadataPreviewer({ tableDetail }: MetadataPreviewerProps) {
  if (!tableDetail) {
    return null;
  }

  const renderResult = () => {
    if (!tableDetail) {
      return null;
    }

    const columns = tableDetail.column.map((column) => column.name);

    const meta = columns.map((value: any, index: any) => ({
      schema_type: 'column',
      name: columns[index],
      key: `${value}_${index}`,
      type: value.type,
      index
    } as ColumnSchema));

    const kvResult = tableDetail.ExampleData.map((row: any) => {
      const obj: { [key: string]: any } = {};
      columns.forEach((name: any, index: number) => {
        const key = `${name}_${index}`;
        obj[key] = row[index];
      });
      return obj;
    });

    const resultTable: ResultTableType = {
      rows: tableDetail.ExampleData.length,
      meta,
      kv_result: kvResult
    };

    return (
      <div className="query-result-table-wrapper">
        <QueryResultTable
          key={tableDetail.table}
          resultTable={resultTable}
          hidePagination
        />
      </div>
    );
  };

  return renderResult();
}
