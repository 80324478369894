import React from 'react';
import { Col, Row } from '@douyinfe/semi-ui';
import { useTranslation } from 'react-i18next';
import {
  Block,
  Container, HeaderTitle
} from '@/components';
import {
  DateUtils, Numbers
} from '@/utils';
import { Optional } from '@/props';
import { LogSearchParams } from '@/api/LogExplorerApi';
import './index.scss';
import Layout from '@/components/Layout/Layout';
import LogsList from '@/views/dashboard/log/LogsList';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { fetchLogList } from '@/store/reducers/logReducer';
import Search from './Search';
import RequestLogChart from './RequestLogChart';

export default function LogExplorer(): React.ReactElement {
  const { Header } = Layout;
  const { t } = useTranslation('logExplorer');

  const dispatcher = useAppDispatch();
  const searchQuery = useAppSelector((state) => state.logs.query);
  const pagination = useAppSelector((state) => state.logs.pagination);

  const format = 'LL @ hh:mm:ss';

  React.useEffect(() => {
    dispatcher(fetchLogList());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = async (values: Record<string, any>): Promise<void> => {
    await dispatcher(fetchLogList({
      ...values,
      page: 1
    } as Optional<LogSearchParams>));
  };

  return (
    <Container className="log-explorer-container max-w-full">
      <HeaderTitle title={t('logExplorer.title')} />

      <Header
        icon="navigation/logExplorer"
        title={t('logExplorer.title')}
      >
        <Search onSearch={handleSearch} />
      </Header>

      <div className="content">

        <Row className="result-info" type="flex" justify="space-between">
          <Col>
            <span className="medium">
              {`${t('logExplorer.resultFound')}: `}
            </span>
            <span className="bolder">
              {`${Numbers.formatFractionDigits(pagination.total)}`}
            </span>
          </Col>
          <Col>
            <span
              className="medium oblique"
            >
              {DateUtils.format(searchQuery.begin_at, format)}
              {' - '}
              {DateUtils.format(searchQuery.end_at, format)}
            </span>
          </Col>
        </Row>

        <Block className="request-log-chart">
          <RequestLogChart />
        </Block>

        <LogsList />
      </div>
    </Container>
  );
}
