import React, { useState } from 'react';
import { Icon } from '@/components';
import { Collapsible } from '@douyinfe/semi-ui';
import { motion, useAnimation } from 'framer-motion';

interface CollapsePanelProps {
  label?: React.ReactNode;
  icon?: React.ReactNode;
  children: React.ReactNode;
  iconPosition?: 'left' | 'right'
}

export default function CollapsePanel({
  label,
  icon,
  children,
  iconPosition = 'left'
}: CollapsePanelProps) {
  const [isOpen, setIsOpen] = useState(true);
  const control = useAnimation();

  const panelIcon = (
    <motion.div animate={control}>
      {icon || <Icon icon="common/arrow-left" className="w-2 h-1 px-1 py-1.5 rotate-90" />}
    </motion.div>
  );
  return (
    <div>
      <div className="">
        <button
          type="button"
          onClick={() => {
            setIsOpen((prev) => {
              control.start({
                rotate: prev ? 180 : 0,
                transition: {
                  duration: 0.25
                } });
              return !prev;
            });
          }}
          className="px-0 py-[12px] m-0 flex items-center gap-1  w-full text-[#A1AAB0] font-medium text-xs"
          style={iconPosition === 'right' ? {
            display: 'flex',
            justifyContent: 'space-between'
          } : undefined}
        >
          {iconPosition === 'left' && panelIcon}
          <div>{label}</div>
          {iconPosition === 'right' && panelIcon}
        </button>
      </div>
      <Collapsible isOpen={isOpen} className="" keepDOM>
        {children}
      </Collapsible>
    </div>
  );
}
