import { ethers } from 'ethers';

export abstract class Wallet {
  provider: ethers.providers.Web3Provider | any;

  async requestAccount(): Promise<string> {
    return this.provider.send('eth_requestAccounts', []);
  }

  async getAccount(): Promise<string> {
    const signer = this.provider.getSigner();
    return signer.getAddress();
  }

  async signMessage(message: string): Promise<string> {
    const signer = this.provider.getSigner();
    return signer.signMessage(message);
  }
}
