import React from 'react';
import './index.scss';
import Header from '@/components/Layout/Header';
import { RouteProps } from '@douyinfe/semi-ui/lib/es/breadcrumb';
import { HeaderTitle } from '@/components';

interface SyncHeaderProps {
  title: string;
  breadcrumbItems: Array<RouteProps | string>;
  children?: React.ReactNode;
}

export default function SyncBreadcrumbHeader({
  title,
  breadcrumbItems,
  children
}: SyncHeaderProps): React.ReactElement {
  return (
    <>
      <HeaderTitle title={title} />
      <Header title={breadcrumbItems} />
      <div>{children}</div>
    </>
  );
}
