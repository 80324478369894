import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export declare type AsyncThunkPromise =
  Promise<any> | ReturnType<any> & {
  abort: (reason?: string) => void,
  requestId: string,
  arg: void,
  unwrap: () => Promise<void>
};
