import { Helmet } from 'react-helmet-async';
import React from 'react';

type HeaderTitleProps = {
  title: string;
}

export default function HeaderTitle({
  title
}: HeaderTitleProps): React.ReactElement {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
}
