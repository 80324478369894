import React, { useImperativeHandle, useState } from 'react';
import './index.scss';
import ChainbaseSwitch from '@/components/Switch';
import { ColumnProps } from '@douyinfe/semi-ui/table/interface';
import { Icon } from '@/components';
import { Card, Form } from '@douyinfe/semi-ui';
import usePrefixedTranslation from '@/utils/usePrefixedTranslation';
import CollapsePanel from '@/components/CollapsePanel';
import Label from '../Label';
import DataToIntegration from '../DataToIntegration';
import SyncTable from '../SyncTable';
import isDB from '../../utils/is-db';

interface ActivateFieldsToSyncCardProps {
  editable?: boolean;
  blockchains: string[];
  tableName: string;
  integration: string;
  fields: string[];
  initialSelectedFields?: string[];
  initialFieldsDesitination?: Record<string, string>;
  onSelectedFieldsChange?: (fields: string[]) => void;
  onDesitinationFieldsChange?: (fields: Record<string, string>) => void;
  onDesitinationTableNameChange?: (tableName: string) => void;
}

interface DataSourceColumn {
  // eslint-disable-next-line react/no-unused-prop-types
  fieldName: string;
}

export interface ActivateFieldsToSyncCardRef {
  reset(): void;
}

const ActivateFieldsToSyncCard = React.forwardRef<ActivateFieldsToSyncCardRef, ActivateFieldsToSyncCardProps>(({
  editable = true,
  blockchains,
  tableName,
  integration,
  fields,
  initialSelectedFields = [],
  initialFieldsDesitination = {},
  onSelectedFieldsChange,
  onDesitinationFieldsChange,
  onDesitinationTableNameChange
}, ref) => {
  const tConfigure = usePrefixedTranslation('sync.jobs.newJobSteps.configureJob.content.');

  const dataSource = fields.map((name) => ({ fieldName: name }));
  const [selectedFields, setSelectedFields] = useState<string[]>(initialSelectedFields);
  const [desitinationFields, setDesitinationFields] = useState<Record<string, string>>(initialFieldsDesitination);

  useImperativeHandle(ref, () => ({
    reset() {
      setSelectedFields(initialSelectedFields);
    }
  }));

  const renderSyncColTableHeader = (): React.ReactElement => {
    let allSelected = true;
    if (fields.length !== selectedFields.length) {
      allSelected = false;
    }

    return (
      <div className="table-header sync-col">
        <ChainbaseSwitch
          checked={allSelected}
          disabled={!editable}
          onChange={(checked) => {
            let newSelectedFields: string[];
            if (checked) {
              newSelectedFields = fields;
            } else {
              newSelectedFields = [];
            }
            setSelectedFields(newSelectedFields);
            if (onSelectedFieldsChange) {
              onSelectedFieldsChange(newSelectedFields);
            }
          }}
        />
        <Label label={tConfigure('sync')} />
      </div>
    );
  };

  const columnProps: ColumnProps[] = [{
    title: renderSyncColTableHeader(),
    width: '120px',
    render(prop: DataSourceColumn) {
      const selected = selectedFields.includes(prop.fieldName);

      return (
        <div className="table-row sync-col">
          <ChainbaseSwitch
            checked={selected}
            disabled={!editable}
            onChange={(checked) => {
              let newSelectedFields;
              if (checked) {
                newSelectedFields = selectedFields.concat(prop.fieldName);
              } else {
                newSelectedFields = selectedFields.filter((field) => field !== prop.fieldName);
              }
              setSelectedFields(newSelectedFields);
              if (onSelectedFieldsChange) {
                onSelectedFieldsChange(newSelectedFields);
              }
            }}
          />
        </div>
      );
    }
  }, {
    title: <Label label={tConfigure('fieldName')} />,
    width: '240px',
    dataIndex: 'fieldName'
  }, {
    width: '120px',
    render() {
      return (
        <div className="five-arrows-wrapper">
          <Icon className="five-arrows" icon="common/five-arrows" />
        </div>
      );
    }
  }, {
    title: <Label label={tConfigure('desitination')} />,
    width: '240px',
    render({ fieldName }: DataSourceColumn) {
      const initValue = initialFieldsDesitination[fieldName] ?? fieldName;
      return (
        <Form.Input
          noLabel
          disabled={!editable}
          field={`field_map.${fieldName}`}
          initValue={initValue}
          onChange={(value) => {
            const newDesitinationFields = {
              ...desitinationFields,
              [fieldName]: value
            };
            setDesitinationFields(newDesitinationFields);
            if (onDesitinationFieldsChange) {
              onDesitinationFieldsChange(newDesitinationFields);
            }
          }}
        />
      );
    }
  }];

  return (
    <Card className="activate-fields-card">
      <CollapsePanel
        label={(
          <>
            <div className="text-left">
              <span className="label-title card-title">
                {tConfigure('activateTheFieldsToSync')}
              </span>
            </div>
            <div className="title-notice">
              {tConfigure('activateTheFieldsToSyncNotice')}
            </div>
          </>
)}
        iconPosition="right"
      >
        <div className="left-to-right">
          <DataToIntegration
            blockchains={blockchains}
            tableName={tableName}
            integration={integration}
          />
        </div>
        {isDB(integration) && (
        <div className="table-name-input-wrapper">
          <Form.Input
            disabled={!editable}
            label={(<Label label="Table Name" />)}
            field="target_table"
            initValue={tableName.replaceAll('.', '_')}
            onChange={(value) => {
              if (onDesitinationTableNameChange) {
                onDesitinationTableNameChange(value);
              }
            }}
          />
        </div>
        )}
        <div className="sync-table-warpper">
          <SyncTable
            dataSource={dataSource}
            columnProps={columnProps}
            containerClassName="table-wrapper"
          />
        </div>
      </CollapsePanel>
    </Card>
  );
});

export default ActivateFieldsToSyncCard;
