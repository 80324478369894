import React from 'react';
import { Row, Typography } from '@douyinfe/semi-ui';
import { Block, Icon, Loading } from '@/components';
import { LoadingTypes } from '@/components/Loading';
import classNames from 'classnames';

export declare type DataItemAttributes = {
  icon?: string;
  title: string;
  value: string;
  color: 'info' | 'success' | 'warning' | 'danger';
  className?: string;
}

type DataItemProps =
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  & {
  data: DataItemAttributes;
  loading?: boolean;
  time: string;
  loadingType?: LoadingTypes;
}

export default function DataItem(
  props: Omit<DataItemProps, ''>
): React.ReactElement {
  const {
    data,
    time,
    loading,
    className,
    ...rest
  } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Block className={classNames('data-item', className)} {...rest}>
      <Row type="flex" justify="space-between" align="middle" className="data-header">
        <p className="title">
          {
            data?.icon ? <Icon icon={data.icon} className="icon" /> : null
          }

          {data.title}
        </p>
        <span className="time">{time}</span>
      </Row>
      <Row className="data-value">
        {
          loading ? <Loading type="grey" /> : (
            <Typography.Text className={classNames('medium')}>
              {data.value}
            </Typography.Text>
          )
        }

      </Row>
    </Block>
  );
}
