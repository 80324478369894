import React from 'react';
import { Breadcrumb, Col, Row } from '@douyinfe/semi-ui';
import './index.scss';
import { RouteProps } from '@douyinfe/semi-ui/lib/es/breadcrumb';
import { Icon } from '@/components';
import classNames from 'classnames';

type HeaderTitleProps = {
  icon?: string
  children: any
}

export function HeaderTitle({
  icon = undefined,
  children
}: Omit<HeaderTitleProps, ''>): React.ReactElement {
  return (
    <div className="header-title">
      {
        icon ? <Icon className="icon" icon={icon} /> : null
      }
      <h3 className="medium">{children}</h3>
    </div>
  );
}

type HeaderProps = {
  icon?: string
  description?: string
  title?: string | Array<RouteProps | string>
  children?: any
}

export default function Header({
  icon,
  title,
  description,
  children
}: Omit<HeaderProps, ''>): React.ReactElement {
  const isSubPage = title instanceof Array;
  const renderTitle = (): React.ReactElement | null => {
    if (title instanceof Array) {
      return (
        <Breadcrumb className="sub-page-breadcrumb" routes={title} />
      );
    }
    return (<HeaderTitle icon={icon}>{title}</HeaderTitle>);
  };
  return (
    <Row
      className={classNames('header', {
        'sub-page': isSubPage
      })}
      type="flex"
      justify="space-between"
      align="middle"
    >
      <Col>
        {renderTitle()}
        {
          description ? <p className="description">{description}</p> : null
        }
      </Col>

      {children}
    </Row>
  );
}
