import { Modal } from '@/components';
import IntegrationConfiguration from '@/views/sync/commons/IntegrationConfiguration';
import IntegrationSelector, { IntegrationType } from '@/views/sync/commons/IntegrationSelector';
import { useTranslation } from 'react-i18next';
import { IntegrationConfigMapping } from '@/config/sync';
import { useContext } from 'react';
import { JobCreationContext } from '../../../JobCreationProvider';

export default function SetupNewIntegration() {
  const { t } = useTranslation();
  const { jobCreationDispatch } = useContext(JobCreationContext);

  const onItemClick = (item: IntegrationType) => {
    const formTemplate = IntegrationConfigMapping[item?.name.toLowerCase()];
    if (!formTemplate) {
      return;
    }

    const modal = Modal.info({
      className: 'integration-configuration-modal',
      title: t('sync.jobs.newJobSteps.setupIntegration.content.createIntegration'),
      icon: null,
      content: (<IntegrationConfiguration
        configType="create"
        onCancel={() => modal.destroy()}
        onCreated={(integrationId) => {
          jobCreationDispatch({ type: 'selectIntegration',
            value: {
              integrationId,
              type: item.name
            } });
          modal.destroy();
        }}
        formTemplate={formTemplate}
        isModal
      />),
      footer: null
    });
  };

  return <IntegrationSelector onItemClick={onItemClick} />;
}
