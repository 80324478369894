import React from 'react';
import { Icon } from '@/components';
import { useTranslation } from 'react-i18next';
import { TabPane, Tabs } from '@douyinfe/semi-ui';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BlockLoading } from '@/components/Loading/BlockLoading';
import { DateUtils, Numbers } from '@/utils';
import classNames from 'classnames';
import SyncBreadcrumbHeader from '../commons/SyncBreadcrumbHeader';
import DataToIntegration from '../commons/DataToIntegration';
import './index.scss';
import JobHistory from './components/job-history';
import Configurations from './components/configrations';
import StateSwitch from '../commons/StateSwitch';
import { useJobDetail } from '../utils/JobRequestHooks';

const ConfigurationTabKey = '#configuration';
const JobHistoryTabKey = '#job-history';

export default function JobDetail(): React.ReactElement {
  const { jobId } = useParams();
  const { t } = useTranslation();

  const { jobData, jobHistoryData, metadata, isLoading } = useJobDetail(jobId);
  const nav = useNavigate();
  const { state } = useLocation();

  if (isLoading) {
    return <BlockLoading />;
  }

  if (!jobId || jobHistoryData === undefined || !jobData) {
    nav('/sync/jobs');
    return <div>404 NOT FOUND</div>;
  }

  const renderProgress = () => {
    const syncEndBlock = jobData.need_sync_block;
    const syncedBlockNumber = jobData.success_sync_block_cnt;
    if (syncEndBlock === undefined || syncedBlockNumber === undefined) {
      return null;
    }

    const SyncingStatus = {
      text: 'Syncing',
      icon: 'sync/jobs/detail/status/in-sync',
      spin: true,
      paused: false
    };

    const CompletedStatus = {
      text: 'Completed',
      icon: 'sync/jobs/detail/status/sync-succeed',
      spin: false,
      paused: false
    };

    const PausedStatus = {
      text: 'Paused',
      icon: 'sync/jobs/detail/status/sync-paused',
      spin: false,
      paused: true
    };

    let syncStatus;
    if (jobData.status === -1) {
      syncStatus = PausedStatus;
    } else if (syncEndBlock === syncedBlockNumber) {
      syncStatus = CompletedStatus;
    } else {
      syncStatus = SyncingStatus;
    }

    const syncProgress = ((syncedBlockNumber / syncEndBlock) * 100).toFixed(1);
    const syncFailedCount = jobData.failed_batch_job_cnt;

    const syncBlockNumberDisplay = `${Numbers.addCustomCommasToNumber(syncedBlockNumber)} / ${Numbers.addCustomCommasToNumber(syncEndBlock)}`;

    return (
      <div className="sync-progress-wrapper">
        <div className="left-wrapper">
          <div className="sync-progress-info-wrapper">
            <div className="left-wrapper">
              <div className="sync-status-wrapper">
                <Icon className={`icon ${syncStatus.spin && 'animate-spin'}`} icon={syncStatus.icon} />
                <span>{syncStatus.text}</span>
              </div>
              <div className="percentage">{`${syncProgress}%`}</div>
              {syncFailedCount && (
              <div className="warning-wrapper">
                <Icon className="icon" icon="common/warning-yellow" />
                <span>{syncFailedCount}</span>
              </div>
              )}
            </div>
            <div className="right-wrapper">
              <span>{`${t('sync.blockSynced')}: `}</span>
              <span>{syncBlockNumberDisplay}</span>
            </div>
          </div>
          <progress
            max={100}
            value={syncProgress}
            className={classNames('progress-bar', {
              paused: syncStatus.paused
            })}
          />
        </div>
      </div>
    );
  };

  const renderHeader = (): React.ReactElement => {
    const breadcrumbItems = [];

    if (state) {
      breadcrumbItems.push({
        href: '/sync/integrations',
        name: 'Integrations'
      });
      const { href, name } = state as {href?: string, name?: string};
      if (href && name) {
        breadcrumbItems.push({ href, name });
      }
    } else {
      breadcrumbItems.push({
        href: '/sync/jobs',
        name: 'Jobs'
      });
    }

    breadcrumbItems.push({
      href: `/sync/jobs/${jobId}`,
      name: jobData.job_name
    });

    const latestBlockTimestampDisplay = () => `Created ${DateUtils.fromNow(jobData.created_at)}`;
    return (
      <SyncBreadcrumbHeader title="Jobs" breadcrumbItems={breadcrumbItems}>
        <div className="header-main">
          <div className="title-indicator">
            <div className="job-details-wrapper">
              <div className="left-wrapper">
                <h1 className="title">{jobData.job_name}</h1>
                <div className="description">
                  <Icon className="icon" icon="refillable/clock" />
                  <span>{latestBlockTimestampDisplay()}</span>
                </div>
                {jobData.next_sync_at && (
                <div className="description">
                  <Icon className="icon" icon="refillable/sync-line" />
                  <span>{`${t('sync.nextSync')}: ${jobData.next_sync_at}`}</span>
                </div>
                )}
              </div>
              <div className="right-wrapper">
                {jobData.sync_frequency !== 'manual' && (
                <div className="enable-switch">
                  <span className="label">Enable</span>
                  <span className="switch-container">
                    <StateSwitch defaultChecked={jobData.status === 1} jobId={jobData.job_id} />
                  </span>
                </div>
                )}
              </div>
            </div>
            <div className="job-detail-data-to-integration">
              <DataToIntegration
                blockchains={metadata?.data?.chain ?? []}
                tableName={jobData.source_table}
                integration={jobData.target}
                onIntegrationClick={() => nav(`/sync/integrations/${jobData.integration_id}`)}
              />
            </div>
            {jobData.sync_frequency !== 'manual' && renderProgress()}
          </div>
        </div>
      </SyncBreadcrumbHeader>
    );
  };

  const renderTab = (tab: string): React.ReactElement => (
    <div className={`tab ${tab}`}>
      <Icon
        className="icon"
        icon={`sync/tabs/${tab}`}
      />
      <div className="title">
        {t(`sync.tabs.${tab}`)}
      </div>
    </div>
  );

  return (
    <div className="sync-job-detail-container">
      {renderHeader()}
      <div className="tabs-container">
        <Tabs>
          <TabPane
            tab={renderTab('job-history')}
            itemKey={JobHistoryTabKey}
          >
            <JobHistory jobHistoryData={jobHistoryData} jobData={jobData} />
          </TabPane>
          <TabPane
            tab={renderTab('configurations')}
            itemKey={ConfigurationTabKey}
          >
            <Configurations jobData={jobData} metadata={metadata} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}
