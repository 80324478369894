import React from 'react';
import _ from 'lodash';
import {
  dataCloudTableList
} from '@/store/reducers/dataCloudReducer';
import { useAppSelector } from '@/store/hooks';
import {
  ArchivedQueryItem,
  getChain,
  SortedBlockChain
} from '@/props';
import { TableSchema } from '@/model/Table';
import ChainIconGroup
  from '@/views/dataCloudStudio/components/SideExplorer/components/DataExplorer/components/ChainIconGroup';

type QueryBlockChainsProps = {
  data: ArchivedQueryItem
}

export function queryAllTable(sql: string | undefined, tableList: TableSchema[]): TableSchema[] {
  if (!sql) {
    return [];
  }

  return _.flatten(_.map(
    _.filter(tableList, (i) => {
      const name = i?.table_name || '';
      return sql?.indexOf(name) !== -1;
    }),
    (i) => (i)
  ));
}

export default function QueryBlockChains({ data }: QueryBlockChainsProps): React.ReactElement {
  const tableList = useAppSelector(dataCloudTableList);

  const chains = _.uniq(
    _.flatten(_.map(
      queryAllTable(data?.content, tableList),
      (i) => (i.schema ? _.map(i.schema, getChain) : [])
    ))
  );

  if (!chains) {
    return <div />;
  }

  const chainList = _.filter(SortedBlockChain, (item) => _.includes(chains, item));

  return (
    <div className="blockchain">
      <ChainIconGroup chains={chainList} />
    </div>
  );
}
