import React from 'react';
import Header from './Header';
import NavHeader from './NavHeader';

function Layout(): React.ReactElement {
  return (<div />);
}

Layout.Header = Header;
Layout.NavHeader = NavHeader;

export default Layout;
