import React from 'react';
import _ from 'lodash';
import { TabPane, Tabs } from '@douyinfe/semi-ui';
import { CopyableButton, Loading } from '@/components';
import { useTranslation } from 'react-i18next';
import PayloadPreview from '@/components/PayloadPreview';
import JSONView from '@/components/JSONView';
import classNames from 'classnames';
import { StringUtils } from '@/utils';

export declare type JSONViewerProps = {
  json: string | object | undefined;
  name: string;
  heightRate: number;
  defaultActiveKey?: 'table' | 'json';
  loading?: boolean;
} & React.HTMLAttributes<HTMLDivElement>

export default function DataViewer({
  loading,
  json,
  heightRate,
  defaultActiveKey,
  name,
  className
}: Omit<JSONViewerProps, ''>): React.ReactElement {
  const { t } = useTranslation();

  const obj = typeof json === 'string' ? StringUtils.toJSON(json) : (json || {});

  const keyValuePairs = _.keys(obj)
    .map((item) => ({
      key: item,
      value: obj[item]
    }));

  return (
    <div
      className={classNames(['json-viewer', className])}
      style={
        {
          height: `${heightRate * 100}%`
        }
      }
    >
      <Tabs
        type="line"
        tabBarExtraContent={(
          <CopyableButton
            content={JSON.stringify(obj)}
            copyTip={t('common.copyClipboard')}
          />
        )}
        defaultActiveKey={defaultActiveKey}
      >
        <TabPane tab={name} itemKey="table">
          {
            loading ? <Loading type="grey" />
              : <PayloadPreview list={keyValuePairs} />
          }

        </TabPane>
        <TabPane tab={t('common.json')} itemKey="json">
          {
            loading ? <Loading type="grey" /> : (
              <JSONView src={obj} />
            )
          }

        </TabPane>
      </Tabs>
    </div>
  );
}
