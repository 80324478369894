import React from 'react';
import { Button } from '@douyinfe/semi-ui';
import { Icon } from '@/components';
import { useTranslation } from 'react-i18next';
import { Numbers } from '@/utils';

type QueryStatusProps = Omit<{
  rows: number;
  size?: number;
  latency?: number;
  onDownload: () => void;
}, ''>

export default function QueryStatus({
  rows,
  size,
  latency,
  onDownload
}: QueryStatusProps): React.ReactElement {
  const { t } = useTranslation(['archivedQueries', 'translation']);

  const handleDownload = (): void => {
    onDownload();
  };

  const renderNumberWithUnits = (value: number, unit: string, fixed: number): React.ReactElement => <span>{`${value?.toFixed(fixed)} ${unit}`}</span>;

  return (
    <div className="query-status text-[#76808A]">
      {/* Success Icon */}
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="14" height="14" rx="3" fill="#5DBD5B" />
        <path d="M4 6.5L6.33333 9L11 4" stroke="white" strokeWidth="2" />
      </svg>
      {/* rows count */}
      {rows !== undefined && renderNumberWithUnits(rows, t('common.rows', { ns: 'translation' }), 0)}
      {/* CU */}
      {/* <span>
        {renderNumberWithUnits(cu, t('common.cu', { ns: 'translation' }), 0)}
      </span> */}
      {/* file size */}
      <span>
        {size !== undefined && Numbers.humanFileSize(size || 0, true, 2, true)}
      </span>
      {/* latency */}
      <span>
        {latency && (latency >= 1000 ? `${(latency / 1000).toFixed(1)} s` : `${(latency).toFixed(0)} ms`)}
      </span>
      <Button
        theme="borderless"
        onClick={handleDownload}
        htmlType="button"
        className="btn-download"
        icon={<Icon icon="dataCloud/download" />}
      />
    </div>
  );
}
