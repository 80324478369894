import React from 'react';
import classNames from 'classnames';
import { useAuth } from '@/config/auth/AuthProvider';
import { useAppDispatch } from '@/store/hooks';
import { fetchChainList } from '@/store/reducers/constantReducer';
import AccountApi from '@/api/AccountApi';
import Announcement from '@/components/Announcement';

type BaseProps = {
  header?: React.ReactElement;
  className?: string;
  children: React.ComponentProps<any>;
};

export default function Base({ header, className, children }: Omit<BaseProps, ''>): React.ReactElement {
  const auth = useAuth();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(fetchChainList());
    auth.setAuthLoading(true);
    AccountApi.currentUserInfo()
      .then((response) => {
        const { code, account } = response.data;
        if (code === 200) {
          auth.setCurrentUser(account);
        } else {
          // TODO toast error
        }
      })
      .finally(() => {
        auth.setAuthLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames('full-container', className)}>
      <Announcement />

      {header}
      <div className="body-content">{children}</div>
    </div>
  );
}
