/* eslint-disable camelcase */
import { Icon, Pagination } from '@/components';
import React from 'react';
import usePagination from '@/utils/usePagination';
import './index.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import SyncTable from '@/views/sync/commons/SyncTable';
import { DateUtils } from '@/utils';
import { JobData } from '@/model/Sync';
import ChainIconGroup from '@/views/dataCloudStudio/components/SideExplorer/components/DataExplorer/components/ChainIconGroup';
import StateSwitch from '../StateSwitch';
import SyncButton from '../SyncButton';

interface JobListProps {
  jobList: JobData[];
  integrationTitle?: string;
}

function SyncProgress({ data } : {
  data: JobData
}) {
  if (data.sync_frequency === 'manual') {
    return <div className="m-auto w-3/4 border-solid border-[1px] border-[#e3e7ec]" />;
  }

  const { success_sync_block_cnt, need_sync_block, failed_batch_job_cnt } = data;
  if (!success_sync_block_cnt || !need_sync_block) {
    return null;
  }
  const syncedPercent = Math.floor(((success_sync_block_cnt / need_sync_block) * 100));

  let icon;
  let iconAnimate;
  let progressType;
  if (syncedPercent === 100) {
    icon = 'sync/jobs/detail/status/sync-succeed';
    progressType = 'sync-succeed';
    iconAnimate = '';
  } else {
    icon = 'sync/jobs/detail/status/in-sync';
    progressType = 'in-sync';
    iconAnimate = 'animate-spin';
  }

  return (
    <div className="status-render-wrapper">
      <div className="icon-wrapper">
        <Icon className={`${iconAnimate} icon`} icon={icon} />
      </div>
      <div className="progress-wrapper">
        <progress
          className={`progress ${progressType}`}
          value={syncedPercent}
          max={100}
        />
        {failed_batch_job_cnt && (
          <div className="sync-failed-count">
            <span>
              <Icon icon="common/warning-yellow" />
            </span>
            <span>
              {failed_batch_job_cnt}
            </span>
          </div>
        )}
      </div>
      <div className="percentage-wrapper">
        {`${syncedPercent}%`}
      </div>
    </div>
  );
}

const getSyncModeDisplay = (syncMode: string) => {
  if (syncMode === 'manual') {
    return 'Ad-Hoc';
  }
  return syncMode.charAt(0).toUpperCase() + syncMode.slice(1);
};

export default function JobList({
  jobList: unsortedJobList,
  integrationTitle
}: JobListProps): React.ReactElement {
  const nav = useNavigate();
  const location = useLocation();

  const jobList = unsortedJobList.sort((a, b) => b.id - a.id);
  const { pageData, onChange } = usePagination(jobList);

  const columnProps = [{
    title: 'Name',
    key: 'name',
    dataIndex: 'job_name',
    width: '200px',
    render(name: string) {
      return (
        <p className="text-ellipsis">
          {name}
        </p>
      );
    }
  }, {
    title: 'Datasets',
    key: 'datasets',
    width: '300px',
    render(data: JobData): React.ReactElement {
      return (
        <div className="dataset">
          <ChainIconGroup chains={data.chain_list} />
          <span className="truncate">{data.source_table}</span>
        </div>
      );
    }
  }, {
    title: 'Integration',
    key: 'integration',
    dataIndex: 'target',
    width: '150px',
    render(integration: string): React.ReactElement {
      return (
        <div className="integration">
          {integration
          && <Icon className="integration-icon" icon={`sync/integrations/engine/${integration.toLowerCase()}`} />}
          <span>{integration}</span>
        </div>
      );
    }
  }, {
    title: 'Status',
    key: 'status',
    width: '300px',
    render: (data: JobData) => <SyncProgress data={data} />
  }, {
    title: 'Sync Mode',
    key: 'syncMode',
    width: '100px',
    render(job: JobData) {
      return <span>{getSyncModeDisplay(job.sync_frequency)}</span>;
    }
  }, {
    title: 'Last Sync',
    key: 'lastSync',
    dataIndex: 'last_sync_at',
    width: '150px',
    render(lastSync: string | null) {
      return lastSync ? DateUtils.fromNow(lastSync) : '';
    }
  }, {
    title: 'Enable',
    key: 'enable',
    width: '80px',
    render(data: JobData) {
      return (
        <div onClick={(e) => e.stopPropagation()}>
          {data.sync_frequency !== 'manual' && (
            <StateSwitch
              defaultChecked={data.status === 1}
              jobId={data.job_id}
              className="switch"
            />
          )}
          { data.sync_frequency === 'manual' && (
            <SyncButton jobId={data.job_id} />
          )}
        </div>
      );
    }
  }, {
    key: 'setting',
    width: 68,
    render(_: any, record: Record<string, any>) {
      return (
        <div className="flex justify-center">
          <button
            type="button"
            className="setting-button"
            onClick={() => nav(`/sync/jobs/${record.job_id}`)}
          >
            <Icon icon="sync/jobs/overview/gear" />
          </button>
        </div>
      );
    }
  }];

  const renderTable = (): React.ReactElement => (
    <SyncTable
      dataSource={pageData}
      columnProps={columnProps}
      containerClassName="table-warpper"
      onRowClick={(record) => {
        let state;
        if (integrationTitle) {
          state = {
            href: location.pathname,
            name: integrationTitle
          };
        }
        nav(`/sync/jobs/${record.job_id}`, { state });
      }}
    />
  );

  const renderPagination = (): React.ReactElement => (
    <footer className="pagination-warpper sync-pagination-warpper">
      <Pagination
        total={jobList.length}
        showSizeChanger
        onChange={onChange}
      />
      <div>
        <span className="job-count-text">Total Jobs:</span>
        <span className="job-count-amount">{jobList.length}</span>
      </div>
    </footer>
  );

  return (
    <div className="job-list-container">
      {renderTable()}
      {renderPagination()}
    </div>
  );
}
