import React from 'react';
import _ from 'lodash';
import { Pagination } from '@/components';
import { ResultTableType, TableRow } from '@/model/Table';
import ResultTable from '@/components/Table/ResultTable';

type ResultTableProps = {
  resultTable: ResultTableType
}

type TableProps<RecordType> = {
  dataSource: RecordType[],
  defaultPageSize?: number,
  defaultCurrentPage?: number
}

interface PaginationState {
  total: number;
  currentPage: number;
  pageSize: number;
}

type TableState<RecordType> = {
  pageTotal: number;
  pagination: PaginationState
  currentPageData: RecordType[],
  setCurrentPage: (page: number, size: number) => void
}

function useTable<RecordType>({
  dataSource,
  defaultPageSize = 10,
  defaultCurrentPage = 1
}: TableProps<RecordType>): TableState<RecordType> {
  const [data, setData] = React.useState<RecordType[]>(dataSource);

  const [pagination, setPagination] = React.useState<PaginationState>({
    total: dataSource?.length || 0,
    currentPage: defaultCurrentPage || 1,
    pageSize: defaultPageSize
  });

  const [currentPageData, setCurrentPageData] = React.useState<RecordType[]>(
    _.slice<RecordType>(
      dataSource,
      (defaultCurrentPage - 1) * defaultPageSize,
      defaultCurrentPage * defaultPageSize
    )
  );

  React.useEffect(() => {
    setData(dataSource);

    setPagination({
      total: dataSource?.length || 0,
      currentPage: defaultCurrentPage || 1,
      pageSize: pagination.pageSize
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  const updateCurrent = (paginationState: PaginationState): void => {
    setPagination(paginationState);
    const {
      currentPage,
      pageSize: size
    } = paginationState;

    const pageData = _.slice<RecordType>(dataSource, (currentPage - 1) * size, currentPage * size);
    setCurrentPageData(pageData || []);
  };

  const setCurrentPage = (page: number, size: number): void => {
    const newPagination = {
      ...pagination,
      pageSize: size,
      currentPage: page
    };
    updateCurrent(newPagination);
  };

  return {
    pageTotal: Math.ceil((data?.length || 0) / pagination.pageSize),
    pagination,
    currentPageData,
    setCurrentPage
  };
}

export default function QueryResultTable(props: ResultTableProps): React.ReactElement {
  const PageSizes: number[] = [50, 100, 200];
  const {
    resultTable
  } = props;

  const {
    meta,
    result = []
  } = resultTable;

  const {
    currentPageData,
    pagination,
    setCurrentPage
  } = useTable<TableRow>({
    dataSource: result,
    defaultPageSize: PageSizes[0]
  });

  if (!meta) {
    return <div />;
  }

  return (
    <>
      <div
        className="result-wrapper"
      >
        <ResultTable meta={meta} data={currentPageData} />
      </div>

      <Pagination
        pageSize={pagination.pageSize}
        total={pagination.total}
        currentPage={pagination.currentPage}
        onChange={(currentPage, pageSize) => {
          setCurrentPage(currentPage, pageSize);
        }}
        showSizeChanger
        pageSizeOpts={PageSizes}
      />
    </>
  );
}
