import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactECharts from 'echarts-for-react';
import { EChartsOption } from 'echarts-for-react/src/types';
import _ from 'lodash';
import { Col, Row } from '@douyinfe/semi-ui';
import moment from 'moment';
import classNames from 'classnames';
import { Block, Loading } from '@/components';
import { ChartQueryData } from '@/props';
import { LabelOption, LineStyleOption } from 'echarts/types/src/util/types';
import { ZRColor } from 'echarts/types/dist/shared';

function FilterItem({
  color,
  title,
  active = false,
  onClick
}: Omit<{
  color?: string;
  title: string;
  active: boolean;
  onClick: () => void;
}, ''>): React.ReactElement {
  return (
    <div
      className={classNames(
        ['filter-item', {
          active
        }]
      )}
      onClick={onClick}
    >
      <span
        className="circle"
        style={
        {
          backgroundColor: color
        }
      }
      />
      <span>{title}</span>
    </div>
  );
}

type RequestChartBlockProps = {
  data: ChartQueryData | undefined;
  loading: boolean;
  timeRange: string;
}

const ColorConfig: {
  [key: string]: {
    index: number;
    key: string;
    className: string;
    color?: string;
  };
} = {
  Request: {
    index: 0,
    key: 'request',
    className: 'info',
    color: '#191E21'
  },
  'Invalid Request': {
    index: 1,
    key: 'invalid',
    className: 'info',
    color: '#5D7083'
  },
  'Rate limited': {
    index: 2,
    key: 'rateLimited',
    className: 'warning',
    color: '#B55024'
  }
};

interface SplitLineOption {
  show?: boolean;
  interval?: 'auto' | number | ((index: number, value: string) => boolean);
  lineStyle?: LineStyleOption<ZRColor | ZRColor[]>;
}

export const ChartSplitLineStyle: SplitLineOption = {
  show: true,
  lineStyle: {
    type: [4, 2],
    color: '#EDEDED'
  }
};

export const ChartTextStyle: Pick<LabelOption, 'color' | 'fontStyle' | 'fontWeight' | 'fontSize' | 'fontFamily'> = {
  fontFamily: 'Roboto Flex',
  fontWeight: 500,
  fontSize: 11,
  color: '#A0A0A0'
};

export default function RequestChartBlock({
  data,
  loading = false,
  timeRange
}: RequestChartBlockProps): React.ReactElement {
  const { t } = useTranslation('dashboard');

  const echartsRef = React.useRef<ReactECharts | null>(null);

  const {
    datetime,
    ...rest
  } = data || {};

  const [hideKeys, setHideKeys] = React.useState<string[]>([]);

  const existKeys = _.keys(rest);

  const sortedKeys = _.filter(
    _.sortBy(
      _.keys(ColorConfig),
      (key) => ColorConfig[key].index
    ),
    (item) => _.includes(existKeys, item)
  );
  const keys = _.filter(sortedKeys, (i) => !_.includes(hideKeys, i));

  const dataList = _.map(keys, (key: string) => ({
    name: key,
    value: rest[key]
  }));

  let timeFormat = 'hh:mm';
  if (timeRange === '7d') {
    timeFormat = 'MM-DD hh:mm';
  } else if (timeRange.endsWith('d')) {
    timeFormat = 'hh';
  }

  const options: EChartsOption = {
    grid: {
      top: '15px',
      left: '10px',
      right: '10px',
      bottom: '5px',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: _.map(
        datetime,
        (item) => (
          moment(item * 1000)
            .format(timeFormat)
        )
      )
    },
    yAxis: {
      type: 'value',
      splitLine: ChartSplitLineStyle
    },
    textStyle: ChartTextStyle,
    series: _.map(dataList, (item) => ({
      name: item.name,
      data: item.value,
      type: 'line',
      smooth: true,
      symbol: 'none',
      lineStyle: {
        width: 3
      }
    })),
    color: _.map(
      dataList,
      (item) => (ColorConfig[item.name].color || '#5D7BA6')
    ),
    tooltip: {
      trigger: 'axis'
    }
  };

  const renderChart = (): React.ReactElement => (
    <div className="request-chart">
      {
        data ? (
          <ReactECharts
            ref={(e): void => {
              echartsRef.current = e;
            }}
            key={JSON.stringify(hideKeys)}
            option={options}
            style={
              {
                height: '100%'
              }
            }
          />
        ) : null
      }

    </div>
  );

  return (
    <Block className="request-block">
      <Row type="flex" align="middle" justify="space-between">
        <Col>
          <p className="title">
            {t('dashboard.requestChartTitle')}
            {' '}
            {t(`dashboard.times.${timeRange}`)}
          </p>
        </Col>
        <Row type="flex" className="filter-group" gutter={8}>
          {
            _.map(sortedKeys, (item) => (
              <Col key={item}>
                <FilterItem
                  color={ColorConfig[item]?.color}
                  title={t(`dashboard.data.${ColorConfig[item]?.key}`)}
                  active={!_.includes(hideKeys, item)}
                  onClick={() => {
                    if (_.includes(hideKeys, item)) {
                      const newList = _.filter(hideKeys, (i) => i !== item);
                      setHideKeys(newList);
                    } else {
                      setHideKeys([...hideKeys, item]);
                    }
                  }}
                />
              </Col>
            ))
          }
        </Row>
      </Row>

      {
        loading ? <Loading type="grey" /> : (renderChart())
      }

    </Block>
  );
}
