import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Buffer } from 'buffer';
import reportWebVitals from './reportWebVitals';
import { config as i18nConfig } from './locals/config';
import App from './App';
import { store } from './store/store';

i18nConfig();

Buffer.from('anything', 'base64');
window.Buffer = window.Buffer || Buffer;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
