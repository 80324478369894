import React, { MouseEventHandler, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { TabPane, Tabs } from '@douyinfe/semi-ui';
import './index.scss';
import { TabBarProps } from '@douyinfe/semi-ui/lib/es/tabs';
import { EventManager, Icon, Modal, Toast } from '@/components';
import { useTranslation } from 'react-i18next';
import { EditorInsert } from '@/components/CodeEditor/CodeEditor';
import { StringUtils } from '@/utils';
import { ResultTableType, TableSchema } from '@/model/Table';
import { sqlFileDB as db, sqlFileDB } from '@/utils/db/dataCloud/sqlFileDB';
import { BlockLoading } from '@/components/Loading/BlockLoading';
import { TrinoSQLMonaco } from '@/config/editor/trino';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { updateTrinoQueriesList } from '@/store/reducers/trinoQueriesReducer';
import useMonacoLanguage from '@/views/dataCloud/index/useMonacoLanguage';
import TaskApi, { CreateQueryData, TaskResponse } from '@/api/TaskApi';
import { parseSql } from '@/utils/hooks/dataCloud/useSqlQuery';
import { TablePrompt } from '@/model/DataCloudStudio';
import CodeEditor from './components/CodeEditor';
import EditorTabBar from './components/EditorTabBar';
import { Parameter, ParametersContext } from '../../../Providers/components/ParametersProvider';
import { ActiveTabContext } from '../../../Providers/components/ActiveTabProvider';
import { SQLFilesContext } from '../../../Providers/components/SQLFilesProvider';
import SaveModal from './components/SaveModal';

export declare type QueryTabItem = {
  key: string;
  id?: string;
  name: string;
  sql: string;
  updated?: boolean;
  resultLoading?: boolean;
  selectText?: string;
  result?: ResultTableType,
}

interface MainProps {
  tableList: TableSchema[]
}

function Main({ tableList }: MainProps) {
  const { t } = useTranslation(['translation', 'archivedQueries']);

  const activeTab = useContext(ActiveTabContext);
  const tabActiveKey = activeTab.current;
  const newTabKey = useRef<string | null>(null);

  useMonacoLanguage('Trino', TrinoSQLMonaco, tableList);

  const { onTabClose, dynamicBuild } = useContext(ParametersContext);
  const sqlFiles = useContext(SQLFilesContext);
  const tabs: QueryTabItem[] | undefined = sqlFiles?.map((file) => ({
    key: file.uuid,
    name: file.filename,
    sql: file.content,
    updated: !file.saved
  }));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!newTabKey.current) return;
    const founded = sqlFiles?.filter((file) => file.uuid === newTabKey.current);
    if (founded) {
      activeTab.toggle(newTabKey.current);
      newTabKey.current = null;
    }
  }, [activeTab, sqlFiles]);

  /**
   * Handlers
   */
  const addNewQuery: MouseEventHandler<HTMLButtonElement> = () => {
    let filename;
    if (tabs === undefined || tabs.length === 0) {
      filename = 'Query 1';
    } else {
      const defaultRegex = /Query [1-9]*/;
      let maxValue = 0;
      // eslint-disable-next-line no-plusplus
      for (let i = tabs.length - 1; i >= 0; i--) {
        const { name } = tabs[i];
        if (defaultRegex.test(name)) {
          const [, idxStr] = name.split(' ');
          const idx = parseInt(idxStr, 10);
          if (Number.isInteger(idx)) {
            maxValue = Math.max(maxValue, idx);
          }
        }
      }
      filename = `Query ${maxValue + 1}`;
    }

    const uuid = StringUtils.uuid();
    db.sqlFile.add({
      uuid,
      filename,
      content: '',
      parameters: '[]'
    }).then((key) => {
      newTabKey.current = key.toString();
    });
  };

  const closeTab = (tabKey: string): void => {
    sqlFileDB.sqlFile.delete(tabKey);
  };

  const handleClose = (tabKey: string): void => {
    const target = _.find(tabs, (item) => item.key === tabKey);
    if (!target) {
      // ERROR
      return;
    }

    onTabClose(tabKey);
    closeTab(tabKey);
  };

  const onTabChange = (key: string) => {
    activeTab.toggle(key);
    setTimeout(() => {
      EventManager.emit(`${EditorInsert}:${key}`, {
        method: 'focus'
      });
    }, 50);
  };

  const onSave = useCallback((tabKey: string, fromShortcut?: boolean) => {
    const targetFile = sqlFiles?.find((file) => file.uuid === tabKey);
    if (!targetFile) return;

    const onComplete = (result: TaskResponse<Partial<CreateQueryData>[]>, onlySave?: boolean) => {
      const data = result.data[0];
      const queryId = data.id;
      if (!queryId) return;
      dispatch(updateTrinoQueriesList());
      if (onlySave) {
        sqlFileDB.sqlFile.update(targetFile.uuid, {
          saved: true
        });
      } else {
        sqlFileDB.sqlFile.update(targetFile.uuid, {
          filename: data.name,
          description: data.description,
          saved: true,
          queryId
        });
      }
    };

    // 点击save按钮 or cmd+s且文件未创建
    if (!fromShortcut || !targetFile.queryId) {
      const modal = Modal.info({
        title: t(`archivedQueries.form.${targetFile.queryId ? 'updateTitle' : 'createTitle'}`, { ns: 'archivedQueries' }),
        className: 'archived-queries-modal',
        footer: null,
        content: <SaveModal
          toSave={targetFile}
          onCancel={() => modal.destroy()}
          onComplete={onComplete}
        />
      });
    } else { // cmd+s文件已创建，直接保存
      const taskClient = new TaskApi();

      const parameters = (JSON.parse(targetFile.parameters) as Parameter[]).map((val) => ({
        key: val.name,
        type: val.dataType,
        value: val.defaultValue
      }));

      taskClient.updateQuery({
        id: targetFile.queryId,
        sql: parseSql(targetFile.content),
        parameters
      }).then((r) => {
        const result = r.data;
        if (result.message !== 'success') {
          Toast.error({
            content: result.message,
            showClose: false
          });
        } else {
          Toast.success({
            content: result.message,
            showClose: false
          });
          onComplete(result, true);
        }
      }).catch((e) => {
        Toast.error(e?.response?.data?.message ?? 'Failed to save');
      });
    }
  }, [dispatch, sqlFiles, t]);

  const onSaveClick = useCallback(async (tabKey: string) => {
    onSave(tabKey, false);
  }, [onSave]);

  /**
   * 编辑器挂载的时候只会绑定useCallback返回的第一个函数
   * 使用nonce同步执行
   */
  const [saveNonce, setSaveNonce] = useState(0);
  const prevSaveNonce = useRef(0);

  const onSaveShortcut = () => {
    setSaveNonce((prev) => prev + 1);
  };

  useEffect(() => {
    if (saveNonce === 0) return;
    if (prevSaveNonce.current === saveNonce) return;
    prevSaveNonce.current = saveNonce;

    if (tabActiveKey) {
      onSave(tabActiveKey, true);
    }
  }, [onSave, saveNonce, tabActiveKey]);

  const debounceSave = useMemo(() => _.debounce((value?: string) => {
    if (tabActiveKey) {
      const newList = dynamicBuild(value ?? '');
      sqlFileDB.sqlFile.update(tabActiveKey, {
        content: value ?? '',
        saved: false,
        parameters: JSON.stringify(newList)
      });
    }
  }, 500), [dynamicBuild, tabActiveKey]);

  const renderSaveButton = () => (
    <button
      className="p-0 mx-[16px] px-2 h-6 flex flex-row items-center justify-center gap-2 border-solid border-[1px] border-[#E3E7EC] bg-[#FAFBFC] rounded-[4px] hover:border-[#D1D7DD] hover:bg-[#F6F8FA] absolute top-2 right-0"
      type="button"
      onClick={() => {
        if (activeTab?.current) {
          onSaveClick(activeTab?.current);
        }
      }}
    >
      <Icon icon="dataCloud/sqlStudio/editor/save-button" />
      <span className="text-xs">
        {t('common.save')}
      </span>
    </button>
  );

  const renderTabBarExtraContent = (): React.ReactNode => (
    <div className="flex gap-2 -mr-[35px]">
      {renderSaveButton()}
    </div>
  );

  const renderTabBar = (tabBarProps: TabBarProps): React.ReactElement => (
    <EditorTabBar
      tabBarProps={tabBarProps}
      onAddQuery={addNewQuery}
    />
  );

  const renderSingleTab = (query: QueryTabItem): React.ReactElement => (
    <div className="flex justify-start -ml-5 items-center">
      <span className="flex">
        <Icon icon="dataCloud/sqlStudio/editor/tab-logo" />
      </span>
      <span className="ml-2">
        {query?.name}
      </span>
      <span className="ml-[4px] relative top-[2px] opacity-95">{query?.updated ? '*' : ''}</span>
    </div>
  );

  return (
    <Tabs
      className="sql-editor-tabs"
      type="card"
      activeKey={tabActiveKey}
      collapsible
      onTabClose={handleClose}
      tabPaneMotion={false}
      onChange={onTabChange}
      tabBarExtraContent={renderTabBarExtraContent()}
      renderTabBar={renderTabBar}
    >
      {
          _.map(tabs, (item) => (
            <TabPane
              key={item.key}
              closable
              tab={renderSingleTab(item)}
              icon="1"
              itemKey={item.key}
              className="h-full bg-[#191E21]"
            >
              <CodeEditor
                editorKey={item.key}
                language="Trino"
                value={item.sql}
                onChange={(value) => debounceSave(value)}
                onSave={onSaveShortcut}
              />
            </TabPane>
          ))
        }
    </Tabs>
  );
}

export default function SQLEditor(): React.ReactElement {
  const tablePrompt = useAppSelector((state) => state.dataCloudStudio.tablePrompt);

  const transfer = (item: TablePrompt): TableSchema => ({
    schema_type: 'table',
    name: item.table,
    description: '',
    table_name: item.table,
    schema: [],
    columns: item.column.map((row, index) => ({
      schema_type: 'column',
      name: row.name,
      index,
      type: row.dataType
    }))
  });

  const tableList = _.map(_.flatten(_.filter(tablePrompt.tables, (item) => !!item)), transfer);
  if (tablePrompt.loading) {
    return (
      <>
        <div className="h-[52px]" />
        <div className="bg-[#191E21] h-[calc(100%-52px)]">
          <BlockLoading />
        </div>
      </>
    );
  }

  return <Main tableList={tableList} />;
}
