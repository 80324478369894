import React, { useState } from 'react';
import { useAuth } from '@/config/auth/AuthProvider';
import { Button, Popover } from '@douyinfe/semi-ui';
import classNames from 'classnames';
import { Icon, Toast } from '@/components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AccountApi from '@/api/AccountApi';
import _ from 'lodash';

type MenuItem = {
  name: string,
  icon: string,
  onClick: () => void
}

function Menu(): React.ReactElement {
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation('translation');

  const handleLogout = async (): Promise<void> => {
    const response = await AccountApi.logout();
    if (response.data.code === 200) {
      auth.setCurrentUser(null);
    }

    navigate('/login');
    Toast.toastResponse(response);
  };

  const menu: MenuItem[] = [
    {
      name: t('common.settings'),
      icon: 'navigation/settings',
      onClick: () => {
        navigate('/settings/billing');
      }
    },
    {

      name: t('common.logout'),
      icon: 'navigation/logout',
      onClick: handleLogout
    }
  ];

  return (
    <div className={classNames('flex-column', 'menu', 'relative')}>
      {
        _.map(menu, (item) => (
          <Button
            key={item.name}
            theme="borderless"
            type="secondary"
            className="btn-menu-item"
            onClick={() => {
              item?.onClick();
            }}
            icon={<Icon icon={item.icon} className="icon" />}
          >
            {item.name}
          </Button>
        ))
      }
      <div className="w-4 h-4 absolute -left-[6px] top-6">
        <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M-2.62268e-07 6L6 0.803848L6 11.1962L-2.62268e-07 6Z" fill="#252B2E" />
        </svg>
      </div>
    </div>
  );
}

export default function UserMenu(): React.ReactElement {
  const { user } = useAuth();
  const [visible, setVisible] = useState(false);

  if (!user) {
    return <div />;
  }

  return (
    <Popover
      content={<Menu />}
      clickToHide
      visible={visible}
      position="rightBottom"
      trigger="click"
      className="menu-popover bg-[#252B2E]"
      onVisibleChange={setVisible}
    >
      <div
        className={`flex border-t border-solid border-[#252B2E] py-6 pr-5 pl-[27px] cursor-pointer
         transition-all ${visible ? 'bg-[#373F46]' : 'bg-[#191E21]'} relative hover:bg-[#373F46]`}
      >
        <Icon icon="user/default-avatar" className="w-[30px] h-[30px] mr-2" />
        <div className="flex-1 overflow-hidden">
          <p className="text-[#D1D7DD]">{user.username}</p>
          <p className="text-[#76808A] overflow-hidden text-ellipsis">{user.email}</p>
        </div>

        <Icon
          icon="common/arrow-left-triangle"
          className={`text-[#A1AAB0] absolute top-6 right-4 transition-all ${visible ? 'rotate-180' : ''}`}
        />
      </div>
    </Popover>
  );
}
