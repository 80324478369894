import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useLocation, useNavigate, Location, useSearchParams
} from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { Form } from '@douyinfe/semi-ui';
import {
  ButtonLoading, Card, HeaderTitle, AuthToast
} from '@/components';
import AccountApi from '@/api/AccountApi';
import { useLoadingCallback } from '@/utils';
import { CommonRegExp } from '@/config/constant';
import './index.scss';
import { useAuth } from '@/config/auth/AuthProvider';
import InputGroup from './InputGroup';
import OrLine from './OrLine';
import WalletLogin from './WalletLogin';

function Login(): React.ReactElement {
  const auth = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { t } = useTranslation();
  const prevLocation = location.state as {
    from: Location | null;
  };

  const getRedirectUrl = (): string => prevLocation?.from?.pathname || '/';

  const target = getRedirectUrl();

  const { callback: handleLogin, loading } = useLoadingCallback(async (type: 'phone' | 'wallet', data) => {
    const InvokeFunction = type === 'wallet' ? AccountApi.loginByWallet : AccountApi.login;
    const response = await InvokeFunction(data);

    const code = response?.data?.code;

    if (code === 100224) {
      navigate('/register');
    } else if (code === 200) {
      const userInfoResponse = await AccountApi.currentUserInfo();

      const { account } = userInfoResponse.data;

      auth?.setCurrentUser(account);

      if (!!account && auth !== null) {
        // login success to home page
        const redirectUrl = searchParams?.get('redirectUrl');
        if (redirectUrl) {
          navigate(redirectUrl, { replace: true });
        } else {
          navigate(target, { replace: true });
        }
      }
    } else {
      AuthToast.showResponseMessage(response);
    }
    return response;
  });

  const handleSubmit = async (record: any): Promise<void> => {
    await handleLogin('phone', {
      username: record.email,
      password: CryptoJS.MD5(record.password).toString()
    });
  };

  const handleLoginByWallet = async (account: string | undefined, sign: string): Promise<void> => {
    await handleLogin('wallet', {
      address: account,
      signature: sign
    });
  };

  return (
    <div className="login">
      <HeaderTitle title={t('login.headerTitle')} />
      <Form onSubmit={handleSubmit}>
        <Card>
          <div>
            <WalletLogin onAccountChange={handleLoginByWallet} />
          </div>
          <OrLine />

          <div className="login-form">
            <InputGroup
              autoFocus
              name="email"
              type="text"
              label={t('login.emailPlaceholder')}
              rules={[
                {
                  type: 'string',
                  required: true,
                  pattern: CommonRegExp.email,
                  message: 'email format error'
                }
              ]}
            />
            <InputGroup
              name="password"
              type="password"
              label={t('login.passwordPlaceholder')}
              rules={[
                {
                  type: 'string',
                  required: true
                }
              ]}
              assist={(
                <a href="/reset" className="btn-forget medium" tabIndex={-1}>
                  {t('login.forgetPasswordPlaceholder')}
                </a>
              )}
            />
            <ButtonLoading size="large" className="btn-enhance uppercase bolder" htmlType="submit" loading={loading}>
              {t('login.loginButton')}
            </ButtonLoading>
          </div>
        </Card>
      </Form>

      <div className="btn-signup medium">
        {t('login.newUser')}
        <a href="/register">{t('login.signUpPlaceholder')}</a>
      </div>
    </div>
  );
}

export default Login;
