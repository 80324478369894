import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SplitPane, { Pane } from 'react-split-pane';
import _ from 'lodash';
import { loader } from '@monaco-editor/react';
import * as monaco from 'monaco-editor';
import { useAppDispatch } from '@/store/hooks';
import { dataCloudActions } from '@/store/reducers/dataCloudReducer';
import { ArchivedQueryItem } from '@/props';
import { StringUtils } from '@/utils';
import { HeaderTitle } from '@/components';
import NavHeader from '@/components/Layout/NavHeader';
import VersionSwitch from '@/views/dataCloudStudio/components/VersionSwitch';
import TableNavigation from './TableNavigation';
import Content from './Content';
import './index.scss';
import { QueryTabItem } from './SQLEditor';

loader.config({ monaco });

export default function DataCloudTerminal(): React.ReactElement {
  const { t } = useTranslation();
  const calcSize = (): any => ({
    minSize: 385,
    size: 435,
    maxSize: window.window.innerWidth / 2
  });

  const [size, setSize] = React.useState(calcSize());

  const location = useLocation();
  const dispatch = useAppDispatch();
  const query = location.state as ArchivedQueryItem;

  React.useEffect(() => {
    const tabs = _.map<ArchivedQueryItem, QueryTabItem>(
      query ? [query] : [],
      (item: ArchivedQueryItem): QueryTabItem => ({
        key: item?.uuid || StringUtils.uuid(),
        id: item.uuid,
        name: item.title || '',
        sql: item.content || '',
        updated: false
      })
    );
    const urlSearchParams = new URLSearchParams(location.search);

    if (tabs.length === 0) {
      const sql = urlSearchParams.get('sql');
      const name = urlSearchParams.get('name');
      if (sql) {
        tabs.push({
          key: StringUtils.uuid(),
          id: StringUtils.uuid(),
          name: name || 'Query 1',
          sql: sql || '',
          updated: false
        });
        urlSearchParams.delete('sql');
        urlSearchParams.delete('name');
      }
    }

    // clear location state
    window.history.replaceState(
      {
        idx: window.history?.state?.idx
      },
      document.title,
      `${window.location.pathname}${
        urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : ''
      }`
    );

    dispatch(dataCloudActions.init({ tabs, from: 'v1' }));

    if (tabs.length > 0) {
      setTimeout(() => {
        const el = document.getElementById('code-editor__format');
        if (el) {
          el?.click();
        }
      }, 2000);
    }

    const resizeCallback = (): void => {
      setSize(calcSize());
    };

    window.addEventListener('resize', resizeCallback);

    return () => {
      window.removeEventListener('resize', resizeCallback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, dispatch]);

  return (
    <div className="terminal-container">
      <HeaderTitle title={t('dataCloud.name')} />
      <NavHeader
        routers={[t('dataCloud.name')]}
        afterBreadcrumb={<VersionSwitch version="v1" />}
      />

      <div className="panel-wrapper">
        <SplitPane
          split="vertical"
          size={size.size}
          minSize={size.minSize}
          maxSize={size.maxSize}
        >
          <Pane className="navigation-pane">
            <TableNavigation />
          </Pane>
          <Pane className="content-pane">
            <Content />
          </Pane>
        </SplitPane>
      </div>
    </div>
  );
}
