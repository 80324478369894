import React from 'react';
import { Alter, Toast } from '@/components';
import { ModalFooter } from '@/components/Modal';
import { useTranslation } from 'react-i18next';
import { useLoadingCallback } from '@/utils';
import request, { AxiosResponse } from 'axios';
import { Response } from '@/model';
import './DeleteModal.scss';
import { includes } from 'lodash';

export default function DeleteModal(props: Omit<{
  title: string,
  onDelete: () => Promise<AxiosResponse<Response>>,
  onClose: (isDelete?: boolean) => void,
}, ''>): React.ReactElement {
  const { t } = useTranslation('translation');
  const {
    title,
    onClose,
    onDelete
  } = props;

  const {
    loading,
    callback: handleDelete
  } = useLoadingCallback(async () => {
    try {
      const response = await onDelete();
      if (includes([0, 200], response.data.code)) {
        onClose(true);
      }
    } catch (e) {
      if (request.isAxiosError(e) && e.response) {
        Toast.toastResponse(e?.response);
      }
    }
  });

  return (
    <div className="delete-wrapper">
      <p className="tip">
        {title}
      </p>
      <Alter type="danger" className="undone-tip">
        {t('common.deleteUndoneTip')}
      </Alter>

      <ModalFooter
        okButtonProps={
          {
            theme: 'light'
          }
        }
        loadingType="danger"
        confirmLoading={loading}
        onOk={handleDelete}
        okText={t('common.delete')}
        okType="danger"
        onCancel={() => onClose()}
      />
    </div>
  );
}
