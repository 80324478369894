import _ from 'lodash';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import DataCloudStudioApi from '@/api/DataCloudStudioApi';
import { TablePrompt } from '@/model/DataCloudStudio';

type StateProps = {
  tablePrompt: {
    loading: boolean;
    tables: TablePrompt[];
  }
};

const initialState: StateProps = {
  tablePrompt: {
    loading: false,
    tables: []
  }
};

export const fetchTablePrompt = createAsyncThunk(
  'dataCloudStudio/fetchTablePrompt',
  async () => {
    const response = await DataCloudStudioApi.getDefaultPrompt();
    const { data: tables } = response.data;
    return tables;
  }
);

export const dataCloudStudioReducer = createSlice({
  name: 'DataCloudStudioSlice',
  initialState,
  reducers: {
    appendTables: (state, action: PayloadAction<TablePrompt[]>) => {
      state.tablePrompt.tables = _.uniqBy([...state.tablePrompt.tables, ...action.payload], 'table');
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTablePrompt.pending, (state) => {
      state.tablePrompt.loading = true;
    }).addCase(fetchTablePrompt.fulfilled, (state, action) => {
      state.tablePrompt.loading = false;
      state.tablePrompt.tables = _.uniqBy([...state.tablePrompt.tables, ...action.payload], 'table');
    }).addCase(fetchTablePrompt.rejected, (state) => {
      state.tablePrompt.loading = false;
    });
  }
});

export default dataCloudStudioReducer.reducer;
