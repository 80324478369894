import { useNavigate, useSearchParams } from 'react-router-dom';
import { IntegrationConfigMapping } from '@/config/sync';
import { FeatureName, useWhiteList } from '@/hooks/UseWhiteList';
import IntegrationSelector from '../commons/IntegrationSelector';
import IntegrationEditPageHeader from '../commons/IntegrationEditPageHeader';
import IntegrationConfiguration from '../commons/IntegrationConfiguration';
import './index.scss';

const IntegrationParamKey = 'integration';

export default function IntegrationCreation() {
  const [searchParams, setSearchParams] = useSearchParams();
  const nav = useNavigate();
  const integrationType = searchParams.get(IntegrationParamKey);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const { checkWhitelist, openRequestModal } = useWhiteList(FeatureName.sync_service, { rollback: false });

  const noEffectiveIntegrationType = !integrationType || !(integrationType.toLowerCase() in IntegrationConfigMapping);

  if (noEffectiveIntegrationType) {
    return (
      <div className="integration-creation-container">
        <IntegrationEditPageHeader />
        <div className="new-integration-selector-cotainer">
          <IntegrationSelector onItemClick={(item) => {
            if (checkWhitelist()) {
              setSearchParams({ [IntegrationParamKey]: item.name });
            } else {
              openRequestModal();
            }
          }}
          />
        </div>
      </div>
    );
  }

  const config = IntegrationConfigMapping[integrationType.toLowerCase()];

  const handleCreated = (integrationId: string) => {
    nav(`/sync/integrations/${integrationId}`);
  };

  return (
    <div className="integration-creation-container">
      <IntegrationEditPageHeader integrationConfig={config} />
      <div className="content-wrapper">
        <IntegrationConfiguration
          configType="create"
          onCancel={() => nav(-1)}
          onCreated={handleCreated}
          formTemplate={config}
        />
      </div>
    </div>
  );
}
