import { AxiosResponse } from 'axios';
import Request from './Request';

export default class DashboardApi {
  static getDashboardInfo(data: any): Promise<AxiosResponse> {
    return Request.get('/v1/dashboard/info', {
      params: data
    });
  }
}
