import { ReactNode } from 'react';
import APIGeneratorProvider from './components/APIGeneratorProvider';
import ActiveTabProvider from './components/ActiveTabProvider';
import ParametersProvider from './components/ParametersProvider';
import SQLFilesProivder from './components/SQLFilesProvider';
import EditorRefProvider from './components/EditorRefProvider';
import QueryTriggerProvider from './components/QueryTriggerProvider';
import './index.scss';

const ProviderList = [
  QueryTriggerProvider,
  ActiveTabProvider,
  EditorRefProvider,
  SQLFilesProivder,
  ParametersProvider,
  APIGeneratorProvider
];

export function DataCloudProviders({ children }: {children: ReactNode}) {
  return (
    <div className="terminal-container relative">
      {ProviderList.reduceRight((acc, Provider) => <Provider>{acc}</Provider>, children)}
    </div>
  );
}
