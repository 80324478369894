import React, { CSSProperties } from 'react';
import './index.scss';
import classNames from 'classnames';

export const LoadingTypeConfig = {
  primary: {
    front: '#FFF',
    backend: 'rgba(255, 255, 255, 0.13)'
  },
  grey: {
    front: '#1E1E1E',
    backend: '#F0F0F0'
  },
  info: {
    front: 'var(--v2-color-blue600)',
    backend: 'var(--v2-color-grey300)'
  },
  danger: {
    front: 'var(--semi-color-danger)',
    backend: '#F0F0F0'
  }
};

export declare type LoadingTypes = 'primary' | 'info' | 'grey' | 'danger' | undefined;

declare interface LoadingProps {
  type?: LoadingTypes;
  centered?: boolean;
  className?: string;
}

const defaultProps: LoadingProps = {
  type: 'primary',
  centered: false
};

export default function Loading({
  type = 'primary',
  centered,
  className
} = defaultProps): React.ReactElement {
  const color = LoadingTypeConfig[type];

  return (
    <div
      className={classNames('loading', type, { centered }, className)}
      style={{
        borderColor: color.backend,
        borderTopColor: color.front
      } as CSSProperties}
    />
  );
}
