/* eslint-disable react/jsx-props-no-spreading */
import { Table } from '@/components';
import { ColumnProps } from '@douyinfe/semi-ui/table/interface';
import React from 'react';
import './index.scss';

interface SyncTableProps {
  dataSource: any;
  columnProps: ColumnProps[];
  containerClassName?: string;
  onRowClick?: (record: Record<string, any>) => void;
  resizable?: boolean;
}

export default function SyncTable({
  dataSource,
  columnProps,
  containerClassName,
  onRowClick,
  resizable
}: SyncTableProps): React.ReactElement {
  return (
    <div className={`sync-table-container ${containerClassName} ${onRowClick ? 'clickable' : ''}`}>
      <Table
        resizable={resizable}
        dataSource={dataSource}
        columns={columnProps}
        onRow={(record) => ({
          onClick: () => {
            if (onRowClick) {
              onRowClick(record);
            }
          }
        })}
      />
    </div>
  );
}
